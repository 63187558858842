<template>
  <v-container
    fluid
    class="py-1"
  >
    <v-row dense>
      <v-col
        cols="auto"
        style="position: absolute; right: 0; top: 0;"
      >
        <v-btn
          small
          icon
          elevation="1"
          :disabled="gettingOrderBookings"
          @click="getBookings"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        style="font-weight: 500; font-size: 16px;"
        class="text-left"
      >
        {{ $t('order.bookings.title') }} <div
          v-if="gettingOrderBookings"
          style="font-weight: 400; font-size: 12px;"
        >
          {{ $t('order.bookings.pleaseWait') }}
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="gettingOrderBookings"
      dense
    >
      <v-col
        cols="12"
        class="overflow-y-auto"
        style="max-height: 20vh;"
      >
        <template v-for="i in bookingLineItems.length">
          <v-row
            :key="i"
            dense
            align="center"
            justify="space-between"
          >
            <v-col
              cols="12"
              md="6"
            >
              <v-skeleton-loader
                type="list-item-avatar-three-line"
                tile
              />
            </v-col>
            <v-col
              cols="12"
              md="auto"
            >
              <v-skeleton-loader
                type="button"
                class="mx-auto"
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-row
      v-else
      dense
      justify="center"
    >
      <v-col
        v-if="Array.isArray(bookings) && bookings.length < 1"
        cols="auto"
      >
        {{ $t('order.bookings.none') }}
      </v-col>
      <template v-for="booking in bookings">
        <v-col
          :key="booking.uuid"
          cols="12"
        >
          <v-card>
            <v-list
              class="overflow-y-auto"
              style="max-height: 20vh;"
            >
              <v-list-item>
                <v-list-item-avatar
                  v-if="$vuetify.breakpoint.width > 320"
                  tile
                  style="border-radius: 4px;"
                >
                  <v-img
                    :src="bookingImage(booking)"
                    class="grey"
                    style="border-radius: 4px;"
                  />
                </v-list-item-avatar>
                <v-list-item-content style="text-align: left;">
                  <v-list-item-title>
                    {{ $t('order.bookings.booking') }} #{{ booking.booking_id }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ bookingTitle(booking) }} {{ (booking.quantity && booking.quantity > 1) ? 'x' + booking.quantity : '' }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ $t('order.bookings.startsAt') }}: {{ bookingStartsAt(booking) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action v-if="$vuetify.breakpoint.width > 460">
                  <v-btn
                    large
                    color="success"
                    depressed
                    @click="fetchTicket(booking)"
                  >
                    <span>
                      {{ $t('button.fetchTicket') }}
                    </span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-btn
              v-if="$vuetify.breakpoint.width <= 460"
              block
              @click="fetchTicket(booking)"
            >
              <span>
                {{ $t('button.fetchTicket') }}
              </span>
            </v-btn>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: "OrderBookings",
    props: {
        bookingLineItems: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            // gettingOrderBookings: false,
            // bookings: []
        }
    },
    computed: {
        gettingOrderBookings: {
            get() {
                return this.$store.getters.gettingOrderBookings
            },
            set(status) {
                this.$store.commit('updateGettingOrderBookings', status)
            }
        },
        primaryColor() {
            return this.$store.getters.primaryColor
        },
        bookings() {
            return this.$store.getters.orderBookings
        },
        organizationUUID() {
            return this.$store.state.organizationUUID
        }
    },
    mounted() {
        this.getBookings()
    },
    methods: {
        getBookings() {
            this.gettingOrderBookings = true
            this.$store.dispatch('getOrderBookings', 0)
        },
        bookingImage(booking) {
            if (booking !== null && booking !== undefined) {
                if (booking.item !== null && booking.item !== undefined) {
                    if (Array.isArray(booking.item.images)) {
                        const firstImage = booking.item.images[0]
                        if (firstImage.file !== null && firstImage.file !== undefined) {
                            if (firstImage.file.sources !== null && firstImage.file.sources !== undefined) {
                                if (firstImage.file.sources.medium !== null && firstImage.file.sources.medium !== undefined) {
                                    return firstImage.file.sources.medium
                                }
                            }
                        }
                    }
                }
            }
            return ''
        },
        bookingTitle(booking) {
            if (booking !== null && booking !== undefined) {
                if (booking.item !== null && booking.item !== undefined) {
                    return booking.item.title
                }
            }
            return ''
        },
        fetchTicket(booking) {
            if (this.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223') {
                if (booking.externalDistributionUrl !== null && booking.externalDistributionUrl !== undefined) {
                    open(booking.externalDistributionUrl, '_blank')
                } else {
                    return
                }
            }
            open('https://ticket.salescloud.is?uuid=' + booking.uuid, '_blank')
        },
        bookingStartsAt(booking) {
            if (booking !== null && booking !== undefined) {
                if (booking.startsAtTime !== null && booking.startsAtTime !== undefined) {
                    let startsAtTime = booking.startsAtTime
                    if ((startsAtTime + '').length === 10) {
                        startsAtTime = startsAtTime * 1000
                    }
                    let startDate = new Date(startsAtTime).toISOString()
                    return startDate.substr(0, 10) + ' ' + startDate.substr(11, 5)
                }
            }
            return ''
        }
    }
}
</script>

<style scoped>

</style>
