<template>
  <v-dialog
    :value="true"
    persistent
  >
    <v-card>
      <v-card
        tile
        :color="primaryColor"
        :class="textColor(primaryColor)"
        flat
      >
        <v-card-title>
          {{ welcomeMessageHeader }}
        </v-card-title>
      </v-card>
      <v-card-text class="pt-6 text-justify">
        {{ welcomeMessageText }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          x-large
          :color="accentColor"
          :class="textColor(accentColor)"
          @click="close"
        >
          <span>
            {{ $t('buttons.close') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	name: "WelcomeMessage",
	computed: {
		organizationUUID() {
			return this.$store.state.organizationUUID
		},
		channelUUID() {
			return this.$store.state.channelUUID
		},
		locationUUID() {
			return this.$store.state.locationUUID
		},
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		welcomeMessageHeader() {
			return this.$store.state.welcomeMessageHeader
		},
		welcomeMessageText() {
			return this.$store.state.welcomeMessageText
		}
	},
	methods: {
		textColor(color) {
			return this.$store.getters.textColor(color)
		},
		close() {
			if(!this.inIframe()) {
				window.localStorage.setItem(this.organizationUUID + ':' +this.channelUUID + ':' + this.locationUUID +':showWelcomeMessage', 'false')
			}
			this.$emit('closed')
		},
		inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
	}
}
</script>

<style scoped>

</style>
