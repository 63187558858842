import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VBottomSheet,{attrs:{"scrollable":"","value":true,"persistent":""}},[_c(VCard,{attrs:{"tile":"","flat":""}},[_c(VCardTitle,{staticClass:"py-2 white--text",style:('background-color:' + _vm.primaryColor + ';')},[_vm._v(" "+_vm._s(_vm.checkoutSectionTitle)+" ")]),_c(VCardText,{staticClass:"py-3"},[(_vm.showScrollButton)?_c('div',{staticStyle:{"position":"absolute","right":"16px","bottom":"75px"}},[_c('div',[_c(VBtn,{attrs:{"fab":"","small":"","color":"rgba(0,0,0,0.5)","dark":""},on:{"click":_vm.scrollToInput}},[_c(VIcon,[_vm._v(" mdi-chevron-down ")])],1)],1)]):_vm._e(),_vm._l((_vm.checkoutSectionTexts),function(text,index){return [_c('div',{key:index,domProps:{"innerHTML":_vm._s(text)}})]}),_c('div',{ref:"section-input",attrs:{"id":"section-input"}},[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_vm._l((_vm.checkoutSectionUIFields),function(field){return [(field.key !== 'html' && field.key !== 'vhtml')?_c('UiField',{key:field.key,attrs:{"field":field},on:{"fieldValueChange":_vm.fieldValueChange}}):_vm._e()]})],2)],1)],2),_c(VCardActions,{staticClass:"pa-0"},[_c(VContainer,{staticClass:"pa-2"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,[_c(VBtn,{attrs:{"tile":"","x-large":"","block":"","color":"error","depressed":""},on:{"click":_vm.back}},[_c(VIcon,[_vm._v(" mdi-chevron-left ")]),_c(VSpacer),_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")],1)],1),_c(VCol,[_c(VBtn,{attrs:{"tile":"","x-large":"","block":"","color":"success","depressed":"","disabled":!_vm.valid},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t('buttons.confirm'))+" "),_c(VSpacer),_c(VIcon,[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }