<template>
  <v-card tile>
    <v-card
      tile
      dark
      :color="primaryColor"
      flat
    >
      <v-card-title>
        {{ paymentInstance.title }}
        <v-spacer />
        <v-btn
          dark
          :color="accentColor"
          @click="back"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text
      class="overflow-y-auto"
      :style="$vuetify.breakpoint.height < 700 ? 'max-height: 65vh' : ''"
    >
      <v-container fluid>
        <v-form v-model="valid">
          <v-row
            v-if="showCustomerInfo"
            dense
          >
            <v-col cols="12">
              <v-text-field
                v-model="customerName"
                :label="$t('customer.name') + (checkIfRequired(billingMinimal, 'name') ? ' *' : ' (' + $t('optional') + ')')"
                outlined
                :color="primaryColor"
                :rules="checkIfRequired(billingMinimal, 'name') ? [rules.required] : []"
                name="name"
                autocomplete="name"
                hide-details="auto"
                :disabled="paymentInProgress"
              />
            </v-col>

            <v-col
              v-if="showCustomerInfo"
              cols="12"
            >
              <v-text-field
                v-model="customerPhone"
                :label="$t('customer.phone') + (checkIfRequired(billingMinimal, 'phone') ? ' *' : ' (' + $t('optional') + ')')"
                outlined
                :color="primaryColor"
                type="number"
                :rules="checkIfRequired(billingMinimal, 'phone') ? [rules.required] : []"
                name="phone"
                hide-details="auto"
                autocomplete="tel-national"
                :disabled="paymentInProgress"
              />
            </v-col>

            <v-col
              cols="12"
              v-if="showCustomerInfo"
            >
              <v-text-field
                v-model="customerEmail"
                :label="$t('customer.email') + (checkIfRequired(billingMinimal, 'email') ? ' *' : ' (' + $t('optional') + ')')"
                outlined
                :color="primaryColor"
                :rules="checkIfRequired(billingMinimal, 'email') ? [rules.required] : []"
                name="email"
                autocomplete="email"
                hide-details="auto"
                :disabled="paymentInProgress"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <template v-for="field in uiFields">
              <UiField
                v-if="field"
                :key="field.key"
                :field="field"
                @fieldValueChange="changeFieldValue"
              />
            </template>
          </v-row>
        </v-form>
      </v-container>
      <v-alert
        v-if="errorText"
        type="error"
        class="mb-0"
      >
        {{ $t('payment.fillWarning') }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
        :disabled="paymentInProgress"
        :loading="paymentInProgress"
        tile
        x-large
        height="65"
        block
        color="green"
        dark
        @click="pay"
      >
        <span>{{ $t('buttons.pay') }} {{ amountAndCurrencyAsText }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UiField from "../uiField";

export default {
	name: "PaymentMethodInstance",
	components: {
		UiField
	},
	props: {
		paymentInstance: {
            type: Object,
            default: () => ({})
        }
	},
	data() {
		return {
			errorText: false,
			valid: false,
			showCode: false,
			name: null,
      selectedPaymentInstance: null
		}
	},
  computed: {
    amountAndCurrencyAsText() {
      return this.$store.getters.currencyAsText(this.cartTotal)
    },
    uiFields() {
      if(this.selectedPaymentInstance && this.selectedPaymentInstance.uiFields && this.selectedPaymentInstance.uiFields.length > 0) {
        return this.selectedPaymentInstance.uiFields
      }
      return []
    },
    showCustomerInfo() {
      return this.$store.state.showCustomerInfo
    },
    customerName: {
      get() {
        return this.$store.state.customerName
      },
      set(newName) {
        newName = this.$store.getters.pruneEmojisFromText(newName)
        this.$store.commit('updateCustomerName', newName)
      }
    },
    customerEmail: {
			get() {
				return this.$store.state.customerEmail
			},
			set(email) {
				this.$store.commit('updateCustomerEmail', email)
			}
		},
    customerPhone: {
      get() {
        return this.$store.state.customerPhone
      },
      set(phone) {
        this.$store.commit('updateCustomerPhone', phone)
      }
    },
    primaryColor() {
      return this.$store.state.primaryColor
    },
    accentColor() {
      return this.$store.state.accentColor
    },
    paymentInProgress: function() {
      return this.$store.state.paymentInProgress
    },
    cartTotal: function() {
      return this.$store.getters.cartTotal
    },
    rules() {
      return this.$store.getters.rules
    },
    billingMinimal() {
      return this.$store.state.billingMinimal
    },
    paymentDetails() {
      if(this.uiFields) {
        return this.uiFields.reduce((obj, field) => {
          if(field) {
            if(field.key === 'cardNumber' && field.value !== null) {
              obj[field.key] = field.value.replace(/\D/g, '')
            }
            obj[field.key] = field.value
            return obj
          }
        }, {})
      }
      return null
    }
  },
	watch: {
		valid(value) {
			if(value) {
				this.errorText = false
			}
		},
    paymentInstance(value) {
      this.selectedPaymentInstance = value
    }
	},
  mounted() {
    this.selectedPaymentInstance = this.paymentInstance
  },
	methods: {
    changeFieldValue({value, key}) {
      const index = this.selectedPaymentInstance.uiFields.findIndex(field => field.key === key)
      // Handling if field key is not found
      if(index < 0) {
        return
      }
      this.selectedPaymentInstance.uiFields[index].value = value
      this.$emit('updatePaymentMethodUIFields', { index: index, value: value })
    },
		back() {
			this.$emit('back')
		},
		checkIfRequired(type, fieldName) {
			if(type.uiFields) {
				let found = false;
				type.uiFields.forEach(field => {
					if(field && field.key === fieldName && field.required === true) {
						found = true
					}
				})
				return found
			}
      return false
		},
		pay() {
			this.errorText = false

			if(!this.valid) {
				this.errorText = true
				return
			}

			const paymentRequest = {
				paymentInstance: this.selectedPaymentInstance,
				amount: this.cartTotal.amount,
				currency_code: this.cartTotal.currency_code,
				details: this.paymentDetails,
				payerName: this.customerName,
				payerPhone: this.customerPhone,
        payerEmail: this.customerEmail
			}

			this.$emit('pay', paymentRequest)
		},
		currencyFormat(value) {
			return this.$store.getters.currencyFormat(value)
		},
	}
}
</script>

<style scoped>

</style>
