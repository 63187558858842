<template>
  <v-card
    tile
    flat
  >
    <v-card
      tile
      dark
      :color="primaryColor"
    >
      <v-card-title>
        {{ $t('payment.cardDetails') }}
        <v-spacer />
        <v-btn
          dark
          :color="accentColor"
          @click="backFromFallbackForm"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text
      class="overflow-y-auto"
      :style="$vuetify.breakpoint.height < 700 ? 'max-height: 65vh' : ''"
    >
      <v-form v-model="filled">
        <v-row class="ma-0 pl-0 pr-0 pt-6">

          <v-col
            v-if="showCustomerInfo"
            cols="12"
            class="ma-0 pt-0 pb-0"
          >
            <v-text-field
              v-model="customerName"
              :label="$t('customer.name') + (checkIfRequired(billingMinimal, 'name') ? ' *' : ' (' + $t('optional') + ')')"
              outlined
              :color="primaryColor"
              :rules="checkIfRequired(billingMinimal, 'name') ? rules.required : []"
              name="name"
              autocomplete="name"
              :disabled="paymentInProgress"
            />
          </v-col>

          <v-col
            v-if="showCustomerInfo"
            cols="12"
            class="ma-0 pt-0 pb-0"
          >
            <v-text-field
              v-model="customerPhone"
              :label="$t('customer.phone') + (checkIfRequired(billingMinimal, 'phone') ? ' *' : ' (' + $t('optional') + ')')"
              outlined
              :color="primaryColor"
              type="number"
              :rules="checkIfRequired(billingMinimal, 'phone') ? rules.required : []"
              name="phone"
              autocomplete="tel-national"
              :disabled="paymentInProgress"
            />
          </v-col>

          <v-col
            cols="12"
            v-if="showCustomerInfo"
          >
            <v-text-field
              :label="$t('customer.email') + (checkIfRequired(billingMinimal, 'email') ? ' *' : ' (' + $t('optional') + ')')"
              filled
              :background-color="primaryColor"
              dark
              hide-details
              :rules="checkIfRequired(billingMinimal, 'email')"
              v-model="customerEmail"
              name="email"
              autocomplete="email"
            />
          </v-col>

        </v-row>
        <v-row class="ma-0 pl-0 pr-0">
          <v-col
            class="ma-0 pb-0"
            :class="showCustomerInfo ? 'pt-0' : ''"
          >
            <v-text-field
              ref="card_number"
              v-model="card_number"
              :disabled="paymentInProgress"
              name="cc-number"
              outlined
              :color="primaryColor"
              :label="$t('payment.cardNumber')"
              required
              autocomplete="cc-number"
              :rules="rules.required"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 pl-0 pr-0">
          <v-col class="ma-0 pt-0 pb-0">
            <v-select
              ref="exp_month"
              v-model="exp_month"
              :disabled="paymentInProgress"
              name="cc-exp-month"
              outlined
              :color="primaryColor"
              :label="$t('payment.expirationMonth')"
              :items="months"
              required
              autocomplete="cc-exp-month"
              :rules="rules.required"
            />
          </v-col>
          <v-col class="ma-0 pt-0 pb-0">
            <v-select
              ref="exp_year"
              v-model="exp_year"
              :disabled="paymentInProgress"
              name="cc-exp-year"
              outlined
              :color="primaryColor"
              :label="$t('payment.expirationYear')"
              :items="years"
              required
              autocomplete="cc-exp-year"
              :rules="rules.required"
            />
          </v-col>
        </v-row>
        <v-row class="ma-0 pl-0 pr-0">
          <v-col class="ma-0 pt-0 pb-0">
            <v-text-field
              ref="exp_cvc"
              v-model="cvc"
              :disabled="paymentInProgress"
              :append-icon="showCode ? 'mdi-eye' : 'mdi-eye-off'"
              :color="primaryColor"
              :type="showCode ? 'text' : 'password'"
              outlined
              :label="$t('payment.cvc')"
              required
              autocomplete="cc-csc"
              :rules="rules.required"
              @click:append="showCode = !showCode"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row
        v-if="errorText"
        class="ma-0 pl-0 pr-0"
      >
        <v-col class="ma-0 pt-0 pb-0 error--text">
          {{ $t('payment.fillWarning') }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
        :disabled="paymentInProgress"
        :loading="paymentInProgress"
        tile
        x-large
        height="65"
        block
        color="green"
        dark
        @click="payOrderFallback"
      >
        <span>{{ $t('buttons.pay') }} {{ amountAndCurrencyAsText }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "FallbackPaymentForm",
	data() {
		return {
			filled: false,
			errorText: false,
			showCode: false,
			rules: {
				required: [
					v => !!v || this.$t('payment.required')
				]
			}
		}
	},
	computed: {
		amountAndCurrencyAsText() {
			return this.$store.getters.currencyAsText(this.cartTotal)
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		accentColor() {
			return this.$store.getters.accentColor
		},
		showCustomerInfo() {
			return this.$store.state.showCustomerInfo
		},
		billingMinimal() {
			return this.$store.state.billingMinimal
		},
		customerName: {
			get() {
				return this.$store.state.customerName
			},
			set(name) {
				this.$store.commit('updateCustomerName', name)
			}
		},
    customerEmail: {
			get() {
				return this.$store.state.customerEmail
			},
			set(email) {
				this.$store.commit('updateCustomerEmail', email)
			}
		},
		customerPhone: {
			get() {
				return this.$store.state.customerPhone
			},
			set(phone) {
				this.$store.commit('updateCustomerPhone', phone)
			}
		},
		card_number: {
			get: function() {
				return this.$store.state.card_number
			},
			set: function(newValue) {
				return this.$store.commit('setCardNumber', newValue)
			}
		},
		exp_month: {
			get: function() {
				return this.$store.state.exp_month
			},
			set: function(newValue) {
				return this.$store.commit('setExpMonth', newValue)
			}
		},
		exp_year: {
			get: function() {
				return this.$store.state.exp_year
			},
			set: function(newValue) {
				return this.$store.commit('setExpYear', newValue)
			}
		},
		cvc: {
			get: function() {
				return this.$store.state.cvc
			},
			set: function(newValue) {
				return this.$store.commit('setCVC', newValue)
			}
		},
		paymentInProgress: function() {
			return this.$store.state.paymentInProgress
		},
		months: function() {
			return this.$store.getters.months
		},
		years: function() {
			return this.$store.getters.years
		},
		cartTotal: function() {
			return this.$store.getters.cartTotal
		},
	},
	methods: {
		backFromFallbackForm: function() {
			this.$store.commit('setFallbackPaymentForm', false)
		},
		checkIfRequired(type, fieldName) {
			if(type.uiFields) {
				let found = false;
				type.uiFields.forEach(field => {
					if(field.key === fieldName && field.required === true) {
						found = true
					}
				})
				return found
			} else {
				return false
			}
		},
		payOrderFallback: function() {
			// if(this.showCustomerInfoCard && !this.showCustomerInformationInput) {
			// 	this.$store.commit('updateShowCustomerInformationInput', true);
			// 	return
			// }

			if(!this.filled) {
				this.errorText = true
				return
			}

			this.$store.dispatch('validateBeforePayment').then(result => {
				if(result.success === false) {
					this.$store.commit('updateErrorMessage', result.message)
					this.$store.commit('updateErrorTitle', this.$t('errors.paymentPreprocessError'))
					this.$store.commit('updateShowBottomError', true)
					return
				}
				if(result.success === true) {
					this.$store.dispatch('processPaymentFallback', {
						amount: this.cartTotal.amount,
						currency_code: this.cartTotal.currency_code
					})
				}
			})
		},
		currencyFormat(value) {
			return this.$store.getters.currencyFormat(value)
		},
	}
}
</script>

<style scoped>

</style>
