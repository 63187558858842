import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';
import jsesc from 'jsesc';

import coupon from '@/store/coupon';
import currencies from '@/store/currencies';
import event from '@/store/event';

import i18n from '../i18n';

import payment from '@/store/payment';
import product from '@/store/item';
import translations from '@/store/translations';
import validations from '@/store/validations';
import variations from '@/store/variations';

Vue.use(Vuex);

/**
 * @property {Object} price
 * @property {String} uuid
 * @property {String} primary_color
 * @property {String} accent_color
 * @property {String} thumbnail
 * @property {Boolean} administrative
 * @property {Array} variations
 * @property {Array} default_variations
 * @property {String} systemTimeInMillis
 * @property {Object} opening_hours
 * @property {String} starthours
 * @property {String} endhours
 * @property {String} ept
 * @property {String} etags
 * @property {Number} sorting_weight
 * @property {Array} checkoutSectionInstanceList
 * @property sale_price
 * @property paymentMethodInstanceList
 * @property organizationTranslationsI18nFormatted
 */

// function iterate(category) {
// 	if(category) {
// 		if(!category.hasSubCategories) {
// 			return category.uuid
// 		} else {
//
// 		}
// 	}
// }

export default new Vuex.Store({
  state: {
    graphUrl: process.env.VUE_APP_GRAPH_URL ? process.env.VUE_APP_GRAPH_URL : 'https://graph.salescloud.is/?',
    paymentRequestUrl: 'https://service-payment-request.salescloud.is/v/1',
    itemsList: null,
    channelUUID: null,
    organizationUUID: null,
    paymentInstanceUUID: null,
    paymentInProgress: false,
    hasError: false,
    message: null,
    subMessage: null,
    unitID: null,
    storedUrlParams: null,
    sessionToken: null,
    organization: null,
    categories: null,
    itemsByCategories: {},
    channel: null,
    categoriesFromParams: [],
    paymentResponse: null,
    paymentMethod: null,
    paymentMethodInstanceOption: null,
    currentItemInFull: null,
    fallbackPaymentForm: false,
    cart: [],
    currencyType: 'ISK', //could be set from params
    loading: false,
    card_number: null,
    exp_year: null,
    exp_month: null,
    cvc: null,
    hideListPictures: false,
    primaryColor: null,
    accentColor: null,
    showSelectedItemVariationSelection: false,
    showItemDetails: false,
    hideItemDetails: false,
    selectedItem: null,
    attributes: [],
    showCart: false,
    singlePageCategories: true,
    singlePageVariations: true,
    missingItemVariationSelection: false,
    itemVariationCategoriesNotSelected: [],
    removeDefaultVariationsFromPrice: true,
    appBarHeight: null,
    appFooterHeight: null,
    selectCategoryHeight: null,
    categorySelectIndex: '000',
    categorySubIndex: 0,
    landingPageFromParams: false,
    showLandingPage: false,
    systemTime: null,
    locationUUID: null,
    location: null,
    defaultWaitTime: '900',
    showWaitingTime: false,
    showSubCategoryMenu: false,
    subCategoryFromParams: true,
    showCustomerInformationInput: false,
    customerName: null,
    customerEmail: null,
    customerPhone: null,
    customerComment: null,
    checkoutSectionList: null,
    showComment: false,
    showCustomerComment: false,
    showCustomerInfo: false,
    billingMinimal: null,
    orderComment: null,
    customerFilledInfo: false,
    language: 'is',
    selectableLanguages: [],
    locales: null,
    hideClosedCategories: false,
    showWelcomeMessage: false,
    welcomeMessageHeader: 'Welcome',
    welcomeMessageText: 'Welcome',
    currentTreeLocation: [],
    itemsByCategoriesNew: {},
    selectedCategory: null,
    selectedCategoryIndex: null,
    allCategories: [],
    sawItems: false,
    disableVariationSelection: false,
    showOffers: false,
    upsellCategory: null,
    upsellItems: [],
    defaultCountry: null,
    showOtherItems: false,
    includeItemsInCategories: [],
    excludeItemsInCategories: [],
    excludeCategories: [],
    localeFromParam: null,
    giftCardPaymentAvailable: false,
    showGiftCardInput: false,
    paymentInstances: [],
    showBottomError: false,
    errorMessage: '',
    errorTitle: '',
    errorSubMessage: '',
    organizationSettings: null,
    removeDefaultVariationsInParams: false,
    lastErrorInFull: null,
    limitCategoryOpeningHoursToParamCategories: true,
    upsellAllowed: false,
    agreedToAgeRestrictions: false,
    previewTimestamp: null,
    showAddGuestsToTableButton: false,
    additionalCartItemUUIDs: [],
    additionalCartItems: [],
    additionalCartItemsQuantityOnAdd: 1,
    additionalCartItemsEnabled: true,
    additionalCartItemsAddedMultipleTimesPerCart: false,
    additionalCartItemsQuantityFollowsOtherCartItemsQuantity: false,
    showLocationClosedMessage: false,
    showUnitClosedMessage: false,
    followLocationOpeningHours: false,
    enableReservationMode: false,
    reservationLink: '',
    showingReservationLandingPage: false,
    showItemImagesInItemDetails: false,
    reservationWidgetUUID: null,
    appProfileSlug: null,
    showTakeawayTextOnRoomService: false,
    excludedPaymentMethods: [],
    waiverCheckoutSection: null,
    checkoutSectionData: null,
    showDateSelectionForEvents: true,
    showTimeSelectionForEvents: true,
    wasPaidWithApplePay: false,
    variationLocationBlocks: null,
  },
  mutations: {
    updateVariationLocationBlocks(state, blocks) {
      state.variationLocationBlocks = blocks;
    },
    updateWasPaidWithApplePay(state, status) {
      state.wasPaidWithApplePay = status;
    },
    setShowDateSelectionForEvents(state, status) {
      state.showDateSelectionForEvents = status;
    },
    setShowTimeSelectionForEvents(state, status) {
      state.showTimeSelectionForEvents = status;
    },
    setCheckoutSectionData(state, data) {
      state.checkoutSectionData = data;
    },
    updateCheckoutSectionInstanceValue(state, { section, key, value }) {
      if (
        typeof section !== 'undefined' &&
        section !== null &&
        typeof key !== 'undefined' &&
        key !== null &&
        typeof value !== 'undefined' &&
        value !== null
      ) {
        if (Array.isArray(state.checkoutSectionList) && state.checkoutSectionList.length > 0) {
          const checkoutSectionIndex = state.checkoutSectionList.findIndex(s => s && s.namespace === section);
          if (checkoutSectionIndex >= 0) {
            if (
              Array.isArray(state.checkoutSectionList[checkoutSectionIndex].uiFields) &&
              state.checkoutSectionList[checkoutSectionIndex].uiFields.length > 0
            ) {
              const fieldIndex = state.checkoutSectionList[checkoutSectionIndex].uiFields.findIndex(field => field && field.key === key);
              if (fieldIndex >= 0) {
                state.checkoutSectionList[checkoutSectionIndex].uiFields[fieldIndex].value = value;
              }
            }
          }
        }
      }
    },
    setWaiverCheckoutSection(state, section) {
      state.waiverCheckoutSection = section;
    },
    setChannel(state, channel) {
      state.channel = channel;
    },
    setExcludedPaymentMethods(state, paymentMethods) {
      state.excludedPaymentMethods = paymentMethods;
    },
    setShowTakeawayTextOnRoomService(state, status) {
      state.showTakeawayTextOnRoomService = status;
    },
    setShowItemImagesInItemDetails(state, status) {
      state.showItemImagesInItemDetails = status;
    },
    setReservationWidgetUUID(state, uuid) {
      state.reservationWidgetUUID = uuid;
    },
    setAppProfileSlug(state, slug) {
      state.appProfileSlug = slug;
    },
    updateShowingReservationLandingPage(state, status) {
      state.showingReservationLandingPage = status;
    },
    setEnableReservationMode(state, status) {
      state.enableReservationMode = status;

      if (status === true && (state.appProfileSlug || state.reservationWidgetUUID)) {
        state.showingReservationLandingPage = true;
      }
    },
    setReservationLink(state, link) {
      state.reservationLink = link;
    },
    setAdditionalCartItemUUIDs(state, uuids) {
      state.additionalCartItemUUIDs = uuids;
    },
    setAdditionalCartItems(state, items) {
      state.additionalCartItems = items;
    },
    resetCart(state) {
      state.cart = [];
    },
    setPreviewTimestamp(state, timestamp) {
      state.previewTimestamp = timestamp;
    },
    setAgreedToAgeRestrictions(state, status) {
      state.agreedToAgeRestrictions = status;
    },
    setUpsellAllowed(state, status) {
      state.upsellAllowed = status;
    },
    updateUpsellCategory(state, category) {
      state.upsellCategory = category;
    },
    updateUpsellItems(state, items) {
      state.upsellItems = items;
    },
    setLimitCategoryOpeningHoursToParamCategories(state, status) {
      state.limitCategoryOpeningHoursToParamCategories = status;
    },
    updateLastErrorInFull(state, lastError) {
      state.lastErrorInFull = lastError;
    },
    setRemoveDefaultVariationsInParams(state, status) {
      state.removeDefaultVariationsInParams = status;
    },
    setOrganizationSettings(state, settings) {
      state.organizationSettings = settings;
      if (Array.isArray(settings.booleans)) {
        const alwaysChargeDefaultVariations = settings.booleans.find(
          b => b.key === 'item:variations_settings:always_charge_default_variations'
        );
        if (alwaysChargeDefaultVariations && !state.removeDefaultVariationsInParams) {
          state.removeDefaultVariationsFromPrice = !alwaysChargeDefaultVariations.value;
        }
      }
    },
    updateErrorSubMessage(state, subMessage) {
      state.errorSubMessage = subMessage;
    },
    updateErrorTitle(state, title) {
      state.errorTitle = title;
    },
    updateErrorMessage(state, message) {
      state.errorMessage = message;
    },
    updateShowBottomError(state, status) {
      state.showBottomError = status;
    },
    setPaymentInstances(state, instances) {
      state.paymentInstances = instances;
    },
    setGiftCardPaymentAvailable(state, status) {
      state.giftCardPaymentAvailable = status;
    },
    updateShowGiftCardInput(state, status) {
      state.showGiftCardInput = status;
    },
    setExcludeCategories(state, categories) {
      state.excludeCategories = categories;
    },
    setIncludeItemsInCategories(state, categoriesList) {
      state.includeItemsInCategories = categoriesList;
    },
    setExcludeItemsInCategories(state, categoriesList) {
      state.excludeItemsInCategories = categoriesList;
    },
    updateShowOtherItems(state, status) {
      // Show items under hardcoded category Other if they are missing from a category
      state.showOtherItems = status;
    },
    updateDefaultCountry(state, country) {
      state.defaultCountry = country;
    },
    setSelectableLanguages(state, languages) {
      state.selectableLanguages = languages;
    },
    updateShowOffers(state, status) {
      state.showOffers = status;
    },
    updateDisableVariationSelection(state, status) {
      state.disableVariationSelection = status;
    },
    updateSawItems(state, status) {
      state.sawItems = status;
    },
    updateAllCategories(state, categories) {
      state.allCategories = categories;
    },
    updateSelectedCategory(state, category) {
      state.selectedCategory = category;
    },
    updateSelectedCategoryIndex(state, index) {
      state.selectedCategoryIndex = index;
    },
    updateCurrentTreeLocation(state, array) {
      state.currentTreeLocation = array;
    },
    updateWelcomeMessageHeader(state, text) {
      state.welcomeMessageHeader = text;
    },
    updateWelcomeMessageText(state, text) {
      state.welcomeMessageText = text;
    },
    updateShowWelcomeMessage(state, status) {
      state.showWelcomeMessage = status;
    },
    updateHideClosedCategories(state, status) {
      state.hideClosedCategories = status;
    },
    updateShowCustomerComment(state, status) {
      state.showCustomerComment = status;
    },
    setLocales(state, locales) {
      state.locales = locales;
    },
    setLanguage(state, language) {
      i18n.locale = language;
      state.language = language;
    },
    updateCustomerFilledInfo(state, status) {
      state.customerFilledInfo = status;
    },
    setCheckoutSectionList(state, list) {
      state.checkoutSectionList = list;
    },
    updateCustomerName(state, name) {
      state.customerName = name;
    },
    updateCustomerEmail(state, email) {
      state.customerEmail = email;
    },
    updateCustomerPhone(state, phone) {
      state.customerPhone = phone;
    },
    updateCustomerComment(state, comment) {
      state.customerComment = comment;
    },
    updateShowCustomerInformationInput(state, status) {
      state.showCustomerInformationInput = status;
    },
    updateSubCategoryFromParams(state, status) {
      state.subCategoryFromParams = status;
    },
    updateCategorySubIndex(state, index) {
      state.categorySubIndex = index;
    },
    updateShowSubCategoryMenu(state, status) {
      state.showSubCategoryMenu = status;
    },
    updateShowWaitingTime(state, status) {
      state.showWaitingTime = status;
    },
    setLocation(state, location) {
      state.locationUUID = location;
    },
    updateSystemTime(state, time) {
      state.systemTime = time;
    },
    setLandingPageFromParams(state, status) {
      state.landingPageFromParams = status;
      // If landing page from params is true then we also set showing landing page as true
      if (status) {
        state.showLandingPage = status;
      }
    },
    updateShowLandingPage(state, status) {
      state.showLandingPage = status;
    },
    updateCategorySelectIndex(state, index) {
      state.categorySelectIndex = index;
    },
    updateAppBarHeight(state, height) {
      state.appBarHeight = height;
    },
    updateAppFooterHeight(state, height) {
      state.appFooterHeight = height;
    },
    updateSelectCategoryHeight(state, height) {
      state.selectCategoryHeight = height;
    },
    updateRemoveDefaultVariationsFromPrice(state, status) {
      state.removeDefaultVariationsFromPrice = status;
    },
    updateHideItemDetails(state, status) {
      state.hideItemDetails = status;
    },
    updateSinglePageCategories(state, status) {
      state.singlePageCategories = status;
    },
    updateSinglePageVariations(state, status) {
      state.singlePageVariations = status;
    },
    updateShowCart(state, status) {
      state.showCart = status;
    },
    updateSelectedItem(state, item) {
      state.selectedItem = item;
    },
    updateShowItemDetails(state, status) {
      state.showItemDetails = status;
    },
    updateShowSelectedItemVariationSelection(state, status) {
      state.showSelectedItemVariationSelection = status;
    },
    updateCategoriesFromParams(state, array) {
      state.categoriesFromParams = array;
    },
    updatePrimaryColor(state, color) {
      state.primaryColor = color;
    },
    updateAccentColor(state, color) {
      state.accentColor = color;
    },
    updateHideListPictures(state, status) {
      state.hideListPictures = status;
    },
    updateHasError(state, status) {
      state.hasError = status;
    },
    updateMessage(state, message) {
      state.message = message;
    },
    updateSubMessage(state, message) {
      state.subMessage = message;
    },
    setCVC(state, cvc) {
      state.cvc = cvc;
    },
    setExpYear(state, exp_year) {
      state.exp_year = exp_year;
    },
    setExpMonth(state, exp_month) {
      state.exp_month = exp_month;
    },
    setCardNumber(state, card_number) {
      state.card_number = card_number;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCurrentItemInFull(state, item) {
      state.currentItemInFull = item;
    },
    setPaymentResponse(state, newPaymentResponse) {
      state.paymentResponse = newPaymentResponse;
      state.fallbackPaymentForm = false;
      if (
        newPaymentResponse &&
        newPaymentResponse.success === true &&
        newPaymentResponse.paymentTransaction &&
        newPaymentResponse.paymentTransaction.status === 'success'
      ) {
        state.cart = [];
      }
    },
    setFallbackPaymentForm(state, fallbackPaymentForm) {
      state.fallbackPaymentForm = fallbackPaymentForm;
    },
    addItemToCart(state, item) {
      if (state.cart.length === 0) {
        state.paymentResponse = null;
      }

      // item.selected_variations = item.default_variations
      // item.selectedVariations = item.default_variations

      state.cart.push(item);

      if (Array.isArray(state.additionalCartItems) && state.additionalCartItems.length > 0) {
        for (let itemIndex = 0; itemIndex < state.additionalCartItems.length; itemIndex++) {
          const additionalCartItem = state.additionalCartItems[itemIndex];
          if (state.cart.some(lineItem => lineItem.uuid === additionalCartItem.uuid)) {
            continue;
          }
          state.cart.push(additionalCartItem);
        }
      }
    },
    removeItemFromCart(state, index) {
      state.cart.splice(index, 1);
    },
    setInitialData(state, initialData) {
      state.defaultCountry = initialData.defaultCountry;
      const defaultLanguage = state.defaultCountry.code.toLowerCase();

      if (defaultLanguage === 'en' || i18n.locale === null || typeof i18n.locale === 'undefined') {
        i18n.locale = defaultLanguage;
      }

      state.attributes = initialData.attributes.sort((a, b) => a.weight - b.weight);

      state.paymentInstances = initialData.paymentMethodInstanceList;
      state.giftCardPaymentAvailable = state.paymentInstances.some(instance => instance.namespace === 'giftcard');
      state.location = initialData.location;

      if (state.location && state.location.data) {
        try {
          state.location.data = JSON.parse(state.location.data);
        } catch (e) {
          state.location.data = null;
        }
      }

      state.paymentMethod = initialData.paymentMethod;
      state.variationOption = initialData.variationOption;
      state.paymentMethodInstanceOption = initialData.paymentMethodInstanceOption;
      state.systemTime = initialData.systemTimeInMillis;
    },
    updateItemsList(state, newList) {
      state.itemsList = newList;
    },
    updateChannelUUID(state, newUUID) {
      state.channelUUID = newUUID;
    },
    updateOrganizationUUID(state, newUUID) {
      state.organizationUUID = newUUID;
    },
    updatePaymentInstanceUUID(state, newUUID) {
      state.paymentInstanceUUID = newUUID;
    },
    updateUnitID(state, unitID) {
      state.unitID = unitID;
    },
    updateStoredUrlParams(state, newParams) {
      state.storedUrlParams = newParams;
    },
    updateSessionToken(state, newToken) {
      state.sessionToken = newToken;
    },
    updateOrganizationInfo(state, newOrganization) {
      state.organization = newOrganization;
    },
    updateCategories(state, newCategories) {
      state.categories = newCategories;
    },
    updateCart(state, newCartItem) {
      state.cart.push(newCartItem);
    },
    updatePaymentInProgress(state, paymentInProgress) {
      state.paymentInProgress = paymentInProgress;
    },
    updateMissingVariationSelection(state, status) {
      state.missingItemVariationSelection = status;
    },
    updateCategoriesNotSelected(state, notSelected) {
      state.itemVariationCategoriesNotSelected = notSelected;
    },
    addToTree(state, uuid) {
      state.currentTreeLocation.push(uuid);
    },
    removeLastFromTree(state) {
      state.currentTreeLocation.pop();
    },
    setShowAddGuestsToTableButton(state, status) {
      state.showAddGuestsToTableButton = status;
    },
    setShowLocationClosedMessage(state, status) {
      state.showLocationClosedMessage = status;
    },
    setShowUnitClosedMessage(state, status) {
      state.showUnitClosedMessage = status;
    },
    setFollowLocationOpeningHours(state, status) {
      state.followLocationOpeningHours = status;
    },
  },
  actions: {
    getCheckoutSectionInstanceList({ state, dispatch }) {
      let object = {};
      object.order = {};
      object.order.lineItems = state.cart.reduce((lineItems, lineItem) => {
        if (lineItem) {
          lineItems.push({
            uuid: lineItem.uuid,
            namespace: lineItem.type,
            type: lineItem.type,
            quantity: lineItem.quantity,
          });
        }
        return lineItems;
      }, []);

      object = JSON.stringify(object);
      object = jsesc(object, { json: 'true' });

      const body = {
        query:
          'query {\n' +
          '   checkoutSectionInstanceList(organization: "' +
          state.organizationUUID +
          '", channel: "' +
          state.channelUUID +
          '", unmanagedObject: ' +
          object +
          ') {\n' +
          '       namespace\n' +
          '       data\n' +
          '       uiFields {\n' +
          '           key\n' +
          '           type\n' +
          '           label\n' +
          '           required\n' +
          '       }\n' +
          '   }\n' +
          '}',
      };

      return axios.post(state.graphUrl, body).then(response => {
        if (response && response.data && response.data.data && response.data.data.checkoutSectionInstanceList) {
          dispatch('setCheckoutSectionsSettings', { checkoutSectionInstanceList: response.data.data.checkoutSectionInstanceList });
          return response.data.data.checkoutSectionInstanceList;
        }
        return [];
      });
    },
    reserveTimeSlot({ state }, { item, slot, quantity }) {
      const body = {
        query:
          'mutation {\n' +
          '   reserveAvailableTimeSlot(sessionToken: "' +
          state.sessionToken.session_token +
          '", organization: "' +
          state.organizationUUID +
          '" , item: "' +
          item.uuid +
          '", startTimeInMillis: ' +
          slot.timestampInMillis +
          ', endTimeInMillis: ' +
          (slot.timestampInMillis + slot.paddingInMillis) +
          ', quantity: ' +
          quantity +
          ', skipLock: ' +
          (item.reservationMode === 'relaxed') +
          ') {\n' +
          '     success\n' +
          '     uuid\n' +
          '   }\n' +
          '}',
      };

      return axios.post(state.graphUrl, body).then(result => {
        if (result && result.data && result.data.data && result.data.data.reserveAvailableTimeSlot) {
          return result.data.data.reserveAvailableTimeSlot;
        }
        return null;
      });
    },
    releaseTimeSlot({ state }, { item, slot }) {
      const body = {
        query:
          'mutation {\n' +
          '   releaseAvailableTimeSlot(sessionToken: "' +
          state.sessionToken.session_token +
          '", organization: "' +
          state.organizationUUID +
          '" , item: "' +
          item.uuid +
          '", startTimeInMillis: ' +
          slot.timestampInMillis +
          ', endTimeInMillis: ' +
          (slot.timestampInMillis + slot.paddingInMillis) +
          ', skipLock: ' +
          (item.reservationMode === 'relaxed') +
          ')\n' +
          '}',
      };

      return axios.post(state.graphUrl, body).then(result => {
        if (result && result.data && result.data.data && result.data.data.releaseAvailableTimeSlot) {
          return result.data.data.releaseAvailableTimeSlot;
        }
        return null;
      });
    },
    fetchSessionToken({ state, commit }) {
      const graphOptions = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const graphBody = {
        query: 'mutation { newAnonymousSession { session_token, ttl } }',
      };

      return axios.post(state.graphUrl, graphBody, graphOptions).then(result => {
        if (result && result.data && result.data.data && result.data.data.newAnonymousSession) {
          commit('updateSessionToken', result.data.data.newAnonymousSession);
        }
      });
    },
    getAvailableTimeSlots({ state }, { item, date }) {
      if (!item || !date) {
        return [];
      }

      const startTimeInMillis = new Date(date).setUTCHours(0, 0, 0, 0);
      const endTimeInMillis = new Date(date).setUTCHours(23, 59, 59, 999);

      const body = {
        query:
          'query GetAvailableTimeSlots($sessionToken: String!, $organization: String!, $channel: String!, $location: String, $item: String, $startTimeInMillis: Float!, $endTimeInMillis: Float!) {\n' +
          '   availableTimeSlots(sessionToken: $sessionToken, organization: $organization, channel: $channel, location: $location, item: $item, startTimeInMillis: $startTimeInMillis, endTimeInMillis: $endTimeInMillis) {\n' +
          '       label\n' +
          '       timestampInMillis\n' +
          '       paddingInMillis\n' +
          '       reservedForCurrentSession\n' +
          '       reservationCount\n' +
          '       bookingCount\n' +
          '       availableCount\n' +
          '       isBlocked\n' +
          '   }\n' +
          '}',
        variables: {
          sessionToken: state.sessionToken.session_token,
          organization: state.organizationUUID,
          channel: state.channelUUID,
          location: state.locationUUID,
          item: item.uuid,
          startTimeInMillis,
          endTimeInMillis,
        },
      };

      return axios.post(state.graphUrl, body).then(response => {
        if (response && response.data && response.data.data) {
          if (response.data.data.availableTimeSlots) {
            return response.data.data.availableTimeSlots;
          }
        }
        return [];
      });
    },
    async processPaymentFromInstance({ state, commit, getters }, paymentRequest) {
      commit('updatePaymentInProgress', true);

      const cartItems = getters.getCartItemsForPayment;

      const isApplePay = Boolean(paymentRequest.methodName && paymentRequest.methodName === 'https://apple.com/apple-pay');

      const checkoutSectionInstancesData = getters.checkoutSectionInstancesData(state.checkoutSectionList);

      let paymentMethodInstanceNamespace = paymentRequest.paymentInstance.namespace;
      let paymentMethodInstanceUUID = paymentRequest.paymentInstance.uuid;

      // Handling when coupon has made the order amount zero, otherwise the payment method instance will complain about not being able to handle zero amount payments
      if (paymentRequest.amount === 0 && getters.activeCoupon && getters.activeCoupon.uuid) {
        paymentMethodInstanceNamespace = 'complete'; // We should add a new namespace for free orders?
        paymentMethodInstanceUUID = 'complete';
      }

      const salescloudPaymentRequest = {
        namespace: paymentMethodInstanceNamespace,
        requestId: paymentRequest.requestId ? paymentRequest.requestId : String(Date.now()),
        amount: paymentRequest.amount,
        currency_code: paymentRequest.currency_code,
        isApplePay,
        details: paymentRequest.details,
        comment: state.customerComment,
        location: state.locationUUID,
        items: cartItems,
        unit: state.unitID,
        checkoutSectionInstancesData,
        sequence: true,
        orderOrigin: 'etags',
        orderFulfillment: 'eatIn',
      };

      if (paymentRequest.payerName) {
        if (!salescloudPaymentRequest.billingInfo) {
          salescloudPaymentRequest.billingInfo = {};
        }
        salescloudPaymentRequest.payerName = paymentRequest.payerName;
        salescloudPaymentRequest.billingInfo.name = paymentRequest.payerName;
      }

      if (paymentRequest.payerPhone) {
        if (!salescloudPaymentRequest.billingInfo) {
          salescloudPaymentRequest.billingInfo = {};
        }
        salescloudPaymentRequest.payerPhone = paymentRequest.payerPhone;
        salescloudPaymentRequest.billingInfo.phone = paymentRequest.payerPhone;
      }

      if (paymentRequest.payerEmail) {
        if (!salescloudPaymentRequest.billingInfo) {
          salescloudPaymentRequest.billingInfo = {};
        }
        salescloudPaymentRequest.payerEmail = paymentRequest.payerEmail;
        salescloudPaymentRequest.billingInfo.email = paymentRequest.payerEmail;
      }

      if (getters.activeCoupon) {
        salescloudPaymentRequest.coupon = getters.activeCoupon.uuid;
      }

      if (salescloudPaymentRequest.namespace === 'verifone_ecommerce') {
        const card = {
          cardNumber: salescloudPaymentRequest.details.cardNumber,
          expiryMonth: salescloudPaymentRequest.details.expiryMonth,
          expiryYear: salescloudPaymentRequest.details.expiryYear,
          cvv: salescloudPaymentRequest.details.cardSecurityCode,
        };
        try {
          salescloudPaymentRequest.details.cardNumber = await window.verifone.encryptCard(
            card,
            process.env.VUE_APP_VERIFONE_SECURE_CAPTURE_KEY
          );
          delete salescloudPaymentRequest.details.expiryMonth;
          delete salescloudPaymentRequest.details.expiryYear;
          delete salescloudPaymentRequest.details.cardSecurityCode;
        } catch (e) {
          console.warn('[VUEX][PROCESSPAYMENT][verifone_ecommerce][window.verifone.encryptCard]');
        }
      }

      if (
        paymentRequest &&
        paymentRequest.details &&
        paymentRequest.details.xid &&
        paymentRequest.details.mdStatus &&
        paymentRequest.entangleKey
      ) {
        salescloudPaymentRequest.entangleKey = paymentRequest.entangleKey;
        delete salescloudPaymentRequest.items;
      }

      const paymentUrl = `${state.paymentRequestUrl}/${state.organizationUUID}/${state.channelUUID}/${paymentMethodInstanceUUID}`;

      return axios
        .post(paymentUrl, salescloudPaymentRequest)
        .then(response => {
          if (response.data) {
            commit('setPaymentResponse', response.data);
          }
          return response.data;
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('process payment from instance error', error);
          if (error) {
            commit('updateLastErrorInFull', error);
            commit('updateHasError', true);
            commit('updateMessage', 'Error 500');
            commit('updateSubMessage', 'Error connecting to server');
          }
          return null;
        })
        .finally(() => {
          if (state.paymentResponse === null || typeof state.paymentResponse === 'undefined') {
            commit('updatePaymentInProgress', false);
          }
          if (state.paymentResponse && state.paymentResponse.success === false) {
            commit('updatePaymentInProgress', false);
          }
          if (
            state.paymentResponse &&
            state.paymentResponse.success === true &&
            state.paymentResponse.paymentTransaction &&
            state.paymentResponse.paymentTransaction.status !== 'pending'
          ) {
            commit('updatePaymentInProgress', false);
          }
        });
    },
    processGiftCardPayment({ state, commit, getters }, paymentRequest) {
      commit('updatePaymentInProgress', true);
      const cartItems = getters.getCartItemsForPayment;

      const giftCardPaymentInstance = state.paymentInstances.find(instance => instance.namespace === 'giftcard');

      if (!giftCardPaymentInstance) {
        commit('updateHasError', true);
        commit('updateMessage', 'Error 400');
        commit('updateSubMessage', 'Error connecting to server');
        return;
      }

      const salescloudPaymentRequest = {
        namespace: giftCardPaymentInstance.namespace,
        requestId: `GC${Date.now()}`,
        amount: paymentRequest.amount,
        currency_code: paymentRequest.currency_code,
        isApplePay: false,
        details: paymentRequest.details,
        comment: state.customerComment,
        location: state.locationUUID,
        items: cartItems,
        unit: state.unitID,
        coupon: getters.activeCoupon ? getters.activeCoupon.uuid : null,
        sequence: true,
        orderOrigin: 'etags',
        orderFulfillment: 'eatIn',
      };

      if (paymentRequest.payerName) {
        salescloudPaymentRequest.payerName = paymentRequest.payerName;
      }

      if (paymentRequest.payerPhone) {
        salescloudPaymentRequest.payerPhone = paymentRequest.payerPhone;
      }

      if (paymentRequest.payerEmail) {
        salescloudPaymentRequest.payerEmail = paymentRequest.payerEmail;
      }

      const paymentUrl = `${state.paymentRequestUrl}/${state.organizationUUID}/${state.channelUUID}/${giftCardPaymentInstance.uuid}`;

      axios
        .post(paymentUrl, salescloudPaymentRequest)
        .then(response => {
          if (response.data) {
            commit('setPaymentResponse', response.data);
          }
        })
        .catch(error => {
          if (error) {
            commit('updateHasError', true);
            commit('updateMessage', 'Error 500');
            commit('updateSubMessage', 'Error connecting to server');
          }
        })
        .finally(() => {
          if (state.paymentResponse === null || typeof state.paymentResponse === 'undefined') {
            commit('updatePaymentInProgress', false);
          }
          if (state.paymentResponse && state.paymentResponse.success === false) {
            commit('updatePaymentInProgress', false);
          }
          if (
            state.paymentResponse &&
            state.paymentResponse.success === true &&
            state.paymentResponse.paymentTransaction &&
            state.paymentResponse.paymentTransaction.status !== 'pending'
          ) {
            commit('updatePaymentInProgress', false);
          }
        });
    },
    setLocale({ state }, language) {
      i18n.locale = language;
      state.localeFromParam = language;
    },
    removeOtherCategories({ state }) {
      const keys = Object.keys(state.itemsByCategories);
      for (let i = 0; i < keys.length; i++) {
        if (Number(keys[i])) {
          state.itemsByCategories[keys[i]].items = [];
        }
      }
    },
    updateSelectedCategory({ commit, state }, category) {
      commit('updateSelectedCategory', category);

      // ADDING OTHER CATEGORY
      if (
        state.showOtherItems &&
        category &&
        category.hasSubCategories &&
        (!category.subCategories.some(c => c.name === i18n.t('items.other')) ||
          category.subCategories.some(c => c.name === i18n.t('items.other') && (!c.items || !c.items.length)))
      ) {
        const { subCategories, items } = state.itemsByCategories[category.uuid];

        if (state.itemsByCategories[category.uuid] && state.itemsByCategories[category.uuid].hasSubCategories && items && items.length) {
          const itemsList = [];
          let itemExistsWithoutSub = false;
          items.forEach(item => {
            if (item.categories && item.categories.length) {
              const filtered = item.categories.filter(ca => subCategories.some(c => c.uuid === ca.uuid));
              if (!filtered || !filtered.length) {
                itemExistsWithoutSub = true;
                itemsList.push(item);
              }
            }
          });

          if (itemExistsWithoutSub) {
            const uuid = Math.round(Math.random() * Date.now()).toString();
            const singleCat = {
              name: i18n.t('items.other'),
              uuid,
              isSubCategory: true,
              hasSubCategories: false,
              parent: category,
              required: false,
              single: false,
              administrative: false,
              opening_hours: null,
              weight: 1000,
              items: itemsList,
            };
            category.subCategories.push(singleCat);
            state.itemsByCategories[uuid] = singleCat;
          }
        }
      }
    },
    getLocationBlocks({ state, commit }) {
      const body = {
        query:
          'query {\n' +
          ' itemLocationBlocks(organization: "' +
          state.organizationUUID +
          '") {\n' +
          '     location\n' +
          '     item\n' +
          ' }\n' +
          ' variationLocationBlocks(organization: "' +
          state.organizationUUID +
          '", location: "' +
          state.locationUUID +
          '") {\n' +
          '     location\n' +
          '     item\n' +
          '     variation\n' +
          ' }\n' +
          '}',
      };

      return axios
        .post(state.graphUrl, body)
        .then(response => {
          if (response && response.data && response.data.data) {
            const { itemLocationBlocks, variationLocationBlocks } = response.data.data;
            if (itemLocationBlocks) {
              commit('updateItemLocationBlocks', itemLocationBlocks);
            }
            if (variationLocationBlocks) {
              commit('updateVariationLocationBlocks', variationLocationBlocks);
            }
            return response.data.data;
          }
          return null;
        })
        .catch(() => {
          return null;
        });
    },
    getInitialData({ state, commit, dispatch, getters }) {
      commit('setLoading', true);

      if (getters.missingInitialValues) {
        commit('updateHasError', true);
        commit('updateMessage', 'No item reference');
        commit('updateSubMessage', 'The link that your are trying to access is incomplete.');
        commit('setLoading', false);
        return;
      }

      let locationQuery = '';

      if (state.locationUUID) {
        locationQuery =
          '  location(organization:"' +
          state.organizationUUID +
          '", uuid:"' +
          state.locationUUID +
          '") {\n' +
          '     label\n' +
          '     description\n' +
          '     ept\n' +
          '     opening_rule\n' +
          '     opening_hours {\n' +
          '       day\n' +
          '       starthours\n' +
          '       endhours\n' +
          '       comment\n' +
          '     }\n' +
          '  }\n';
      }

      return axios({
        url: state.graphUrl,
        method: 'post',
        data: {
          query:
            'query {\n' +
            '   paymentMethodInstanceOption(organization: "' +
            state.organizationUUID +
            '", uuid: "' +
            state.paymentInstanceUUID +
            '") {\n' +
            '       title\n' +
            '       namespace\n' +
            '   }\n' +
            '   itemLocationBlocks(organization: "' +
            state.organizationUUID +
            '") {\n' +
            '       item\n' +
            '       location\n' +
            '   }\n' +
            '   variationLocationBlocks(organization: "' +
            state.organizationUUID +
            '", location: "' +
            state.locationUUID +
            '") {\n' +
            '       location\n' +
            '       item\n' +
            '       variation\n' +
            '   }\n' +
            '   itemsList(organization: "' +
            state.organizationUUID +
            '", sellInEtags: true' +
            (state.locationUUID ? ', locations: "' + [state.locationUUID] + '", location: "' + state.locationUUID + '"' : '') +
            ') {\n' +
            '       title\n' +
            '       uuid\n' +
            '       type\n' +
            '       itemIsAvailableBasedOnOpeningHours {\n' +
            '         isAvailable\n' +
            '       }\n' +
            '       exclude_discounts\n' +
            '       minimumOrderQuantity\n' +
            '       maximumOrderQuantity\n' +
            '       maximumGroupedQuantity\n' +
            '       maxAheadBookingInDays\n' +
            '       durationInMinutes\n' +
            '       sellInEtags\n' +
            '       pauseBetweenEventsInMinutes\n' +
            '       isAllDayEvent\n' +
            '       unavailableDates\n' +
            '       unitOfMeasure\n' +
            '       startDate\n' +
            '       endDate\n' +
            '       startSellTimeInMillis\n' +
            '       endSellTimeInMillis\n' +
            '       reservationMode\n' +
            '       dates\n' +
            '       additionalItems {\n' +
            '           uuid\n' +
            '           title\n' +
            '           price {\n' +
            '               amount\n' +
            '               currency_code\n' +
            '           }\n' +
            '           sale_price {\n' +
            '               amount\n' +
            '               currency_code\n' +
            '           }\n' +
            '       }\n' +
            '       translatedContent {\n' +
            '           key\n' +
            '           value\n' +
            '           language\n' +
            '       }\n' +
            '       price {\n' +
            '           amount\n' +
            '           currency_code\n' +
            '       }\n' +
            '       sale_price {\n' +
            '           amount\n' +
            '           currency_code\n' +
            '       }\n' +
            '       event_guest_price {\n' +
            '           amount\n' +
            '           currency_code\n' +
            '       }\n' +
            '       description {\n' +
            '           value\n' +
            '           summary\n' +
            '           format\n' +
            '       }\n' +
            '       categories {\n' +
            '           uuid\n' +
            '           name\n' +
            '           description\n' +
            '           translatedContent {\n' +
            '               key\n' +
            '               value\n' +
            '               language\n' +
            '           }\n' +
            '           opening_hours {\n' +
            '               day\n' +
            '               starthours\n' +
            '               endhours\n' +
            '               comment\n' +
            '           }\n' +
            '       }\n' +
            '       sorting_weight\n' +
            '       status\n' +
            '       variations {\n' +
            '           uuid\n' +
            '           label\n' +
            '           price {\n' +
            '               amount\n' +
            '               currency_code\n' +
            '           }\n' +
            '           status\n' +
            // '        sale_price {\n' +
            // '            amount\n' +
            // '            currency_code\n' +
            // '        }\n' +
            '           default\n' +
            '           sorting_weight\n' +
            '           translatedContent {\n' +
            '               key\n' +
            '               value\n' +
            '               language\n' +
            '           }\n' +
            '           attribute {\n' +
            '               uuid\n' +
            '               name\n' +
            '               translatedContent {\n' +
            '                   key\n' +
            '                   value\n' +
            '                   language\n' +
            '               }\n' +
            '               weight\n' +
            '               required\n' +
            '               single\n' +
            '               multiple\n' +
            '               ept\n' +
            '               opening_hours {\n' +
            '                   day\n' +
            '                   starthours\n' +
            '                   endhours\n' +
            '                   comment\n' +
            '               }\n' +
            '           }\n' +
            '       }\n' +
            '       default_variations {\n' +
            '           uuid\n' +
            '           label\n' +
            '           price {\n' +
            '               amount\n' +
            '               currency_code\n' +
            '           }\n' +
            '           default\n' +
            '           sorting_weight\n' +
            '           translatedContent {\n' +
            '               key\n' +
            '               value\n' +
            '               language\n' +
            '           }\n' +
            '           attribute {\n' +
            '               uuid\n' +
            '               name\n' +
            '               weight\n' +
            '               required\n' +
            '               single\n' +
            '               multiple\n' +
            '               ept\n' +
            '               translatedContent {\n' +
            '                   key\n' +
            '                   value\n' +
            '                   language\n' +
            '               }\n' +
            '               opening_hours {\n' +
            '                   day\n' +
            '                   starthours\n' +
            '                   endhours\n' +
            '                   comment\n' +
            '               }\n' +
            '           }\n' +
            '       }\n' +
            '       images {\n' +
            '           file {\n' +
            '               sources {\n' +
            '                   original\n' +
            '                   medium\n' +
            '                   thumbnail\n' +
            '               }\n' +
            '           }\n' +
            '       }\n' +
            '   }\n' +
            '   attributes(organization:"' +
            state.organizationUUID +
            '") {\n' +
            '       uuid\n' +
            '       name\n' +
            '       weight\n' +
            '       required\n' +
            '       single\n' +
            '       multiple\n' +
            '       maxVariations\n' +
            '       translatedContent {\n' +
            '           key\n' +
            '           value\n' +
            '           language\n' +
            '       }\n' +
            '   }\n' +
            '   variationOption(organization: "' +
            state.organizationUUID +
            '", uuid: "' +
            state.unitID +
            '") {\n' +
            '       _id\n' +
            '       label\n' +
            '   }\n' +
            '   channel(organization: "' +
            state.organizationUUID +
            '", uuid: "' +
            state.channelUUID +
            '") {\n' +
            '       label\n' +
            '       description\n' +
            '       ept\n' +
            '       data\n' +
            '       acceptedPaymentMethodInstances {\n' +
            '         uuid\n' +
            '         legacyId\n' +
            '         namespace\n' +
            '       }\n' +
            '   }\n' +
            '   categories(uuid: "' +
            state.organizationUUID +
            '", type: "products") {\n' +
            '       name\n' +
            '       uuid\n' +
            '       weight\n' +
            '       description\n' +
            '       parent {\n' +
            '           uuid\n' +
            '           name\n' +
            '       }\n' +
            '       translatedContent {\n' +
            '           key\n' +
            '           value\n' +
            '           language\n' +
            '       }\n' +
            '       administrative\n' +
            '       required\n' +
            '       single\n' +
            '       opening_hours{\n' +
            '           day\n' +
            '           starthours\n' +
            '           endhours\n' +
            '           comment\n' +
            '       }\n' +
            '       ept\n' +
            '       primary_color\n' +
            //'    accent_color\n' +
            '   }\n' +
            '   systemTimeInMillis\n' +
            '   defaultCountry {\n' +
            '       name\n' +
            '       code\n' +
            '   }\n' +
            '   defaultCurrency {\n' +
            '       name\n' +
            '       code\n' +
            '   }\n' +
            locationQuery +
            '   organization(uuid: "' +
            state.organizationUUID +
            '") {\n' +
            '       label\n' +
            '       description\n' +
            '       email\n' +
            '       phone\n' +
            '       primary_color\n' +
            '       accent_color\n' +
            '       defaultTimezone\n' +
            '       defaultCurrency {\n' +
            '           code\n' +
            '           name\n' +
            '       }\n' +
            '       settings {\n' +
            '           booleans {\n' +
            '               key\n' +
            '               value\n' +
            '           }\n' +
            '           strings {\n' +
            '               key\n' +
            '               value\n' +
            '           }\n' +
            '           stringLists {\n' +
            '               key\n' +
            '               value\n' +
            '           }\n' +
            '           integers {\n' +
            '               key\n' +
            '               value\n' +
            '           }\n' +
            '       }\n' +
            '       address {\n' +
            '           name_line\n' +
            '           thoroughfare\n' +
            '           locality\n' +
            '           country\n' +
            '           postal_code\n' +
            '       }\n' +
            '       logo {\n' +
            '           file {\n' +
            '               sources {\n' +
            '                   original\n' +
            '               }\n' +
            '           }\n' +
            '       }\n' +
            '   }\n' +
            '   paymentMethodInstanceList(organization: "' +
            state.organizationUUID +
            '", checkout: true) {\n' +
            '       uuid\n' +
            '       icon\n' +
            '       title\n' +
            '       description\n' +
            '       namespace\n' +
            '       checkout\n' +
            '       terminal\n' +
            '       isApplePayCapable\n' +
            '       uiFields {\n' +
            '           key\n' +
            '           type\n' +
            '           label\n' +
            '           required\n' +
            '           value\n' +
            '           cols\n' +
            '           placeholder\n' +
            '           hint\n' +
            '           multiple\n' +
            '           options {\n' +
            '               key\n' +
            '               label\n' +
            '           }\n' +
            '       }\n' +
            '   }\n' +
            '   checkoutSectionInstanceList(organization: "' +
            state.organizationUUID +
            '", channel: "' +
            state.channelUUID +
            '") {\n' +
            '       namespace\n' +
            '       data\n' +
            '       uiFields {\n' +
            '           key\n' +
            '           type\n' +
            '           label\n' +
            '           required\n' +
            '       }\n' +
            '   }\n' +
            '   organizationTranslationsI18nFormatted(organization: "' +
            state.organizationUUID +
            '") \n' +
            '}',
        },
      })
        .then(response => {
          if (response && response.data && response.data.data) {
            commit('setInitialData', response.data.data);

            const {
              categories,
              itemsList,
              organization,
              checkoutSectionInstanceList,
              organizationTranslationsI18nFormatted,
              defaultCurrency,
              paymentMethodInstanceList,
              itemLocationBlocks,
              channel,
              variationLocationBlocks,
            } = response.data.data;

            if (
              Array.isArray(categories) &&
              (state.organizationUUID === '32036a02-fd37-4044-bbb1-e55970e4531f' ||
                state.organizationUUID === '76180a17-7a76-419a-acca-a2d292af6326')
            ) {
              const upsellCategory = categories.find(category => category && category.name && category.name.toLowerCase() === 'upsell');
              if (upsellCategory && Array.isArray(itemsList)) {
                const upsellItems = itemsList.filter(
                  item => item && Array.isArray(item.categories) && item.categories.some(category => category.uuid === upsellCategory.uuid)
                );
                if (Array.isArray(upsellItems) && upsellItems.length > 0) {
                  commit('updateUpsellItems', upsellItems);
                  commit('updateUpsellCategory', upsellCategory);
                  commit('updateShowOffers', true);
                }
              }
            }

            if (Array.isArray(state.additionalCartItemUUIDs) && state.additionalCartItemUUIDs.length > 0) {
              commit(
                'setAdditionalCartItems',
                itemsList.filter(item => item && state.additionalCartItemUUIDs.includes(item.uuid))
              );
            }

            commit('setTranslations', response.data.data.organizationTranslationsI18nFormatted);

            dispatch('setupCategories', { categories });
            dispatch('setUpsell', { categories, itemsList });

            commit('setTranslations', organizationTranslationsI18nFormatted);

            commit('setDefaultCurrency', defaultCurrency);

            let channelToState = channel;
            if (channelToState && channelToState.data) {
              try {
                channelToState.data = JSON.parse(channelToState.data);
              } catch (e) {
                channelToState.data = null;
              }
            }

            commit('setChannel', channelToState);

            // WARNING
            // We fetch paymentMethodInstanceList with checkout: true and terminal: true (requiring the payment method to have both checked in the backend to show up here)
            // rather than foregoing those values completely (thus being able to fetch all active payment method instances on an organization)
            // Reason is because below we want to filter out payment method instances based on acceptedPaymentMethodInstances set on channel
            // BUT if an organization has not set what accepted payment methods are on a channel then ALL payment methods become accepted meaning we can not rely on it being
            // a proper filter on what payment methods we want to show here
            let paymentMethodInstances = paymentMethodInstanceList;

            if (Array.isArray(paymentMethodInstances) && paymentMethodInstances) {
              // const defaultPaymentMethod = state.paymentInstanceUUID ? paymentMethodInstances.filter(instance => instance && instance.uuid === state.paymentInstanceUUID) : []

              if (
                state.channel &&
                Array.isArray(state.channel.acceptedPaymentMethodInstances) &&
                state.channel.acceptedPaymentMethodInstances.length > 0
              ) {
                paymentMethodInstances = paymentMethodInstances.filter(
                  instance =>
                    instance && state.channel.acceptedPaymentMethodInstances.some(accepted => accepted && accepted.uuid === instance.uuid)
                );
              } else {
                paymentMethodInstances = paymentMethodInstances.filter(instance => instance.checkout === true);
              }
              // paymentMethodInstances = defaultPaymentMethod.concat(paymentMethodInstances)
            }

            commit('setPaymentMethodInstances', paymentMethodInstances);

            commit('updateItemLocationBlocks', itemLocationBlocks);
            commit('updateVariationLocationBlocks', variationLocationBlocks);

            commit('updateOrganizationInfo', organization);

            dispatch('setColours');

            if (organization && organization.defaultCurrency) {
              commit('setDefaultOrganizationCurrency', organization.defaultCurrency);
              // TODO: Language hack for tiffin cafe
              if (organization.defaultCurrency.code.toLowerCase() === 'gbp') {
                state.language = 'en';
                i18n.locale = 'en';
              }
            }
            if (organization && organization.settings) {
              commit('setOrganizationSettings', organization.settings);
            }

            let itemType = 'product';
            if (getters.eventsMode === true) {
              itemType = 'event';
            }

            state.itemsList = itemsList.filter(
              item =>
                item.type === itemType &&
                item.itemIsAvailableBasedOnOpeningHours !== null &&
                item.itemIsAvailableBasedOnOpeningHours.isAvailable === true
            );

            dispatch('setupItems', { categories: state.categories });
            dispatch('setItemsByCategories');

            dispatch('setCheckoutSectionsSettings', { checkoutSectionInstanceList });
          }
        })
        .catch(error => {
          if (error) {
            commit('updateHasError', true);
            commit('updateMessage', 'Error 500');
            commit('updateSubMessage', 'There was an error contacting the server.');
          }
        })
        .finally(() => {
          commit('setLoading', false);
        });
    },
    processPaymentFallback({ commit, state, getters }, paymentRequest) {
      /**
       * cart is an array of item objects
       * if an item object has variations it will also have selected_variations that has been added to keep track
       * of what variations have been added
       * variations in this array will contain the default variation properties as well as additional boolean properties:
       * - added: Only applied to non-default variations
       * - isDefault: Notifies if the variation is a default variation or not
       * - removed: If a default variation has been removed this will be true
       * This way we can keep track of what was specifically added or removed and makes it easier to keep track of
       * and display towards user on frontend and a user on backend
       * [
       * {
       * 		categories: Array
       * 		default_categories: Array
       * 		description: Object
       * 		images: Object
       * 		price: Object
       * 		sorting_weight: Integer
       * 		status: Boolean
       * 		title: String
       * 		type: String
       * 		uuid: String
       * 		variations: Array
       * 		selected_variations: Array ++ADDED TO ORIGINAL ITEM OBJECT++
       * }
       */

      commit('updatePaymentInProgress', true);

      const cartItems = getters.getCartItemsForPayment;
      let paymentNamespace = 'valitor_greidslugatt';
      if (getters.hasBorgunPaymentMethod && !getters.hasValitorPaymentMethod) {
        paymentNamespace = 'borgun_rpg';
      }

      let paymentInstanceUUID = state.paymentInstanceUUID;

      // TODO: For future refactor
      // if(typeof paymentRequest.paymentInstance !== 'undefined' && paymentRequest.paymentInstance !== null) {
      //   paymentNamespace = paymentRequest.paymentInstance.namespace
      //   paymentInstanceUUID = paymentRequest.paymentInstance.uuid
      // }

      // TODO: RequestID?

      // Handling when coupon has made the order amount zero, otherwise the payment method instance will complain about not being able to handle zero amount payments
      if(paymentRequest.amount === 0 && getters.activeCoupon && getters.activeCoupon.uuid) {
        paymentInstanceUUID = 'complete';
        paymentNamespace = 'complete';
      }

      const salescloudPaymentRequest = {
        namespace: paymentNamespace,
        requestId: '123456',
        amount: paymentRequest.amount,
        currency_code: paymentRequest.currency_code,
        details: {
          cardNumber: state.card_number,
          cardSecurityCode: state.cvc,
          expiryMonth: state.exp_month,
          expiryYear: state.exp_year,
        },
        billingInfo: {
          name: state.customerName,
          email: state.customerEmail,
          phone: state.customerPhone,
        },
        comment: state.customerComment,
        location: state.locationUUID,
        items: cartItems,
        unit: state.unitID,
        coupon: getters.activeCoupon ? getters.activeCoupon.uuid : null,
        sequence: true,
        orderOrigin: 'etags',
        orderFulfillment: 'eatIn',
      };

      const paymentUrl = `${state.paymentRequestUrl}/${state.organizationUUID}/${state.channelUUID}/${paymentInstanceUUID}`;

      axios
        .post(paymentUrl, salescloudPaymentRequest)
        .then(response => {
          if (response.data) {
            commit('setPaymentResponse', response.data);
          }
        })
        .catch(error => {
          if (error) {
            commit('updateLastErrorInFull', error);
            commit('updateHasError', true);
            commit('updateMessage', 'Error 500');
            commit('updateSubMessage', 'Error connecting to server');
          }
        })
        .finally(() => {
          if (state.paymentResponse === null || typeof state.paymentResponse === 'undefined') {
            commit('updatePaymentInProgress', false);
          }
          if (state.paymentResponse && state.paymentResponse.success === false) {
            commit('updatePaymentInProgress', false);
          }
          if (
            state.paymentResponse &&
            state.paymentResponse.success === true &&
            state.paymentResponse.paymentTransaction &&
            state.paymentResponse.paymentTransaction.status !== 'pending'
          ) {
            commit('updatePaymentInProgress', false);
          }
        });
    },
    async processPayment({ commit, state, getters }, paymentRequest) {
      // This is currently ONLY run when customer is paying with browser wallet (aka card information saved in browser)
      commit('updatePaymentInProgress', true);
      let paymentInstanceUUID = state.paymentInstanceUUID;

      const cartItems = getters.getCartItemsForPayment;

      const isApplePay = paymentRequest.methodName && paymentRequest.methodName === 'https://apple.com/apple-pay' ? true : false;

      // TODO: If not allow apple pay with borgun_rpg add additional value whether or not to use apple pay method
      let paymentNamespace = 'valitor_greidslugatt';

      if (getters.hasBorgunPaymentMethod && !getters.hasValitorPaymentMethod) {
        paymentNamespace = 'borgun_rpg';
      }

      // TODO: For future refactor
      // if(typeof paymentRequest.paymentInstance !== 'undefined' && paymentRequest.paymentInstance !== null) {
      //   paymentNamespace = paymentRequest.paymentInstance.namespace
      //   paymentInstanceUUID = paymentRequest.paymentInstance.uuid
      // }

      const defaultPaymentMethodForWallet = getters.getDefaultPaymentMethodForWalletPayment;
      paymentNamespace = defaultPaymentMethodForWallet.namespace;
      paymentInstanceUUID = defaultPaymentMethodForWallet.uuid;

      // Handling when coupon has made the order amount zero, otherwise the payment method instance will complain about not being able to handle zero amount payments
      if (paymentRequest.amount === 0 && getters.activeCoupon && getters.activeCoupon.uuid) {
        paymentInstanceUUID = 'complete';
        paymentNamespace = 'complete';
      }

      const salescloudPaymentRequest = {
        namespace: paymentNamespace,
        requestId: paymentRequest.requestId,
        amount: paymentRequest.amount,
        currency_code: paymentRequest.currency_code,
        isApplePay,
        details: paymentRequest.details,
        comment: state.customerComment,
        location: state.locationUUID,
        items: cartItems,
        unit: state.unitID,
        coupon: getters.activeCoupon ? getters.activeCoupon.uuid : null,
        sequence: true,
        orderOrigin: 'etags',
        orderFulfillment: 'eatIn',
      };

      if (paymentRequest.payerName) {
        salescloudPaymentRequest.billingInfo = {};
        salescloudPaymentRequest.billingInfo.name = paymentRequest.payerName;
      }

      if (paymentRequest.payerPhone) {
        salescloudPaymentRequest.billingInfo.phone = paymentRequest.payerPhone;
      }

      if (salescloudPaymentRequest.namespace === 'verifone_ecommerce') {
        const card = {
          cardNumber: salescloudPaymentRequest.details.cardNumber,
          expiryMonth: salescloudPaymentRequest.details.expiryMonth,
          expiryYear: salescloudPaymentRequest.details.expiryYear,
          cvv: salescloudPaymentRequest.details.cardSecurityCode,
        };
        try {
          salescloudPaymentRequest.details.cardNumber = await window.verifone.encryptCard(
            card,
            process.env.VUE_APP_VERIFONE_SECURE_CAPTURE_KEY
          );
          delete salescloudPaymentRequest.details.expiryMonth;
          delete salescloudPaymentRequest.details.expiryYear;
          delete salescloudPaymentRequest.details.cardSecurityCode;
        } catch (e) {
          console.warn('[VUEX][PROCESSPAYMENT][verifone_ecommerce][window.verifone.encryptCard]');
        }
      }

      const paymentUrl = `${state.paymentRequestUrl}/${state.organizationUUID}/${state.channelUUID}/${paymentInstanceUUID}`;

      return axios
        .post(paymentUrl, salescloudPaymentRequest)
        .then(response => {
          if (response.data) {
            paymentRequest
              .complete('success')
              .then(() => {
                commit('setPaymentResponse', response.data);
              })
              .catch(error => {
                // eslint-disable-next-line no-console
                console.log('complete process payment fault', error);
              });
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log('payment request error', error);
          paymentRequest.complete('fail');
        })
        .finally(() => {
          if (state.paymentResponse === null || typeof state.paymentResponse === 'undefined') {
            commit('updatePaymentInProgress', false);
          }
          if (state.paymentResponse && state.paymentResponse.success === false) {
            commit('updatePaymentInProgress', false);
          }
          if (
            state.paymentResponse &&
            state.paymentResponse.success === true &&
            state.paymentResponse.paymentTransaction &&
            state.paymentResponse.paymentTransaction.status !== 'pending'
          ) {
            commit('updatePaymentInProgress', false);
          }
        });
    },
    setInitialData({ commit }, initialData) {
      commit('setInitialData', initialData);
    },
    updateItemsList({ commit }, newList) {
      commit('updateItemsList', newList);
    },
    updateChannelUUID({ commit }, newUUID) {
      commit('updateChannelUUID', newUUID);
    },
    updateOrganizationUUID({ commit }, newUUID) {
      commit('updateOrganizationUUID', newUUID);
    },
    updateStoredUrlParams({ commit }, newParams) {
      commit('updateStoredUrlParams', newParams);
    },
    updateSessionToken({ commit }, newToken) {
      commit('updateSessionToken', newToken);
    },
    updateOrganizationInfo({ commit }, newOrganizationInfo) {
      commit('updateOrganizationInfo', newOrganizationInfo);
    },
    updateCategories({ commit }, newCategories) {
      commit('updateCategories', newCategories);
    },
    updateCart({ commit }, newCartItem) {
      commit('updateCart', newCartItem);
    },
    goToNextCategory({ commit }) {
      commit('updateShowSubCategoryMenu', true);
    },
    goToPreviousCategory({ state, commit }) {
      if (state.sawItems) {
        const uuid = state.currentTreeLocation.pop();
        commit('updateSelectedCategory', state.itemsByCategories[uuid]);
        commit('updateCategorySelectIndex', uuid);
        commit('updateShowSubCategoryMenu', true);
      } else {
        const newTree = state.currentTreeLocation.slice(0, -1);
        if (newTree) {
          const uuid = newTree.pop();
          state.currentTreeLocation.pop();
          commit('updateSelectedCategory', state.itemsByCategories[uuid]);
          commit('updateCategorySelectIndex', uuid);
          commit('updateShowSubCategoryMenu', true);
        }
      }
    },
    setUpsell({ state, commit }, { categories, itemsList }) {
      const upsellOrganizationUUIDs = ['32036a02-fd37-4044-bbb1-e55970e4531f', '76180a17-7a76-419a-acca-a2d292af6326'];

      if (Array.isArray(categories) && upsellOrganizationUUIDs.includes(state.organizationUUID)) {
        const upsellCategory = categories.find(category => category && category.name && category.name.toLowerCase() === 'upsell');
        if (upsellCategory && Array.isArray(itemsList)) {
          const upsellItems = itemsList.filter(
            item => item && Array.isArray(item.categories) && item.categories.some(category => category.uuid === upsellCategory.uuid)
          );
          if (Array.isArray(upsellItems) && upsellItems.length > 0) {
            commit('updateUpsellItems', upsellItems);
            commit('updateUpsellCategory', upsellCategory);
            commit('updateShowOffers', true);
          }
        }
      }
    },
    setCheckoutSectionsSettings({ state, commit }, { checkoutSectionInstanceList }) {
      if (checkoutSectionInstanceList && checkoutSectionInstanceList.length) {
        checkoutSectionInstanceList.forEach(section => {
          if (typeof section !== 'undefined' && section !== null && typeof section.data !== 'undefined' && section.data !== null) {
            try {
              section.data = JSON.parse(section.data);
            } catch (e) {
              //
            }
          }
        });
      }
      state.checkoutSectionList = checkoutSectionInstanceList;
      state.checkoutSectionData = checkoutSectionInstanceList.reduce((sectionData, section) => {
        if (section && Array.isArray(section.uiFields)) {
          const setup = {
            namespace: section.namespace,
            data: {},
          };

          for (let fieldIndex = 0; fieldIndex < section.uiFields.length; fieldIndex++) {
            const field = section.uiFields[fieldIndex];
            if (field.type === 'html') {
              continue;
            }
            setup.data[field.key] = null;
          }
          sectionData.push(setup);
        }

        return sectionData;
      }, []);

      state.checkoutSectionList.forEach(section => {
        if (section.namespace === 'billing_minimal') {
          state.showCustomerInfo = true;
          state.billingMinimal = section;
        }

        if (section.namespace === 'coupon') {
          commit('setAllowCoupon', true);
        }

        if (section.namespace === 'order_comment') {
          state.showComment = true;
          state.orderComment = section;
        }

        if (section.namespace === 'waiver') {
          // Add waiver section?
        }
      });
    },
    setItemsByCategories({ state, getters }) {
      if (Array.isArray(state.itemsList)) {
        const itemsLength = state.itemsList.length;

        for (let itemIndex = 0; itemIndex < itemsLength; itemIndex++) {
          const item = state.itemsList[itemIndex];

          if (typeof item !== 'undefined' && item !== null && Object.keys(item).length > 0) {
            if (getters.hideItemsBlockedByLocation && getters.productIsBlockedByLocation(item)) {
              continue;
            }

            if (Array.isArray(item.categories) && item.categories.length > 0) {
              const itemCategories = item.categories;
              const categoriesLength = itemCategories.length;

              // Check if item is an etag item
              // Note: This is a temporary solution until we have finished phasing out usage of the Etags category
              // We do not want to fill organizations Etags with products they didn't put into etags, causing more service calls
              if (
                (typeof item.sellInEtags === 'undefined' || item.sellInEtags === null) &&
                state.organizationUUID !== 'f4f71f77-c059-4f3c-9f48-a12318714685'
              ) {
                if (!itemCategories.some(category => category.name === 'eTags')) {
                  continue;
                }
              } else if (item.sellInEtags === false) {
                continue;
              }

              // Check if item is in an excluded category
              if (Array.isArray(state.excludeItemsInCategories) && state.excludeItemsInCategories.length > 0) {
                if (itemCategories.some(category => category && state.excludeItemsInCategories.includes(category.uuid))) {
                  continue;
                }
              }

              // Check if item is in an included category
              if (Array.isArray(state.includeItemsInCategories) && state.includeItemsInCategories.length > 0) {
                if (!itemCategories.some(category => category && state.includeItemsInCategories.includes(category.uuid))) {
                  continue;
                }
              }

              for (let categoryIndex = 0; categoryIndex < categoriesLength; categoryIndex++) {
                const category = item.categories[categoryIndex];

                if (category.uuid) {
                  const catUUID = category.uuid;
                  if (
                    state.itemsByCategories[catUUID] &&
                    Array.isArray(state.itemsByCategories[catUUID].items) &&
                    !state.itemsByCategories[catUUID].items.some(i => i && i.uuid === item.uuid)
                  ) {
                    state.itemsByCategories[catUUID].items.push(item);
                  }
                  if (
                    state.itemsByCategoriesNew[catUUID] &&
                    Array.isArray(state.itemsByCategoriesNew[catUUID].items) &&
                    !state.itemsByCategoriesNew[catUUID].items.some(i => i && i.uuid === item.uuid)
                  ) {
                    state.itemsByCategoriesNew[catUUID].items.push(item);
                  }
                }
              }
            }
          }
        }
      }
    },
    setColours({ state }) {
      if (state.organization !== null) {
        if (!state.primaryColor) {
          if (state.location && state.location.primary_color) {
            state.primaryColor = state.location.primary_color;
          } else if (state.channel && state.channel.primary_color) {
            state.primaryColor = state.channel.primary_color;
          } else if (state.organization && state.organization.primary_color) {
            state.primaryColor = state.organization.primary_color;
          } else {
            state.primaryColor = 'primary';
          }
        }
        if (!state.accentColor) {
          if (state.location && state.location.accent_color) {
            state.accentColor = state.location.accent_color;
          } else if (state.channel && state.channel.accent_color) {
            state.accentColor = state.channel.accent_color;
          } else if (state.organization && state.organization.accent_color) {
            state.accentColor = state.organization.accent_color;
          } else {
            state.accentColor = 'accent';
          }
        }
      }
    },
    setupCategories({ state }, { categories }) {
      if (Array.isArray(categories) && categories.length > 0) {
        let sortedCategories = categories.sort((a, b) => a.weight - b.weight);
        state.allCategories = sortedCategories.slice();

        // Finding subcategories and top level categories
        const parsedCategories = [];
        const initialCategoriesLength = sortedCategories.length;

        for (let i = 0; i < initialCategoriesLength; i++) {
          const category = sortedCategories[i];

          if (typeof category !== 'undefined' && category !== null) {
            // Excluding certain categories
            if (state.excludeCategories.some(c => c === category.uuid)) {
              continue;
            }

            if (category.administrative === true) {
              continue;
            }

            if (Array.isArray(category.parent) && category.parent.length > 0) {
              for (let parentIndex = 0; parentIndex < category.parent.length; parentIndex++) {
                const parentCategory = category.parent[parentIndex];

                if (parentCategory.administrative === true) {
                  continue;
                }

                category.isSubCategory = true;

                const foundParent = sortedCategories.find(c => c.uuid === parentCategory.uuid);
                if (!foundParent.hasSubCategories) {
                  foundParent.hasSubCategories = true;
                }
                if (!foundParent.subCategories) {
                  foundParent.subCategories = [];
                }
                foundParent.subCategories.push(category);
              }
            }

            parsedCategories.push(category);
          }
        }

        sortedCategories = parsedCategories;

        // Categories need to be sorted by order in which they are received from params or by weight
        if (Array.isArray(state.categoriesFromParams) && state.categoriesFromParams.length > 0) {
          if (state.organizationUUID === '76180a17-7a76-419a-acca-a2d292af6326') {
            state.categoriesFromParams.push('f07416f0-ef80-4a92-a994-7e9229cdbe0a');
          }
          const sortByParamsCats = [];

          state.categoriesFromParams.forEach(item => {
            let found = false;
            sortedCategories.filter(cat => {
              if (!found && cat.uuid === item) {
                sortByParamsCats.push(cat);
                found = true;
                return false;
              }
              return true;
            });
          });
          state.categories = sortByParamsCats;
        } else {
          state.categories = sortedCategories.sort((a, b) => a.weight - b.weight);
        }
      }

      if (state.subCategoryFromParams) {
        state.categories.forEach(category => {
          if (category.hasSubCategories) {
            category.subCategories = category.subCategories.sort((a, b) => a.weight - b.weight);
          }
        });
      }
    },
    setupItems({ state, dispatch }, { categories }) {
      if (Array.isArray(categories) && categories.length > 0) {
        for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
          const category = categories[categoryIndex];

          if (category.name === 'eTags' || category.name === 'Drinks for eTags') {
            continue;
          }

          if (category.name === 'Website') {
            continue;
          }

          if (category.administrative) {
            continue;
          }

          // if(typeof category.parent !== 'undefined' && category.parent !== null) {
          //   category.isSubCategory = true
          // }

          state.itemsByCategories[category.uuid] = {
            ...category,
            items: [],
          };

          if (category.hasSubCategories) {
            dispatch('setupItems', { categories: category.subCategories });
          }
        }
      }

      // const categoriesLength = categories.length
      //
      // for (let i = 0; i < categoriesLength; i++) {
      //
      //     if (categories[i].name === 'eTags' || categories[i].name === 'Drinks for eTags') {
      //         continue;
      //     }
      //
      //     if (categories[i].name === 'Website') {
      //         continue;
      //     }
      //
      //     if (categories[i].administrative) {
      //         continue;
      //     }
      //
      //     state.itemsByCategories[categories[i].uuid] = {
      //         ...categories[i],
      //         items: []
      //     }
      //
      //     // // Checks whether we defined categories to be displayed in params
      //     // // and if the current category in the loop isn't in the definition then we remove it
      //     // if (state.categoriesFromParams && state.categoriesFromParams.length > 0) {
      //     //     if (!state.categoriesFromParams.some(c => c === categories[i].uuid)) {
      //     //         continue;
      //     //     }
      //     // }
      //     //
      //     // state.itemsByCategories[categories[i].uuid] = {
      //     //     ...categories[i],
      //     //     items: []
      //     // }
      // }
      //
      // for (const key of Object.keys(state.itemsByCategories)) {
      //     const ibcKey = state.itemsByCategories[key]
      //     if (ibcKey.hasSubCategories) {
      //         const subCategoriesLength = ibcKey.subCategories.length
      //
      //         for (let j = 0; j < subCategoriesLength; j++) {
      //             const subUUID = ibcKey.subCategories[j].uuid
      //             if (subUUID in state.itemsByCategories) {
      //                 state.itemsByCategories[subUUID].isAlsoSubCategory = true
      //             } else {
      //                 state.itemsByCategories[subUUID] = {
      //                     ...ibcKey.subCategories[j],
      //                     isSubCategory: true,
      //                     items: []
      //                 }
      //             }
      //         }
      //     }
      // }

      // SORTING ITEMS BY WEIGHT
      for (const key in state.itemsByCategories) {
        if (state.itemsByCategories[key] && state.itemsByCategories[key].items) {
          state.itemsByCategories[key].items = state.itemsByCategories[key].items.sort((a, b) => a.sorting_weight - b.sorting_weight);
        }
      }
    },
  },
  getters: {
    subCategoryDepth: state => {
      const organizationUUIDs = [
        '61404407-7714-48f8-8194-be8cc9871737', // Duck and Rose
      ];

      if (!organizationUUIDs.includes(state.organizationUUID)) {
        return 0;
      }

      const categories = state.categories.filter(category => category && category.hasSubCategories);
      for (let i = 0; i < categories.length; i++) {
        const topCategory = categories[i];
        if (!topCategory) {
          continue;
        }

        if (!Array.isArray(topCategory.subCategories) || topCategory.subCategories.length < 1) {
          continue;
        }

        for (let j = 0; j < topCategory.subCategories.length; j++) {
          const level1 = topCategory.subCategories[j];
          if (!level1) {
            continue;
          }
          if (Array.isArray(level1.subCategories) && level1.subCategories.length > 0) {
            for (let k = 0; k < level1.subCategories.length; k++) {
              const level2 = level1.subCategories[k];
              if (!level2) {
                continue;
              }
              if (Array.isArray(level2.subCategories) && level2.subCategories.length > 0) {
                return 2;
              }
            }
          }
        }
      }
      return 0;
    },
    checkoutSectionInstancesData: () => checkoutSectionInstances => {
      if (Array.isArray(checkoutSectionInstances) && checkoutSectionInstances.length > 0) {
        const checkoutSectionData = [];
        for (let sectionIndex = 0; sectionIndex < checkoutSectionInstances.length; sectionIndex++) {
          const checkoutSection = checkoutSectionInstances[sectionIndex];
          if (Array.isArray(checkoutSection.uiFields) && checkoutSection.uiFields.length > 0) {
            const sectionData = {
              namespace: checkoutSection.namespace,
              data: {},
            };

            for (let fieldIndex = 0; fieldIndex < checkoutSection.uiFields.length; fieldIndex++) {
              const field = checkoutSection.uiFields[fieldIndex];
              sectionData.data[field.key] = field.value;
            }

            sectionData.data = JSON.stringify(sectionData.data);
            checkoutSectionData.push(sectionData);
          }
        }
        return checkoutSectionData;
      }
      return null;
    },
    paymentRequestUrl: state => {
      return state.paymentRequestUrl;
    },
    itemHasSalePrice: () => item => {
      return (
        item &&
        item.sale_price !== null &&
        typeof item.sale_price !== 'undefined' &&
        item.sale_price.amount !== null &&
        typeof item.sale_price.amount !== 'undefined' &&
        item.sale_price.amount > 0
      );
    },
    itemLineVariationsPrice: (state, getters) => item => {
      let amount = 0;
      if (getters.itemHasSelectedVariations(item)) {
        amount = item.selected_variations.reduce((sum, variation) => {
          if (typeof variation === 'undefined' || variation === null) {
            return sum;
          }
          if (typeof variation.price === 'undefined' || variation.price === null) {
            return sum;
          }
          if (isNaN(Number(variation.price.amount))) {
            return sum;
          }

          return sum + Number(variation.price.amount);
        }, 0);

        if (!state.removeDefaultVariationsFromPrice && Array.isArray(item.default_variations) && item.default_variations.length > 0) {
          const missingDefaultVariations = item.default_variations.filter(
            variation => variation && !item.selected_variations.some(v => v && v.uuid === variation.uuid)
          );
          if (missingDefaultVariations.length > 0) {
            amount += missingDefaultVariations.reduce((sum, variation) => {
              if (typeof variation === 'undefined' || variation === null) {
                return sum;
              }
              if (typeof variation.price === 'undefined' || variation.price === null) {
                return sum;
              }
              if (isNaN(Number(variation.price.amount))) {
                return sum;
              }
              return sum + Number(variation.price.amount);
            }, 0);
          }
        }
      }
      return amount;
    },
    itemLineIncludesVariationsSalePrice: (state, getters) => item => {
      if (getters.itemHasSelectedVariations(item)) {
        return item.selected_variations.reduce((sum, variation) => {
          const variationSalePrice = (variation && variation.sale_price && variation.sale_price.amount) || 0;
          const variationPrice = (variation && variation.price && variation.price.amount) || 0;
          if (state.removeDefaultVariationsFromPrice && variation.removed) {
            return sum;
          }
          return sum + (variationSalePrice ? variationSalePrice : variationPrice);
        }, 0);
      }
      return 0;
    },
    itemLineSalePrice: (state, getters) => item => {
      const itemPrice = (item && item.sale_price && item.sale_price.amount) || 0;
      if (itemPrice) {
        return itemPrice + getters.itemLineVariationsPrice(item);
      }
      return getters.itemLineVariationsPrice(item);
    },
    errorMessage: state => {
      return state.errorMessage;
    },
    showBottomError: state => {
      return state.showBottomError;
    },
    paymentResponse: state => {
      return state.paymentResponse;
    },
    accentColor: state => {
      return state.accentColor;
    },
    primaryColor: state => {
      return state.primaryColor;
    },
    hasBorgunPaymentMethod: state => {
      return state.paymentInstances && state.paymentInstances.find(instance => instance.namespace === 'borgun_rpg');
    },
    hasValitorPaymentMethod: state => {
      return state.paymentInstances && state.paymentInstances.find(instance => instance.namespace === 'valitor_pay');
    },
    rules: () => {
      return {
        required: v => Boolean(v) || i18n.t('payment.required'),
      };
    },
    categoryHasSubCategories: () => (category, categories) => {
      return categories.some(cat => cat.parent && cat.parent.length && cat.parent.some(c => c.uuid === category.uuid));
    },
    hexToLuma: () => colour => {
      if (colour) {
        const hex = colour.replace(/#/, '');
        const r = parseInt(hex.substr(0, 2), 16);
        const g = parseInt(hex.substr(2, 2), 16);
        const b = parseInt(hex.substr(4, 2), 16);

        return [0.299 * r, 0.587 * g, 0.114 * b].reduce((first, second) => first + second) / 255;
      }
      return 0;
    },
    colorIsLight: (state, getters) => colour => {
      return 1 - getters.hexToLuma(colour) < 0.5;
    },
    textColor: (state, getters) => color => {
      if (getters.colorIsLight(color)) {
        return 'black--text';
      }
      return 'white--text';
    },
    categoryOpeningHourComment: (state, getters) => category => {
      let timestamp = state.systemTime;
      if (getters.previewTimestamp !== null && typeof getters.previewTimestamp !== 'undefined') {
        timestamp = getters.previewTimestamp;
      }
      const currentDay = new Date(timestamp).getUTCDay().toString();

      if (category.opening_hours && category.opening_hours.length) {
        const comments = category.opening_hours.filter(
          comment => comment !== null && typeof comment !== 'undefined' && comment.day === currentDay
        );

        if (comments && comments.length) {
          const endHours = [];
          comments.forEach(comment => {
            let end = comment.endhours && getters.hourPadding(comment.endhours, comment.endhours.length);
            if (end) {
              end = [Number(end.slice(0, 2)), Number(end.slice(2))];
              let ts = state.systemTime;
              if (getters.previewTimestamp !== null && typeof getters.previewTimestamp !== 'undefined') {
                ts = getters.previewTimestamp;
              }
              endHours.push(new Date(ts).setUTCHours(end[0], end[1]));
            }
          });
          for (let endHoursIndex = 0; endHoursIndex < endHours.length; endHoursIndex++) {
            const hour = endHours[endHoursIndex];
            let endHoursTimestamp = state.systemTime;
            if (typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
              endHoursTimestamp = getters.previewTimestamp;
            }
            if (endHoursTimestamp < hour) {
              return comments[endHoursIndex].comment;
            }
          }
          return comments[comments.length - 1].comment;
        }
        return null;
      }
      return null;
    },
    hourPadding: () => (hour, hourLength) => {
      switch (hourLength) {
        case 1:
          return hour.padStart(2, '0').padEnd(4, '0');
        case 2:
          return hour.padEnd(4, '0');
        case 3:
          return hour.padStart(4, '0');
        case 4:
          return hour;
        default:
          return null;
      }
    },
    systemTimeInMillis: state => {
      return state.systemTime;
    },
    openingHours: (state, getters) => category => {
      const openingHours = [];

      let timestamp = state.systemTime;
      if (getters.previewTimestamp !== null && typeof getters.previewTimestamp !== 'undefined') {
        timestamp = getters.previewTimestamp;
      }

      const currentDay = new Date(timestamp).getDay();

      if (category.opening_hours && category.opening_hours.length) {
        const hours = category.opening_hours.filter(
          time => time !== null && typeof time !== 'undefined' && time.day === currentDay.toString()
        );

        if (hours && hours.length) {
          hours.forEach(hour => {
            let start = hour.starthours && getters.hourPadding(hour.starthours, hour.starthours.length);
            let end = hour.endhours && getters.hourPadding(hour.endhours, hour.endhours.length);

            if (start) {
              start =
                start.length < 4
                  ? [start.slice(0, 1).padStart(2, '0'), start.slice(1).padEnd(2, '0')]
                  : [start.slice(0, 2), start.slice(2)];
            }

            if (end) {
              end = end.length < 4 ? [end.slice(0, 1).padStart(2, '0'), end.slice(1).padEnd(2, '0')] : [end.slice(0, 2), end.slice(2)];
            }

            openingHours.push({
              openHour: {
                text: start && start.join(':'),
                milliseconds: start && new Date(timestamp).setHours(Number(start[0]), Number(start[1])),
              },
              closedHour: {
                text: end && end.join(':'),
                milliseconds: end && new Date(timestamp).setHours(Number(end[0]), Number(end[1])),
              },
            });
          });
        }
      }

      return openingHours;
    },
    categoryIsOpen: (state, getters) => category => {
      let open = false;

      if (state.location && state.location.opening_rule === 'location_is_always_closed') {
        return false;
      }

      const openingHours = getters.openingHours(category);

      openingHours.forEach(time => {
        let timestampToUse = state.systemTime;
        if (typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
          timestampToUse = getters.previewTimestamp;
        }
        if (time && time.openHour && timestampToUse < time.openHour.milliseconds && !open) {
          open = false;
        } else if (time && time.openHour && timestampToUse > time.closedHour.milliseconds && !open) {
          open = false;
        } else {
          open = true;
        }
      });

      if (!openingHours || !openingHours.length) {
        open = true;
      }

      return open;
    },
    itemsInCart: state => itemUUID => {
      return state.cart.filter(item => item.uuid === itemUUID);
    },
    getAvailableLocales: () => {
      return i18n.availableLocales;
    },
    getCartItemsForPayment: (state, getters) => {
      const activeCoupon = getters.activeCoupon;

      const items = [];

      if (Array.isArray(state.cart) && state.cart.length > 0) {
        for (let cartIndex = 0; cartIndex < state.cart.length; cartIndex++) {
          const cartItem = state.cart[cartIndex];
          if (cartItem) {
            let lineItemVariations = null;

            if (Array.isArray(cartItem.selected_variations) && cartItem.selected_variations.length > 0) {
              lineItemVariations = cartItem.selected_variations.filter(variation => !variation.removed);
            }

            let itemDiscountPercent = typeof cartItem.discountPercent !== 'undefined' ? cartItem.discountPercent : 0;

            if (itemDiscountPercent < 1 && typeof activeCoupon !== 'undefined' && activeCoupon !== null) {
              if (getters.lineItemIsRedeemableOnCoupon(cartItem)) {
                itemDiscountPercent = activeCoupon.percentOff;
              }
            }

            let values = {};

            if (Array.isArray(cartItem.timeSlots) && cartItem.timeSlots.length > 0) {
              values.timeSlots = [];
              for (let slotIndex = 0; slotIndex < cartItem.timeSlots.length; slotIndex++) {
                const slot = cartItem.timeSlots[slotIndex];

                values.timeSlots.push({
                  startsAtTime: slot.timestampInMillis / 1000,
                  endsAtTime: Math.floor((slot.timestampInMillis + slot.paddingInMillis) / 1000),
                  quantity: 1,
                });
              }
            }

            items.push({
              uuid: cartItem.uuid,
              price: cartItem.sale_price && cartItem.sale_price.amount ? cartItem.sale_price : cartItem.price,
              discountPercent: itemDiscountPercent,
              variations: lineItemVariations,
              quantity: cartItem.quantity ? cartItem.quantity : 1,
              timeSlots: values.timeSlots,
            });
          }
        }
      }
      return items;
    },
    getWaitTime: state => {
      let waitTime = state.defaultWaitTime;
      if (state.location && state.location.ept) {
        waitTime = state.location.ept;
      } else if (state.channel && state.channel.ept) {
        waitTime = state.channel.ept;
      }
      return Number(waitTime) / 60;
    },
    getHourFormat: () => hourString => {
      let hour = 0;
      if (typeof hourString !== 'undefined' && hourString !== '') {
        if (hourString.includes(':')) {
          hourString = hourString.replace(/:/g, '');
        }
        if (hourString.length === 1) {
          // such as 9 instead of 0900
          hour = `0${hourString}:00`;
        }
        if (hourString.length === 2) {
          // such as 11 instead of 1100
          hour = `${hourString}:00`;
        }
        if (hourString.length === 3) {
          // such as 900 instead of 0900
          hour = `0${hourString.substring(0, 1)}:${hourString.substring(1)}`;
        }
        if (hourString.length >= 4) {
          hour = `${hourString.substring(0, 2)}:${hourString.substring(2, 4)}`;
        }
      }
      return hour;
    },
    getHourMilliseconds: (state, getters) => hourFormat => {
      if (hourFormat === null) {
        return null;
      }
      const split = hourFormat.split(':');
      const [hours, minutes] = split;
      let timestamp = state.systemTime;
      if (getters.previewTimestamp !== null && typeof getters.previewTimestamp !== 'undefined') {
        timestamp = getters.previewTimestamp;
      }
      return new Date(timestamp).setHours(hours, minutes);
    },
    getOpeningHours: (state, getters) => openingHours => {
      let timestamp = state.systemTime;
      if (getters.previewTimestamp !== null && typeof getters.previewTimestamp !== 'undefined') {
        timestamp = getters.previewTimestamp;
      }

      const currentDay = new Date(timestamp).getDay();
      const currentHours = openingHours.filter(hours => hours !== null && typeof hours !== 'undefined' && hours.day === currentDay);

      if (currentHours === null || typeof currentHours === 'undefined' || currentHours.length === 0) {
        return null;
      }

      const hoursArray = [];

      currentHours.forEach(item => {
        const openHour = getters.getHourFormat(item.starthours);
        const closedHour = getters.getHourFormat(item.endhours);
        const openHourMilliseconds = getters.getHourMilliseconds(openHour);
        const closedHourMilliseconds = getters.getHourMilliseconds(closedHour);

        hoursArray.push({
          openHour,
          openHourMilliseconds,
          closedHour,
          closedHourMilliseconds,
        });
      });

      return hoursArray;
    },
    isOpen: (state, getters) => hours => {
      let isOpen = true;
      hours.forEach(hour => {
        let timestamp = state.systemTime;
        if (typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
          timestamp = getters.previewTimestamp;
        }
        isOpen = !(timestamp < hour.openHourMilliseconds || timestamp > hour.closedHourMilliseconds);
      });
      return isOpen;
    },
    categoryIsDisabled: (state, getters) => category => {
      if (category.opening_hours && category.opening_hours.length) {
        const hours = getters.getOpeningHours(category.opening_hours);
        if (hours === null) {
          return false;
        }
        return !getters.isOpen(hours);
      }
      return false;
    },
    etagDescription: state => {
      if (state.organizationUUID === '77f503d1-8992-4d59-929a-aec764f5a01b') {
        if (state.channelUUID === 'dd1e71bf-da5d-4814-a22e-81b4fb711539') {
          return 'Við eldum allt frá grunni við hverja pöntun. Stórar pantanir eða margar pantanir í einu gætu tekið allt að 45 mínútur að undirbúa.';
        }
        return 'Við eldum allt frá grunni við hverja pöntun. Stórar pantanir eða margar pantanir í einu gætu tekið allt að 30 mínútur að undirbúa.';
      }

      if (state.locationUUID === '64ee8c41-0528-456b-b056-8eaff29e934e') {
        if (i18n.locale === 'is') {
          return (
            '<p>' +
            '<div>Kæru gestir</div>' +
            '<div>Velkominn í herbergisþjónustu Bjórgarðsins sem er í boði alla daga frá kl 12:00 – 22:30.</div>' +
            '<div>Deli kælirinn í móttökunni er opinn 24/7.</div>' +
            '</p>' +
            '<p>Þegar pöntun er framkvæmd í með QR kóða er pöntunin send inn í eldhús og til þjónanna okkar.</p>' +
            '<p style="font-weight: 600;">ÞJÓNUSTUGJALD 1000 ISK, ER SJÁLFKRAFA LAGT Á HVERJA PÖNTUN.</p>' +
            '<p>Vinsamlegast setjið tóma bakka, rusl og hnífapör fyrir utan herbergið til hreinsunar eftir máltíðina.</p>' +
            '<p>Fyrir spurningar eða sérstakar beiðnir, vinsamlegast hafið samband við okkur í síma eða skiljið eftir athugasemd í lok pöntunar.</p>'
          );
        }
        return (
          '<div>Dear guest</div>' +
          '<p>Welcome to the Beer Garden Room Service Menu. Room service is provided from 12:00 – 22:30 every day. The Deli cooler in the reception is open 24/7.</p>' +
          '<p>When placing an order through our QR system our food and beverage team will be notified instantly and preparation will begin.</p>' +
          '<p style="font-weight: 600;">ROOM SERVICE FEE OF 1.000 ISK, WILL BE AUTOMATICALLY CHARGED WITH EACH ORDER.</p>' +
          '<p>Please place empty trays, trash and cutlery in front of the room for cleaning after each meal.</p>' +
          '<p>If you have any questions or special requests, please call reception, or leave a comment at the end of the order.</p>'
        );
      }

      // Fosshótel Reykjavík
      if (state.organizationUUID === 'b8ab61c1-18f9-4a33-8513-14926bb7ea16') {
        // if(i18n.locale === 'is') {
        //   return '<p>' +
        //     '<div>Kæru gestir</div>' +
        //     '<div>Velkominn í herbergisþjónustu Bjórgarðsins sem er í boði alla daga frá kl 11:30 – 23:00.</div>' +
        //     '<div>Deli kælirinn í móttökunni er opinn 24/7.</div>' +
        //     '</p>' +
        //     '<p>Þegar pöntun er framkvæmd í með QR kóða er pöntunin send inn í eldhús og til þjónanna okkar.</p>' +
        //     '<p style="font-weight: 600;">ÞJÓNUSTUGJALD UPPÁ 1.000 ISK, ER SJÁLFKRAFA LAGT Á HVERJA PÖNTUN.</p>' +
        //     '<p>Vinsamlegast setjið tóma bakka, rusl og hnífapör fyrir utan herbergið til hreinsunar eftir máltíðina.</p>' +
        //     '<p>Fyrir spurningar eða sérstakar beiðnir, vinsamlegast hafið samband við okkur í síma eða skiljið eftir athugasemd í lok pöntunar.</p>'
        // }

        // Regular eTags description throughout the year
        return (
          '<div style="font-size: 14px;">' +
          '<p><div>Dear guest</div>' +
          '<div>Welcome to the Beer Garden Room Service Menu. Room service is provided from 12:00 – 22:30 every day. The Deli cooler in the reception is open 24/7.</div></p>' +
          '<p>When placing an order through our QR system our food and beverage team will be notified instantly and preparation will begin.</p>' +
          '<p style="font-weight: 600;">ROOM SERVICE FEE OF 1.000 ISK, WILL BE AUTOMATICALLY CHARGED WITH EACH ORDER.</p>' +
          '<p>Please place empty trays, trash and cutlery in front of the room for cleaning after each meal.</p>' +
          '<p>If you have any questions or special requests, please call the reception or leave a comment at the end of the order.</p>' +
          '</div>'
        );

        // // Union closure eTags description
        // return '<div style="font-size: 14px;">' +
        //   '<p><div>Dear guest</div>' +
        //   '<div>As a result of the industrial action organized by the trade union, we are unfortunately unable to provide room service.</div></p>' +
        //   '<p>Please kindly note that our store at the front desk is open 24/7 where you can get basic refreshments and drinks. </p>' +
        //   '<p>We sincerely apologize for this inconvenience. </p>' +
        //   '<p>Best regards, </p>' +
        //   '<p>Team Fosshotel Reykjavík</p>' +
        //   '</div>'

        // eTags description during christmas and new years.
        // return '<div style="font-size: 14px;">' +
        //  '<p><div style="font-weight: 600;">Welcome to Beer Garden</div>' +
        //  '<div>Room service is provided from 11:30–23:00 every day.</div>' +
        //  '<div>When placing an order through our QR code our Food & Beverage team will be notified instantly and preperation will begin.</div>' +
        //  '<div>For any questions or special requests about your order contact our reception for assistance.</div></p>' +
        //  '<p>Room service fee ISK 1000.- will be automatically charged with every order.</p>' +
        //  '<p style="font-weight: 600;">Kindly note, during the festive season (December 24th, 25th, 26th, 31st and January 1st) the room service fee will be ISK 3000.-</p>' +
        //  '<p>Kindly place empty trays and cutlery outside your room door.</p>' +
        //  '<p>The Deli cooler in the reception is open 24/7</p>' +
        //  '<p><div>We hope you will enjoy.</div>' +
        //  '<div>Team Beer Garden</div></p>' +
        //  '</div>'
      }

      if (
        state.organizationUUID === '940c4e5b-3ba9-4298-ae14-12d89bda3eac' &&
        state.locationUUID === 'd908247b-def8-4aa4-9773-6c3565fd0469'
      ) {
        return (
          '<div style="font-size: 12px;">' +
          // '<p>' +
          // '<div>Dear guest</div>' +
          // '<div>Our restaurant and room service are closed from 7th of February until further notice due to strike actions.  </div>' +
          // '<div>Our breakfast buffet will remain available and open on regular hours – between 7:00 – 10:00 am each morning </div>' +
          // '</p>' +
          '<p>When placing an order through the QR code our food and beverage team will be notified instantly and preparation will begin.</p>' +
          '<p style="font-weight: 600;">ROOM SERVICE FEE OF 1.000 ISK WILL BE AUTOMATICALLY CHARGED WITH EACH ORDER.</p>' +
          '<p>' +
          '<div>Please place empty trays, trash and cutlery in front of you room for cleaning after each meal.</div>' +
          '<div>If you have any questions or special requests, please call the reception, or leave a comment at the end of the order.</div>' +
          '</p>' +
          '</div>'
        );
      }

      if (state.organizationUUID === '50ca679f-5a52-44a1-b0ae-dc6f6be27651') {
        return i18n.t('special.americanBar.descriptionText');
      }

      if (state.channel && state.channel.data && state.channel.data.etags) {
        return state.channel.data.etags;
      } else if (state.location && state.location.data && state.location.data.etags) {
        return state.location.data.etags;
      }
      return null;
    },
    validateItemSelectionBeforeAddingToCart: (state, getters) => item => {
      // A little bit of insurance
      if (item === null) {
        return false;
      }

      const categories = getters.itemVariationCategories(item);
      const requiredCategories = [];

      // itemVariationCategories return null if item has no variations so we use that to our advantage
      if (categories === null) {
        return true;
      }
      // We roll through the categories of the item and check if any are required
      categories.forEach(c => {
        if (c.required) {
          requiredCategories.push(c);
        }
      });

      if (requiredCategories && requiredCategories.length > 0) {
        let missingSelection = false;
        const categoriesNotSelected = [];

        // If we have required categories we need to iterate through the item's selected variations and see if
        // they belong to the required categories/attributes
        requiredCategories.forEach(c => {
          if (item.selected_variations && item.selected_variations.length) {
            if (!item.selected_variations.some(v => v.attribute.uuid === c.uuid)) {
              missingSelection = true;
              categoriesNotSelected.push(c);
            }
          } else {
            missingSelection = true;
            categoriesNotSelected.push(c);
          }
        });

        if (missingSelection) {
          state.missingItemVariationSelection = true;
          state.itemVariationCategoriesNotSelected = categoriesNotSelected;
          return false;
        }
        state.missingItemVariationSelection = false;
        state.itemVariationCategoriesNotSelected = [];
        return true;
      }
      // If there are no required categories we validate as okay
      // What was the code below for?
      // if((!item.default_variations || !item.default_variations.length) && (!item.selected_variations || !item.selected_variations.length)) {
      // 	return false
      // } else {
      // 	return true
      // }
      return true;
    },
    categoryOnlyContainsOtherOrNone: () => categories => {
      if (categories && categories.length > 0) {
        return categories.length === 1 && categories[0].uuid === null;
      }
      return true;
    },
    variationsByAttribute: state => attribute => {
      //return state.selectedItem.variations.filter(v => v.attribute && v.attribute.uuid === attribute.uuid)
      if (state.selectedItem === null || typeof state.selectedItem === 'undefined' || !Array.isArray(state.selectedItem.variations)) {
        return [];
      }
      return state.selectedItem.variations.filter(v => {
        if (attribute.uuid === null && (v.attribute === null || v.attribute.uuid === null)) {
          return v;
        }
        return v.attribute && v.attribute.uuid === attribute.uuid;
      });
    },
    variationsByItemAttribute: () => (item, attribute) => {
      if (typeof item !== 'undefined' && item !== null) {
        if (Array.isArray(item.variations)) {
          if (typeof attribute !== 'undefined' && attribute !== null) {
            return item.variations
              .filter(variation => {
                if (typeof variation !== 'undefined' && variation !== null) {
                  if (typeof variation.attribute !== 'undefined' && variation.attribute !== null) {
                    return variation.attribute.uuid === attribute.uuid;
                  }
                }
                return false;
              })
              .sort((a, b) => a.sorting_weight - b.sorting_weight);
          }
        }
      }
      return [];
    },
    itemVariationCategories: (state, getters) => item => {
      if (getters.itemHasVariations(item)) {
        const itemAttributes = state.attributes.filter(a => {
          return a && item && Array.isArray(item.variations) && item.variations.some(v => v && v.attribute && v.attribute.uuid === a.uuid);
        });

        const hasItemsWithoutAttribute = item.variations.some(v => v.attribute === null || v.attribute.uuid === null);

        if (hasItemsWithoutAttribute) {
          itemAttributes.push({
            uuid: null,
            name: 'Annað',
            weight: '100',
            single: null,
            required: null,
          });
        }

        return itemAttributes.sort((a, b) => a.weight - b.weight); //.sort((e,f) => f.single - e.single).sort((c,d) => d.required - c.required)
      }
      return [];
    },
    itemBasePrice: (state, getters) => item => {
      if (item.price && item.price.amount) {
        if (getters.itemHasDefaultVariations(item)) {
          return (
            item.price.amount +
            item.default_variations.reduce((acc, v) => {
              return acc + v.price.amount;
            }, 0)
          );
        }
        return item.price.amount;
      } else if (getters.itemHasVariations(item)) {
        // if item has no price but variations maybe have price we return a 'min - max' price
        const max = Math.max(...item.variations.map(i => Number(i.price.amount)));
        const min = Math.min(...item.variations.map(i => Number(i.price.amount)));
        // if(!max) { return min }
        // if(!min) { return max }
        if (max === min) {
          return max;
        }
        const from = getters.currencyAsText({ amount: min, currency_code: item.variations[0].price.currency_code });
        const to = getters.currencyAsText({ amount: max, currency_code: item.variations[0].price.currency_code });
        return `${from} - ${to}`;
      }
      return null;
    },
    itemLinePrice: (state, getters) => item => {
      const itemPrice = (item && item.price && item.price.amount) || 0;

      if (itemPrice) {
        return itemPrice + getters.itemLineVariationsPrice(item);
      }
      return getters.itemLineVariationsPrice(item);
    },
    itemHasDefaultVariations: () => item => {
      return item.default_variations && item.default_variations.length > 0;
    },
    itemHasVariations: () => item => {
      if (item) {
        return Array.isArray(item.variations) && item.variations.length > 0;
      }
      return false;
    },
    itemHasSelectedVariations: () => item => {
      return item.selected_variations && item.selected_variations.length > 0;
    },
    itemHasDescriptionValue: () => item => {
      return Boolean(item.description && item.description.value);
    },
    getThumbnailImage: () => item => {
      if (
        item.images &&
        item.images.length &&
        item.images[0] &&
        item.images[0].file &&
        item.images[0].file.sources &&
        item.images[0].file.sources.thumbnail
      ) {
        return item.images[0].file.sources.thumbnail;
      }
      //return 'https://www.baconismagic.ca/wp-content/uploads/2019/01/lisbon-food-arroz-con-marisco.jpg'
      return '';
    },
    getCategoryThumbnailImage: () => item => {
      if (
        item.images &&
        item.images.length &&
        item.images[0] &&
        item.images[0].file &&
        item.images[0].file.sources &&
        item.images[0].file.sources.thumbnail
      ) {
        return item.images[0].file.sources.thumbnail;
      }
      return null;
    },
    graphUrl: state => state.graphUrl,
    itemsList: state => state.itemsList,
    organizationUUID: state => state.organizationUUID,
    channelUUID: state => state.channelUUID,
    storedUrlParams: state => state.storedUrlParams,
    sessionToken: state => state.sessionToken,
    organizationInfo: state => state.organizationInfo,
    categories: state => state.categories,
    itemsBySubCategories: (state, getters) => {
      const categories = [];

      const keys = Object.keys(state.itemsByCategories);
      const arrayLength = keys.length;

      for (let i = 0; i < arrayLength; i++) {
        if (
          state.itemsByCategories[keys[i]] &&
          state.itemsByCategories[keys[i]].items &&
          state.itemsByCategories[keys[i]].items.length > 0 &&
          (state.itemsByCategories[keys[i]].isSubCategory || state.itemsByCategories[keys[i]].isAlsoSubCategory)
        ) {
          if (
            getters.itemsByCategories[state.categorySelectIndex].subCategories.some(
              c => state.itemsByCategories[keys[i]] && c.uuid === state.itemsByCategories[keys[i]].uuid
            )
          ) {
            categories.push(state.itemsByCategories[keys[i]]);
          }
        }
      }

      categories.forEach(category => {
        category.items = category.items.sort((a, b) => a.sorting_weight - b.sorting_weight);
      });

      return categories.map((item, index) => {
        return { ...item, index };
      });
    },
    categoryHasItems: state => category => {
      return Boolean(
        state.itemsByCategories[category.uuid] &&
          state.itemsByCategories[category.uuid].items &&
          state.itemsByCategories[category.uuid].items.length
      );
    },
    categoryItemsNotBelongingToSubCategories: state => category => {
      if (!category || !category.hasSubCategories) {
        return null;
      }
      return state.itemsByCategories[category.uuid].items;
    },
    subCategories: (state, getters) => category => {
      if (!category.hasSubCategories || !category) {
        return null;
      }
      return category.subCategories.filter(cat => getters.categoryHasItems(cat));
    },
    itemsByCategory: state => category => {
      if (category === null || typeof category === 'undefined') {
        return [];
      }
      const keys = Object.keys(state.itemsByCategories);
      const arrayLength = keys.length;

      let itemsInCategory = [];

      for (let i = 0; i < arrayLength; i++) {
        if (category.uuid === keys[i] && state.itemsByCategories[keys[i]].items.length > 0) {
          itemsInCategory = state.itemsByCategories[keys[i]].items;
        }
      }

      return itemsInCategory;
    },
    categoryIteration: (state, getters) => categories => {
      if (categories && categories.length) {
        const c = categories.filter(category => {
          if (getters.categoryHasItems(category)) {
            if (!state.hideClosedCategories) {
              let returnCategory = true;

              if (category.opening_hours && category.opening_hours.length) {
                const oHours = getters.openingHours(category);

                if (oHours && oHours.length) {
                  oHours.forEach(openingHour => {
                    if (
                      openingHour &&
                      openingHour.openHour &&
                      openingHour.closedHour &&
                      openingHour.openHour.milliseconds === openingHour.closedHour.milliseconds
                    ) {
                      returnCategory = false;
                    }
                  });
                }
              }
              if (returnCategory) {
                return category;
              }
              return false;
            }
            if (getters.categoryIsOpen(category)) {
              return category;
            }
            return false;
          }
          return false;
        });

        const cat = [];
        for (let i = 0; i < c.length; i++) {
          const current = [c[i].uuid];
          cat.push(current);

          if (c[i].hasSubCategories) {
            const subs = c[i].subCategories
              .map(ca => {
                if (state.itemsByCategories[ca.uuid]) {
                  return [c[i].uuid, ca.uuid];
                }
                return null;
              })
              .filter(noNull => noNull);
            const theRest = getters.categoryIteration(c[i].subCategories).filter(hasLength => hasLength.length > 1);
            subs.forEach(sub => {
              cat.push(sub);

              if (theRest.length) {
                theRest.forEach(rest => {
                  if (rest[0] === sub.slice(-1)[0]) {
                    cat.push(sub.concat(rest.slice(1)));
                  }
                });
              }
            });
          }
        }
        return cat;
      }
      return [];
    },
    getCategorySelectList: (state, getters) => {
      if (!state.categories || !state.categories.length) {
        return [];
      }

      const start = [];
      if (state.landingPageFromParams) {
        start.push({
          text: i18n.t('category.frontPage'),
          name: i18n.t('category.frontPage'),
          value: '000',
          disabled: false,
          route: [],
        });
      }

      return start.concat(
        getters
          .categoryIteration(state.categories)
          .map(categoryUUIDs => {
            const [uuid] = categoryUUIDs.slice(-1);
            const category = state.itemsByCategories[uuid];
            let text = '';

            for (let i = 1; i < categoryUUIDs.length; i++) {
              text += '&mdash;';
            }

            if (text.length) {
              text += ` ${getters.translateByType('category.name', category)}`;
            } else {
              text = getters.translateByType('category.name', category);
            }

            let closed = false;
            if (!getters.categoryIsOpen(category)) {
              closed = true;
            }

            if (getters.categoryHasItems(category)) {
              return {
                ...category,
                text,
                name: getters.translateByType('category.name', category),
                value: uuid,
                disabled: closed,
                route: categoryUUIDs,
              };
            }
            return null;
          })
          .filter(i => i)
      );
    },
    categorySelect: (state, getters) => categories => {
      const c = categories.filter(category => {
        if (!state.hideClosedCategories) {
          let returnCategory = true;

          if (category.opening_hours && category.opening_hours.length) {
            const oHours = getters.openingHours(category);

            if (oHours && oHours.length) {
              oHours.forEach(openingHour => {
                if (
                  openingHour &&
                  openingHour.openHour &&
                  openingHour.closedHour &&
                  openingHour.openHour.milliseconds === openingHour.closedHour.milliseconds
                ) {
                  returnCategory = false;
                }
              });
            }
          }
          if (returnCategory) {
            return category;
          }
          return false;
        }
        if (getters.categoryIsOpen(category)) {
          return category;
        }
        return false;
      });

      let cats = [];
      if (c && c.length) {
        for (let i = 0; i < c.length; i++) {
          cats.push(c[i]);
          if (cats[i].hasSubCategories) {
            const sub = getters.categorySelect(cats[i].subCategories);
            cats = cats.concat(sub);
          }
        }
        cats = cats.map(ca => {
          let closed = false;
          if (!getters.categoryIsOpen(ca)) {
            closed = true;
          }
          return {
            text: ca.name,
            value: ca.uuid,
            disabled: closed,
          };
        });
      }

      return cats;
    },
    itemsByCategories: state => {
      const categories = [];

      const keys = Object.keys(state.itemsByCategories);
      const arrayLength = keys.length;

      for (let i = 0; i < arrayLength; i++) {
        if (
          state.itemsByCategories[keys[i]] &&
          state.itemsByCategories[keys[i]].items &&
          state.itemsByCategories[keys[i]].items.length > 0 &&
          state.categories.some(c => c.uuid === keys[i])
        ) {
          categories.push(state.itemsByCategories[keys[i]]);
        }
      }

      categories.forEach(category => {
        category.items = category.items.sort((a, b) => a.sorting_weight - b.sorting_weight);
      });

      if (state.landingPageFromParams && !state.singlePageCategories) {
        let items = [
          {
            name: i18n.t('category.frontPage'),
            uuid: '000',
          },
        ];
        items = items.concat(categories);

        return items.map((item, index) => {
          return { ...item, index };
        });
        // return categories.map((item, index) => { return { ...item, index: index+1 }})
      }

      return categories.map((item, index) => {
        return { ...item, index };
      });
    },
    missingInitialValues: state => {
      if (state.organizationUUID !== null && state.channelUUID !== null && state.paymentInstanceUUID !== null && state.unitID !== null) {
        return false;
      }
      return true;
    },
    initErrors: state => {
      const errors = [];

      if (state.organization === null) {
        errors.push({
          message: 'Unable to identify organization',
        });
      }

      if (state.channel === null) {
        errors.push({
          message: 'Unable to identify channel',
        });
      }

      if (state.paymentMethodInstanceOption === null) {
        errors.push({
          message: 'Unable to identify payment method',
        });
      }

      if (state.variationOption === null) {
        errors.push({
          message: 'Unable to identify variation',
        });
      }

      return errors;
    },
    cart: state => state.cart,
    cartEmpty: state => {
      if (Array.isArray(state.additionalCartItems) && state.additionalCartItems.length > 0) {
        const cartWithoutAdditionalCartItems = state.cart.filter(
          lineItem => lineItem && !state.additionalCartItems.some(item => item && item.uuid === lineItem.uuid)
        );
        return cartWithoutAdditionalCartItems.length === 0;
      }
      return state.cart.length === 0;
    },
    cartTotal: (state, getters) => {
      let currencyCode = 'ISK';
      if (getters.defaultCurrency && getters.defaultCurrency.code) {
        currencyCode = getters.defaultCurrency.code;
      }
      if (getters.defaultOrganizationCurrency) {
        currencyCode = getters.defaultOrganizationCurrency.code;
      }

      const total = {
        amount: 0,
        currency_code: currencyCode,
      };

      state.cart.forEach(item => {
        if (getters.itemHasSalePrice(item)) {
          total.amount += getters.itemLineSalePrice(item);
        } else if (getters.activeCoupon) {
          total.amount += getters.itemLinePriceWithCoupon(item);
        } else {
          total.amount += getters.itemLinePrice(item);
        }
      });

      return total;
    },
    months: () => {
      return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    },
    years: (state, getters) => {
      let systemTime = getters.systemTimeInMillis;
      if (typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
        systemTime = getters.previewTimestamp;
      }
      const currentYear = new Date(Number(systemTime)).getUTCFullYear();
      return Array.from({ length: 10 }, (_, i) => {
        return (currentYear + i).toString();
      });
      // return ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029']
    },
    paymentDisplayTotal: (state, getters) => {
      const total = {
        label: 'Total',
        amount: {
          currency: getters.defaultOrganizationCurrency.code || getters.defaultCurrency.code || 'ISK',
          value: 0,
        },
      };

      state.cart.forEach(item => {
        if (getters.itemHasSalePrice(item)) {
          total.amount.value += getters.itemLineSalePrice(item);
        } else if (getters.activeCoupon) {
          total.amount.value += getters.itemLinePriceWithCoupon(item);
        } else {
          total.amount.value += getters.itemLinePrice(item);
        }
      });

      if (total.amount.currency !== 'ISK') {
        total.amount.value = total.amount.value / 100;
      }
      return total;
    },
    paymentDisplayItems: (state, getters) => {
      const paymentDisplayItems = [];

      state.cart.forEach(item => {
        let itemValue = 0;
        if (getters.itemHasSalePrice(item)) {
          itemValue = getters.itemLineSalePrice(item);
        } else if (getters.activeCoupon) {
          itemValue = getters.itemLinePriceWithCoupon(item);
        } else {
          itemValue = getters.itemLinePrice(item);
        }
        if (item.price.currency_code !== 'ISK') {
          itemValue = itemValue / 100;
        }

        const itemTitle = getters.translateByType('item.title', item);

        paymentDisplayItems.push({
          label: itemTitle,
          amount: {
            currency: item.price.currency_code,
            value: itemValue,
          },
        });
      });

      if (getters.activeCoupon && !getters.applePayCapable) {
        paymentDisplayItems.push(...getters.additionalDisplayItems);
      }

      return paymentDisplayItems;
    },
    additionalDisplayItems: (state, getters) => {
      const paymentDisplayItems = [];

      if (getters.activeCoupon) {
        let totalBeforeDiscount = state.cart.reduce((sum, item) => {
          return sum + getters.itemLinePrice(item);
        }, 0);

        let totalWithDiscount = state.cart.reduce((sum, item) => {
          let itemValue = 0;
          if (getters.itemHasSalePrice(item)) {
            itemValue = getters.itemLineSalePrice(item);
          } else if (getters.activeCoupon) {
            itemValue = getters.itemLinePriceWithCoupon(item);
          } else {
            itemValue = getters.itemLinePrice(item);
          }

          return sum + itemValue;
        }, 0);

        if (getters.defaultCurrency !== 'ISK' && getters.defaultOrganizationCurrency !== 'ISK') {
          totalBeforeDiscount = totalBeforeDiscount / 100;
          totalWithDiscount = totalWithDiscount / 100;
        }

        const subTotal = {
          label: 'Subtotal',
          pending: true,
          amount: {
            currency: getters.defaultOrganizationCurrency.code || getters.defaultCurrency.code || 'ISK',
            value: totalBeforeDiscount,
          },
        };

        const discount = {
          label: `Discount (${getters.activeCoupon.percentOff}%)`,
          pending: true,
          amount: {
            currency: getters.defaultOrganizationCurrency.code || getters.defaultCurrency.code || 'ISK',
            value: (totalBeforeDiscount - totalWithDiscount) * -1,
          },
        };
        paymentDisplayItems.push(subTotal, discount);
      }
      return paymentDisplayItems;
    },
    locationUUID: state => {
      return state.locationUUID;
    },
    unitID: state => {
      return state.unitID;
    },
    limitCategoryOpeningHoursToParamCategories: state => {
      return state.limitCategoryOpeningHoursToParamCategories;
    },
    categoriesFromParams: state => {
      return state.categoriesFromParams;
    },
    upsellItems: state => {
      return state.upsellItems;
    },
    upsellCategory: state => {
      return state.upsellCategory;
    },
    pruneEmojisFromText: () => text => {
      // return text.replace(/(?=\p{Emoji})(?!\p{Number})/ug, '')
      return text.replace(/\p{Extended_Pictographic}/gu, '');
    },
    upsellAllowed: state => {
      return state.upsellAllowed;
    },
    agreedToAgeRestrictions: state => {
      return state.agreedToAgeRestrictions;
    },
    showSubCategoryMenu: state => {
      return state.showSubCategoryMenu;
    },
    currentTreeLocation: state => {
      return state.currentTreeLocation;
    },
    previewTimestamp: state => {
      return state.previewTimestamp;
    },
    pruneDescription: () => description => {
      let prunedDescription = description;
      const htmlTags = /(<.+(\s*.*)>|<\/.+>|<.+\s*\/>|\n|&nbsp;*)/gi;
      const tagsToSplitOn = /(<\/p>|<br\s*\/>)/gi;
      if (typeof prunedDescription === 'undefined' || prunedDescription === null) {
        prunedDescription = '';
      }
      prunedDescription = prunedDescription.split(tagsToSplitOn);
      for (let i = 0; i < prunedDescription.length; i++) {
        prunedDescription[i] = prunedDescription[i].replace(htmlTags, '').trim();
      }
      return prunedDescription.filter(line => line !== '');
    },
    showAddGuestsToTableButton: state => {
      return state.showAddGuestsToTableButton;
    },
    additionalCartItems: state => {
      return state.additionalCartItems;
    },
    locationIsOpen: (state, getters) => {
      if (!state.followLocationOpeningHours) {
        return true;
      }

      // if(typeof state.location !== 'undefined' && state.location !== null && Object.keys(state.location).length > 0) {
      //   // Assume that if location has no set opening hours at all then it is open
      //   if(!Array.isArray(state.location.opening_hours) || state.location.opening_hours.length < 1) {
      //     return true
      //   }
      //   // if(state.location.opening_rule === 'location_is_always_open') {
      //   //   return true
      //   // }
      //
      //   if(state.location.opening_rule === 'location_is_always_closed') {
      //     return false
      //   }
      //
      //   const locationOpeningHours = getters.openingHours(state.location)
      //
      //   if(Array.isArray(locationOpeningHours) && locationOpeningHours.length > 0) {
      //     let isOpen = false
      //     for(let hoursIndex = 0; hoursIndex < locationOpeningHours.length; hoursIndex++) {
      //       const openingHour = locationOpeningHours[hoursIndex]
      //
      //       if(typeof openingHour !== 'undefined' && openingHour !== null) {
      //         let timestampToUse = state.systemTime
      //         if(typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
      //           timestampToUse = getters.previewTimestamp
      //         }
      //
      //         if(openingHour.openHour && openingHour.openHour.milliseconds > timestampToUse && !isOpen) {
      //           isOpen = false
      //         } else if(openingHour.closedHour && openingHour.closedHour.milliseconds < timestampToUse && !isOpen) {
      //           isOpen = false
      //         } else {
      //           isOpen = true
      //         }
      //       }
      //     }
      //     return isOpen
      //   }
      //
      //   if(typeof state.location.opening_rule === 'undefined' || state.location.opening_rule === null) {
      //     return true
      //   }
      //
      //   return false
      // }
      return true;
    },
    locationOpeningHours: (state, getters) => {
      if (typeof state.location !== 'undefined' && state.location !== null && Object.keys(state.location).length > 0) {
        return getters.openingHours(state.location);
      }
      return [];
    },
    excludedPaymentMethods: state => {
      return state.excludedPaymentMethods;
    },
  },
  modules: {
    validations,
    coupon,
    currencies,
    product,
    variations,
    payment,
    translations,
    event,
  },
});
