import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"py-0",attrs:{"fluid":""}},[_c(VDivider,{staticClass:"grey darken-3"}),_c(VRow,[_c(VCol,{staticClass:"py-5",attrs:{"cols":"12"}},[_c(VContainer,{attrs:{"fluid":""}},[(_vm.upsellCategoryDescription)?_c(VRow,[_c(VCol,{staticClass:"text-center pb-1 pt-0 text-uppercase",staticStyle:{"font-size":"18px","font-weight":"800"},attrs:{"cols":"12"}},[_c('div',{style:(_vm.primaryColor ? 'color:' + _vm.primaryColor + ';' : ''),domProps:{"innerHTML":_vm._s(_vm.upsellCategoryDescription)}})])],1):_vm._e(),_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"pa-0 text-start",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":"","small":"","color":_vm.primaryColor},on:{"click":_vm.previous}},[_c(VIcon,{attrs:{"size":"36","left":""}},[_vm._v(" mdi-chevron-left ")])],1)],1),_c(VCol,{staticClass:"flex-grow-1 pa-0",attrs:{"cols":"10"}},[_c(VCarousel,{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._l((_vm.upsellItems),function(item){return [_c(VCarouselItem,{key:item.uuid},[_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c('UpsellItem',{attrs:{"item":item}})],1)],1)],1)]})],2)],1),_c(VCol,{staticClass:"pa-0 text-end",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":"","small":"","color":_vm.primaryColor},on:{"click":_vm.next}},[_c(VIcon,{attrs:{"right":"","size":"36"}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1),_c(VDivider,{staticClass:"grey darken-3"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }