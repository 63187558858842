import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"tile":""}},[_c(VCard,{attrs:{"tile":"","dark":"","color":_vm.primaryColor,"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('customer.comment'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.accentColor,"dark":""},on:{"click":_vm.close}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")])])],1)],1),_c(VContainer,[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":_vm.$t('customer.comment') + ' (' + _vm.$t('optional') + ')',"outlined":"","color":_vm.primaryColor,"hide-details":""},model:{value:(_vm.customerComment),callback:function ($$v) {_vm.customerComment=$$v},expression:"customerComment"}})],1)],1)],1),_c(VBtn,{attrs:{"block":"","tile":"","x-large":"","dark":"","height":"65","color":_vm.accentColor},on:{"click":_vm.logComment}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.logComment'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }