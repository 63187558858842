import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"animated-button",attrs:{"id":"add-guests-to-table","block":"","large":"","color":"red","dark":""},on:{"click":_vm.goToAddGuests}},[(!_vm.verySmallScreen)?_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-alert-circle-outline ")]):_vm._e(),_c(VSpacer),_c('span',[_vm._v(_vm._s(_vm.$t('buttons.c19Register')))]),_c(VSpacer),(!_vm.verySmallScreen)?_c(VIcon,{attrs:{"right":""}},[_vm._v(" mdi-alert-circle-outline ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }