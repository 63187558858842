import i18n from "@/i18n";

export default {
	state: {
		translations: []
	},
	mutations: {
		setTranslations(state, translations) {
            const keys = Object.keys(translations)
            for(let i = 0; i < keys.length; i++) {
                i18n.mergeLocaleMessage(keys[i], translations[keys[i]])
            }
		}
	},
	actions: {
    },
	getters: {
        translateByType: () => (typeString, object) => {
            if(typeString === null || typeof typeString === 'undefined' || object === null || typeof object === 'undefined') {
                return ''
            }
            const arr = typeString.split('.')
            let string = ''
            arr.forEach(str => {
                string += str + ':'
            })
            if(i18n.te(string + object.uuid)) {
                return i18n.t(string + object.uuid)
            }
            if(arr.length === 1) {
                return object
            } else if(arr.length === 2) {
                return object[arr[1]]
            } else if(arr.length === 3) {
                return object[arr[1]][arr[2]]
            } else if(arr.length === 4) {
                return object[arr[1]][arr[2]][arr[3]]
            }
            return 'Check translation error.'
        }
	},
}
