import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-4",attrs:{"outlined":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")]),_c(VCardText,[_c('div',{domProps:{"innerHTML":_vm._s(_vm.itemDescription)}})]),(_vm.itemHasVariations)?_c(VCardText,[_vm._l((_vm.itemVariationCategories),function(variationCategory){return [_c('div',{key:variationCategory.uuid,staticClass:"py-2"},[_c('EventItemVariationCategory',{attrs:{"item":_vm.item,"attribute":variationCategory,"selected-variations":_vm.selectedVariations},on:{"setQuantity":_vm.setVariationQuantity}})],1)]})],2):_c(VCardText,[_c('EventItemQuantity',{attrs:{"item":_vm.item,"selected-quantity":_vm.selectedItemQuantity},on:{"setQuantity":_vm.updateItemQuantity}})],1),(_vm.showAvailabilityCalendar && _vm.showDateSelectionForEvents)?_c(VCardText,[_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('selectDate'))+" ")]),_c('EventCalendar',{attrs:{"item":_vm.item,"date":_vm.selectedDate},on:{"selectDate":_vm.selectDate}})],1):_vm._e(),(_vm.selectedDate && _vm.showTimeSelectionForEvents)?_c(VCardText,[_c('div',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t('selectTime'))+" "+_vm._s(_vm.$t('for'))+" "+_vm._s(_vm.selectedDateText)+" ")]),(_vm.isGettingAvailableTimeSlots)?_c('div',{staticClass:"text-center pt-2"},[_c('div',[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1),_c('div',{staticClass:"pt-2 text--secondary"},[_vm._v(" Fetching information ")])]):_c('EventTimeSlots',{attrs:{"available-time-slots":_vm.availableTimeSlots,"selected-time-slots":_vm.selectedTimeSlots,"item":_vm.item,"selected-quantity":_vm.selectedItemQuantity},on:{"selectTimeSlot":_vm.selectTimeSlot}})],1):_vm._e(),_c(VCardActions,[_c(VBtn,{attrs:{"block":"","depressed":"","large":"","color":"success","disabled":!_vm.canAddToCart},on:{"click":_vm.addToCart}},[_vm._v(" "+_vm._s(_vm.$t('buttons.add'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }