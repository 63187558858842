import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"6"}},[_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.variationLabel)+" ")])]),_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"row align-center justify-end"},[_c(VBtn,{staticStyle:{"height":"32px !important","width":"32px !important","min-width":"32px"},attrs:{"depressed":"","disabled":!_vm.canDecrement},on:{"click":_vm.decrement}},[_c(VIcon,{attrs:{"color":_vm.primaryColor}},[_vm._v(" mdi-minus ")])],1),_c('div',{staticClass:"px-3",staticStyle:{"font-weight":"500","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.quantity)+" ")]),_c(VBtn,{staticStyle:{"height":"32px !important","width":"32px !important","min-width":"32px"},attrs:{"depressed":"","disabled":!_vm.canIncrement},on:{"click":_vm.increment}},[_c(VIcon,{attrs:{"color":_vm.primaryColor}},[_vm._v(" mdi-plus ")])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }