<template>
  <div>
    <v-row
      style="font-size: 18px; font-weight: 500;"
      dense
      class="justify-space-between align-center"
      @click="toggleVariations"
    >
      <v-col cols="auto">
        {{ attributeName }} {{ variationCategory.required ? '*' : '' }}
      </v-col>
      <v-col
        style="font-weight: 400; font-size: 14px;"
        cols="auto"
      >
        <span>
          {{ selectedVariationsInVariationCategory }}
        </span>
        <span v-if="!eventsMode">
          {{ $t('of') }}
        </span>
        <span v-if="!eventsMode">
          {{ totalVariationsInCategory }}
        </span>
        <span v-if="eventsMode">
          {{ $t('selected') }}
        </span>
        <v-icon>{{ hideVariations ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
      </v-col>
    </v-row>
    <div v-show="hideVariations !== true">
      <MultipleQuantityVariations
        v-if="variationCategory.multiple || eventsMode"
        :variations="variationsByAttribute"
        :selected-variations="selectedVariations"
        :variation-category="variationCategory"
        v-on="$listeners"
      />
      <Variations
        v-else
        :variation-category="variationCategory"
        :variations="variationsByAttribute"
        :selected-variations="selectedVariations"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import MultipleQuantityVariations from "@/components/item/variations/MultipleQuantityVariations";
import Variations from "@/components/item/variations/Variations";

export default {
    name: "VariationCategory",
    components: {
        Variations,
        MultipleQuantityVariations
    },
    props: {
        variationCategory: {
            type: Object,
            default: () => ({})
        },
        selectedVariations: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            hideVariations: true
        }
    },
    computed: {
      eventsMode() {
        return this.$store.getters.eventsMode
      },
        attributeName() {
            return this.$store.getters.translateByType('attribute.name', this.variationCategory)
        },
        variationsByAttribute() {
            if(this.categoryOnlyContainsOtherOrNone) {
                return this.selectedItem.variations
            }
            return this.$store.getters.variationsByAttribute(this.variationCategory).sort((f,s) => f.sorting_weight - s.sorting_weight).sort((a,b) => this.isDefaultVariation(b) - this.isDefaultVariation(a));
        },
        categoryOnlyContainsOtherOrNone: function() {
            return this.$store.getters.categoryOnlyContainsOtherOrNone(this.itemVariationCategories)
        },
        itemVariationCategories() {
            return this.$store.getters.itemVariationCategories(this.selectedItem)
        },
        selectedItem() {
            return this.$store.state.selectedItem
        },
        defaultVariations: function() {
            if(!this.selectedItem) { return [] }
            if(!this.selectedItem.default_variations) { return [] }
            return this.selectedItem.default_variations
        },
        selectedVariationsInVariationCategory() {
            if(typeof this.variationCategory !== 'undefined' && this.variationCategory !== null) {
                if(Array.isArray(this.selectedVariations)) {
                    return this.selectedVariations.filter(v => typeof v !== 'undefined' && v !== null
                        && !v.removed
                        && typeof v.attribute !== 'undefined' && v.attribute !== null
                        && v.attribute.uuid === this.variationCategory.uuid).length
                }
            }
            return 0
        },
        totalVariationsInCategory() {
            if(typeof this.variationCategory !== 'undefined' && this.variationCategory !== null) {
                if(this.variationCategory.single === true) {
                    return 1
                }
                if(typeof this.variationCategory.maxVariations !== 'undefined' && this.variationCategory.maxVariations > 0) {
                  return this.variationCategory.maxVariations
                }
                const variations = this.variationsByAttribute
                if(Array.isArray(variations)) {
                    return variations.filter(v => typeof v !== 'undefined' && v !== null
                        && !v.removed
                        && typeof v.attribute !== 'undefined' && v.attribute !== null
                        && v.attribute.uuid === this.variationCategory.uuid).length
                }
            }
            return 0
        },
    },
	mounted() {
		if(typeof this.variationCategory !== 'undefined' && this.variationCategory !== null && Object.keys(this.variationCategory).length > 0) {
			if(typeof this.variationCategory.required !== 'undefined' && (this.variationCategory.required === true || this.variationCategory.required === 'true')) {
				this.hideVariations = false
			}
		}
    if(this.eventsMode === true) {
      this.hideVariations = false
    }
	},
    methods: {
        toggleVariations() {
            this.hideVariations = !this.hideVariations
        },
        isDefaultVariation(variation) {
            return this.defaultVariations.some(v => v.uuid === variation.uuid);
        },
    }
}
</script>

<style scoped>

</style>
