<template>
  <v-card
    :id="`${categoryName}-select`"
    elevation="3"
    :color="categoryColor"
    :dark="!(categoryImage)"
    min-width="150"
    min-height="50"
    class="grow"
    @click="selectCategory"
  >
    <v-img
      v-if="!!(categoryImage)"
      :src="categoryImage"
      min-width="150"
      height="50"
    >
      <v-overlay
        absolute
        color="#FFFFFF"
      >
        <v-card-title
          class="text-break justify-center text-center"
          style="color: rgba(0,0,0,0.87)"
        >
          {{ categoryName }}
        </v-card-title>
      </v-overlay>
    </v-img>
    <v-card-title
      v-else
      class="text-break justify-center text-center"
    >
      {{ categoryName }}
    </v-card-title>
    <v-dialog
      v-if="categoryIsClosed"
      v-model="categoryIsClosed"
    >
      <v-card v-if="Array.isArray(categoryOpenHours) && categoryOpenHours.length > 0 && categoryOpenHours[0].openHour.milliseconds === categoryOpenHours[0].closedHour.milliseconds">
        <v-card
          tile
          elevation="0"
          dark
          :color="primary"
        >
          <v-card-title class="text-center justify-center text-break">
            {{ categoryText ? categoryText : $t('category.closedToday') }}
          </v-card-title>
        </v-card>
        <v-card-text class="text-center py-4">
          {{ $t('category.sorryClosed') }}
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            :color="accentColor"
            dark
            large
            @click="categoryIsClosed = !categoryIsClosed"
          >
            <span>
              {{ $t('buttons.close') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card
          tile
          elevation="0"
          dark
          :color="primary"
        >
          <v-card-title class="text-center justify-center text-break">
            {{ categoryText ? categoryText : $t('category.closed') }}
          </v-card-title>
        </v-card>
        <v-card-text
          v-if="categoryText"
          class="text-center py-4"
        >
          {{ categoryText }}
        </v-card-text>
        <v-card-text
          v-else
          class="text-center py-4"
        >
          <div>{{ $t('category.openingHours') }}</div>
          <div
            v-for="(time, index) in categoryOpenHours"
            :key="index"
            class="py-1"
          >
            <span v-if="time && time.openHour && time.closedHour">{{ time.openHour.text + ' - ' + time.closedHour.text }}</span>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            :color="accentColor"
            dark
            large
            @click="categoryIsClosed = !categoryIsClosed"
          >
            <span>
              {{ $t('buttons.close') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/** Shows a category as a box **/
export default {
	name: "CategoryBox",
	props: {
		category: {
            type: Object,
            default: () => ({})
        }
	},
	data() {
		return {
			categoryIsClosed: false,
		}
	},
	computed: {
		categoryName() {
            return this.$store.getters.translateByType('category.name', this.category)
		},
		categories: function() {
			return this.$store.state.categories
		},
		subCategoryFromParams() {
			return this.$store.state.subCategoryFromParams
		},
		categoryIsOpen: function() {
			return this.$store.getters.categoryIsOpen(this.category)
		},
		categoryOpenHours: function() {
			return this.$store.getters.openingHours(this.category)
		},
		categoryColor: function() {
			if(!this.categoryIsOpen) {
				return 'grey'
			}
				return this.primaryColor
		},
		categoryText: function() {
			return this.$store.getters.categoryOpeningHourComment(this.category)
		},
		primaryColor: function() {
			if(this.category.primary_color) {
				return this.category.primary_color
			}
      return this.$store.state.primaryColor
		},
		primary: function() {
			return this.$store.state.primaryColor
		},
		accentColor: function() {
			return this.$store.state.accentColor
		},
		categoryImage: function() {
			return this.$store.getters.getCategoryThumbnailImage(this.category);
		},
		isDisabled: function() {
			return this.$store.getters.categoryIsDisabled(this.category)
		},
		showCategoryMenu: function() {
			return this.$store.state.showSubCategoryMenu
		},
		subCategoriesHaveItems: function() {
			if(!this.category.hasSubCategories) {
				return false
			}
      let hasItems = false

      this.category.subCategories.forEach(category => {
        let c = this.$store.state.itemsByCategories[category.uuid]
        if(c && c.items && c.items.length) {
          hasItems = true
        }
      })
      return hasItems
		}
	},
	methods: {
		selectCategory() {
			// eslint-disable-next-line no-console
			// console.log(this.$store.getters.itemsByCategory(this.categories[0]))
			if(!this.categoryIsOpen) {
				this.categoryIsClosed = true
				return
			}

			this.$store.dispatch('updateSelectedCategory', this.category)
			this.$store.commit('addToTree', this.category.uuid)


			if(!this.showCategoryMenu) {
				this.$store.commit('updateCategorySelectIndex', this.category.uuid);
				this.$store.commit('updateShowLandingPage', false);
				this.$store.commit('updateShowSubCategoryMenu', false);
			} else {
				this.$store.commit('updateCategorySelectIndex', this.category.uuid);
				this.$store.commit('updateShowLandingPage', false);
				this.$store.commit('updateShowSubCategoryMenu', false);
      }

			if(this.category.hasSubCategories && this.subCategoriesHaveItems && this.subCategoryFromParams) {
				this.$store.dispatch('goToNextCategory')
				//this.$store.commit('addToTree', this.category.uuid)
				this.$store.commit('updateSawItems', false)
			}
		},
	},
}
</script>

<style scoped>

</style>
