import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasCurrentItemInFull)?_c(VDialog,{attrs:{"id":_vm.itemTitle,"width":"500","scrollable":""},model:{value:(_vm.hasCurrentItemInFull),callback:function ($$v) {_vm.hasCurrentItemInFull=$$v},expression:"hasCurrentItemInFull"}},[_c(VCard,[(_vm.showItemImagesInItemDetails)?_c(VImg,{staticClass:"mt-3 mx-3",staticStyle:{"background":"transparent"},attrs:{"src":_vm.itemImage,"max-height":"150","contain":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1)]},proxy:true}],null,false,2426952748)}):_vm._e(),_c(VCardTitle,{attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")]),_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.descriptionValue)}}),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"id":`${_vm.itemTitle}-back`,"color":"error","text":""},on:{"click":function($event){_vm.hasCurrentItemInFull = false}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])]),_c(VSpacer),(_vm.hasVariations && !_vm.disableVariationSelection && !_vm.isInSpecificCategory)?_c(VBtn,{attrs:{"id":`${_vm.itemTitle}-variations`,"color":_vm.accentColor,"dark":""},on:{"click":function($event){return _vm.changeVariations()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.change'))+" ")])]):_vm._e(),((_vm.hasDefaultVariations || _vm.hasPrice) && !_vm.itemIsBlockedByLocation)?_c(VBtn,{attrs:{"id":`${_vm.itemTitle}-add`,"color":_vm.accentColor,"dark":""},on:{"click":function($event){return _vm.addItemToCart()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.addSimple'))+" ")])]):_vm._e(),(_vm.itemIsBlockedByLocation)?_c(VBtn,{attrs:{"disabled":"","color":_vm.accentColor}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('soldOut'))+" ")])]):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }