import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[(Array.isArray(_vm.availableTimeSlots) && _vm.availableTimeSlots.length <= 0)?_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"flat":"","color":"grey lighten-2"}},[_c(VCardText,{staticClass:"text-center"},[_vm._v(" No available times ")])],1)],1)],1):(Array.isArray(_vm.availableTimeSlots) && _vm.availableTimeSlots.length > 0)?_c(VRow,[_vm._l((_vm.availableTimeSlots),function(slot){return [_c(VCol,{key:slot.label,attrs:{"cols":"auto"}},[(slot.availableCount < 1 || slot.isBlocked === true)?_c(VBtn,{attrs:{"disabled":"","depressed":""}},[_vm._v(" "+_vm._s(slot.label)+" "+_vm._s(_vm.$t('soldOut'))+" ")]):(_vm.slotAtThreshold(slot))?_c(VBtn,{attrs:{"color":_vm.slotIsSelected(slot) ? 'success' : 'warning',"depressed":_vm.slotIsSelected(slot),"loading":_vm.reservingSlot(slot)},on:{"click":function($event){return _vm.selectSlot(slot)}}},[_vm._v(" "+_vm._s(slot.label)+" - "+_vm._s(slot.availableCount)+" "+_vm._s(_vm.$t('remaining'))+" ")]):(_vm.slotNotAvailable(slot))?_c(VBtn,{attrs:{"disabled":"","depressed":""}},[_vm._v(" "+_vm._s(slot.label)+" - "+_vm._s(slot.availableCount)+" "+_vm._s(_vm.$t('remaining'))+" ")]):_c(VBtn,{attrs:{"color":_vm.slotIsSelected(slot) ? 'success' : '',"depressed":_vm.slotIsSelected(slot),"loading":_vm.reservingSlot(slot)},on:{"click":function($event){return _vm.selectSlot(slot)}}},[_vm._v(" "+_vm._s(slot.label)+" ")])],1)]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }