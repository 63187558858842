<template>
  <div>
    <BackButton />
    <v-container>
      <v-row
        v-if="headerText"
        dense
        justify="center"
      >
        <v-col cols="auto">
          <span
            class="display-1 font-weight-bold"
            :style="'color: ' + primaryColor + ';'"
          >{{ headerText }}</span>
        </v-col>
        <v-col
          v-if="categoryHasDescription && false"
          cols="12"
          class="px-5 text-center"
        >
          <div
            style="font-size: 16px; font-weight:400;"
            v-html="categoryDescription"
          />
        </v-col>
        <v-col
          v-if="descriptionText && false"
          cols="12"
          class="pl-5 pr-5"
        >
          <p class="text-justify ma-0">
            {{ descriptionText }}
          </p>
        </v-col>
      </v-row>
      <v-row
        v-if="showWaitTime"
        dense
        justify="center"
        class="pt-0"
      >
        <v-col
          cols="auto"
          class="caption font-weight-bold"
        >
          {{ $t('waitTime', { waitTime: waitTime }) }}
        </v-col>
      </v-row>
      <v-row
        class="overflow-y-auto"
        dense
        justify="center"
      >
        <v-col cols="auto">
          <category-list-box :categories="itemsBySubCategories" />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="showDialog"
      :value="showDialog"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('pleaseBeAware') }}
        </v-card-title>
        <v-card-text>
          <div
            style="font-size: 16px; font-weight:400;"
            v-html="categoryDescription"
          />
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <v-btn
                  large
                  text
                  color="red"
                  block
                  @click="notAgree"
                >
                  <span>
                    {{ $t('buttons.notAgree') }}
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  large
                  :color="primaryColor"
                  dark
                  @click="agree"
                >
                  <span>
                    {{ $t('buttons.agree') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryListBox from "../components/landing/CategoryListBox";
import BackButton from "@/components/BackButton";

/** Optional sub category landing page, turned on/off by setting the param showSubCategory as true/false, default is false **/
export default {
	name: "SubCategoryLandingPage",
	components: {
		BackButton,
		CategoryListBox
	},
  data() {
    return {
      showDialog: false
    }
  },
	computed: {
    agreedToAgeRestrictions() {
      return this.$store.getters.agreedToAgeRestrictions
    },
		buttonText: function() {
			// here we need to next to last value
			if(this.currentTree && this.currentTree.length) {
				return this.$t('buttons.backToCategory', { category: this.itemsByCategoriesNew[this.currentTree.slice(-1)].name })
			}
      return this.$t('buttons.backToLandingPage')
		},
		currentTree: function() {
			let fixedTree = this.$store.state.currentTreeLocation.slice()
			//fixedTree.pop()
			return fixedTree
		},
		itemsByCategoriesNew: function() {
			return this.$store.state.itemsByCategories
		},
		itemsBySubCategories: function() {
			return this.$store.getters.subCategories(this.$store.state.selectedCategory)
		},
		showWaitTime: function() {
			return this.$store.state.showWaitingTime
		},
		waitTime: function() {
			return this.$store.getters.getWaitTime
		},
		primaryColor: function() {
			return this.$store.state.primaryColor
		},
		accentColor: function() {
			return this.$store.state.accentColor
		},
		headerText: function() {
			const zoneObject = this.$store.state.variationOption;
			return zoneObject.label
			// if(zoneObject.label.toLowerCase().includes('table') || zoneObject.label.toLowerCase().includes('borð')) {
			// 	return zoneObject.label
			// } else {
			// 	return this.$t('table') + ' ' + zoneObject.label
			// }
		},
		descriptionText: function() {
			return this.$store.getters.etagDescription
		},
		selectedCategory: function() {
			return this.$store.state.selectedCategory
		},
		translatedCategoryDescription() {
            return this.$store.getters.translateByType('category.description', this.selectedCategory)
		},
		categoryDescription: function() {
			let description = this.translatedCategoryDescription
			if(description !== null && description !== undefined) {
        description = description.replace(/<p>/gi, '')
        description = description.replace(/<\/p>/gi, '')
				// eslint-disable-next-line no-irregular-whitespace
				description = description.replace(/<p>NBSP<\/p>/gi, '')
				description = description.replace(/<p>&nbsp;<\/p>/gi, '')
				description = description.replace(/<div> <\/div>\n/gi, '')
				// eslint-disable-next-line no-irregular-whitespace
				description = description.replace(/<div> <\/div>/gi, '')
				description = description.replace(/<p>\s*<\/p>/gi, '')
				description = description.replace(/<div>\s*<\/div>/gi, '')
				description = description.replace(/<div>&nbsp;<\/div>/gi, '')
				description = description.replace(/\n/gi, '')
        description = description.trim()
			}
			return description
		},
		organizationIsBarionOrMinigardurinn: function() {
			return this.$store.getters.organizationIsBarion || this.$store.getters.organizationIsMinigardurinn
		},
		categoryHasDescription: function() {
			return this.categoryDescription !== null && this.categoryDescription !== undefined && this.organizationIsBarionOrMinigardurinn === true
		}
	},
	mounted() {
		window.scrollTo(0,0)
    if(this.categoryHasDescription && this.agreedToAgeRestrictions === false) {
      this.showDialog = true
    }
	},
	methods: {
    agree() {
      this.showDialog = false
      this.$store.commit('setAgreedToAgeRestrictions', true)
    },
    notAgree() {
      this.showDialog = false
      this.$store.commit('setAgreedToAgeRestrictions', false)
      this.backToLandingPage()
    },
		backToLandingPage() {
			if(Array.isArray(this.currentTree) && this.currentTree.length > 1) {
				this.$store.dispatch('goToPreviousCategory')
			} else {
				this.$store.commit('updateShowLandingPage', true);
				this.$store.commit('updateShowSubCategoryMenu', false);
				this.$store.commit('updateCategorySelectIndex', '000');
			}
		},
	}
}
</script>

<style scoped>

</style>
