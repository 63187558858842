<template>
  <v-bottom-sheet
    v-model="showCart"
    scrollable
    :persistent="paymentInProgress || removeFromCartWarning || showCart"
  >
    <v-sheet height="auto">
      <CardAuthentication
        v-if="show3DSecure"
        :authentication-content="cardAuthenticationContent"
        @close="closeCardAuthenticationView"
      />

      <PaymentInProgress
        v-if="(paymentInProgress || paymentIsPending) && !show3DSecure"
        :pending="paymentIsPending"
      />

      <ValidationInProgress v-if="validationInProgress" />

      <PaymentSuccess
        v-if="paymentSuccess && !paymentIsPending"
        class="overflow-y-auto"
      />

      <PaymentFailure v-if="paymentFailure" />

      <BottomErrorMessage v-if="showBottomErrorMessage" />

      <RemoveFromCartConfirm
        v-if="removeFromCartWarning && !showBottomErrorMessage"
        :item-title="itemTitle(cart[selectedItemIndex])"
        @cancel="removeFromCartWarning = !removeFromCartWarning"
        @confirm="removeFromCart(selectedItemIndex)"
      />

      <CheckoutSection
        v-if="showWaiver"
        :checkout-section="waiverCheckoutSection"
        @back="showWaiver = false"
        @next="nextStep"
      />

      <CustomerComment v-if="showCustomerComment" />

      <GiftCardPayment v-if="showGiftcardPayment" />

      <FallbackPaymentForm v-if="showFallbackPaymentForm" />

      <Coupon
        v-if="showCoupon"
        @close="showCouponInput = !showCouponInput"
      />

      <PaymentMethodInstance
        v-if="showPaymentInput"
        :payment-instance="selectedPaymentMethod"
        @back="showPaymentInput = false"
        @pay="payOrder"
        @updatePaymentMethodUIFields="updateUIField"
      />

      <PaymentMethodSelectionScreen
        v-if="showPaymentSelection"
        @close="showPaymentSelection = false"
      />

      <v-card v-show="showCartCard">
        <v-card
          flat
          tile
          dark
          :color="primaryColor"
        >
          <v-card-title class="title">
            {{ $t('cart.title') }}
            <v-spacer />
            <v-btn
              v-if="allowCoupon"
              fab
              x-small
              dark
              :color="accentColor"
              class="mr-2"
              depressed
              @click="showCouponInput = !showCouponInput"
            >
              <v-icon>mdi-ticket-percent-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="shouldShowCustomerComment"
              fab
              x-small
              dark
              :color="accentColor"
              class="mr-2"
              depressed
              @click="addComment"
            >
              <v-icon>mdi-comment-text-outline</v-icon>
            </v-btn>
            <v-btn
              dark
              :color="accentColor"
              depressed
              @click="closeCart"
            >
              <span>
                {{ $t('buttons.close') }}
              </span>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-card-text class="px-0 py-1">
          <v-list
            class="overflow-y-auto"
            two-line
            :max-height="allowCoupon ? '50vh' : '60vh'"
          >
            <CustomerCommentAsText v-if="hasCustomerCommentText" />
            <CouponAsText v-if="showActiveCouponDiscount" />
            <template v-for="(item, index) in cart">
              <v-list-item
                :key="item.uuid + index"
                :ripple="false"
                @click="variationDetail(item)"
              >
                <v-list-item-avatar v-if="!hideListPictures">
                  <v-img :src="checkImage(item)" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title :class="itemBelongsToAClosedCategory(item) ? 'font-italic' : ''">
                    {{ itemTitle(item) }}
                  </v-list-item-title>

                  <v-list-item-subtitle
                    v-if="itemHasSelectedVariations(item)"
                    class="font-italic"
                    :class="itemBelongsToAClosedCategory(item) ? 'text--disabled' : ''"
                  >
                    {{ variationString(item.selected_variations) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="lineItemHasTimeSlots(item)">
                    {{ timeSlotsAsText(item) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <span
                      v-if="productHasSalePrice(item)"
                      class="font-weight-bold green--text darken-3"
                    >{{
                      currencyAsText(itemLineSalePrice(item), item.price.currency_code)
                    }}</span>
                    <span
                      :class="
                        itemBelongsToAClosedCategory(item)
                          ? 'font-italic'
                          : (activeCoupon && itemIsRedeemableOnCoupon(item)) || productHasSalePrice(item)
                            ? 'text-decoration-line-through'
                            : 'font-weight-bold green--text darken-3'
                      "
                    >{{ currencyAsText(itemLinePrice(item), item.price.currency_code) }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="activeCoupon && itemIsRedeemableOnCoupon(item) && !itemBelongsToAClosedCategory(item)"
                    class="font-weight-bold green--text darken-3"
                  >
                    {{ currencyAsText(itemLinePriceWithCoupon(item), item.price.currency_code) }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle
                    v-if="itemBelongsToAClosedCategory(item)"
                    class="text-wrap"
                  >
                    <v-icon
                      small
                      color="error"
                    >
                      mdi-alert-circle
                    </v-icon>
                    {{ $t('errors.itemCurrentlyNotAvailable') }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="showItemInfoText(item)">
                  <v-menu nudge-top="30">
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop=""
                      >
                        <v-icon color="yellow darken-3">
                          mdi-alert-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        {{ $t('nonRemovableItem') }}
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-action>

                <v-list-item-action>
                  <v-btn
                    icon
                    :color="item.active ? 'deep-purple accent-4' : 'grey'"
                    :disabled="disableRemoveFromCart(item)"
                    @click="showRemoveWarning(index)"
                    @click.stop=""
                  >
                    <v-icon
                      color="red darken-2"
                      x-large
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index < cart.length - 1"
                :key="index"
                :inset="!hideListPictures"
              />
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions
          v-if="!locationIsOpen"
          class="pa-0"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row class="ma-0">
              <v-col
                class="pa-0"
                cols="12"
              >
                <v-btn
                  disabled
                  block
                  class="ma-0"
                  height="65"
                  x-large
                  tile
                >
                  <div>
                    <div>
                      {{ $t('location.closed') }}
                    </div>
                    <div class="caption">
                      {{ $t('location.cannotOrder') }}
                    </div>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-card-actions
          v-if="paymentMethods.length > 0 && locationIsOpen"
          class="pa-0"
        >
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              v-if="eventsMode && waiverCheckoutSection && !showPayment"
              class="ma-0"
            >
              <v-col class="pa-0">
                <v-btn
                  style="white-space: normal"
                  tile
                  x-large
                  height="65"
                  block
                  color="success"
                  @click="showWaiver = true"
                >
                  <div>
                    Next
                    <v-icon right>
                      mdi-chevron-right
                    </v-icon>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-else
              class="ma-0"
            >
              <v-col
                v-if="showMorePaymentOptionsButton"
                class="pa-0"
                cols="3"
                style="border-right: 1px solid #FFFFFF"
              >
                <v-btn
                  x-large
                  tile
                  class="ma-0"
                  block
                  height="65"
                  :disabled="paymentInProgress"
                  :dark="!paymentInProgress"
                  color="green lighten-2"
                  @click="showPaymentSelection = true"
                >
                  <v-icon large>
                    mdi-dots-horizontal
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                class="pa-0"
                :cols="bottomButtonsColsWidth"
              >
                <v-btn
                  v-if="selectedPaymentMethod.isApplePay"
                  :disabled="paymentInProgress"
                  :dark="!paymentInProgress"
                  :loading="paymentInProgress"
                  tile
                  x-large
                  height="65"
                  block
                  color="black"
                  lang="is"
                  style="-webkit-appearance: -apple-pay-button; width: 100%; border-radius: 0; -apple-pay-button-style: black;"
                  @click="payApplePay"
                >
                  <span />
                </v-btn>
                <v-btn
                  v-else
                  style="white-space: normal"
                  :disabled="paymentInProgress"
                  :dark="!paymentInProgress"
                  :loading="paymentInProgress"
                  tile
                  x-large
                  height="65"
                  block
                  color="green"
                  @click="checkoutOrder"
                >
                  <div>
                    <div>{{ $t('buttons.pay') }} {{ currencyAsText(cartTotal.amount, cartTotal.currency_code) }}</div>
                    <div
                      v-if="showMorePaymentOptionsButton"
                      class="caption"
                    >
                      {{ $t('with') }} {{ selectedPaymentMethod.title }}
                    </div>
                  </div>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-sheet>
    <v-dialog
      v-if="showItemVariationDetail"
      v-model="showItemVariationDetail"
      scrollable
    >
      <v-card>
        <v-card-title>{{ selectedItemVariationDetail.title }}</v-card-title>
        <v-divider />
        <v-card-text class="pb-0 px-2">
          <v-list dense>
            <v-list-item
              v-for="(variation, index) in sortedSelectedVariationDetail"
              :key="index + selectedItemVariationDetail.uuid"
            >
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon
                    v-if="!variation.isDefault && variation.added"
                    small
                    color="success"
                  >
                    mdi-plus
                  </v-icon>
                  <v-icon
                    v-if="variation.isDefault && variation.removed"
                    small
                    color="error"
                  >
                    mdi-minus
                  </v-icon>
                  {{ variationLabel(variation) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            :color="accentColor"
            block
            large
            @click="showItemVariationDetail = !showItemVariationDetail"
          >
            <span>
              {{ $t('buttons.close') }}
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>

<script>
import axios from 'axios';
import CustomerComment from './CustomerComment';
import GiftCardPayment from './cart/GiftCardPayment';
import PaymentInProgress from './cart/PaymentInProgress';
import PaymentSuccess from './cart/PaymentSuccess';
import PaymentFailure from './cart/PaymentFailure';
import RemoveFromCartConfirm from './cart/RemoveFromCartConfirm';
import FallbackPaymentForm from './cart/FallbackPaymentForm';
import BottomErrorMessage from './cart/BottomErrorMessage';
import ValidationInProgress from './cart/ValidationInProgress';
import Coupon from './Coupon';
import PaymentMethodSelectionScreen from './cart/PaymentMethodSelectionScreen';
import PaymentMethodInstance from './cart/PaymentMethodInstance';
import CustomerCommentAsText from '@/components/CustomerCommentAsText';
import CouponAsText from '@/components/cart/CouponAsText';
import CheckoutSection from '@/components/cart/CheckoutSection';
import CardAuthentication from '@/components/cart/CardAuthentication';
/**
 * @property {Object} paymentTransaction
 * @property {String} entangle_key
 * @property {Boolean} success
 * @property {String} message
 */
export default {
  name: 'BottomCart',
  components: {
    CardAuthentication,
    CheckoutSection,
    CouponAsText,
    CustomerCommentAsText,
    PaymentMethodInstance,
    PaymentMethodSelectionScreen,
    Coupon,
    ValidationInProgress,
    BottomErrorMessage,
    FallbackPaymentForm,
    RemoveFromCartConfirm,
    PaymentFailure,
    PaymentSuccess,
    PaymentInProgress,
    GiftCardPayment,
    CustomerComment,
  },
  filters: {
    currency: function(value) {
      if (!value) {
        return value;
      }
      if (typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  data() {
    return {
      error: '',
      applePayCapable: false,
      walletPaymentAvailable: false,
      show1: false,
      showItemVariationDetail: false,
      selectedItemVariationDetail: null,
      removeFromCartWarning: false,
      filled: false,
      errorText: false,
      selectedItemIndex: null,
      selectedItem: null,
      showPaymentSelection: false,
      showPaymentInput: false,
      showWaiver: false,
      checkoutSectionList: null,
      showPayment: false,
      show3DSecure: false,
      cardAuthenticationContent: null,
      temporarilyStoredPaymentRequest: null,
      rules: {
        required: [v => !!v || this.$t('payment.required')],
      },
    };
  },
  computed: {
    eventsMode() {
      return this.$store.getters.eventsMode;
    },
    waiverCheckoutSectionFilled() {
      if (this.waiverCheckoutSection) {
        if (Array.isArray(this.waiverCheckoutSection.uiFields) && this.waiverCheckoutSection.uiFields.length > 0) {
          for (let fieldIndex = 0; fieldIndex < this.waiverCheckoutSection.uiFields.length; fieldIndex++) {
            const field = this.waiverCheckoutSection.uiFields[fieldIndex];
            if (field.type !== 'html') {
              // console.log('field', field.key, field.value)
            }
          }
          const fields = this.waiverCheckoutSection.uiFields.filter(f => f && f.type !== 'html');
          return fields.every(f => this.hasValue(f));
        }
      }
      return false;
    },
    waiverCheckoutSection() {
      const notShowWaiverForItems = [
        '5a6a333f-3e37-4bdd-96b4-070aaa7be150', // Perlan, Wonders of Iceland
      ];

      if (this.cart.every(item => item && notShowWaiverForItems.includes(item.uuid))) {
        return null;
      }

      if (Array.isArray(this.checkoutSections)) {
        const waiverIndex = this.checkoutSections.findIndex(section => section && section.namespace === 'waiver');
        if (waiverIndex >= 0) {
          return this.checkoutSections[waiverIndex];
        }
      }
      return null;
    },
    checkoutSections() {
      return this.$store.state.checkoutSectionList;
    },
    canProceedToPayment() {
      return this.$store.getters.proceedToCheckout;
    },
    locationIsOpen() {
      return this.$store.getters.locationIsOpen;
    },
    showActiveCouponDiscount() {
      if (this.activeCoupon !== null && this.activeCoupon !== undefined) {
        if (this.activeCoupon.item !== null && this.activeCoupon.item !== undefined) {
          if (Array.isArray(this.activeCoupon.item.redeemableItems) && this.activeCoupon.item.redeemableItems.length > 0) {
            return this.cart.some(item => this.itemIsRedeemableOnCoupon(item));
          }
        }
        return true;
      }
      return false;
    },
    additionalCartItems() {
      return this.$store.getters.additionalCartItems;
    },
    showMorePaymentOptionsButton() {
      return this.paymentMethods.length > 1;
    },
    paymentMethods() {
      return this.$store.getters.paymentMethods;
    },
    paymentMethodInstances() {
      return this.$store.getters.paymentMethodInstances;
    },
    selectedPaymentMethod() {
      return this.$store.getters.selectedPaymentMethod;
    },
    allowCoupon() {
      return this.$store.getters.allowCoupon;
    },
    activeCoupon() {
      return this.$store.getters.activeCoupon;
    },
    showCoupon() {
      return (
        this.showCouponInput &&
        !this.paymentInProgress &&
        !this.paymentResponse &&
        !this.fallbackPaymentForm &&
        !this.showBottomErrorMessage
      );
    },
    showCouponInput: {
      get() {
        return this.$store.getters.showCouponInput;
      },
      set(value) {
        this.$store.commit('updateShowCouponInput', value);
      },
    },
    validationInProgress() {
      return this.$store.getters.validating;
    },
    showCustomerComment() {
      return (
        this.showCustomerCommentCard &&
        !this.validationInProgress &&
        !this.paymentInProgress &&
        !this.paymentResponse &&
        !this.fallbackPaymentForm &&
        !this.showBottomErrorMessage
      );
    },
    showGiftcardPayment() {
      return (
        this.showGiftCardInput &&
        !this.validationInProgress &&
        !this.paymentInProgress &&
        !this.paymentResponse &&
        !this.fallbackPaymentForm &&
        !this.showBottomErrorMessage
      );
    },
    showFallbackPaymentForm() {
      return (
        this.fallbackPaymentForm &&
        !this.validationInProgress &&
        !this.paymentInProgress &&
        !this.paymentResponse &&
        !this.showBottomErrorMessage
      );
    },
    showBottomErrorMessage() {
      return this.$store.getters.showBottomError;
    },
    showCartCard() {
      return (
        !this.validationInProgress &&
        !this.paymentInProgress &&
        !this.paymentResponse &&
        !this.fallbackPaymentForm &&
        !this.removeFromCartWarning &&
        !this.showCustomerInformationInput &&
        !this.showCustomerCommentCard &&
        !this.showGiftCardInput &&
        !this.showBottomErrorMessage &&
        !this.showCouponInput &&
        !this.showPaymentSelection &&
        !this.showPaymentInput &&
        !this.show3DSecure
      );
    },
    hasApplePayPaymentMethod() {
      return this.$store.getters.hasApplePayPaymentMethod;
    },
    showGiftCardInput() {
      return this.$store.state.showGiftCardInput;
    },
    organizationUUID() {
      return this.$store.state.organizationUUID;
    },
    billingMinimal() {
      return this.$store.state.billingMinimal;
    },
    showCustomerInfo() {
      return this.$store.state.showCustomerInfo;
    },
    hasCustomerCommentText() {
      return !!(this.$store.state.customerComment && this.$store.state.customerComment.length);
    },
    filledCustomerInfo() {
      return this.$store.state.customerFilledInfo;
    },
    customerName: {
      get() {
        return this.$store.state.customerName;
      },
      set(name) {
        this.$store.commit('updateCustomerName', name);
      },
    },
    customerPhone: {
      get() {
        return this.$store.state.customerPhone;
      },
      set(phone) {
        this.$store.commit('updateCustomerPhone', phone);
      },
    },
    customerComment() {
      return this.$store.state.customerComment;
    },
    showCustomerInfoCard() {
      return this.$store.state.showCustomerInfo;
    },
    shouldShowCustomerComment() {
      return this.$store.state.showComment;
    },
    showCustomerCommentCard() {
      return this.$store.state.showCustomerComment;
    },
    showCustomerInformationInput: function() {
      return this.$store.state.showCustomerInformationInput;
    },
    tableText: function() {
      const zoneObject = this.$store.state.variationOption;
      return zoneObject.label;
    },
    waitTime() {
      return this.$store.getters.getWaitTime;
    },
    primaryColor: function() {
      return this.$store.state.primaryColor;
    },
    accentColor: function() {
      return this.$store.state.accentColor;
    },
    sortedSelectedVariationDetail: function() {
      if (this.selectedItemVariationDetail && this.itemHasSelectedVariations(this.selectedItemVariationDetail)) {
        let a = this.selectedItemVariationDetail.selected_variations.filter(v => v.isDefault && !v.removed);
        let b = this.selectedItemVariationDetail.selected_variations.filter(v => v.removed);
        let c = this.selectedItemVariationDetail.selected_variations.filter(v => v.added);
        let d = this.selectedItemVariationDetail.selected_variations.filter(v => !v.isDefault && !v.added);
        return a
          .concat(b)
          .concat(c)
          .concat(d);
        //return this.selectedItemVariationDetail.selected_variations.sort((a,b) => a.isDefault - b.isDefault).sort((c,d) => c.removed - d.removed).sort((e,f) => e.added - f.added)
      } else {
        return null;
      }
    },
    paymentResponse: function() {
      return this.$store.getters.paymentResponse;
    },
    paymentResponseAmount: function() {
      if (this.paymentResponse && this.paymentResponse.paymentTransaction) {
        let po = {
          amount: this.paymentResponse.paymentTransaction.amount,
          currency_code: this.paymentResponse.paymentTransaction.currency_code,
        };
        return this.$store.getters.currencyAsText(po);
      } else {
        return null;
      }
    },
    paymentInProgress: function() {
      return this.$store.state.paymentInProgress;
    },
    fallbackPaymentForm: function() {
      return this.$store.state.fallbackPaymentForm;
    },
    showCart: {
      get: function() {
        return this.$store.state.showCart;
      },
      set: function(value) {
        this.$store.commit('updateShowCart', value);
      },
    },
    card_number: {
      get: function() {
        return this.$store.state.card_number;
      },
      set: function(newValue) {
        return this.$store.commit('setCardNumber', newValue);
      },
    },
    exp_month: {
      get: function() {
        return this.$store.state.exp_month;
      },
      set: function(newValue) {
        return this.$store.commit('setExpMonth', newValue);
      },
    },
    exp_year: {
      get: function() {
        return this.$store.state.exp_year;
      },
      set: function(newValue) {
        return this.$store.commit('setExpYear', newValue);
      },
    },
    cvc: {
      get: function() {
        return this.$store.state.cvc;
      },
      set: function(newValue) {
        return this.$store.commit('setCVC', newValue);
      },
    },
    months: function() {
      return this.$store.getters.months;
    },
    years: function() {
      return this.$store.getters.years;
    },
    cart: function() {
      return this.$store.state.cart;
    },
    cartTotal: function() {
      return this.$store.getters.cartTotal;
    },
    hideListPictures() {
      return this.$store.state.hideListPictures;
    },
    paymentDisplayTotal: function() {
      return this.$store.getters.paymentDisplayTotal;
    },
    paymentDisplayItems: function() {
      return this.$store.getters.paymentDisplayItems;
    },
    cartEmpty: function() {
      return this.$store.getters.cartEmpty;
    },
    organizationName: function() {
      return this.$store.state.organization.label;
    },
    giftCardPaymentAvailable: function() {
      return this.$store.state.giftCardPaymentAvailable;
    },
    bottomButtonsColsWidth: function() {
      if (this.showMorePaymentOptionsButton) {
        return '9';
      }
      // else if((this.walletPaymentAvailable || this.applePayCapable) && !this.fallbackPaymentForm) {
      // 	if(this.giftCardPaymentAvailable) {
      // 		return '6'
      // 	}
      // 	return '9'
      // } else if(this.giftCardPaymentAvailable) {
      // 	return '9'
      // }
      return '12';
    },
    paymentSuccess() {
      if (typeof this.paymentResponse !== 'undefined' && this.paymentResponse !== null) {
        if (typeof this.paymentResponse.paymentTransaction !== 'undefined' && this.paymentResponse.paymentTransaction !== null) {
          if (this.paymentResponse.paymentTransaction.status === 'success') {
            return true;
          }
        } else if (this.paymentResponse.success === true) {
          return true;
        }
      }
      return false;
    },
    paymentFailure() {
      if (typeof this.paymentResponse !== 'undefined' && this.paymentResponse !== null) {
        if (typeof this.paymentResponse.paymentTransaction !== 'undefined' && this.paymentResponse.paymentTransaction !== null) {
          if (this.paymentResponse.paymentTransaction.status === 'failure') {
            // Trying to not show payment failure if doing 3d secure secondary payment
            if (
              typeof this.paymentResponse.paymentTransaction.payload !== 'undefined' &&
              this.paymentResponse.paymentTransaction.payload !== null &&
              this.paymentResponse.paymentTransaction.payload.mdStatus === '1'
            ) {
              return false;
            }
            return true;
          }
          return false;
        } else if (this.paymentResponse.success === false) {
          return true;
        }
      }
      return false;
    },
    paymentIsPending() {
      return (
        this.paymentResponse &&
        typeof this.paymentResponse.paymentTransaction !== 'undefined' &&
        this.paymentResponse.paymentTransaction !== null &&
        this.paymentResponse.paymentTransaction.status === 'pending'
      );
    },
    cartItems() {
      return this.$store.getters.cart;
    },
    paymentMethodInstanceApplePayCapable() {
      const list = this.$store.getters.applePayCapablePaymentMethods;
      if (Array.isArray(list) && list.length > 0) {
        return list[0];
      }
      return null;
    },
  },
  watch: {
    filled(value) {
      if (value) {
        this.errorText = false;
      }
    },
    show3DSecure(currentValue, previousValue) {
      if (currentValue === false && previousValue === true) {
        if (typeof this.paymentResponse !== 'undefined' && this.paymentResponse !== null) {
          if (typeof this.paymentResponse.paymentTransaction !== 'undefined' && this.paymentResponse.paymentTransaction !== null) {
            if (
              typeof this.paymentResponse.paymentTransaction.payload !== 'undefined' &&
              this.paymentResponse.paymentTransaction.payload !== null
            ) {
              if (this.paymentResponse.paymentTransaction.payload.mdStatus === '1') {
                let paymentRequest = {};
                if (typeof this.temporarilyStoredPaymentRequest !== 'undefined' && this.temporarilyStoredPaymentRequest !== null) {
                  paymentRequest = JSON.parse(JSON.stringify(this.temporarilyStoredPaymentRequest));

                  paymentRequest.details.skipCardVerification = true;
                  paymentRequest.details.cavv = this.paymentResponse.paymentTransaction.payload.cavv;
                  paymentRequest.details.xid = this.paymentResponse.paymentTransaction.payload.xid;
                  paymentRequest.details.mdStatus = this.paymentResponse.paymentTransaction.payload.mdStatus;
                  paymentRequest.details.dsTransId = this.paymentResponse.paymentTransaction.payload.dsTransId;

                  // Making sure we tag this payment with the same order, so we don't create two different orders
                  if (this.paymentResponse.paymentTransaction.entangle_key) {
                    paymentRequest.entangleKey = this.paymentResponse.paymentTransaction.entangle_key;
                  }
                  if (this.paymentResponse.paymentTransaction.entangleKey) {
                    paymentRequest.entangleKey = this.paymentResponse.paymentTransaction.entangleKey;
                  }

                  this.$store.dispatch('processPaymentFromInstance', paymentRequest).then(() => {
                    this.temporarilyStoredPaymentRequest = null;
                  });
                }
              }
            }
          }
        }
      }
    },
    paymentResponse(newValue) {
      if (typeof newValue !== 'undefined' && newValue !== null) {
        if (typeof newValue.paymentTransaction !== 'undefined' && newValue.paymentTransaction !== null) {
          if (newValue.paymentTransaction.status === 'pending') {
            if (typeof newValue.paymentTransaction.payload !== 'undefined' && newValue.paymentTransaction.value !== null) {
              if (
                typeof newValue.paymentTransaction.payload['3DSecureHTML'] !== 'undefined' &&
                newValue.paymentTransaction.payload['3DSecureHTML'] !== null &&
                newValue.paymentTransaction.payload['3DSecureHTML'] !== ''
              ) {
                if (!this.cardAuthenticationContent) {
                  this.cardAuthenticationContent = newValue.paymentTransaction.payload['3DSecureHTML'];
                }
                if (this.cardAuthenticationContent) {
                  setTimeout(() => {
                    this.show3DSecure = true;
                  }, 2500);
                }
              }
            }
            const payload = {
              orderUUID: newValue.paymentTransaction.uuid,
              paymentInstanceUUID: this.$store.getters.selectedPaymentMethod.uuid,
            };
            this.$store.dispatch('checkPendingPayment', payload);
            this.$store.commit('updatePendingPaymentLastCheckedDate', new Date().getTime());
          } else {
            if (newValue.paymentTransaction.status === 'success' && this.$store.state.landingPageFromParams) {
              this.$store.commit('updateCategorySelectIndex', 0);
              this.$store.commit('updateShowLandingPage', true);
            }
            this.show3DSecure = false;
            this.cardAuthenticationContent = null;
          }
        } else {
          if (newValue && newValue.success && this.$store.state.landingPageFromParams) {
            this.$store.commit('updateCategorySelectIndex', 0);
            this.$store.commit('updateShowLandingPage', true);
          }
          this.show3DSecure = false;
          this.cardAuthenticationContent = null;
        }
      }
    },
    showItemVariationDetail(value) {
      if (!value) {
        this.selectedItemVariationDetail = null;
      }
    },
  },
  beforeMount() {
    if (!this.inIframe()) {
      this.checkForWalletSupport();

      //if (window.ApplePaySession && window.ApplePaySession.canMakePayments() && this.hasValitorPaymentMethod) {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments() && this.hasApplePayPaymentMethod) {
        this.$store.commit('setApplePayCapable', true);
        this.applePayCapable = true;
      }
    }
  },
  mounted() {
    this.$store.commit('setWaiverCheckoutSection', this.waiverCheckoutSection);
    //this.$store.commit('setFallbackPaymentForm', true)
  },
  methods: {
    closeCardAuthenticationView() {
      this.show3DSecure = false;
    },
    timeSlotsAsText(item) {
      let text = '';
      if (item && Array.isArray(item.timeSlots) && item.timeSlots.length > 0) {
        for (let slotIndex = 0; slotIndex < item.timeSlots.length; slotIndex++) {
          const slot = item.timeSlots[slotIndex];

          if (slot) {
            const slotDate = new Date(slot.timestampInMillis);
            text += slotDate.toDateString() + ' @ ' + slotDate.toISOString().substring(11, 16);
          }
        }
      }
      return text;
    },
    lineItemHasTimeSlots(item) {
      if (item && Array.isArray(item.timeSlots) && item.timeSlots.length > 0) {
        return true;
      }
      return false;
    },
    hasValue(field) {
      if (field && field.key) {
        if (typeof field.value === 'undefined') {
          return false;
        }
        if (field.value === null) {
          return false;
        }
        if (field.value === false) {
          return false;
        }

        return true;
      }
      return false;
    },
    nextStep() {
      this.showWaiver = false;
      this.showPayment = true;
    },
    updateUIField({ index, value }) {
      if (
        typeof this.selectedPaymentMethod !== 'undefined' &&
        this.selectedPaymentMethod !== null &&
        Array.isArray(this.selectedPaymentMethod) &&
        this.selectedPaymentMethod.length > index
      ) {
        this.selectedPaymentMethod.uiFields[index].value = value;
      }
    },
    itemIsRedeemableOnCoupon(item) {
      return this.$store.getters.lineItemIsRedeemableOnCoupon(item);
    },
    disableRemoveFromCart(item) {
      if (typeof item !== 'undefined' && item !== null && Object.keys(item).length > 0) {
        if (Array.isArray(this.additionalCartItems) && this.additionalCartItems.length > 0) {
          return this.additionalCartItems.some(additionalItem => additionalItem.uuid === item.uuid);
        }
      }
      return false;
    },
    showItemInfoText(item) {
      return this.disableRemoveFromCart(item);
    },
    itemTitle(item) {
      return this.$store.getters.translateByType('item.title', item);
    },
    productHasSalePrice(item) {
      return this.$store.getters.itemHasSalePrice(item);
    },
    itemLineSalePrice(item) {
      return this.$store.getters.itemLineSalePrice(item);
    },
    currencyAsText(amount, currencyCode) {
      let priceObject = {
        amount: amount,
        currency_code: currencyCode,
      };
      return this.$store.getters.currencyAsText(priceObject);
    },
    itemBelongsToAClosedCategory(item) {
      let validate = this.$store.getters.validateLineItem(item);
      let itemIsBlockedByLocation = this.$store.getters.productIsBlockedByLocation(item);
      return (validate !== null && validate !== undefined && validate.success === false) || itemIsBlockedByLocation;
    },
    payWithGiftCard() {
      this.$store.commit('updateShowGiftCardInput', true);
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    payWithCard() {
      this.checkoutOrderFallback();
    },
    checkForWalletSupport: async function() {
      let details = {
        total: {
          label: 'Total',
          amount: {
            currency: 'USD',
            value: '0',
          },
        },
      };

      let supported = [
        {
          supportedMethods: 'basic-card',
        },
      ];

      if (window.PaymentRequest) {
        try {
          let canMake = await new PaymentRequest(supported, details).canMakePayment();
          if (canMake) {
            this.walletPaymentAvailable = true;
            this.$store.commit('setWalletPaymentAvailable', true);
          }
        } catch (e) {
          this.walletPaymentAvailable = false;
        }
      }
    },
    currencyFormat(value) {
      return this.$store.getters.currencyFormat(value);
    },
    checkIfRequired(type, fieldName) {
      if (type.uiFields) {
        let found = false;
        type.uiFields.forEach(field => {
          if (field.key === fieldName && field.required === true) {
            found = true;
          }
        });
        return found;
      } else {
        return false;
      }
    },
    addComment: function() {
      this.$store.commit('updateShowCustomerComment', true);
    },
    logComment: function() {
      this.$store.commit('updateShowCustomerComment', false);
    },
    fetchApplePayPaymentSession(paymentRequestUrl, organizationUUID, channelUUID, paymentMethodInstanceUUID, endpoint, event) {
      const paymentSessionUrl =
        paymentRequestUrl +
        '/' +
        organizationUUID +
        '/' +
        channelUUID +
        '/' +
        paymentMethodInstanceUUID +
        '/apple-payment-session?endpoint=' +
        endpoint +
        '&context=items.salescloud.is';

      event.complete(
        axios.get(paymentSessionUrl).then(response => {
          return response.data;
        })
      );
    },
    currency: function(value) {
      return this.$store.getters.currencyFormat(value);
    },
    backFromFallbackForm: function() {
      this.$store.commit('setFallbackPaymentForm', false);
    },
    closeCart: function() {
      this.$store.commit('updateShowCart', false);
    },
    variationDetail: function(item) {
      if (this.itemHasSelectedVariations(item)) {
        this.selectedItemVariationDetail = item;
        this.showItemVariationDetail = true;
      }
    },
    variationLabel(variation) {
      return this.$store.getters.translateByType('variation.label', variation);
    },
    variationString: function(variationArray) {
      return variationArray.map(variation => this.variationLabel(variation).trim()).join(', ');
    },
    itemHasSelectedVariations: function(item) {
      return this.$store.getters.itemHasSelectedVariations(item);
    },
    itemLinePrice: function(item) {
      return this.$store.getters.itemLinePrice(item);
    },
    itemLinePriceWithCoupon(item) {
      return this.$store.getters.itemLinePriceWithCoupon(item);
    },
    retryPayment: function() {
      this.$store.commit('setPaymentResponse', null);
    },
    removeFromCart(index) {
      this.$store.commit('removeItemFromCart', index);
      this.removeFromCartWarning = false;
      this.selectedItemIndex = null;
      this.selectedItem = null;

      if (this.cartEmpty) {
        this.$store.commit('resetCart');
        this.showCart = false;
        this.$store.commit('setActiveCoupon', null);
        this.$store.commit('updateCustomerComment', null);
      }
    },
    showRemoveWarning(index) {
      this.removeFromCartWarning = true;
      this.selectedItemIndex = index;
      this.selectedItem = this.cart && this.cart[index] ? this.cart[index] : null;
      //this.$store.commit('removeItemFromCart', index)

      if (this.cartEmpty) {
        this.showCart = false;
      }
    },
    payOrderFallback: function() {
      // if(this.showCustomerInfoCard && !this.showCustomerInformationInput) {
      // 	this.$store.commit('updateShowCustomerInformationInput', true);
      // 	return
      // }

      if (!this.filled) {
        this.errorText = true;
        return;
      }
      this.$store.dispatch('processPaymentFallback', {
        amount: this.cartTotal.amount,
        currency_code: this.cartTotal.currency_code,
      });
    },
    checkImage(item) {
      if (
        !item.images ||
        !item.images.length ||
        !item.images[0] ||
        !item.images[0].file ||
        !item.images[0].file.sources ||
        !item.images[0].file.sources.thumbnail
      ) {
        return '';
        //return 'https://www.baconismagic.ca/wp-content/uploads/2019/01/lisbon-food-arroz-con-marisco.jpg'
      } else {
        return item.images[0].file.sources.thumbnail;
      }
    },
    validateBeforePayment() {
      return this.$store.dispatch('validateBeforePayment').then(result => {
        if (result && result.success === false) {
          this.$store.commit('updateErrorMessage', result.message);
          this.$store.commit('updateErrorTitle', this.$t('errors.paymentPreprocessError'));
          this.$store.commit('updateShowBottomError', true);
        }

        return result.success;
      });
    },
    checkoutOrder() {
      if (this.fallbackPaymentForm) {
        this.payOrderFallback();
        return;
      }

      if (!this.selectedPaymentMethod.isApplePay && !this.selectedPaymentMethod.isWallet) {
        this.showPaymentInput = true;
        return;
      }

      // let validateResponse = this.validateBeforePayment()
      // if(!validateResponse) {
      // 	return
      // }

      this.$store.commit('setFallbackPaymentForm', false);

      let networks = ['diners', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'];
      let types = ['debit', 'credit', 'prepaid'];

      // ATTN: country code???

      const applePayMethod = {
        supportedMethods: 'https://apple.com/apple-pay',
        data: {
          version: 3,
          merchantIdentifier: 'merchant.salescloud.is',
          merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
          supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
          countryCode: 'IS',
        },
      };

      let supportedInstruments = [
        {
          supportedMethods: 'basic-card',
          data: { supportedNetworks: networks, supportedTypes: types },
        },
      ];

      if (this.applePayCapable) {
        supportedInstruments.push(applePayMethod);
      }

      let details = {
        total: this.paymentDisplayTotal,
        displayItems: this.paymentDisplayItems,
      };

      let options = {};

      if (this.showCustomerInfo) {
        options.requestPayerName = true;
        options.requestPayerPhone = true;
        options.requestPayerEmail = true;
      }

      // Check that the Payment Request API is available on this device
      if (window.PaymentRequest) {
        try {
          // Show donate button
          let request = new PaymentRequest(supportedInstruments, details, options);
          const $this = this;

          if (request.canMakePayment()) {
            request.onmerchantvalidation = function(event) {
              const paymentRequestUrl = $this.$store.state.paymentRequestUrl;
              const organizationUUID = $this.$store.state.organizationUUID;
              const channelUUID = $this.$store.state.channelUUID;
              const paymentMethodInstanceUUID = $this.selectedPaymentMethod.uuid;
              $this.fetchApplePayPaymentSession(
                paymentRequestUrl,
                organizationUUID,
                channelUUID,
                paymentMethodInstanceUUID,
                event.validationURL,
                event
              );
            };

            request
              .show()
              .then(
                function(instrumentResponse) {
                  instrumentResponse.amount = $this.cartTotal.amount;
                  instrumentResponse.currency_code = $this.cartTotal.currency_code;
                  $this.$store.dispatch('validateBeforePayment').then(result => {
                    if (result.success === false) {
                      $this.$store.commit('updateLastErrorInFull', result);
                      $this.$store.commit('updateErrorMessage', result.message);
                      $this.$store.commit('updateErrorTitle', this.$t('errors.paymentPreprocessError'));
                      $this.$store.commit('updateShowBottomError', true);
                      return;
                    }

                    if (result.success === true) {
                      $this.$store.dispatch('processPayment', instrumentResponse);
                    }
                  });
                }.bind(this)
              )
              .catch(
                function(err) {
                  if (err && err.code && err.code !== 20) {
                    this.checkoutOrderFallback();
                  }
                }.bind(this)
              );
          }
        } catch (e) {
          this.checkoutOrderFallback();
        }
      } else {
        this.checkoutOrderFallback();
      }
    },
    checkoutOrderFallback() {
      this.$store.commit('setFallbackPaymentForm', true);
    },
    payOrder(paymentRequest) {
      this.temporarilyStoredPaymentRequest = paymentRequest;
      this.showPaymentInput = false;

      this.$store.dispatch('validateBeforePayment').then(result => {
        if (result.success === false) {
          this.$store.commit('updateErrorMessage', result.message);
          this.$store.commit('updateErrorTitle', this.$t('errors.paymentPreprocessError'));
          this.$store.commit('updateShowBottomError', true);
          return;
        }

        if (result.success === true) {
          this.$store.commit('setFallbackPaymentForm', false);
          this.$store.dispatch('processPaymentFromInstance', paymentRequest);
        }
      });
    },
    payApplePay() {
      let networks = ['diners', 'discover', 'jcb', 'mastercard', 'unionpay', 'visa'];
      let types = ['debit', 'credit', 'prepaid'];

      const applePayMethod = {
        supportedMethods: 'https://apple.com/apple-pay',
        data: {
          version: 12,
          merchantIdentifier: 'merchant.salescloud.is',
          merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
          supportedNetworks: ['amex', 'discover', 'masterCard', 'visa'],
          countryCode: 'IS',
        },
      };

      let supportedInstruments = [
        {
          supportedMethods: 'basic-card',
          data: { supportedNetworks: networks, supportedTypes: types },
        },
      ];

      if (window.ApplePaySession.canMakePayments()) {
        supportedInstruments.push(applePayMethod);
      }

      let details = {
        total: this.paymentDisplayTotal,
        displayItems: this.paymentDisplayItems,
      };

      let options = {};

      options.requestPayerName = this.showCustomerInfo;
      options.requestPayerPhone = this.showCustomerInfo;
      options.requestPayerEmail = this.showCustomerInfo;

      // Check that the Payment Request API is available on this device
      if (window.PaymentRequest) {
        try {
          // Show donate button
          let request = new PaymentRequest(supportedInstruments, details, options);
          const $this = this;

          if (request.canMakePayment()) {
            request.onmerchantvalidation = function(event) {
              const paymentRequestUrl = $this.$store.state.paymentRequestUrl;
              const organizationUUID = $this.$store.state.organizationUUID;
              const channelUUID = $this.$store.state.channelUUID;
              const paymentMethodInstanceUUID = $this.selectedPaymentMethod.uuid;
              $this.fetchApplePayPaymentSession(
                paymentRequestUrl,
                organizationUUID,
                channelUUID,
                paymentMethodInstanceUUID,
                event.validationURL,
                event
              );
            };

            request
              .show()
              .then(
                function(instrumentResponse) {
                  instrumentResponse.paymentInstance = $this.selectedPaymentMethod;
                  instrumentResponse.amount = $this.cartTotal.amount;
                  instrumentResponse.currency_code = $this.cartTotal.currency_code;
                  if (instrumentResponse.details) {
                    instrumentResponse.details.skipCardVerification = true;
                  }

                  $this.$store
                    .dispatch('processPaymentFromInstance', instrumentResponse)
                    .then(paymentRequestResponse => {
                      if (
                        typeof paymentRequestResponse !== 'undefined' &&
                        paymentRequestResponse !== null &&
                        typeof paymentRequestResponse.paymentTransaction !== 'undefined' &&
                        paymentRequestResponse.paymentTransaction !== null &&
                        paymentRequestResponse.paymentTransaction.status === 'success'
                      ) {
                        instrumentResponse.complete('success');
                        $this.$store.commit('updateWasPaidWithApplePay', true);
                      } else {
                        instrumentResponse.complete('fail');
                      }
                    })
                    .catch(() => {
                      instrumentResponse.complete('unknown');
                    });
                }.bind(this)
              )
              .catch(
                function(err) {
                  // this.error = 'in catch'
                  if (err && err.code && err.code !== 20) {
                    // this.checkoutOrderFallback()
                  }
                }.bind(this)
              );
          }
        } catch (e) {
          // this.checkoutOrderFallback()
          // console.log(e)
        }
      } else {
        // console.log("no payment request")
        // this.checkoutOrderFallback()
      }
    },
  },
};
</script>

<style scoped></style>
