<template>
  <v-container>
    <WelcomeMessage
      v-if="showWelcomeMessage"
      v-model="showWelcomeMessage"
      @closed="closedWelcome"
    />
    <v-row>
      <v-col
        v-if="enableReservationMode"
        cols="12"
        class="pa-0"
      >
        <v-btn
          :color="accentColor"
          :dark="!accentColorIsLight"
          large
          block
          tile
          depressed
          @click="showingReservationLandingPage = true"
        >
          <v-icon left>
            mdi-keyboard-backspace
          </v-icon>
          {{ $t('buttons.back') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <LanguageSelection
          style="left: 15px; position: absolute;"
          :style="enableReservationMode ? 'top: 96px;' : (subCategoryDepth > 1) ? 'top: 0; left: 16px!important;' : 'top: 52px;'"
        />
        <v-row
          v-if="headerText"
          dense
          justify="center"
        >
          <v-col cols="auto">
            <span
              class="display-1 font-weight-bold"
              :style="'color: ' + primaryColor + ';'"
            >{{ headerText }}</span>
          </v-col>
          <v-col
            v-if="descriptionText"
            cols="12"
            class="pl-5 pr-5"
          >
            <p
              class="text-center ma-0"
              :style="descriptionTextFormat ? 'font-weight: 600; font-size: 20px;' : ''"
              v-html="descriptionText"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="showWaitTime"
          dense
          justify="center"
          class="pt-0 pb-1"
        >
          <v-col
            cols="auto"
            class="caption font-weight-bold"
          >
            {{ $t('waitTime', { waitTime: waitTime }) }}
          </v-col>
        </v-row>
        <AddGuestsToTable v-if="pandemicIsActive" />
        <Upsell v-if="showOffers" />
        <v-row v-if="!locationIsOpen">
          <v-col cols="12">
            <v-banner
              color="grey lighten-2"
              rounded
              class="text-center"
            >
              <div>
                <div
                  style="font-weight: 600; font-size: 20px;"
                  class="pb-2"
                >
                  {{ $t('location.closed') }}
                </div>
                <div
                  style="font-weight: 300;"
                  class="pb-2"
                >
                  {{ $t('location.cannotOrder') }}
                </div>
                <div
                  v-if="Array.isArray(locationOpeningHours) && locationOpeningHours.length > 0"
                  style="font-size: 14px;"
                >
                  {{ $t('openingHours') }}:
                  <template v-for="(hour, index) in locationOpeningHours">
                    <span :key="hour.openHour.text">
                      <span style="font-weight: 500;">
                        {{ hour.openHour.text }}
                      </span>
                      -
                      <span style="font-weight: 500;">
                        {{ hour.closedHour.text + ((locationOpeningHours.length > 1 && showComma(index)) ? ', ' : '') }}
                      </span>
                    </span>
                    <span
                      v-if="locationOpeningHours.length > 1 && index === locationOpeningHours.length - 2"
                      :key="hour.openHour.text + '-and'"
                    >
                      {{ $t('and') }}
                    </span>
                  </template>
                </div>
              </div>
            </v-banner>
          </v-col>
        </v-row>
        <v-row
          class="overflow-y-auto"
          dense
          justify="center"
        >
          <v-col cols="auto">
            <category-list-box :categories="itemsByCategories" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="false"
        cols="12"
      >
        <v-container class="grey lighten-2">
          <v-row
            justify="center"
            dense
          >
            <v-col cols="auto">
              <div class="subtitle-2">
                eTags by
                <a
                  target="_blank"
                  href="https://www.salescloud.is"
                  style="text-decoration: none;"
                  rel="noopener noreferrer"
                >
                  SalesCloud
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="false"
            justify="center"
            dense
          >
            <v-col cols="auto">
              <div class="caption text-center">
                {{ organizationName }} partners with SalesCloud to provide secure checkout and payments processing.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoryListBox from "../components/landing/CategoryListBox";
import WelcomeMessage from "@/components/landing/WelcomeMessage";
import LanguageSelection from "@/components/LanguageSelection";
import AddGuestsToTable from "../components/AddGuestsToTable";
import Upsell from "@/components/Upsell";

/** Optional landing page, turned on/off by setting the param landingPage as true/false, default is false **/
export default {
  name: "LandingPage",
  components: {
    Upsell,
    AddGuestsToTable,
    LanguageSelection,
    WelcomeMessage,
    CategoryListBox
  },
  data() {
    return {
      //
    }
  },
  computed: {
    subCategoryDepth() {
      return this.$store.getters.subCategoryDepth
    },
    showingReservationLandingPage: {
      get() {
        return this.$store.state.showingReservationLandingPage
      },
      set(value) {
        this.$store.commit('updateShowingReservationLandingPage', value)
      }
    },
    enableReservationMode() {
      return this.$store.state.enableReservationMode && (this.$store.state.appProfileSlug || this.$store.state.reservationWidgetUUID)
    },
    primaryColorIsLight() {
      return this.$store.getters.colorIsLight(this.primaryColor)
    },
    accentColorIsLight() {
      return this.$store.getters.colorIsLight(this.accentColor)
    },
    locationIsOpen() {
      return this.$store.getters.locationIsOpen
    },
    locationOpeningHours() {
      return this.$store.getters.locationOpeningHours
    },
    pandemicIsActive() {
      return this.$store.getters.showAddGuestsToTableButton
    },
    showOffers: function () {
      return this.$store.state.showOffers
    },
    today: function () {
      let timestamp = this.$store.getters.systemTimeInMillis
      if (this.$store.getters.previewTimestamp !== undefined && this.$store.getters.previewTimestamp !== null) {
        timestamp = this.$store.getters.previewTimestamp
      }

      return new Date(timestamp).toISOString()
    },
    categories: function () {
      return this.$store.state.categories
    },
    showWelcomeMessage: function () {
      return this.$store.state.showWelcomeMessage
    },
    organizationName: function () {
      return this.$store.state.organization.label
    },
    hasLandingPageFromParams: function () {
      return this.$store.state.landingPageFromParams
    },
    itemsByCategories: function () {
      if (this.hasLandingPageFromParams) {
        return this.$store.getters.itemsByCategories.slice(1)
      }
      return this.$store.getters.itemsByCategories
    },
    showWaitTime: function () {
      return this.$store.state.showWaitingTime
    },
    waitTime: function () {
      return this.$store.getters.getWaitTime
    },
    primaryColor: function () {
      return this.$store.state.primaryColor
    },
    accentColor: function () {
      return this.$store.state.accentColor
    },
    headerText: function () {
      const zoneObject = this.$store.state.variationOption;
      return zoneObject.label
      // if(zoneObject.label.toLowerCase().includes('table') || zoneObject.label.toLowerCase().includes('borð')) {
      // 	return zoneObject.label
      // } else {
      // 	return this.$t('table') + ' ' + zoneObject.label
      // }
    },
    descriptionText: function () {
      return this.$store.getters.etagDescription
    },
    organizationUUID() {
      return this.$store.getters.organizationUUID
    },
    descriptionTextFormat() {
      return this.organizationUUID === '50ca679f-5a52-44a1-b0ae-dc6f6be27651'
    }
  },
  mounted() {
    this.$store.commit('updateCurrentTreeLocation', [])
    this.$store.commit('updateSawItems', false)

    // https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
    // window.navigator.userLanguage is IE only and it's the language set in Windows Control Panel - Regional Options
    // and NOT browser language, but you could suppose that a user using a machine with Window Regional settings set to France is probably a French user.
    // navigator.language is FireFox and all other browser.
    //let lang = window.navigator.languages ? window.navigator.languages[0] : (window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage || window.navigator.systemLanguage);
    //if(lang) {
    //	if(lang.length > 2) {
    //		this.$store.commit('setLanguage', lang.substr(0,2))
    //	} else {
    //		this.$store.commit('setLanguage', lang)
    //	}
    //}
  },
  methods: {
    closedWelcome() {
      this.$store.commit('updateShowWelcomeMessage', false)
      //add to local storage?
    },
    textColor(color) {
      return this.$store.getters.textColor(color)
    },
    showComma(index) {
      return this.locationOpeningHours.length > 1 && index < this.locationOpeningHours.length - 2
    }
  }
}
</script>

<style scoped>

</style>
