import axios from "axios";

/**
 * @property percentOff
 * @property exclude_discounts
 */
export default {
  state: {
    activeCoupon: null,
    showCouponInput: false,
    gettingCoupon: false,
    allowCoupon: false,
  },
  mutations: {
    setAllowCoupon(state, status) {
      state.allowCoupon = status
    },
    setActiveCoupon(state, coupon) {
      state.activeCoupon = coupon
    },
    updateShowCouponInput(state, status) {
      state.showCouponInput = status
    },
    updateGettingCoupon(state, status) {
      state.gettingCoupon = status
    }
  },
  actions: {
    getCoupon({commit, getters}, couponCode) {
      commit('updateGettingCoupon', true)

      let couponCodeTemp = couponCode
      if (typeof couponCodeTemp !== 'undefined' && couponCodeTemp !== null) {
        couponCodeTemp = couponCodeTemp.toUpperCase()
      }

      const body = {
        query: 'query {\n' +
          `   coupon(code: "${couponCodeTemp}", organization: "${getters.organizationUUID}"){\n` +
          '       uuid\n' +
          '       label\n' +
          '       description\n' +
          '       code\n' +
          '       percentOff\n' +
          '       item {\n' +
          '           uuid\n' +
          '           title\n' +
          '           redeemableItems\n' +
          '       }\n' +
          '   }\n' +
          '}'
      }

      return axios.post(getters.graphUrl, body).then(result => {
        const activeCoupon = result.data.data.coupon
        if (activeCoupon) {
          commit('setActiveCoupon', activeCoupon)
        }
        return activeCoupon
      }).catch(() => {
        return null
      }).finally(() => {
        commit('updateGettingCoupon', false)
      })
    }
  },
  getters: {
    allowCoupon: (state) => {
      return state.allowCoupon
    },
    activeCoupon: (state) => {
      return state.activeCoupon
    },
    activeCouponRedeemableItems: (state) => {
      if (typeof state.activeCoupon !== 'undefined' && state.activeCoupon !== null) {
        if (typeof state.activeCoupon.item !== 'undefined' && state.activeCoupon.item !== null) {
          if (Array.isArray(state.activeCoupon.item.redeemableItems)) {
            return state.activeCoupon.item.redeemableItems
          }
        }
      }
      return []
    },
    showCouponInput: (state) => {
      return state.showCouponInput
    },
    gettingCoupon: (state) => {
      return state.gettingCoupon
    },
    getAmountWithCouponApplied: (state) => (amount) => {
      if (state.activeCoupon && state.activeCoupon.percentOff) {
        return amount - Math.floor((state.activeCoupon.percentOff / 100) * amount)
      }
      return amount
    },
    lineItemIsRedeemableOnCoupon: (state, getters) => (item) => {
      if(item.exclude_discounts) {
        return false
      }

      if(!item.exclude_discounts) {
        if(typeof state.activeCoupon !== 'undefined' && state.activeCoupon !== null) {
          if (getters.activeCouponRedeemableItems.length > 0) {
            return getters.activeCouponRedeemableItems.includes(item.uuid)
          }
        }
      }
      return true
    },
    itemLinePriceWithCoupon: (state, getters) => (item) => {
      if (item.exclude_discounts) {
        return getters.itemLinePrice(item)
      } else if (getters.itemHasSalePrice(item)) {
        return getters.itemLineSalePrice(item)
      } else if (getters.activeCouponRedeemableItems.length > 0 && !getters.activeCouponRedeemableItems.includes(item.uuid)) {
        return getters.itemLinePrice(item)
      }
      return getters.getAmountWithCouponApplied(getters.itemLinePrice(item))
    }
  }
}
