<template>
  <div>
    <template v-for="variation in variations">
      <MultipleQuantityVariationsItem
        :key="variation.uuid"
        :variation="variation"
        :selected-quantity="selectedQuantity(variation)"
        :max="variationCategoryMaximumVariationCount"
        :min="variationCategoryMinimumVariationCount"
        :total-quantity-in-category="totalVariationQuantityFromVariationCategory"
        :max-variations-reached="maxVariationsReached"
        @setQuantity="setVariationQuantity"
      />
    </template>
  </div>
</template>

<script>
import MultipleQuantityVariationsItem from "@/components/item/variations/MultipleQuantityVariationsItem";

/**
 * @property maximumVariationQuantity
 * @property minimumVariationQuantity
 */
export default {
    name: "MultipleQuantityVariations",
    components: {
        MultipleQuantityVariationsItem
    },
    props: {
        variations: {
            type: Array,
            default: () => []
        },
        selectedVariations: {
            type: Array,
            default: () => []
        },
        variationCategory: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            totalQuantity: 0
        }
    },
    computed: {
        variationCategoryExists() {
            return typeof this.variationCategory !== 'undefined' && this.variationCategory !== null
        },
        variationCategoryMinimumVariationCount() {
            if(this.variationCategoryExists) {
                if(typeof this.variationCategory.minimumVariationQuantity !== 'undefined') {
                    return this.variationCategory.minimumVariationQuantity
                }
            }
            return 0
        },
        variationCategoryMaximumVariationCount() {
            let maxVariationCount = 10
            if(this.variationCategoryExists) {
                if(typeof this.variationCategory.maximumVariationQuantity !== 'undefined') {
                    maxVariationCount = this.variationCategory.maximumVariationQuantity
                }
                if(typeof this.variationCategory.maxVariations !== 'undefined' && this.variationCategory.maxVariations > 0) {
                  maxVariationCount = this.variationCategory.maxVariations
                }
            }
            return maxVariationCount
        },
        selectedVariationsInCategory() {
            if(this.variationCategoryExists && Array.isArray(this.selectedVariations)) {
                return this.selectedVariations.filter(v => typeof v !== 'undefined' && v !== null
                    && typeof v.attribute !== 'undefined' && v.attribute !== null
                    && v.attribute.uuid === this.variationCategory.uuid)
            }
            return []
        },
      maxVariationsReached() {
          return (this.variationCategoryMaximumVariationCount - this.selectedVariationsInCategory.length) <= 0
      },
        totalVariationQuantityFromVariationCategory() {
            return this.selectedVariationsInCategory.length
        }
    },
    methods: {
        setVariationQuantity({ variation, quantity }) {
            const newSelectedVariations = this.selectedVariations.filter(v => v.uuid !== variation.uuid)
            for(let i = 0; i < quantity; i++) {
                newSelectedVariations.push(variation)
            }
            this.$emit('setSelectedVariations', newSelectedVariations)
        },
        selectedQuantity(variation) {
            return this.selectedVariations.filter(v => v.uuid === variation.uuid).length
        }
    }
}
</script>

<style scoped>

</style>
