export default {
	state: {},
	mutations: {},
	actions: {},
	getters: {
		defaultVariations: () => (item) => {
			return item && item.default_variations || []
		},
		selectedVariations: () => (item) => {
			return item && item.selected_variations || []
		},
		availableVariations: () => (item) => {
			return item && item.variations || []
		},
		variationIsDefaultVariation: (state, getters) => (variation, item) => {
			return getters.defaultVariations(item).some(v => v.uuid === variation.uuid)
		},
		variationIsSelected: (state, getters) => (variation, item) => {
			return getters.selectedVariations(item).some(v => v.uuid === variation.uuid)
		},
		variationsIsRemovedDefaultVariation: (state, getters) => (variation, item) => {
			return getters.variationIsDefaultVariation(variation, item) && !getters.variationIsSelected(variation, item)
		},
		variationsBasePrice: () => (variations) => {
			if(variations && Array.isArray(variations)) {
				return variations.reduce((variation, sum) => {
					if(variation && variation.price && variation.price.amount) {
						return sum + variation.price.amount
					}
					return sum
				}, 0)
			}
			return 0
		},
		variationsBaseIncludingSalePrice: () => (variations) => {
			if(variations && Array.isArray(variations)) {
				return variations.reduce((sum, variation) => {
					const variationBasePrice = variation && variation.price && variation.price.amount || 0
					const variationSalePrice = variation && variation.sale_price && variation.sale_price.amount || 0

					return sum + (variationSalePrice || variationBasePrice)
				}, 0)
			}
			return 0
		}
	}
}
