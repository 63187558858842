<template>
  <v-card
    tile
    flat
  >
    <v-card
      tile
      dark
      :color="primaryColor"
    >
      <v-card-title>
        {{ $t('selectPaymentMethod') }}
        <v-spacer />
        <v-btn
          dark
          :color="accentColor"
          depressed
          @click="back"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text
      class="overflow-y-auto"
      :style="$vuetify.breakpoint.height < 700 ? 'max-height: 65vh' : ''"
    >
      <v-item-group
        v-model="index"
        mandatory
      >
        <v-row>
          <template v-for="(paymentMethod, methodIndex) in paymentMethods">
            <v-col
              :id="`${paymentMethod.title}-select`"
              :key="methodIndex"
              cols="12"
              class="px-0"
            >
              <v-item v-slot="{ active, toggle }">
                <v-card
                  :color="active ? 'grey lighten-2' : ''"
                  tile
                  flat
                  @click="toggle"
                >
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-avatar>
                        <v-icon
                          v-if="active"
                          size="36"
                          color="green darken-1"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                        <v-icon
                          v-else
                          size="36"
                        >
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="auto">
                      <v-avatar>
                        <v-img
                          v-if="isUrl(paymentMethod.icon)"
                          :src="paymentMethod.icon"
                        />
                        <v-icon
                          v-else
                          x-large
                          color="black"
                        >
                          {{ paymentMethod.icon }}
                        </v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col
                      class="title"
                      :class="paymentMethod.isApplePay ? 'font-weight-bold' : ''"
                    >
                      {{ paymentMethod.title }}
                    </v-col>
                  </v-row>
                </v-card>
              </v-item>
            </v-col>
          </template>
        </v-row>
      </v-item-group>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
        id="select-payment-method"
        style="white-space: normal"
        tile
        x-large
        height="65"
        block
        color="green"
        dark
        @click="selectPaymentMethod"
      >
        <span>
          {{ $t('buttons.select') }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "PaymentMethodSelectionScreen",
	data() {
		return {
			index: 0
		}
	},
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		accentColor() {
			return this.$store.getters.accentColor
		},
		paymentMethods() {
			return this.$store.getters.paymentMethods
		},
		selectedPaymentMethod: {
			get() {
				return this.$store.getters.selectedPaymentMethod
			},
			set(value) {
				this.$store.commit('updateSelectedPaymentMethod', value)
			}
		}
	},
	mounted() {
		if(this.selectedPaymentMethod) {
			this.index = this.paymentMethods.indexOf(this.selectedPaymentMethod)
		}
	},
	methods: {
		back() {
			this.$emit('close')
		},
		isUrl(string) {
			let url
			try {
				url = new URL(string)
			} catch (_) {
				return false
			}
			return url.protocol === 'http:' || url.protocol === 'https:'
		},
		selectPaymentMethod() {
			this.selectedPaymentMethod = this.paymentMethods[this.index]
			this.back()
		},
	}
}
</script>

<style scoped>

</style>
