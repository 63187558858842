<template>
  <v-container>
    <v-row dense>
      <template v-for="variation in variations">
        <v-col
          :key="variation.uuid"
          cols="12"
        >
          <VariationItem
            :variation="variation"
            :selected-variations="selectedVariations"
            :multiple="isMultipleSelect"
            :max-variations-reached="maxVariationsReached"
            v-on="$listeners"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import VariationItem from "@/components/item/variations/VariationItem";

export default {
    name: "Variations",
    components: {
        VariationItem
    },
    props: {
        variations: {
            type: Array,
            default: () => []
        },
        selectedVariations: {
            type: Array,
            default: () => []
        },
        variationCategory: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        variationCategoryName() {
            return this.$store.getters.translateByType('attribute.name', this.variationCategory)
        },
        variationCategoryRequired() {
            if(typeof this.variationCategory !== 'undefined' && this.variationCategory !== null) {
                return this.variationCategory.required === true
            }
            return false
        },
        isMultipleSelect() {
            if(typeof this.variationCategory !== 'undefined' && this.variationCategory !== null) {
                return !(this.variationCategory.single === true)
            }
            return true
        },
      variationCategoryExists() {
        return typeof this.variationCategory !== 'undefined' && this.variationCategory !== null && Object.keys(this.variationCategory).length > 0
      },
      variationCategoryMaximumVariationCount() {
        let maxVariationCount = 0
        if(this.variationCategoryExists) {
          if(typeof this.variationCategory.maxVariations !== 'undefined' && this.variationCategory.maxVariations > 0) {
            maxVariationCount = this.variationCategory.maxVariations
          }
        }
        return maxVariationCount
      },
      selectedVariationsInCategory() {
        if(this.variationCategoryExists && Array.isArray(this.selectedVariations)) {
          return this.selectedVariations.filter(v =>
            typeof v !== 'undefined' && v !== null
            && !v.removed
            && typeof v.attribute !== 'undefined' && v.attribute !== null
            && v.attribute.uuid === this.variationCategory.uuid)
        }
        return []
      },
      maxVariationsReached() {
        return this.variationCategoryMaximumVariationCount > 0 && (this.variationCategoryMaximumVariationCount - this.selectedVariationsInCategory.length) <= 0
      },
      totalVariationQuantityFromVariationCategory() {
        return this.selectedVariationsInCategory.length
      }
    }
}
</script>

<style scoped>

</style>
