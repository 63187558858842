<template>
  <v-container fluid>
    <v-row class="text-start yellow lighten-1">
      <v-col
        cols="12"
        class="black--text"
        style="border: 1px solid black; border-bottom: 0"
      >
        Last Error In Full
      </v-col>
      <v-col
        cols="12"
        class="black--text"
        style="border: 1px solid black"
      >
        {{ lastErrorInFull }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "LastErrorInFull",
	computed: {
		lastErrorInFull() {
			return this.$store.state.lastErrorInFull
		}
	}
}
</script>

<style scoped>

</style>
