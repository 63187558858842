import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center pt-10 d-flex flex-column",attrs:{"color":"red","dark":"","tile":"","flat":"","min-height":"50vh"}},[_c(VCardTitle,{staticClass:"pa-0 justify-center"},[_c(VIcon,{attrs:{"size":"64","color":"white"},on:{"dblclick":function($event){_vm.showLastErrorInFull = !_vm.showLastErrorInFull}}},[_vm._v(" mdi-emoticon-sad ")])],1),_c(VCardTitle,{staticClass:"text-center display-1",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.$t('payment.failure.title'))+" ")]),(_vm.showLastErrorInFull)?_c(VCardText,{staticClass:"overflow-y-auto"},[_c('LastErrorInFull')],1):_vm._e(),(_vm.paymentResponse && _vm.paymentResponse.paymentTransaction && _vm.paymentResponse.paymentTransaction.message)?_c(VCardText,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.paymentResponse.paymentTransaction.message)+" ")]):_vm._e(),_c(VCardText,{staticClass:"title"},[_c('div',[_vm._v(_vm._s(_vm.$t('payment.failure.notConfirmed')))]),_c('div',[_vm._v(_vm._s(_vm.$t('payment.failure.tryAgain')))])]),_c(VSpacer,{staticClass:"fill-height"}),_c(VCardActions,[_c(VBtn,{attrs:{"x-large":"","depressed":"","block":""},on:{"click":_vm.retryPayment}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.tryAgain'))+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }