<template>
  <v-card tile>
    <v-card-title class="text-break">
      <span>{{ $t('cart.remove') }} <span class="font-italic">{{ itemTitle }}</span> {{ $t('cart.fromCart') }}</span>
    </v-card-title>
    <v-card-actions>
      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-btn
              :id="`${itemTitle}-remove-from-cart-cancel`"
              x-large
              outlined
              block
              @click="cancel"
            >
              <span>
                {{ $t('buttons.cancel') }}
              </span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :id="`${itemTitle}-remove-from-cart-confirm`"
              x-large
              dark
              block
              color="error"
              @click="confirm"
            >
              <span>
                {{ $t('buttons.remove') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RemoveFromCartConfirm",
  props: {
    itemTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>

</style>
