<template>
  <v-list-item :disabled="item.addingToCart">
    <v-list-item-content
      :id="`${itemTitle}-details`"
      :style="hideItemDetails ? '' : 'cursor: pointer;'"
      @click="showItemInFull(item)"
    >
      <v-list-item-title>
        {{ itemTitle }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="salescloud-item-description"
        v-html="itemDescription(item)"
      />
      <v-list-item-subtitle
        v-if="!itemIsAvailableForOrdering"
        class="caption text--disabled font-italic pa-2"
        style="border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.12);"
      >
        {{ $t('errors.itemCurrentlyNotAvailable') }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-row
        align="center"
        dense
      >
        <v-col
          v-if="itemsInCart"
          class="caption grow"
          cols="auto"
        >
          {{ itemsInCart }} {{ $t('cart.inCart') }}
        </v-col>
      </v-row>
    </v-list-item-action>
    <v-list-item-avatar
      v-if="!hideListPictures"
      @click="showItemInFull(item)"
    >
      <v-img :src="getImage" />
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
    export default {
        name: "ListItem",
        props: {
            item: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            itemTitle() {
                return this.$store.getters.translateByType('item.title', this.item)
            },
            itemIsAvailableForOrdering() {
                return !this.itemIsBlockedByLocation
            },
            disableVariationSelection() {
                return this.$store.state.disableVariationSelection
            },
            itemsInCart() {
                return this.$store.getters.itemsInCart(this.item.uuid).length
            },
            primaryColor() {
                return this.$store.state.primaryColor
            },
            accentColor() {
                return this.$store.state.accentColor
            },
            hideListPictures() {
                return this.$store.state.hideListPictures
            },
            getImage() {
                return this.$store.getters.getThumbnailImage(this.item)
            },
            itemHasDescriptionValue() {
                return this.$store.getters.itemHasDescriptionValue(this.item)
            },
            itemHasVariations() {
                return this.$store.getters.itemHasVariations(this.item)
            },
            basePrice() {
                return this.$store.getters.itemBasePrice(this.item)
            },
            hideItemDetails() {
                return this.$store.state.hideItemDetails
            },
            selectedCategory() {
                return this.$store.state.selectedCategory
            },
            lineItemPrice() {
                return this.$store.getters.itemLinePrice(this.item)
            },
            lineItemSalePrice() {
                return this.$store.getters.itemLineSalePrice(this.item)
            },
            lineItemHasSalePrice() {
                return this.$store.getters.itemHasSalePrice(this.item)
            },
            productSalePrice() {
                return this.$store.getters.productBaseSalePrice(this.item)
            },
            itemIsBlockedByLocation() {
              // return true
              return this.$store.getters.productIsBlockedByLocation(this.item)
            }
        },
        mounted() {
        },
        methods: {
            currencyFormat(value) {
                return this.$store.getters.currencyFormat(value)
            },
            showItemInFull(item) {
                if(this.hideItemDetails) {
                    return
                }

                this.$store.commit('setCurrentItemInFull', item)
                // if(this.itemHasDescriptionValue) {
                // 	this.$store.commit('setCurrentItemInFull', item)
                // }
            },
            itemTranslatedDescriptionValue(item) {
                return this.$store.getters.translateByType('item.description.value', item)
            },
            itemDescription(item) {
                let textType = 'text--primary'
                if(!this.itemIsAvailableForOrdering) {
                  textType = 'text--disabled'
                }
                let html = '<span class="' + textType + '">' + this.currencyAsText(this.basePrice) + '</span>'
                if(this.lineItemHasSalePrice) {
                    html = '<span class="' + textType + '">' + this.currencyAsText(this.productSalePrice) + '</span> <span class="text--disabled text-decoration-line-through">' + this.currencyAsText(this.basePrice) + '</span>'
                }

                if(item.description && this.itemTranslatedDescriptionValue(item)) {
                    html += ' &mdash; '
                    html += this.itemTranslatedDescriptionValue(item)
                }

                return html
            },
            currencyAsText(amount) {
                // To handle products with no price but multiple variation prices
                if(amount && amount.toString().includes(' - ')) {
                    return amount
                }

                const price = {
                    amount: amount,
                    currency_code: this.item.price.currency_code
                }

                return this.$store.getters.currencyAsText(price)
            },
            addItemToCart(item) {
                if(!this.disableVariationSelection && this.itemHasVariations) {
                    this.$store.commit('updateSelectedItem', JSON.parse(JSON.stringify(item)))
                    this.$store.commit('updateShowSelectedItemVariationSelection', true)
                } else {
                    this.$store.commit('addItemToCart', item)
                }
            },
        }
    }
</script>

<style scoped>
    .salescloud-item-description p {
        display: inline;
    }
</style>
