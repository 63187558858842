<template>
  <v-bottom-sheet
    scrollable
    :value="true"
    persistent
  >
    <v-card
      tile
      flat
    >
      <v-card-title
        :style="'background-color:' + primaryColor + ';'"
        class="py-2 white--text"
      >
        {{ checkoutSectionTitle }}
      </v-card-title>
      <v-card-text class="py-3">
        <div
          v-if="showScrollButton"
          style="position:absolute; right: 16px; bottom: 75px;"
        >
          <div>
            <v-btn
              fab
              small
              color="rgba(0,0,0,0.5)"
              dark
              @click="scrollToInput"
            >
              <v-icon>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </div>
        </div>
        <template v-for="(text, index) in checkoutSectionTexts">
          <div
            :key="index"
            v-html="text"
          />
        </template>
        <div
          id="section-input"
          ref="section-input"
        >
          <v-form v-model="valid">
            <template v-for="field in checkoutSectionUIFields">
              <UiField
                v-if="field.key !== 'html' && field.key !== 'vhtml'"
                :key="field.key"
                :field="field"
                @fieldValueChange="fieldValueChange"
              />
            </template>
          </v-form>
        </div>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-container class="pa-2">
          <v-row dense>
            <v-col>
              <v-btn
                tile
                x-large
                block
                color="error"
                depressed
                @click="back"
              >
                <v-icon>
                  mdi-chevron-left
                </v-icon>
                <v-spacer />
                {{ $t('buttons.back') }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                tile
                x-large
                block
                color="success"
                depressed
                :disabled="!valid"
                @click="nextStep"
              >
                {{ $t('buttons.confirm') }}
                <v-spacer />
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import UiField from "@/components/uiField"

export default {
  name: "CheckoutSection",
  components: {
    UiField
  },
  props: {
    checkoutSection: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showScrollButton: true,
      values: null,
      canConfirm: false,
      valid: false
    }
  },
  computed: {
    inputIsVisible() {
      // this.$nextTick(() => {
      //   const element = document.getElementById('section-input')
      //   console.log('el',element)
      //   if(element) {
      //     const rect = element.getBoundingClientRect()
      //     console.log(document.documentElement.clientHeight)
      //     console.log(window.innerHeight)
      //     console.log('ele', rect)
      //     return this.isInViewport(element)
      //   }
      // })
      return false
    },
    valuesFilled() {
      if(typeof this.checkoutSection !== 'undefined' && this.checkoutSection !== null) {
        if(Array.isArray(this.checkoutSections) && this.checkoutSections.length > 0) {
          const sectionIndex = this.checkoutSections.findIndex(section => section && section.namespace === this.checkoutSection.namespace)
          if(sectionIndex >= 0) {
            if(Array.isArray(this.checkoutSections[sectionIndex].uiFields)) {
              if(this.checkoutSections[sectionIndex].uiFields.length >= 0) {
                for(let index = 0; index < this.checkoutSections[sectionIndex].uiFields.length; index++) {
                  const field = this.checkoutSections[sectionIndex].uiFields[index]
                  if(field.type === 'html' || field.type === 'vhtml') {
                    continue
                  }

                  if(typeof field.value === 'undefined' || field.value === null || field.value === false) {
                    return false
                  }
                }
                return true
                // return this.checkoutSections[sectionIndex].uiFields.every(field => field && (field.type === 'html' || typeof field.value !== 'undefined' && field.value !== null && field.type !== 'html'))
              }
              return true
            }
          }
        }
      }
      return false
    },
    primaryColor() {
      return this.$store.getters.primaryColor
    },
    checkoutSectionTitle() {
      if(typeof this.checkoutSection !== 'undefined' && this.checkoutSection !== null) {
        if(typeof this.checkoutSection.namespace !== 'undefined' && this.checkoutSection.namespace !== null && this.checkoutSection.namespace !== '') {
          const title = this.$t(this.checkoutSection.namespace)
          return title.substring(0,1).toUpperCase() + title.substring(1)
        }
      }
      return ''
    },
    checkoutSectionTexts() {
      const texts = []
      if(typeof this.checkoutSection !== 'undefined' && this.checkoutSection !== null) {
        if(typeof this.checkoutSection.data !== 'undefined' && this.checkoutSection.data !== null) {
          for(let i in this.checkoutSection.data) {
            const info = this.checkoutSection.data[i]
            if(typeof info !== 'undefined' && info !== null) {
              if(info.format === 'filtered_html') {
                if(typeof info.value !== 'undefined' && info.value !== null && info.value !== '') {
                  texts.push(info.value)
                }
              }
            }
          }
        }
      }
      return texts
    },
    checkoutSectionUIFields() {
      if(typeof this.checkoutSection !== 'undefined' && this.checkoutSection !== null) {
        if(Array.isArray(this.checkoutSection.uiFields) && this.checkoutSection.uiFields.length > 0) {
          return this.checkoutSection.uiFields
        }
      }
      return []
    },
    checkoutSections() {
      return this.$store.state.checkoutSectionList
    },
    waiverCheckoutSection() {
      return this.$store.state.waiverCheckoutSection
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    nextStep() {
      this.$emit('next')
    },
    scrollToInput() {
      const element = document.getElementById('section-input')
      if(element) {
        element.scrollIntoView()
      }
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect()

      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    fieldValueChange({ value, key }) {
      if(typeof this.checkoutSection !== 'undefined' && this.checkoutSection !== null) {
        this.$store.commit('updateCheckoutSectionInstanceValue', { section: this.checkoutSection.namespace, key, value })
        let checkoutSectionData = this.$store.state.checkoutSectionData
        if(!Array.isArray(checkoutSectionData)) {
          checkoutSectionData = []
        }
        const section = this.checkoutSection.namespace
        const sectionIndex = checkoutSectionData.findIndex(s => s && s.namespace === section)

        if(sectionIndex >= 0) {
          //
        } else {
          // init
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
