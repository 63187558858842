<template>
  <v-card outlined>
    <v-date-picker
      full-width
      no-title
      :min="calendarMinSelectableDate"
      :max="calendarMaxSelectableDate"
      :allowed-dates="calendarAllowedDates"
      v-model="selectedDate"
    />
  </v-card>
</template>

<script>
export default {
  name: "EventCalendar",
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    date: {
      type: String,
      default: null
    }
  },
  computed: {
    selectedDate: {
      get() {
        return this.date
      },
      set(value) {
        this.$emit('selectDate', value)
      }
    },
    itemIsDefined() {
      return typeof this.item !== 'undefined' && this.item !== null
    },
    itemMaxAheadBookingInDays() {
      if(this.itemIsDefined) {
        if(typeof this.item.maxAheadBookingInDays !== 'undefined' && this.item.maxAheadBookingInDays > 0) {
          return this.item.maxAheadBookingInDays
        }
      }
      return null
    },
    calendarMinSelectableDate() {
      let date = new Date()

      if(this.itemIsDefined) {
        let startDate = 0
        let startSellTimeInMillis = 0

        if(typeof this.item.startDate !== 'undefined' && this.item.startDate > 0) {
          startDate = this.item.startDate
        }

        if(typeof this.item.startSellTimeInMillis !== 'undefined' && this.item.startSellTimeInMillis > 0) {
          startSellTimeInMillis = this.item.startSellTimeInMillis
        }

        if(date.getTime() < startDate || date.getTime() < startSellTimeInMillis) {
          if(startDate < startSellTimeInMillis) {
            date = new Date(startDate)
          } else {
            date = new Date(startSellTimeInMillis)
          }
        }
      }

      return date.toISOString().substring(0, 10)
    },
    calendarMaxSelectableDate() {
      const date = new Date(this.calendarMinSelectableDate)

      let maxAheadBookingInDays = 30
      if(this.itemMaxAheadBookingInDays) {
        maxAheadBookingInDays = this.itemMaxAheadBookingInDays
      }

      let updatedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + maxAheadBookingInDays)

      // Check for end date and end sell time in millis
      // Check if end date or end sell time in millis are less than updated date
      // If so, check which one is lower and use that for updated date
      return updatedDate.toISOString().substring(0, 10)
    },
    itemUnavailableDatesInMillis() {
      const unavailableDates = []
      if(this.itemIsDefined) {
        if(Array.isArray(this.item.unavailableDates) && this.item.unavailableDates.length > 0) {
          for(let dateIndex = 0; dateIndex < this.item.unavailableDates.length; dateIndex++) {
            const date = this.item.unavailableDates[dateIndex]
            if(date) {
              const parsedDate = date.split(/[-/.]/)
              if(parsedDate.length >= 3) {
                const date = Number(parsedDate[0])
                const month = Number(parsedDate[1])
                const year = Number(parsedDate[2])
                if(!isNaN(date) && !isNaN(month) && !isNaN(year)) {
                  unavailableDates.push(new Date(year, month, date).getTime())
                }
              }
            }
          }
        }
      }
      return unavailableDates
    },
    itemDates() {
      if(this.itemIsDefined) {
        if(Array.isArray(this.item.dates) && this.item.dates.length > 0) {
          return this.item.dates
        }
      }
      return []
    }
  },
  methods: {
    calendarAllowedDates(value) {
      const dateValue = new Date(value)

      if(Array.isArray(this.itemUnavailableDatesInMillis)) {
        if(this.itemUnavailableDatesInMillis.some(millis => millis === dateValue.getTime())) {
          return false
        }
      }

      if(Array.isArray(this.itemDates) && this.itemDates.length > 0) {
        return this.itemDates.some(date => {
          if(date) {
            const availableDate = new Date(date * 1000).setUTCHours(0, 0, 0, 0)
            return availableDate === dateValue.getTime()
          }
          return false
        })
      }
      return true
    },
  },
  mounted() {
    //
  }
}
</script>

<style scoped>

</style>
