<template>
  <v-card
    class="text-center pt-10 pb-5 d-flex flex-column"
    tile
    flat
    height="50vh"
  >
    <v-container class="flex fill-height">
      <v-row align="center">
        <v-col>
          <div>
            <v-progress-circular
              indeterminate
              :color="primaryColor"
              size="48"
            />
          </div>

          <div
            v-if="pending === true"
            class="pt-8"
          >
            <div class="font-weight-bold title">
              {{ $t('payment.pending') }}
            </div>
            <div
              v-if="paymentPendingLastCheckedDate"
              class="pt-1"
            >
              {{ $t('payment.lastChecked') }} {{ lastCheckedText }}
            </div>
          </div>
          <div
            v-else
            class="pt-8 font-weight-bold title"
          >
            {{ $t('payment.inProgress') }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
	name: "PaymentInProgress",
  props: {
    pending: Boolean
  },
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		paymentPendingLastCheckedDate() {
			return this.$store.getters.pendingPaymentLastCheckedDate
		},
		lastCheckedText() {
			if(this.paymentPendingLastCheckedDate) {
				const date = new Date(this.paymentPendingLastCheckedDate * 1).toISOString()
				return date.substr(0,10) + ' ' + date.substr(11, 8)
			}
			return ''
		},
    text() {
      if(this.pending === true) {
        return this.$t('payment.pending')
      }
      return this.$t('payment.inProgress')
    }
	}
}
</script>

<style scoped>

</style>
