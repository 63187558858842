import 'babel-polyfill';
import 'url-search-params-polyfill';
import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import Vuetify from 'vuetify/lib';
//import vuetify from './plugins/vuetify'

import store from './plugins/vuex';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import i18n from './i18n';
import Hotjar from 'vue-hotjar';

import { version } from '../package.json';

// eslint-disable-next-line
console.info(`SalesCloud eTags v${version}`);

if(process && process.env && process.env.VUE_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: version,
  });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue.installVueErrorHandler(Vue);
}

if(process && process.env && process.env.VUE_APP_HOTJAR_ID) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID, // Hotjar Site ID
  });
}

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi',
  },
};

Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  store,
  vuetify: new Vuetify(vuetifyOptions),
  i18n,
  render: (h) => h(App),
}).$mount('#app');
