import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"locale-changer"},[(false)?_c(VRow,{staticStyle:{"width":"75px"},attrs:{"dense":""}},[_c(VCol,{staticClass:"pa-0"},[_c(VSelect,{attrs:{"solo":"","items":_vm.availableLocales,"hide-details":""},model:{value:(_vm.currentLocale),callback:function ($$v) {_vm.currentLocale=$$v},expression:"currentLocale"}})],1)],1):_vm._e(),_c(VMenu,{attrs:{"offset-x":"","right":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-3 ml-2 grey lighten-2",attrs:{"fab":"","id":"locale-select","text":"","outlined":"","color":"black","dark":"","small":""}},'v-btn',attrs,false),on),[_c(VAvatar,{attrs:{"size":"30"}},[_c(VImg,{attrs:{"src":_vm.getFlagBasedOnLocale(_vm.$i18n.locale)}})],1)],1)]}}])},[_c(VSheet,[_c(VList,{staticClass:"px-1",attrs:{"rounded":"","dense":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.availableLocales),function(item,index){return _c(VListItem,{key:index,attrs:{"id":`${item} - select`},on:{"click":function($event){return _vm.selectLocale(item)}}},[_c(VListItemAvatar,{attrs:{"size":"24"}},[_c(VImg,{attrs:{"src":_vm.getFlagBasedOnLocale(item)}})],1),_c(VListItemTitle,{staticClass:"caption text-capitalize"},[_vm._v(" "+_vm._s(_vm.findLanguageInfo(item))+" ")])],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }