<template>
  <v-card tile>
    <v-card
      tile
      dark
      :color="primaryColor"
      flat
    >
      <v-card-title>
        {{ $t('customer.comment') }}
        <v-spacer />
        <v-btn
          :color="accentColor"
          dark
          @click="close"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-textarea
            v-model="customerComment"
            :label="$t('customer.comment') + ' (' + $t('optional') + ')'"
            outlined
            :color="primaryColor"
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      block
      tile
      x-large
      dark
      height="65"
      :color="accentColor"
      @click="logComment"
    >
      <span>
        {{ $t('buttons.logComment') }}
      </span>
    </v-btn>
  </v-card>
</template>

<script>
export default {
	name: "CustomerComment",
	computed: {
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		customerComment: {
			get() {
				return this.$store.state.customerComment
			},
			set(comment) {
				comment = this.$store.getters.pruneEmojisFromText(comment)
				this.$store.commit('updateCustomerComment', comment)
			}
		},
	},
	methods: {
		close() {
			this.$store.commit('updateShowCustomerComment', false)
		},
		logComment: function() {
			this.$store.commit('updateShowCustomerComment', false);
		},
	}
}
</script>

<style scoped>

</style>
