<template>
  <v-container
    fluid
    class="px-0"
  >
    <v-row style="font-size: 14px; font-weight: 500;">
      <v-col cols="12">
        <span>
          <span>
            {{ variationLabel }}
          </span>
          <span
            v-if="variationIsBlocked"
            class="caption"
          >
            ({{ $t('soldOut') }})
          </span>
        </span>
      </v-col>
    </v-row>
    <v-row class="justify-space-between align-center mt-0">
      <v-col cols="auto">
        <v-btn
          fab
          color="red"
          :dark="canDecreaseQuantity"
          small
          :disabled="!canDecreaseQuantity || variationIsBlocked"
          depressed
          @click="decreaseQuantity"
        >
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
      <v-col class="grow">
        <v-select
          v-model="quantity"
          outlined
          hide-details
          :items="quantityOptions"
          item-text="text"
          item-value="value"
          dense
          :disabled="variationIsBlocked"
        >
          <template v-slot:selection="{ item }">
            <span
              class="d-flex justify-center"
              style="width: 100%; padding-left: 28px;"
            >{{ item.value }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span
              class="d-flex justify-center"
              style="width: 100%;"
            >{{ item.value }}</span>
          </template>
        </v-select>
      </v-col>
      <v-col cols="auto">
        <v-btn
          fab
          color="green"
          :dark="canIncreaseQuantity && !maxVariationsReached"
          small
          :disabled="!canIncreaseQuantity || maxVariationsReached || variationIsBlocked"
          depressed
          @click="increaseQuantity"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: "MultipleQuantityVariationsItem",
    props: {
        variation: {
            type: Object,
            default: () => ({})
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 10
        },
        selectedQuantity: {
            type: Number,
            default: 0
        },
      maxVariationsReached: {
          type: Boolean,
        default: false
      },
      totalQuantityInCategory: {
          type: Number,
        default: 0
      }
    },
    computed: {
      variationLocationBlocks() {
        return this.$store.state.variationLocationBlocks
      },
      variationIsBlocked() {
        if(Array.isArray(this.variationLocationBlocks)) {
          if(typeof this.variation !== 'undefined' && this.variation !== null) {
            return this.variationLocationBlocks.some(block => block && block.variation === this.variation.uuid)
          }
        }
        return false
      },
        quantity: {
            get() {
                return this.selectedQuantity
            },
            set(quantity) {
                this.$emit('setQuantity', { variation: this.variation, quantity })
            }
        },
        variationLabel() {
            return this.$store.getters.translateByType('variation.label', this.variation)
        },
        quantityOptions() {
          const options = []
          const length = this.max + 1

          for(let index = 0; index < length; index++) {
            const value = this.min + index

            let disableSelection = false

            if(value > 0 && value >= (length - this.totalQuantityInCategory)) {
              disableSelection = true
            }

            options.push({
              text: value,
              value: value,
              disabled: disableSelection
            })
          }

          return options
        },
        canIncreaseQuantity() {
            return (this.quantity < this.quantityOptions[this.quantityOptions.length - 1].value)
        },
        canDecreaseQuantity() {
            return this.quantity > this.quantityOptions[0].value
        }
    },
    methods: {
        increaseQuantity() {
            this.quantity = this.quantity + 1
        },
        decreaseQuantity() {
            this.quantity = this.quantity - 1
        }
    }
}
</script>

<style scoped>

</style>
