import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center pt-10 pb-5 d-flex flex-column",attrs:{"tile":"","flat":"","height":"50vh"}},[_c(VContainer,{staticClass:"flex fill-height"},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c('div',[_c(VProgressCircular,{attrs:{"indeterminate":"","color":_vm.primaryColor,"size":"48"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }