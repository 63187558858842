import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"tile":"","flat":""}},[_c(VCard,{attrs:{"tile":"","dark":"","color":_vm.primaryColor,"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('coupon.activateCoupon'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","color":_vm.accentColor,"disabled":_vm.isDisabled},on:{"click":_vm.close}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")])])],1)],1),_c(VCardText,[_c('div',{staticClass:"pb-4"},[(!_vm.activeCoupon)?_c('span',[_vm._v(_vm._s(_vm.$t('coupon.noActive')))]):_c('div',[_c('div',[_vm._v(_vm._s(_vm.$t('coupon.active'))+":")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.activeCoupon.code)+" ")]),_c('div',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.activeCoupon.label)+" "+_vm._s(_vm.activeCoupon.description ? '- ' + _vm.activeCoupon.description : '')+" - "+_vm._s(_vm.activeCoupon.percentOff)+"% "+_vm._s(_vm.$t('coupon.discountText'))+" ")])])]),_c(VTextField,{attrs:{"outlined":"","label":_vm.$t('coupon.title'),"hide-details":"","clearable":""},on:{"click:clear":_vm.clearCouponCode},model:{value:(_vm.couponFromInput),callback:function ($$v) {_vm.couponFromInput=$$v},expression:"couponFromInput"}}),_c('div',{staticClass:"pt-4"},[(_vm.activeCoupon)?_c(VBtn,{attrs:{"block":"","color":"error","loading":_vm.clearingCoupon,"depressed":""},on:{"click":_vm.clearCouponCode}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.clearCouponCode'))+" ")])]):_vm._e(),(_vm.activeCoupon && _vm.couponError)?_c('div',{staticClass:"pb-1"}):_vm._e(),(_vm.couponError)?_c(VAlert,{staticClass:"mb-0",attrs:{"type":"error","border":"left"}},[_vm._v(" "+_vm._s(_vm.$t('coupon.errors.unableToApply'))+" ")]):_vm._e()],1)],1),_c(VBtn,{attrs:{"tile":"","block":"","x-large":"","dark":"","height":"65","color":_vm.accentColor,"loading":_vm.processingCoupon},on:{"click":_vm.checkForCoupon}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.confirm'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }