<template>
  <div class="px-4">
    <div class="pb-3">
      <span class="font-weight-bold">{{ $t('commentWithOrder') }}:</span> <span class="font-italic text--secondary">{{ customerComment }}</span>
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
	name: "CustomerCommentAsText",
	computed: {
		customerComment() {
			return this.$store.state.customerComment
		},
	}
}
</script>

<style scoped>

</style>
