import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center d-flex flex-column",attrs:{"color":"green","dark":"","tile":"","flat":"","min-height":"50vh"}},[_c(VContainer,{staticClass:"pt-0"},[_c(VRow,[_c(VCol,[_c(VIcon,{staticStyle:{"padding-top":"20px"},attrs:{"size":"64","color":"white"}},[_vm._v(" mdi-check ")]),(_vm.organizationUUID !== 'cb4c9015-06be-40fe-8c5d-7a7865b4d160')?_c(VCardTitle,{staticClass:"text-center display-1",staticStyle:{"display":"block","font-weight":"900"}},[_vm._v(" "+_vm._s(_vm.$t("payment.success.title"))+" ")]):_c(VCardTitle,{staticClass:"text-center display-1",staticStyle:{"display":"block"}},[_vm._v(" Pöntun Staðfest ")]),(_vm.orderSequence !== null && _vm.orderSequence !== _vm.undefiend)?_c(VCardText,[_c('div',{staticClass:"text-center display-2"},[_vm._v(" #E"+_vm._s(_vm.orderSequence)+" ")])]):_vm._e(),(!_vm.eventsMode)?_c(VCardText,{staticClass:"title"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("payment.success.orderInfo", { amount: _vm.paymentResponseAmount, table: _vm.tableText, }))+" ")]),(_vm.showWaitingTime)?_c('div',[(_vm.organizationIsFiskmarkadurinn)?_c('div',[_vm._v(" Áætlaður biðtími gæti verið allt að 45 mínútur á háannatíma ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("waitTime", { waitTime: _vm.waitTime }))+" ")])]):_vm._e()]):_c(VCardText,[(_vm.orderHasBookingLineItems)?_c(VCard,{staticClass:"pb-2",attrs:{"flat":""}},[_c('OrderBookings',{attrs:{"booking-line-items":_vm.orderBookingLineItems}})],1):_vm._e()],1),_c(VCardActions,[_c(VRow,{attrs:{"dense":""}},[(
                _vm.organizationUUID !== 'cb4c9015-06be-40fe-8c5d-7a7865b4d160'
              )?_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"id":"complete-receipt","block":"","x-large":"","dark":"","color":_vm.accentColor},on:{"click":_vm.getReceipt}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("buttons.receipt"))+" ")])])],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"id":"complete-close","block":"","x-large":"","dark":"","color":_vm.accentColor},on:{"click":_vm.closeCart}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("buttons.close"))+" ")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }