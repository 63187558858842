<template>
  <v-container v-if="hasError && !loading">
    <v-row
      align="center"
      justify="center"
    >
      <v-col>
        <v-alert
          prominent
          type="error"
        >
          <v-row align="center">
            <v-col
              class="grow"
              cols="12"
              sm="auto"
            >
              <div>{{ message }}</div>
              <div class="caption">
                {{ subMessage }}
              </div>
            </v-col>
            <v-col
              class="shrink"
              cols="12"
              sm="auto"
            >
              <v-btn
                block
                large
                :loading="loading"
                @click="tryAgain"
              >
                <span>
                  {{ $t('buttons.tryAgain') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "AlertError",
	computed: {
		loading() {
			return this.$store.state.loading
		},
		hasError() {
			return this.$store.state.hasError
		},
		message() {
			return this.$store.state.message
		},
		subMessage() {
			return this.$store.state.subMessage
		},
	},
	methods: {
		tryAgain() {
			this.$store.commit('updateHasError', false);
			this.$store.dispatch('getInitialData');
		},
	}
}
</script>

<style scoped>

</style>
