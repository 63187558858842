import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,{attrs:{"disabled":_vm.item.addingToCart}},[_c(VListItemContent,{style:(_vm.hideItemDetails ? '' : 'cursor: pointer;'),attrs:{"id":`${_vm.itemTitle}-details`},on:{"click":function($event){return _vm.showItemInFull(_vm.item)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")]),_c(VListItemSubtitle,{staticClass:"salescloud-item-description",domProps:{"innerHTML":_vm._s(_vm.itemDescription(_vm.item))}}),(!_vm.itemIsAvailableForOrdering)?_c(VListItemSubtitle,{staticClass:"caption text--disabled font-italic pa-2",staticStyle:{"border-radius":"4px","border":"1px solid rgba(0, 0, 0, 0.12)"}},[_vm._v(" "+_vm._s(_vm.$t('errors.itemCurrentlyNotAvailable'))+" ")]):_vm._e()],1),_c(VListItemAction,[_c(VRow,{attrs:{"align":"center","dense":""}},[(_vm.itemsInCart)?_c(VCol,{staticClass:"caption grow",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.itemsInCart)+" "+_vm._s(_vm.$t('cart.inCart'))+" ")]):_vm._e()],1)],1),(!_vm.hideListPictures)?_c(VListItemAvatar,{on:{"click":function($event){return _vm.showItemInFull(_vm.item)}}},[_c(VImg,{attrs:{"src":_vm.getImage}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }