<template>
  <v-container>
    <WelcomeMessage
      v-if="showWelcomeMessage"
      v-model="showWelcomeMessage"
      @closed="closedWelcome"
    />
    <v-row>
      <v-col cols="12">
        <LanguageSelection style="top: 0; left: 15px; position: absolute;" />
        <v-row
          v-if="headerText"
          dense
          justify="center"
        >
          <v-col cols="auto">
            <span
              class="display-1 font-weight-bold"
              :style="'color: ' + primaryColor + ';'"
            >{{ headerText }}</span>
          </v-col>
          <v-col
            v-if="descriptionText && false"
            cols="12"
            class="pl-5 pr-5"
          >
            <p
              class="text-center ma-0"
              :style="descriptionTextFormat ? 'font-weight: 600; font-size: 20px;' : ''"
            >
              {{ descriptionText }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="!locationIsOpen">
          <v-col cols="12">
            <v-banner
              color="grey lighten-2"
              rounded
              class="text-center"
            >
              <div>
                <div
                  style="font-weight: 600; font-size: 20px;"
                  class="pb-2"
                >
                  {{ $t('location.closed') }}
                </div>
                <div
                  style="font-weight: 300;"
                  class="pb-2"
                >
                  {{ $t('location.cannotOrder') }}
                </div>
                <div
                  v-if="Array.isArray(locationOpeningHours) && locationOpeningHours.length > 0"
                  style="font-size: 14px;"
                >
                  {{ $t('openingHours') }}:
                  <template v-for="(hour, index) in locationOpeningHours">
                    <span :key="hour.openHour.text">
                      <span style="font-weight: 500;">
                        {{ hour.openHour.text }}
                      </span>
                      -
                      <span style="font-weight: 500;">
                        {{ hour.closedHour.text + ((locationOpeningHours.length > 1 && showComma(index)) ? ', ' : '') }}
                      </span>
                    </span>
                    <span
                      v-if="locationOpeningHours.length > 1 && index === locationOpeningHours.length - 2"
                      :key="hour.openHour.text + '-and'"
                    >
                      {{ $t('and') }}
                    </span>
                  </template>
                </div>
              </div>
            </v-banner>
          </v-col>
        </v-row>
        <v-row
          class="overflow-y-auto px-3"
          justify="center"
        >
          <template v-for="selection in selections">
            <v-col
              :key="selection.key"
              cols="12"
            >
              <v-card
                elevation="3"
                :color="primaryColor"
                :dark="!primaryColorIsLight"
                min-width="150"
                min-height="50"
                class="grow"
                @click="selectOption(selection.key)"
              >
                <v-card-title class="text-break justify-center text-center">
                  {{ selection.title }}
                </v-card-title>
                <v-card-subtitle
                  v-if="selection.key === 'menu' && showTakeawayTextOnRoomService"
                  class="text-break justify-center text-center text-uppercase"
                  style="font-weight: 300; letter-spacing: 1px;"
                >
                  {{ $t('takeaway') }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
    <v-bottom-sheet
      v-if="showReservationFrame"
      v-model="showReservationFrame"
      scrollable
      fullscreen
    >
      <v-sheet
        tile
        :class="iframeIsLoaded ? 'reserve-wrapper' : ''"
      >
        <v-card
          flat
          class="fill-height"
          id="reserve-wrapper"
        >
          <v-card-title id="reserve-title">
            {{ $t('reservations') }}
            <v-spacer />
            <v-btn
              :color="primaryColor"
              :dark="!primaryColorIsLight"
              @click="showReservationFrame = false"
            >
              {{ $t('buttons.back') }}
            </v-btn>
          </v-card-title>
          <v-card-text
            class="px-0 pb-0 fill-height"
          >
            <v-container
              fluid
              class="text-center fill-height py-0"
            >
              <v-row v-if="!iframeIsLoaded">
                <v-col cols="12">
                  <v-progress-circular
                    indeterminate
                    size="96"
                    width="5"
                    :color="primaryColor"
                  />
                </v-col>
                <v-col cols="12">
                  {{ $t('accessing') }}
                </v-col>
              </v-row>
              <v-row
                v-show="iframeIsLoaded"
                class="fill-height"
              >
                <v-col
                  cols="12"
                  class="pa-0 fill-height"
                >
                  <iframe
                    class="reserve-frame"
                    allowfullscreen
                    :src="reservationFrameLink"
                    @load="iframeIsLoaded = true"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import WelcomeMessage from "@/components/landing/WelcomeMessage"
import LanguageSelection from "@/components/LanguageSelection"

export default {
  name: "ReservationLandingPage",
  components: {
    LanguageSelection,
    WelcomeMessage
  },
  data() {
    return {
      showReservationFrame: false,
      iframeIsLoaded: false
    }
  },
  computed: {
    showTakeawayTextOnRoomService() {
      return this.$store.state.showTakeawayTextOnRoomService
    },
    reservationLink() {
      return this.$store.state.reservationLink
    },
    locationIsOpen() {
      return this.$store.getters.locationIsOpen
    },
    locationOpeningHours() {
      return this.$store.getters.locationOpeningHours
    },
    pandemicIsActive() {
      return this.$store.getters.showAddGuestsToTableButton
    },
    showOffers: function () {
      return this.$store.state.showOffers
    },
    categories: function () {
      return this.$store.state.categories
    },
    showWelcomeMessage: function () {
      return this.$store.state.showWelcomeMessage
    },
    primaryColor: function () {
      return this.$store.state.primaryColor
    },
    primaryColorIsLight() {
      return this.$store.getters.colorIsLight(this.primaryColor)
    },
    accentColor: function () {
      return this.$store.state.accentColor
    },
    headerText: function () {
      const zoneObject = this.$store.state.variationOption

      if(zoneObject) {
        return zoneObject.label
      }
      return ''
    },
    descriptionText: function () {
      return this.$store.getters.etagDescription
    },
    organizationUUID() {
      return this.$store.getters.organizationUUID
    },
    descriptionTextFormat() {
      return this.organizationUUID === '50ca679f-5a52-44a1-b0ae-dc6f6be27651'
    },
    selections() {
      const selections = []

      const reservations = {
        title: this.$t('reservations'),
        key: 'reservations'
      }

      selections.push(reservations)

      const menu = {
        title: this.$t('roomService'),
        key: 'menu'
      }

      selections.push(menu)

      return selections
    },
    reservationWidgetUUID() {
      return this.$store.state.reservationWidgetUUID
    },
    appProfileSlug() {
      return this.$store.state.appProfileSlug
    },
    reservationFrameLink() {
      if(typeof this.appProfileSlug !== 'undefined' && this.appProfileSlug !== null && this.appProfileSlug !== '') {
        return 'https://yess.is/' + this.appProfileSlug + '?bookTable=true'
      } else if(typeof this.reservationWidgetUUID !== 'undefined' && this.reservationWidgetUUID !== null && this.reservationWidgetUUID !== '') {
        return 'https://reservations.salescloud.is?uuid=' + this.reservationWidgetUUID
      } else {
        return ''
      }
    }
  },
  mounted() {
    //
  },
  watch: {
    showReservationFrame(value) {
      // if(value === true) {
      //   this.$nextTick(() => {
      //     if(document) {
      //       const wrapper = document.getElementById('reserve-wrapper')
      //       const title = document.getElementById('reserve-title')
      //       if(wrapper) {
      //         console.log('w', wrapper.offsetHeight)
      //       }
      //       if(title) {
      //         console.log('t', title.offsetHeight)
      //       }
      //     }
      //   })
      // }
    }
  },
  methods: {
    selectOption(action) {
      if(action === 'reservations') {
        this.showReservationFrame = true
        // if(typeof this.reservationLink !== 'undefined' && this.reservationLink !== null && this.reservationLink !== '') {
        //   window.open(this.reservationLink, '_blank', 'noopener,noreferrer')
        // }
      } else if(action === 'menu') {
        this.$store.commit('updateShowingReservationLandingPage', false)
      }
    },
    closedWelcome() {
      this.$store.commit('updateShowWelcomeMessage', false)
    },
    textColor(color) {
      return this.$store.getters.textColor(color)
    },
    showComma(index) {
      return this.locationOpeningHours.length > 1 && index < this.locationOpeningHours.length - 2
    }
  }
}
</script>

<style scoped>
.reserve-wrapper {
  height: calc(100vh - 68px);
}

.reserve-frame {
  width: 100%;
  border: 0;
  height: 100%;
}
</style>
