<template>
  <v-btn
    block
    large
    tile
    dark
    :color="accentColor"
    @click="backToLandingPage()"
  >
    <v-icon left>
      mdi-keyboard-backspace
    </v-icon>
    <span>
      {{ buttonText }}
    </span>
  </v-btn>
</template>

<script>
export default {
	name: "BackButton",
	computed: {
		accentColor: function() {
			return this.$store.state.accentColor
		},
		currentTree: function() {
			return this.$store.state.currentTreeLocation
		},
		itemsByCategoriesNew: function() {
			return this.$store.state.itemsByCategories
		},
		customerSawItemsScreen: function() {
			return this.$store.state.sawItems
		},
		buttonText: function() {
			if(this.customerSawItemsScreen) {
				if (this.currentTree && this.currentTree.length) {
					return this.$t('buttons.backToCategory', {category: this.itemsByCategoriesNew[this.currentTree.slice(-1)].name})
				}
        return this.$t('buttons.backToLandingPage')
			}
      const smallerTree = this.currentTree.slice(0, -1)
      if (smallerTree && smallerTree.length) {
        return this.$t('buttons.backToCategory', {category: this.itemsByCategoriesNew[smallerTree.slice(-1)].name})
      }
      return this.$t('buttons.backToLandingPage')
		}
	},
	methods: {
		backToLandingPage: function() {
			if(this.customerSawItemsScreen) {
				if(this.currentTree && this.currentTree.length) {
					this.$store.dispatch('goToPreviousCategory')
				} else {
					this.$store.commit('updateShowLandingPage', true);
					this.$store.commit('updateShowSubCategoryMenu', false);
					this.$store.commit('updateCategorySelectIndex', '000');
				}
			} else {
				const smallerTree = this.currentTree.slice(0, -1)
				if(smallerTree && smallerTree.length) {
					this.$store.dispatch('goToPreviousCategory')
				} else {
					this.$store.commit('updateShowLandingPage', true);
					this.$store.commit('updateShowSubCategoryMenu', false);
					this.$store.commit('updateCategorySelectIndex', '000');
				}
			}
		}
	}
}
</script>

<style scoped>

</style>
