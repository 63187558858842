<template>
  <v-app>
    <v-app-bar
      v-if="initErrors.length === 0"
      id="app-bar"
      app
      :color="primaryColor"
      style="top: 0;"
      @click="backToLandingPage()"
    >
      <v-avatar
        :color="avatarBackgroundColor()"
        size="40"
      >
        <v-img
          v-if=" organization.logo && organization.logo.file && organization.logo.file.sources && organization.logo.file.sources.original"
          :src="organization.logo.file.sources.original"
        />
        <span
          v-else
          :style="avatarTextColor()"
          class="font-weight-bold"
        >{{ organization.label.charAt(0) }}</span>
      </v-avatar>
      <v-toolbar-title class="white--text pl-6">
        {{ getAppTitle }}
      </v-toolbar-title>
      <div
        class="caption white--text"
        style="top: 0; right: 2px; position: absolute;"
      >
        {{ headerText }}
      </div>
      <div
        v-if="showWaitingTime"
        class="caption white--text"
        style="top: 36px; right: 2px; position: absolute"
      >
        {{ $t('topWait', { waitTime: waitTime }) }}
      </div>
    </v-app-bar>
    <v-main>
      <div
        id="version"
        :version="version"
      />
      <alert-error />
      <ProgressCircular />

      <ReservationLandingPage v-if="!loading && !hasError && showingReservationLandingPage" />
      <Items v-if="!loading && !hasError && !showingReservationLandingPage" />
    </v-main>
    <v-footer
      v-if="initErrors.length === 0"
      id="app-footer"
      app
      class="grey lighten-2"
      :color="organization.accent_color"
      style="padding: 0; margin: 0; bottom: 0;"
    >
      <v-container class="grey lighten-2 pa-0">
        <v-row
          justify="center"
          dense
        >
          <v-col cols="auto">
            <div class="subtitle-2 text-center">
              eTags by <a
                target="_blank"
                href="https://www.salescloud.is"
                style="text-decoration: none;"
                rel="noopener noreferrer"
              >SalesCloud</a>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="false"
          justify="center"
          dense
        >
          <v-col cols="auto">
            <div class="caption text-center">
              {{ organizationName }} partners with SalesCloud to provide secure checkout and payments processing.
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-btn
        x-large
        height="65"
        dark
        block
        tile
        depressed
        :color="accentColor"
        @click="showOrder"
      >
        <v-row
          justify="space-between"
          align="center"
        >
          <v-col cols="4">
            {{ cart.length }} {{ $tc('buttons.items', cart.length) }}
          </v-col>
          <v-col cols="4">
            <v-icon large>
              mdi-cart
            </v-icon>
          </v-col>
          <v-col cols="4">
            {{ amountAndCurrencyAsText }}
          </v-col>
        </v-row>
      </v-btn>
    </v-footer>
    <bottom-cart v-if="showCart" />
    <item-details />
    <item-variation-selection v-if="showItemVariationSelection" />
  </v-app>
</template>

<script>
import Items from './components/Items'
import ProgressCircular from "./components/ProgressCircular";
import AlertError from "./components/AlertError";
import ItemDetails from "./components/ItemDetails";
import ItemVariationSelection from "./components/ItemVariationSelection";
import BottomCart from "./components/BottomCart";
import { version } from '../package.json'
import ReservationLandingPage from "@/components/landing/ReservationLandingPage";

/**
 * @property {Object} logo
 * @property {Object} newAnonymousSession
 * @property {Object} ApplePaySession
 * @property {Function} canMakePayments
 */
export default {
	name: 'App',
	components: {
    ReservationLandingPage,
		BottomCart,
		ItemVariationSelection,
		ItemDetails,
		AlertError,
		ProgressCircular,
		Items
	},
  filters: {
    currency: function(value) {
      if(!value) { return value }

      if(typeof value !== 'number') {
        return value;
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  },
	data: () => ({
		applePayCapable: false,
		show1: false,
		showCheckOut: false,
		showSnack: true,
		//sessionToken: null,
		//showCart: false,
		snack: {
			show: false,
			type: null,
			message: '',
			timeout: 1000
		}
	}),
	computed: {
    showingReservationLandingPage: {
      get() {
        return this.$store.state.showingReservationLandingPage
      },
      set(value) {
        this.$store.commit('updateShowingReservationLandingPage', value)
      }
    },
    enableReservationMode() {
      return this.$store.state.enableReservationMode
    },
    version() {
      return version
    },
		amountAndCurrencyAsText() {
			return this.$store.getters.currencyAsText(this.cartTotal)
		},
		hideClosedCategories: function() {
			return this.$store.state.hideClosedCategories
		},
		organizationName: function() {
			return this.$store.state.organization.label
		},
		showWaitingTime() {
			return this.$store.state.showWaitingTime
		},
		waitTime() {
			return this.$store.getters.getWaitTime
		},
		getAppTitle() {
			if(this.location && this.location.label) {
				return this.location.label
			}
      return this.organization.label
		},
		location: function() {
			return this.$store.state.location
		},
		landingPageFromParams: function() {
			return this.$store.state.landingPageFromParams
		},
		headerText: function() {
			const zoneObject = this.$store.state.variationOption;
			return zoneObject.label
			// if(zoneObject.label.toLowerCase().includes('table') || zoneObject.label.toLowerCase().includes('borð')) {
			//   return zoneObject.label
			// } else {
			//   return this.$t('table') + ' ' + zoneObject.label
			// }
		},
		showLandingPage: function() {
			return this.$store.state.showLandingPage
		},
		selectIndex: {
			get() {
				return this.$store.state.categorySelectIndex
			},
			set(value) {
				this.$store.commit('updateCategorySelectIndex', value)
			}
		},
		itemsByCategoriesSelect: function() {
			let items = this.$store.getters.itemsByCategories.map((item, index) => {
				return {
					text: item.name,
					value: (this.landingPageFromParams ? index + 1 : index)
				}
			})

			if(this.landingPageFromParams) {
				let a = [{
					text: 'Forsíða',
					value: 0,
				}]
				items = a.concat(items);
			}

			return items
		},
		primaryColor: function() {
			return this.$store.state.primaryColor
		},
		accentColor: function() {
			return this.$store.state.accentColor
		},
		singlePageCategories() {
			return this.$store.state.singlePageCategories
		},
		hideListPictures() {
			return this.$store.state.hideListPictures
		},
		showItemVariationSelection() {
			return this.$store.state.showSelectedItemVariationSelection
		},
		showCart: {
			get: function() {
				return this.$store.state.showCart
			},
			set: function(value) {
				this.$store.commit('updateShowCart', value)
			}
		},
		hasError: {
			get() {
				return this.$store.state.hasError
			},
			set(value) {
				this.$store.commit('updateHasError', value)
			}
		},
		message: {
			get() {
				return this.$store.state.message
			},
			set(value) {
				this.$store.commit('updateMessage', value)
			}
		},
		subMessage: {
			get() {
				return this.$store.state.subMessage
			},
			set(value) {
				this.$store.commit('updateSubMessage', value)
			}
		},
		card_number: {
			get: function() {
				return this.$store.state.card_number
			},
			set: function(newValue) {
				return this.$store.commit('setCardNumber', newValue)
			}
		},
		exp_month: {
			get: function() {
				return this.$store.state.exp_month
			},
			set: function(newValue) {
				return this.$store.commit('setExpMonth', newValue)
			}
		},
		exp_year: {
			get: function() {
				return this.$store.state.exp_year
			},
			set: function(newValue) {
				return this.$store.commit('setExpYear', newValue)
			}
		},
		cvc: {
			get: function() {
				return this.$store.state.cvc
			},
			set: function(newValue) {
				return this.$store.commit('setCVC', newValue)
			}
		},
		years: function() {
			return this.$store.getters.years
		},
		paymentInProgress: function() {
			return this.$store.state.paymentInProgress
		},
		variationOption: function() {
			return this.$store.state.variationOption
		},
		initErrors: function() {
			return this.$store.getters.initErrors
		},
		graphUrl: function() {
			return this.$store.state.graphUrl
		},
		organization: function() {
			return this.$store.state.organization
		},
		cart: function() {
			return this.$store.state.cart
		},
		sessionToken: function() {
			return this.$store.state.sessionToken
		},
		itemsList: function() {
			return this.$store.state.itemsList
		},
		cartTotal: function() {
			return this.$store.getters.cartTotal
		},
		cartEmpty: function() {
			return this.$store.getters.cartEmpty
		},
		paymentRequestUrl: function() {
			return this.$store.state.paymentRequestUrl
		},
		fallbackPaymentForm: function() {
			return this.$store.state.fallbackPaymentForm
		},
		paymentMethod: function() {
			return this.$store.state.paymentMethod
		},
		loading: function() {
			return this.$store.state.loading
		},
		organizationUUID: function() {
			return this.$store.state.organizationUUID
		},
		channelUUID: function() {
			return this.$store.state.channelUUID
		},
		locationUUID: function() {
			return this.$store.state.locationUUID
		},
		hasCurrentItemInFull: {
			get: function() {
        const currentItemInFull = this.$store.state.currentItemInFull
        return typeof currentItemInFull !== 'undefined' && currentItemInFull !== null
			},
			set: function() {
				this.$store.commit('setCurrentItemInFull', null)
			}
		},
		currentItemInFull: {
			get: function() {
				return this.$store.state.currentItemInFull
			},
			set: function() {
				this.$store.commit('setCurrentItemInFull', null)
			}
		},
    itemsByCategories: function () {
      return this.$store.getters.itemsByCategories
    },
	},
	watch: {
		showItemVariationSelection(value) {
			if(value === false) {
				this.$store.commit('updateSelectedItem', null);
			}
		},
		organization: function(organization) {
			if(organization && organization.primary_color) {
				document.head.querySelector('meta[name="theme-color"]').content = organization.primary_color
			}
		},
		showSnack: function() {
		},
    showCart(value) {
      if(value === true) {
        this.$store.dispatch('getCheckoutSectionInstanceList')
      }
    }
	},
	beforeMount() {
		if (!this.inIframe()) {
      if(typeof window !== 'undefined' && window !== null) {
        /* Only enable apple payments if we are not in an iframe */
        try {
          if(
            typeof window.ApplePaySession !== 'undefined' &&
            window.ApplePaySession !== null &&
            window.ApplePaySession.canMakePayments() &&
            typeof window.PaymentRequest !== 'undefined' &&
            window.PaymentRequest !== null
          ) {
            this.$store.commit('setApplePayCapable', true)
          }
        } catch (e) {
          this.$store.commit('setApplePayCapable', false)
        }

        /* Check for browser wallet support */
        try {
          if(
            typeof window.PaymentRequest !== 'undefined' &&
            window.PaymentRequest !== null
          ) {
            const supported = [{ supportedMethods: 'basic-card' }]
            const details = { total: { label: 'Total', amount: { currency: 'USD', value: '1' }}}
            const paymentRequest = new PaymentRequest(supported, details)
            paymentRequest.canMakePayment().then(result => {
              if(result) {
                this.$store.commit('setWalletPaymentAvailable', true)
              }
            })
          }
        } catch (e) {
          this.$store.commit('setWalletPaymentAvailable', false)
        }

        try {
          if(window.localStorage){
            let welcomeInLocalStorage = window.localStorage.getItem(this.organizationUUID + ':' + this.channelUUID + ':' + this.locationUUID + ':showWelcomeMessage')
            if(welcomeInLocalStorage) {
              if(welcomeInLocalStorage === 'false') { this.$store.commit('updateShowWelcomeMessage', false)}
              if(welcomeInLocalStorage === 'true') { this.$store.commit('updateShowWelcomeMessage', true)}
            }
          }
        } catch (e) {
          // local storage assumed not available
        }
      }
		}
	},
	created() {

		const urlParams = new URLSearchParams(window.location.search);
		this.$store.commit('updateStoredUrlParams', urlParams);

		let organizationUUID = urlParams.get('organizationUUID');
		let channelUUID = urlParams.get('channel');
		let paymentInstanceUUID = urlParams.get('paymentInstanceUUID');
		let unitID = urlParams.get('unitID');
		let locationUUID = urlParams.get('l');
		let eventsMode = urlParams.get('eventsMode')
        this.$store.commit('setEventsMode', eventsMode === 'true')
        if(channelUUID !== null && channelUUID !== undefined && channelUUID === 'd54715be-c71d-4ce8-b87e-633641836037') {
            this.$store.commit('setEventsMode', true)
        }

    // let showTimeSelectionForEvents = urlParams.get('showTimeSelectionForEvents')
    // this.$store.commit('setShowTimeSelectionForEvents', showTimeSelectionForEvents === 'true')
    //
    // let showDateSelectionForEvents = urlParams.get('showDateSelectionForEvents')
    // this.$store.commit('setShowDateSelectionForEvents', showDateSelectionForEvents === 'true')

		let categories = urlParams.getAll('category');
		if(categories) {
			// Allowing for input of category uuids in a single category param separated by a comma
			if(categories.length === 1 && categories[0].includes(',')) {
				categories = categories[0].split(',')
			}
			this.$store.commit('updateCategoriesFromParams', categories)
		}

		let hideListPictures = urlParams.get('hideListPictures');
		if(hideListPictures !== null) {
			if(hideListPictures === 'true') { this.$store.commit('updateHideListPictures', true) }
			if(hideListPictures === 'false') { this.$store.commit('updateHideListPictures', false) }
		}

		let singlePageCategories = urlParams.get('singlePageCategories');
		if(singlePageCategories !== null) {
			if(singlePageCategories === 'true') { this.$store.commit('updateSinglePageCategories', true) }
			if(singlePageCategories === 'false') { this.$store.commit('updateSinglePageCategories', false) }
		}

		let singlePageVariations = urlParams.get('singlePageVariations');
		if(singlePageVariations !== null) {
			if(singlePageVariations === 'true') { this.$store.commit('updateSinglePageVariations', true) }
			if(singlePageVariations === 'false') { this.$store.commit('updateSinglePageVariations', false) }
		} else {
			if(singlePageCategories !== null) {
				this.$store.commit('updateSinglePageVariations', this.singlePageCategories)
			}
		}

		let hideItemDetails = urlParams.get('hideItemDetails');
		if(hideItemDetails !== null) {
			if(hideItemDetails === 'true') { this.$store.commit('updateHideItemDetails', true) }
			if(hideItemDetails === 'false') { this.$store.commit('updateHideItemDetails', false ) }
		}

		let removeDefaultVariationsFromPrice = urlParams.get('removeDefaultVariationsFromPrice');
		if(removeDefaultVariationsFromPrice !== null) {
			if(removeDefaultVariationsFromPrice === 'true') { this.$store.commit('updateRemoveDefaultVariationsFromPrice', true) }
			if(removeDefaultVariationsFromPrice === 'false') { this.$store.commit('updateRemoveDefaultVariationsFromPrice', false) }
			this.$store.commit('setRemoveDefaultVariationsInParams', removeDefaultVariationsFromPrice === 'true' || removeDefaultVariationsFromPrice === 'false')
		}

		// Currency format rule can be space (2 000), dot (2.000) or comma (2,000).
		let currencyFormatRule = urlParams.get('currencyFormatRule');
		if(currencyFormatRule !== null) {
			this.$store.commit('setCurrencyRule', currencyFormatRule);
		}

		// Show items under hardcoded category Other if they are missing from a category
		let showOtherItems = urlParams.get('showOtherItems');
		if(showOtherItems !== null) {
			if(showOtherItems === 'true') { this.$store.commit('updateShowOtherItems', true) }
			if(showOtherItems === 'false') { this.$store.commit('updateShowOtherItems', false) }
		}

		let landingPage = urlParams.get('landingPage');
		if(landingPage !== null) {
			if(landingPage === 'true') { this.$store.commit('setLandingPageFromParams', true) }
			if(landingPage === 'false') { this.$store.commit('setLandingPageFromParams', false) }
			if(!singlePageCategories) { this.$store.commit('updateSinglePageCategories', false ) }
		}

		let showWaitingTime = urlParams.get('showWaitingTime');
		if(showWaitingTime !== null) {
			if(showWaitingTime === 'true') { this.$store.commit('updateShowWaitingTime', true) }
			if(showWaitingTime === 'false') { this.$store.commit('updateShowWaitingTime', false) }
		}

		let subCategoryFromParams = urlParams.get('showSubCategoryMenu');
		if(subCategoryFromParams !== null) {
			if(subCategoryFromParams === 'true') { this.$store.commit('updateSubCategoryFromParams', true) }
			if(subCategoryFromParams === 'false') { this.$store.commit('updateSubCategoryFromParams', false) }
		}

		let disableVariationSelection = urlParams.get('disableVariationSelection')
		if(disableVariationSelection !== null) {
			if(disableVariationSelection === 'true') { this.$store.commit('updateDisableVariationSelection', true) }
			if(disableVariationSelection === 'false') { this.$store.commit('updateDisableVariationSelection', false) }
		}

		let primaryColor = urlParams.get('primaryColor');
		if(primaryColor !== null) {
			if(primaryColor.charAt(0) !== '#') { primaryColor = '#' + primaryColor }
			this.$store.commit('updatePrimaryColor', primaryColor)
		}

		let accentColor = urlParams.get('accentColor');
		if(accentColor !== null) {
			if(accentColor.charAt(0) !== '#') { accentColor = '#' + accentColor }
			this.$store.commit('updateAccentColor', accentColor)
		}

		let languageSelection = urlParams.get('language');
		this.$store.commit('setLanguage', languageSelection)
		this.$store.dispatch('setLocale', languageSelection)

		if(locationUUID !== null) {
			this.$store.commit('setLocation', locationUUID);
		}

		let hideClosedCategories = urlParams.get('hideClosedCategories');
		if(hideClosedCategories !== null) {
			if(hideClosedCategories === 'true') { this.$store.commit('updateHideClosedCategories', true) }
			if(hideClosedCategories === 'false') { this.$store.commit('updateHideClosedCategories', false) }
		}

		let showWelcomeMessage = urlParams.get('showWelcomeMessage');
		if(showWelcomeMessage !== null) {
			if(showWelcomeMessage === 'true') { this.$store.commit('updateShowWelcomeMessage', true) }
			if(showWelcomeMessage === 'false') { this.$store.commit('updateShowWelcomeMessage', false) }
		}

		let welcomeMessageHeader = urlParams.get('welcomeMessageHeader');
		if(welcomeMessageHeader !== null) {
			this.$store.commit('updateWelcomeMessageHeader', welcomeMessageHeader)
		}

		let welcomeMessageText = urlParams.get('welcomeMessageText');
		if(welcomeMessageText !== null) {
			this.$store.commit('updateWelcomeMessageText', welcomeMessageText)
		}

		let includeItemsInCategories = urlParams.getAll('include');
		if(includeItemsInCategories !== null && includeItemsInCategories.length) {
			if(includeItemsInCategories.length === 1 && Array.isArray(includeItemsInCategories) && includeItemsInCategories.includes(',')) {
				includeItemsInCategories = includeItemsInCategories[0].split(',')
			}
			this.$store.commit('setIncludeItemsInCategories', includeItemsInCategories)
		}

		let excludeItemsInCategories = urlParams.getAll('exclude');
		if(excludeItemsInCategories !== null && excludeItemsInCategories.length) {
			if(excludeItemsInCategories.length === 1 && Array.isArray(excludeItemsInCategories) && excludeItemsInCategories.includes(',')) {
				excludeItemsInCategories = excludeItemsInCategories[0].split(',')
			}
			this.$store.commit('setExcludeItemsInCategories', excludeItemsInCategories)
		}

		let excludeCategories = urlParams.getAll('excludeCategories');
		if(excludeCategories !== null && excludeCategories.length) {
			if(excludeCategories.length === 1 && Array.isArray(excludeCategories) && excludeCategories.includes(',')) {
				excludeCategories = excludeCategories[0].split(',')
			}
			this.$store.commit('setExcludeCategories', excludeCategories)
		}

		let allowCoupon = urlParams.get('allowCoupon')
		this.$store.commit('setAllowCoupon', allowCoupon === 'true')

		let hideItemsBlockedByLocation = urlParams.get('hideItemsBlockedByLocation')
		this.$store.commit('setHideItemsBlockedByLocation', hideItemsBlockedByLocation === 'true')

    let hideBlockedItems = urlParams.get('hideBlockedItems')
    this.$store.commit('setHideItemsBlockedByLocation', hideBlockedItems === 'true')

		let limitCategoryOpeningHoursToParamCategories = urlParams.get('limitCategoryOpeningHoursCheck')
        if(limitCategoryOpeningHoursToParamCategories === 'false') {
            this.$store.commit('setLimitCategoryOpeningHoursToParamCategories', false)
        }

		let previewTimestamp = urlParams.get('previewTimestamp')
        if(previewTimestamp !== undefined) {
            if(previewTimestamp === null || previewTimestamp === 'null') {
                this.$store.commit('setPreviewTimestamp', null)
            } else {
                try {
                    this.$store.commit('setPreviewTimestamp', previewTimestamp * 1)
                } catch (e) {
                    // do nothing
                }
            }
        }

		let showAddGuestsToTableButton = urlParams.get('showAddGuests')
    this.$store.commit('setShowAddGuestsToTableButton', showAddGuestsToTableButton === 'true')

    let additionalCartItems = urlParams.getAll('addItem')
    this.$store.commit('setAdditionalCartItemUUIDs', additionalCartItems)

    // let followLocationOpeningHours = urlParams.get('followLocationOpeningHours')
    // Because default value for following location opening hours is set for true we only want to disable it if the value is actually set to false
    // this.$store.commit('setFollowLocationOpeningHours', !(followLocationOpeningHours === 'false'))

    const reservationWidgetUUID = urlParams.get('widgetUUID')
    this.$store.commit('setReservationWidgetUUID', reservationWidgetUUID)

    const appProfileSlug = urlParams.get('slug')
    this.$store.commit('setAppProfileSlug', appProfileSlug)

    const enableReservationMode = urlParams.get('enableReservationMode')
    this.$store.commit('setEnableReservationMode', enableReservationMode === 'true')

    const reservationLink = urlParams.get('reservationLink')
    this.$store.commit('setReservationLink', reservationLink)

    const showItemImagesInItemDetails = urlParams.get('showItemDetailsImages')
    this.$store.commit('setShowItemImagesInItemDetails', showItemImagesInItemDetails === 'true')

    const roomServiceTakeaway = urlParams.get('rst')
    this.$store.commit('setShowTakeawayTextOnRoomService', roomServiceTakeaway === 'true')

		// let upsellAllowed = urlParams.get('upsellAllowed')
		// this.$store.commit('setUpsellAllowed', upsellAllowed === 'true')

		if(organizationUUID !== null && channelUUID !== null && paymentInstanceUUID !== null && unitID !== null) {
			this.$store.commit('updateChannelUUID', channelUUID)
			this.$store.commit('updatePaymentInstanceUUID', paymentInstanceUUID)
			this.$store.commit('updateOrganizationUUID', organizationUUID)
			this.$store.commit('updateUnitID', unitID)
			return;
		}

		this.$store.commit('updateHasError', true);
		this.$store.commit('updateMessage', 'No item reference');
		this.$store.commit('updateSubMessage', 'The link that you are trying to access is incomplete.')
		// this.hasError = true;
		// this.message = 'No item reference';
		// this.subMessage = 'The link that your are trying to access is incomplete.';
	},
	mounted() {
		if(!this.hasError) {
			this.$store.dispatch('getInitialData').then(() => {
				//console.log(this.$store.getters.getDefaultPaymentMethodForWalletPayment)
			})
			//this.getSessionToken();
		}
		if(!this.inIframe()) {
			window.addEventListener('beforeunload', function(e) {
				e.returnValue = 'Are you sure you want to leave? The data you have put in will be lost.'
			})
		}

		// this.$nextTick(function() {
		//   this.$store.commit('updateAppBarHeight', document.getElementById('app-bar').offsetHeight);
		//   this.$store.commit('updateAppFooterHeight', document.getElementById('app-footer').offsetHeight);
		// })
	},
	methods: {
		currencyFormat(value) {
			return this.$store.getters.currencyFormat(value)
		},
		backToLandingPage() {
      if(this.enableReservationMode) {
        this.showingReservationLandingPage = true
      } else if(this.landingPageFromParams) {
				this.$store.commit('updateCategorySelectIndex', '000');
				this.$store.commit('updateShowLandingPage', true);
        this.$store.commit('updateShowSubCategoryMenu', false)
			}
		},
		inIframe() {
			try {
				return window.self !== window.top;
			} catch (e) {
				return true;
			}
		},
		pickTextColorBasedOnBgColorSimple(bgColor, lightColor, darkColor) {
			let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
			let r = parseInt(color.substring(0, 2), 16); // hexToR
			let g = parseInt(color.substring(2, 4), 16); // hexToG
			let b = parseInt(color.substring(4, 6), 16); // hexToB
			return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darkColor : lightColor;
		},
		avatarBackgroundColor: function() {
			if(this.primaryColor === this.accentColor) {
				return this.pickTextColorBasedOnBgColorSimple(this.primaryColor, '#FFFFFF', '#000000')
			}
      return this.accentColor
		},
		avatarTextColor: function() {
			//return 'color: ' + this.primaryColor + ';'
			//return 'color: ' + this.pickTextColorBasedOnBgColorSimple(this.organization.accent_color, '#000000', '#FFFFFF') + ';'
			return 'color: ' + this.pickTextColorBasedOnBgColorSimple(this.avatarBackgroundColor(),'#FAFAFA', '#000000') + ';'
		},
		showOrder: function() {
			if(this.cart.length > 0) {
				this.showCart = !this.showCart
			}
		},
		removeFromCart(index) {
			this.$store.commit('removeItemFromCart', index)

			if(this.cartEmpty) {
				this.showCart = false
			}
		},
		checkImage(item){
			if(!item.images || !item.images.length ||
					!item.images[0] || !item.images[0].file ||
					!item.images[0].file.sources || !item.images[0].file.sources.thumbnail) {
				return ''
			}
      return item.images[0].file.sources.thumbnail
		},
	}
};
</script>
