<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-btn
          id="add-guests-to-table"
          block
          large
          color="red"
          dark
          class="animated-button"
          @click="goToAddGuests"
        >
          <v-icon
            v-if="!verySmallScreen"
            left
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-spacer />
          <span>{{ $t('buttons.c19Register') }}</span>
          <v-spacer />
          <v-icon
            v-if="!verySmallScreen"
            right
          >
            mdi-alert-circle-outline
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "AddGuestsToTable",
	computed: {
		organizationUUID() {
			return this.$store.getters.organizationUUID
		},
		locationUUID() {
			return this.$store.getters.locationUUID
		},
		tableID() {
			return this.$store.getters.unitID
		},
		screenWidth() {
			return this.$vuetify.breakpoint.width
		},
		verySmallScreen() {
			return this.screenWidth <= 300
		}
	},
	methods: {
		goToAddGuests() {
			let url = 'https://guests.salescloud.is/?organization=' + this.organizationUUID + '&l=' + this.locationUUID + '&table=' + this.tableID
			window.open(url, '_blank')
		}
	}
}
</script>

<style scoped>

</style>
