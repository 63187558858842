<template>
  <v-card tile>
    <v-card
      tile
      dark
      :color="primaryColor"
    >
      <v-card-title>
        {{ $t('giftcard.payWithGiftCard') }}
        <v-spacer />
        <v-btn
          dark
          :color="accentColor"
          @click="back"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text>
      <v-form v-model="filled">
        <v-text-field
          v-model="giftCardCodeUpper"
          :label="$t('giftcard.number') + ' *'"
          outlined
          :rules="[rules.required, rules.minLength]"
          :color="primaryColor"
          :error-messages="errorMessage"
          hide-details="auto"
        />
      </v-form>
    </v-card-text>
    <v-card-actions class="pa-0">
      <v-btn
        :disabled="paymentInProgress"
        :loading="paymentInProgress"
        tile
        x-large
        height="65"
        block
        color="green"
        dark
        @click="processGiftCardPayment"
      >
        <span>{{ $t('buttons.pay') }} {{ amountAndCurrencyAsText }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "GiftCardPayment",
	data() {
		return {
			errorMessage: '',
			giftCardCode: null,
			filled: false,
			rules: {
				required: v => !!v || this.$t('payment.required'),
				minLength: v => v && v.length >= 5 || this.$t('giftcard.minRequiredLength')
			}
		}
	},
	computed: {
		amountAndCurrencyAsText() {
			return this.$store.getters.currencyAsText(this.cartTotal)
		},
		giftCardCodeUpper: {
			get() {
				return this.giftCardCode
			},
			set(value) {
				this.giftCardCode = value ? value.toUpperCase() : value
			}
		},
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		paymentInProgress: function() {
			return this.$store.state.paymentInProgress
		},
		cartTotal: function() {
			return this.$store.getters.cartTotal
		},
	},
	watch: {
		giftCardCode() {
			this.errorMessage = ''
		}
	},
	methods: {
		back() {
			this.$store.commit('updateShowGiftCardInput', false)
		},
		processGiftCardPayment() {
			this.errorMessage = ''
			if(!this.filled && !this.giftCardCode) {
				this.errorMessage = this.$t('giftcard.numberRequired')
				return
			}

			if(!this.filled && this.giftCardCode && this.giftCardCode.length < 5) {
				this.errorMessage = this.$t('giftcard.minRequiredLength')
				return
			}

			this.$store.dispatch('validateBeforePayment').then(result => {
				if(result.success === false) {
					this.$store.commit('updateErrorMessage', result.message)
					this.$store.commit('updateErrorTitle', this.$t('errors.paymentPreprocessError'))
					this.$store.commit('updateShowBottomError', true)
					return
				}

				if(result.success === true) {
					const paymentPayload = {
						amount: this.cartTotal.amount,
						currency_code: this.cartTotal.currency_code,
						details: {
							giftcard: this.giftCardCode
						}
					}

					this.$store.dispatch('processGiftCardPayment', paymentPayload)
				}
			})
		},
		currencyFormat(value) {
			return this.$store.getters.currencyFormat(value)
		},
	}
}
</script>

<style scoped>

</style>
