import currencyFormatter from "currency-formatter";

export default {
	state: {
		currencies: [],
		defaultCurrency: null,
		defaultOrganizationCurrency: null,
		divideByThousandCurrencies: [],
		noDivideCurrencies: ['ISK', 'JPY'],
		currencyRule: null,
    hundred: 100,
    thousand: 1000
	},
	mutations: {
		setCurrencies(state, currencies) {
			state.currencies = currencies
		},
		setDefaultCurrency(state, currency) {
			state.defaultCurrency = currency
		},
		setDefaultOrganizationCurrency(state, currency) {
			state.defaultOrganizationCurrency = currency
		},
		setCurrencyRule(state, rule) {
			state.currencyRule = rule
		},
	},
	actions: {},
	getters: {
		currencies: (state) => {
			return state.currencies
		},
		defaultCurrency: (state) => {
			return state.defaultCurrency
		},
		defaultOrganizationCurrency: (state) => {
			return state.defaultOrganizationCurrency
		},
		currencyAsText: (state, getters) => (priceObject) => {
			if(typeof priceObject !== 'undefined' && priceObject !== null) {
        if(typeof priceObject === 'undefined' || priceObject.amount === null) {
          priceObject.amount = 0
        }
        if(typeof priceObject === 'undefined' || priceObject.currency_code === null) {
          priceObject.currency_code = getters.defaultOrganizationCurrency.code || getters.defaultCurrency.code || 'ISK'
        }

        const foundCurrency = currencyFormatter.findCurrency(priceObject.currency_code)
        // Back-up if currency formatter did not find the currency
        if(!foundCurrency) {
          const numberFormatObject = new Intl.NumberFormat('de-DE', { style: 'currency', currency: priceObject.currency_code, currencyDisplay: 'narrowSymbol' })
          if(priceObject.currency_code === 'ISK') {
            return `${priceObject.amount} ${priceObject.currency_code}`
          } else if(state.noDivideCurrencies.includes(priceObject.currency_code)) {
            return numberFormatObject.format(priceObject.amount)
          } else if(state.divideByThousandCurrencies.includes(priceObject.currency_code)) {
            return numberFormatObject.format(priceObject.amount/state.thousand)
          }
          return numberFormatObject.format(priceObject.amount/state.hundred)
        } else {
          const options = {
            code: priceObject.currency_code
          }
          // If we want to allow the option of either using currency symbol or currency three-letter code (ISK, EUR, etc)
          if(getters.showCurrencyCode) {
            // See http://openexchangerates.github.io/accounting.js/
            options.symbol = priceObject.currency_code
            options.format = '%v %s'
          }
          let formattedAmount = priceObject.amount

          formattedAmount = formattedAmount / Math.pow(10, foundCurrency.decimalDigits)
          return currencyFormatter.format(formattedAmount, options)
        }
			}
			return 0
		},
		currencyFormat: (state) => (value) => {
			if (typeof value !== 'number') {
				return value;
			}

			const currencyRegex = /\B(?=(?:\d{3})+(?!\d))/g
			switch (state.currencyRule) {
				case 'dot':
					return value.toString().replace(currencyRegex, '.');
				case 'comma':
					return value.toString().replace(currencyRegex, ',');
				case 'space':
					return value.toString().replace(currencyRegex, ' ');
				case 'default':
					return value.toString()
				default:
					return value.toString()
			}
		},
	}
}
