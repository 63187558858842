<template>
  <v-card
    color="red"
    class="text-center pt-10 d-flex flex-column"
    dark
    tile
    flat
    min-height="50vh"
  >
    <v-card-title class="pa-0 justify-center">
      <v-icon
        size="64"
        color="white"
        @dblclick="showLastErrorInFull = !showLastErrorInFull"
      >
        mdi-emoticon-sad
      </v-icon>
    </v-card-title>
    <v-card-title
      class="text-center display-1"
      style="display:block;"
    >
      {{ $t('payment.failure.title') }}
    </v-card-title>
    <v-card-text
      v-if="showLastErrorInFull"
      class="overflow-y-auto"
    >
      <LastErrorInFull />
    </v-card-text>
    <v-card-text
      v-if="paymentResponse && paymentResponse.paymentTransaction && paymentResponse.paymentTransaction.message"
      class="title"
    >
      {{ paymentResponse.paymentTransaction.message }}
    </v-card-text>
    <v-card-text class="title">
      <div>{{ $t('payment.failure.notConfirmed') }}</div><div>{{ $t('payment.failure.tryAgain') }}</div>
    </v-card-text>
    <v-spacer class="fill-height" />
    <v-card-actions>
      <v-btn
        x-large
        depressed
        block
        @click="retryPayment"
      >
        <span>
          {{ $t('buttons.tryAgain') }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LastErrorInFull from "@/components/dev/LastErrorInFull";
// import Bugsnag from "@bugsnag/js";

export default {
	name: "PaymentFailure",
	components: {LastErrorInFull},
	data() {
		return {
			showLastErrorInFull: false
		}
	},
	computed: {
		paymentResponse() {
			return this.$store.getters.paymentResponse
		},
		isApplePayCapable() {
			return this.$store.getters.applePayCapable
		}
	},
	mounted() {
		// if(this.isApplePayCapable) {
		// 	let paymentResponse = this.paymentResponse
		// 	let error = new Error()
		// 	error.name = 'TransactionError'
		// 	error.message = 'Possible Apple Pay Error'
		// 	error.stack = paymentResponse
		// 	Bugsnag.notify(error)
		// }
		this.$store.commit('updateLastErrorInFull', this.paymentResponse)
	},
	methods: {
		retryPayment: function() {
			this.$store.commit('setPaymentResponse', null)
		},
	}
}
</script>

<style scoped>

</style>
