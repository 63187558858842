<template>
  <v-container
    v-if="loading"
    class="fill-height"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col cols="auto">
        <v-progress-circular
          indeterminate
          :color="primaryColor"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "ProgressCircular",
	computed: {
		primaryColor() {
			return this.$store.state.primaryColor
		},
		loading() {
			return this.$store.state.loading
		},
		hasError() {
			return this.$store.state.hasError
		},
		organization() {
			return this.$store.state.organization
		}
	}
}
</script>

<style scoped>

</style>
