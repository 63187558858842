import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center pt-10 pb-5 d-flex flex-column",attrs:{"tile":"","flat":"","height":"50vh"}},[_c(VContainer,{staticClass:"flex fill-height"},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,[_c('div',[_c(VProgressCircular,{attrs:{"indeterminate":"","color":_vm.primaryColor,"size":"48"}})],1),(_vm.pending === true)?_c('div',{staticClass:"pt-8"},[_c('div',{staticClass:"font-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$t('payment.pending'))+" ")]),(_vm.paymentPendingLastCheckedDate)?_c('div',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('payment.lastChecked'))+" "+_vm._s(_vm.lastCheckedText)+" ")]):_vm._e()]):_c('div',{staticClass:"pt-8 font-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$t('payment.inProgress'))+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }