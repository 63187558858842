import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"tile":""}},[_c(VCard,{attrs:{"tile":"","dark":"","color":_vm.primaryColor}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('giftcard.payWithGiftCard'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"dark":"","color":_vm.accentColor},on:{"click":_vm.back}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")])])],1)],1),_c(VCardText,[_c(VForm,{model:{value:(_vm.filled),callback:function ($$v) {_vm.filled=$$v},expression:"filled"}},[_c(VTextField,{attrs:{"label":_vm.$t('giftcard.number') + ' *',"outlined":"","rules":[_vm.rules.required, _vm.rules.minLength],"color":_vm.primaryColor,"error-messages":_vm.errorMessage,"hide-details":"auto"},model:{value:(_vm.giftCardCodeUpper),callback:function ($$v) {_vm.giftCardCodeUpper=$$v},expression:"giftCardCodeUpper"}})],1)],1),_c(VCardActions,{staticClass:"pa-0"},[_c(VBtn,{attrs:{"disabled":_vm.paymentInProgress,"loading":_vm.paymentInProgress,"tile":"","x-large":"","height":"65","block":"","color":"green","dark":""},on:{"click":_vm.processGiftCardPayment}},[_c('span',[_vm._v(_vm._s(_vm.$t('buttons.pay'))+" "+_vm._s(_vm.amountAndCurrencyAsText))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }