import i18n from "../i18n";
import axios from "axios";

export default {
	state: {
		validating: false
	},
	mutations: {
		updateValidating(state, status) {
			state.validating = status
		}
	},
	actions: {
		validateBeforePayment({ commit, getters }) {
			commit('updateValidating', true)

			const graphUrl = getters.graphUrl
			const organizationUUID = getters.organizationUUID
      const locationUUID = getters.locationUUID

			const body = {
				query: "query {\n" +
					"   systemTimeInMillis\n" +
					'   itemsList(organization: "' + organizationUUID + '", sellInEtags: true' +
          (locationUUID ? ', locations: "' + [ locationUUID ] + '"' : '') +
          ') {\n' +
					"       title\n" +
					"       uuid\n" +
					"       type\n" +
					"       price {\n" +
					"           amount\n" +
					"           currency_code\n" +
					"       }\n" +
					"       description {\n" +
					"           value\n" +
					"           summary\n" +
					"           format\n" +
					"       }\n" +
					"       categories {\n" +
					"           uuid\n" +
					"           name\n" +
					"           opening_hours{\n" +
					"               day\n" +
					"               starthours\n" +
					"               endhours\n" +
					"               comment\n" +
					"           }\n" +
					"       }\n" +
					"       sorting_weight\n" +
					"       status\n" +
					"       variations{\n" +
					"           uuid\n" +
					"           label\n" +
					"           price{\n" +
					"               amount\n" +
					"               currency_code\n" +
					"           }\n" +
					"           default\n" +
					"           sorting_weight\n" +
					"           attribute{\n" +
					"               uuid\n" +
					"               name\n" +
					"               weight\n" +
					"               required\n" +
					"               single\n" +
					"               ept\n" +
					"               opening_hours{\n" +
					"                   day\n" +
					"                   starthours\n" +
					"                   endhours\n" +
					"                   comment\n" +
					"               }\n" +
					"           }\n" +
					"       }\n" +
					"       default_variations{\n" +
					"           uuid\n" +
					"           label\n" +
					"           price{\n" +
					"               amount\n" +
					"               currency_code\n" +
					"           }\n" +
					"           default\n" +
					"           sorting_weight\n" +
					"           attribute{\n" +
					"               uuid\n" +
					"               name\n" +
					"               weight\n" +
					"               required\n" +
					"               single\n" +
					"               ept\n" +
					"               opening_hours{\n" +
					"                   day\n" +
					"                   starthours\n" +
					"                   endhours\n" +
					"                   comment\n" +
					"               }\n" +
					"           }\n" +
					"       }\n" +
					"       images {\n" +
					"           file {\n" +
					"               sources {\n" +
					"                   original\n" +
					"                   medium\n" +
					"                   thumbnail\n" +
					"               }\n" +
					"           }\n" +
					"       }\n" +
					"   }\n" +
					"}"
			}
			return axios.post(graphUrl, body).then(results => {
				commit('updateSystemTime', results.data.data.systemTimeInMillis)
				commit('updateItemsList', results.data.data.itemsList)

				return getters.validateItemsInCartBeforePayment
			}).finally(() => {
				commit('updateValidating', false)
			})
		}
	},
	getters: {
		validating: (state) => {
			return state.validating
		},
		validateItemsInCartBeforePayment: (state, getters) => {
			const response = {
				success: false,
				message: 'Validating items in cart before payment'
			}

			if(!getters.locationIsOpen) {
				response.message = 'Location has closed'
				return response
      		}

			const cart = getters.cart
			for(let i = 0; i < cart.length; i++) {
				const lineItemValidation = getters.validateLineItem(cart[i])
				if(lineItemValidation.success === false) {
					response.message = 'Line item failed validation'
					response.message = lineItemValidation.message
					return response
				}
			}

			response.success = true
			response.message = 'Successfully validated items in cart'
			return response
		},
		validateLineItem: (state, getters) => (lineItem) => {
			const response = {
				success: false,
				message: 'Validating line items'
			}

			const lineItemCategories = lineItem.categories
			const categoryValidation = {}
			if(lineItemCategories && Array.isArray(lineItemCategories)) {
				const categoryResults = []
				const containsParentCategory = lineItemCategories.filter(cat => getters.categoriesFromParams.includes(cat.uuid))

				for(let i = 0; i < lineItemCategories.length; i++) {
					const category = lineItemCategories[i]

					if(getters.limitCategoryOpeningHoursToParamCategories && Array.isArray(getters.categoriesFromParams) && getters.categoriesFromParams.length > 0 && !getters.categoriesFromParams.includes(category.uuid) && containsParentCategory.length > 0) {
						continue
					}

					const itemTitle = getters.translateByType('item.title', lineItem)

					const singleCategoryValidation = getters.validateCategory(lineItemCategories[i], itemTitle)

					const res = {}

					if(singleCategoryValidation.success === false) {
						res.success = false
						res.message = 'Category failed validation'
						res.message = singleCategoryValidation.message
						categoryResults.push(res)
						continue
					}

					res.success = true
					res.message = 'Category validation successful'
					categoryResults.push(res)
				}
				if(categoryResults.some(r => r.success === true)) {
					categoryValidation.success = true
					categoryValidation.message = 'Successfully validated lineItem'
				} else {
					categoryValidation.success = false
					const itemTitle = getters.translateByType('item.title', lineItem)
					categoryValidation.message = i18n.t('errors.categoryOpeningHours', { item: itemTitle })
				}
			}

			const lineItemItem = lineItem.item
			const productBlocked = {}
			if(getters.productIsBlockedByLocation(lineItemItem)) {
				productBlocked.success = false
				productBlocked.message = i18n.t('errors.itemCurrentlyNotAvailable')
			}

			if(categoryValidation.success === false || productBlocked.success === false) {
				response.message = categoryValidation.success === false ? categoryValidation.message : productBlocked.message
				return response
			}

			response.success = true
			response.message = 'Successfully validated lineItem'
			return response
		},
		validateCategory: (state, getters) => (category, itemTitle) => {
			const response = {
				success: false,
				message: 'Validating category'
			}

			if(Array.isArray(category.opening_hours)) {
				// const openingHoursResult = getters.validateOpeningHours(category.opening_hours)
				// if(openingHoursResult.success === false) {
				// 	response.message = 'Opening hours validation failed'
				// 	response.message = openingHoursResult.message
				// 	return response
				// }
				const isOpen = getters.categoryIsOpen(category)
				if(isOpen === false) {
					response.message = i18n.t('errors.categoryOpeningHours', { item: itemTitle })
					return response
				}
			}

			response.success = true
			response.message = 'Successfully validated category'
			return response
		},
		validateOpeningHours: (state, getters) => (openingHours) => {
			const response = {
				success: false,
				message: 'Validating opening hours'
			}

			let timestamp = getters.systemTimeInMillis
      if(typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
        timestamp = getters.previewTimestamp
      }
			const currentTime = new Date(timestamp)
			const today = currentTime.getUTCDay()

			const hours = openingHours.filter(time => time && time.day === today.toString())

			let isOpen = true

			for(let i = 0; i < hours.length; i++) {
				let startHour = hours[i].starthours.split(/(?=(?:.{2})*$)/)
				let endHour = hours[i].endhours.split(/(?=(?:.{2})*$)/)
                let ts = getters.systemTimeInMillis
                if(typeof getters.previewTimestamp !== 'undefined' && getters.previewTimestamp !== null) {
                    ts = getters.previewTimestamp
                }
				const tempTime = new Date(ts)

        const startHours = Number(startHour[0])
        const startMinutes = Number(startHour[1])
        startHour = (isNaN(startHours) || isNaN(startMinutes)) ? 0 : tempTime.setHours(startHours, startMinutes)

        const endHours = Number(endHour[0])
        const endMinutes = Number(endHour[1])
        endHour = (isNaN(endHours) || isNaN(endMinutes)) ? 0 : tempTime.setHours(endHours, endMinutes)

				if(currentTime < startHour) {
					isOpen = false
				}
				if(currentTime > endHour) {
					isOpen = false
				}
			}

			if(isOpen === false) {
				response.message = 'Opening hours not validated'
				return response
			}

			response.success = true
			response.message = 'Successfully validated opening hours'
			return response
		},
		organizationIsBarion: (state, getters) => {
			const organizationUUID = getters.organizationUUID
			return organizationUUID === '76180a17-7a76-419a-acca-a2d292af6326'
		},
		organizationIsMinigardurinn: (state, getters) => {
			const organizationUUID = getters.organizationUUID
			return organizationUUID === '32036a02-fd37-4044-bbb1-e55970e4531f'
		},
    proceedToCheckout: (state, getters) => {
      let proceed = false
      const cart = getters.cart
      if(Array.isArray(cart)) {
        for(let cartIndex = 0; cartIndex < cart.length; cartIndex++) {
          const cartItem = cart[cartIndex]
          const itemLocationBlocks = getters.productIsBlockedByLocation(cartItem)
          let itemInClosedCategories = false
          if(Array.isArray(cartItem.categories) && cartItem.categories.length > 0) {
            for(let categoryIndex = 0; categoryIndex < cartItem.categories.length; categoryIndex++) {
              const category = cartItem.categories[categoryIndex]
              if(itemInClosedCategories === false) {
                itemInClosedCategories = !getters.categoryIsOpen(category)
              }
            }
          }

          if(proceed === false && itemLocationBlocks === false) {
            proceed = true
          }
        }
      }

      return proceed
    }
	}
}
