<template>
  <div>
    <div
      style="font-weight: 500; font-size: 20px;"
      class="pb-4"
    >
      {{ attributeName }}
    </div>
    <v-divider />
    <div>
      <template v-for="variation in variationsByAttribute">
        <div :key="variation.uuid">
          <EventItemVariation
            :variation="variation"
            :selected-quantity="selectedQuantity(variation)"
            :max="maximumOrderQuantity"
            :min="minimumOrderQuantity"
            v-on="$listeners"
          />
          <v-divider />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import EventItemVariation from "@/components/item/variations/EventItemVariation"

export default {
  name: "EventItemVariationCategory",
  components: {
    EventItemVariation
  },
  props: {
    attribute: {
      type: Object,
      default: () => ({})
    },
    selectedVariations: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    minimumOrderQuantity() {
      if(this.itemIsDefined) {
        // We want this to be able to go as low as zero
        if(typeof this.item.minimumOrderQuantity !== 'undefined' && false) {
          return this.item.minimumOrderQuantity
        }
      }
      return 0
    },
    maximumOrderQuantity() {
      if(this.itemIsDefined) {
        if(typeof this.item.maximumOrderQuantity !== 'undefined') {
          return this.item.maximumOrderQuantity
        }
      }
      return 0
    },
    attributeIsDefined() {
      return typeof this.attribute !== 'undefined' && this.attribute !== null
    },
    itemIsDefined() {
      return typeof this.item !== 'undefined' && this.item !== null
    },
    hasSelectedVariations() {
      return Array.isArray(this.selectedVariations) && this.selectedVariations.length > 0
    },
    attributeName() {
      if(this.attributeIsDefined) {
        const key = 'attribute:name:' + this.attribute.uuid
        if(this.$te(key)) {
          return this.$t(key)
        }
        return this.attribute.name
      }
      return ''
    },
    variationsByAttribute() {
      if(this.itemIsDefined && this.attributeIsDefined) {
        return this.$store.getters.variationsByItemAttribute(this.item, this.attribute)
      }
      return []
    }
  },
  methods: {
    selectedQuantity(variation) {
      if(typeof variation !== 'undefined' && variation !== null && this.hasSelectedVariations) {
        return this.selectedVariations.filter(v => v.uuid === variation.uuid).length
      }
      return 0
    },
  }
}
</script>

<style scoped>

</style>
