import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"value":true,"persistent":""}},[_c(VCard,[_c(VCard,{class:_vm.textColor(_vm.primaryColor),attrs:{"tile":"","color":_vm.primaryColor,"flat":""}},[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.welcomeMessageHeader)+" ")])],1),_c(VCardText,{staticClass:"pt-6 text-justify"},[_vm._v(" "+_vm._s(_vm.welcomeMessageText)+" ")]),_c(VCardActions,[_c(VBtn,{class:_vm.textColor(_vm.accentColor),attrs:{"block":"","x-large":"","color":_vm.accentColor},on:{"click":_vm.close}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }