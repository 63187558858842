<template>
  <v-col
    cols="12"
    class="px-0 py-1"
  >
    <v-card :color="primaryColor">
      <v-list
        :color="primaryColor"
        dark
        class="py-0"
      >
        <v-list-item @click="addItemToCart">
          <v-list-item-avatar
            style="border: 2px solid white"
            rounded
          >
            <v-img :src="itemImage" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ itemTitle }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div>
                <span
                  v-if="itemHasSalePrice"
                  class="text-decoration-line-through"
                >{{ itemBasePrice }}</span>
                <span>{{ itemPrice }}</span>
              </div>
              <div v-if="!itemIsAvailableForOrdering">
                <span
                  v-if="itemIsBlockedByLocation"
                  class="caption text--disabled"
                >{{ $t('errors.itemCurrentlyNotAvailable') }}</span>
                <span
                  v-else
                  class="caption text--disabled"
                >{{ $t('soldOut') }}</span>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="itemsInCart">
              {{ itemsInCart }} {{ $t('cart.inCart') }}
            </v-list-item-subtitle>
            <v-list-item-subtitle />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>

<script>
export default {
	name: "UpsellItem",
	props: {
		item: {
            type: Object,
            default: () => ({})
        }
	},
	computed: {
		itemTitle() {
			return this.$store.getters.translateByType('item.title', this.item)
		},
		primaryColor: function () {
			return this.$store.getters.primaryColor
		},
		accentColor: function () {
			return this.$store.getters.accentColor
		},
		itemImage: function () {
			if (this.item && Array.isArray(this.item.images) && this.item.images.length > 0) {
				const firstImage = this.item.images[0]
				if (firstImage && firstImage.file && firstImage.file.sources && firstImage.file.sources.medium) {
					return firstImage.file.sources.medium
				}
			}
			return ''
		},
		itemBasePrice() {
			return this.$store.getters.itemBasePrice(this.item)
		},
		itemHasSalePrice() {
			return this.$store.getters.itemHasSalePrice(this.item)
		},
		itemSalePrice() {
			return this.$store.getters.productBaseSalePrice(this.item)
		},
		itemPrice() {
			if (this.itemHasSalePrice) {
				return this.currencyAsText(this.itemSalePrice)
			}
      return this.currencyAsText(this.itemBasePrice)
		},
		isSmallMobileScreen() {
			return this.$vuetify.breakpoint.width <= 375
		},
		itemIsBlockedByLocation() {
			return this.$store.getters.productIsBlockedByLocation(this.item)
		},
		itemIsAvailableForOrdering() {
			return !this.itemIsBlockedByLocation
		},
		itemsInCart() {
			return this.$store.getters.itemsInCart(this.item.uuid).length
		},
		disableVariationSelection() {
			return this.$store.state.disableVariationSelection
		},
		itemHasVariations() {
			return this.$store.getters.itemHasVariations(this.item)
		},
	},
	mounted() {
		//
	},
	methods: {
		addItemToCart() {
      this.$store.commit('updateSelectedItem', JSON.parse(JSON.stringify(this.item)))
      this.$store.commit('updateShowSelectedItemVariationSelection', true)
		},
		currencyAsText(amount) {
			// To handle products with no price but multiple variation prices
			if(amount && amount.toString().includes(' - ')) {
				return amount
			}

			const price = {
				amount: amount,
				currency_code: this.item.price.currency_code
			}

			return this.$store.getters.currencyAsText(price)
		},
	}
}
</script>

<style scoped>

</style>
