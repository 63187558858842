<template>
  <v-card
    color="green"
    class="text-center d-flex flex-column"
    dark
    tile
    flat
    min-height="50vh"
  >
    <v-container class="pt-0">
      <v-row>
        <v-col>
          <v-icon
            style="padding-top: 20px"
            size="64"
            color="white"
          >
            mdi-check
          </v-icon>
          <v-card-title
            v-if="organizationUUID !== 'cb4c9015-06be-40fe-8c5d-7a7865b4d160'"
            class="text-center display-1"
            style="display: block; font-weight: 900;"
          >
            {{ $t("payment.success.title") }}
          </v-card-title>
          <v-card-title
            v-else
            class="text-center display-1"
            style="display: block"
          >
            Pöntun Staðfest
          </v-card-title>
          <v-card-text v-if="orderSequence !== null && orderSequence !== undefiend">
            <div class="text-center display-2">
              #E{{ orderSequence }}
            </div>
          </v-card-text>
          <v-card-text
            v-if="!eventsMode"
            class="title"
          >
            <div>
              {{
                $t("payment.success.orderInfo", {
                  amount: paymentResponseAmount,
                  table: tableText,
                })
              }}
            </div>
            <div v-if="showWaitingTime">
              <div v-if="organizationIsFiskmarkadurinn">
                Áætlaður biðtími gæti verið allt að 45 mínútur á háannatíma
              </div>
              <div v-else>
                {{ $t("waitTime", { waitTime: waitTime }) }}
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <v-card
              v-if="orderHasBookingLineItems"
              flat
              class="pb-2"
            >
              <OrderBookings :booking-line-items="orderBookingLineItems" />
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-row dense>
              <v-col
                v-if="
                  organizationUUID !== 'cb4c9015-06be-40fe-8c5d-7a7865b4d160'
                "
                cols="12"
              >
                <v-btn
                  id="complete-receipt"
                  block
                  x-large
                  dark
                  :color="accentColor"
                  @click="getReceipt"
                >
                  <span>
                    {{ $t("buttons.receipt") }}
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  id="complete-close"
                  block
                  x-large
                  dark
                  :color="accentColor"
                  @click="closeCart"
                >
                  <span>
                    {{ $t("buttons.close") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import OrderBookings from "@/components/cart/OrderBookings";
export default {
  name: "PaymentSuccess",
  components: { OrderBookings },
  computed: {
    orderHasBookingLineItems() {
      return this.eventsMode;
    },
    orderBookingLineItems() {
      if (Array.isArray(this.order)) {
        return this.order.filter(
          (lineItem) => lineItem && lineItem.type === "event"
        );
      }
      return [];
    },
    order: function () {
      return this.$store.state.cart;
    },
    eventsMode() {
      return this.$store.getters.eventsMode;
    },
    organizationUUID() {
      return this.$store.state.organizationUUID;
    },
    organizationIsFiskmarkadurinn() {
      return this.organizationUUID === "77f503d1-8992-4d59-929a-aec764f5a01b"
    },
    paymentResponse() {
      return this.$store.getters.paymentResponse
    },
    accentColor() {
      return this.$store.getters.accentColor
    },
    paymentResponseAmount() {
      if (this.paymentResponse && this.paymentResponse.paymentTransaction) {
        let priceObject = {
          amount: this.paymentResponse.paymentTransaction.amount,
          currency_code: this.paymentResponse.paymentTransaction.currency_code,
        };
        return this.$store.getters.currencyAsText(priceObject)
      } else {
        return null;
      }
    },
    tableText: function () {
      const zoneObject = this.$store.state.variationOption;
      return zoneObject.label;
    },
    waitTime() {
      return this.$store.getters.getWaitTime;
    },
    showWaitingTime() {
      return this.$store.state.showWaitingTime;
    },
    orderSequence() {

      if(this.paymentResponse !== null && this.paymentResponse !== undefined && this.paymentResponse.orderSequence !== null && this.paymentResponse.orderSequence !== undefined) {
        return this.paymentResponse.orderSequence
      }

      return null
    }
  },
  mounted() {
    this.$store.dispatch("cleanPaymentInstanceValues");
  },
  methods: {
    getReceipt: function () {
      if (
        typeof this.paymentResponse !== "undefined" &&
        this.paymentResponse !== null
      ) {
        if (
          typeof this.paymentResponse.paymentTransaction !== "undefined" &&
          this.paymentResponse.paymentTransaction !== null
        ) {
          if (
            typeof this.paymentResponse.paymentTransaction.entangle_key !==
              "undefined" &&
            this.paymentResponse.paymentTransaction.entangle_key !== null
          ) {
            const url =
              "https://receipt.salescloud.is?uuid=" +
              this.paymentResponse.paymentTransaction.entangle_key.split(
                ":"
              )[0];
            window.open(url, "_blank");
          }
        }
      }
    },
    closeCart: function () {
      this.$store.commit("updateShowCart", false);

      // Resetting all values
      const paymentMethods = this.$store.getters.paymentMethods;
      if (Array.isArray(paymentMethods) && paymentMethods.length > 0) {
        this.$store.commit("updateSelectedPaymentMethod", paymentMethods[0]);
      }
      this.$store.commit("updateCustomerName", null);
      this.$store.commit("updateCustomerEmail", null);
      this.$store.commit("updateCustomerPhone", null);
      this.$store.commit("updateCustomerComment", null);
    },
  },
};
</script>

<style scoped>
</style>
