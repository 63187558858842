<template>
  <v-card
    class="mx-4"
    outlined
  >
    <v-card-title>
      {{ itemTitle }}
    </v-card-title>
    <v-card-text>
      <div v-html="itemDescription" />
    </v-card-text>
    <v-card-text v-if="itemHasVariations">
      <template v-for="variationCategory in itemVariationCategories">
        <div
          :key="variationCategory.uuid"
          class="py-2"
        >
          <EventItemVariationCategory
            :item="item"
            :attribute="variationCategory"
            :selected-variations="selectedVariations"
            @setQuantity="setVariationQuantity"
          />
        </div>
      </template>
    </v-card-text>
    <v-card-text v-else>
      <EventItemQuantity
        :item="item"
        :selected-quantity="selectedItemQuantity"
        @setQuantity="updateItemQuantity"
      />
    </v-card-text>
    <v-card-text v-if="showAvailabilityCalendar && showDateSelectionForEvents">
      <div class="pb-2">
        {{ $t('selectDate') }}
      </div>
      <EventCalendar
        :item="item"
        :date="selectedDate"
        @selectDate="selectDate"
      />
    </v-card-text>
    <v-card-text v-if="selectedDate && showTimeSelectionForEvents">
      <div class="pb-2">
        {{ $t('selectTime') }} {{ $t('for') }} {{ selectedDateText }}
      </div>
      <div
        v-if="isGettingAvailableTimeSlots"
        class="text-center pt-2"
      >
        <div>
          <v-progress-linear indeterminate />
        </div>
        <div class="pt-2 text--secondary">
          Fetching information
        </div>
      </div>
      <EventTimeSlots
        v-else
        :available-time-slots="availableTimeSlots"
        :selected-time-slots="selectedTimeSlots"
        :item="item"
        :selected-quantity="selectedItemQuantity"
        @selectTimeSlot="selectTimeSlot"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        depressed
        large
        color="success"
        @click="addToCart"
        :disabled="!canAddToCart"
      >
        {{ $t('buttons.add') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import EventItemVariationCategory from "@/components/item/variations/EventItemVariationCategory"
import EventCalendar from "@/components/item/events/EventCalendar"
import EventTimeSlots from "@/components/item/events/EventTimeSlots"
import EventItemQuantity from "@/components/item/EventItemQuantity";

export default {
  name: "EventListItem",
  components: {
    EventItemQuantity,
    EventTimeSlots,
    EventCalendar,
    EventItemVariationCategory
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showAvailabilityCalendar: false,
      selectedDate: null,
      selectedItemQuantity: 0,
      selectedTimeSlots: [],
      availableTimeSlots: [],
      selectedVariations: [],
      isGettingAvailableTimeSlots: false
    }
  },
  computed: {
    showDateSelectionForEvents() {
      return this.$store.state.showDateSelectionForEvents
    },
    showTimeSelectionForEvents() {
      return this.$store.state.showTimeSelectionForEvents
    },
    dateHasTimeSlots() {
      return Array.isArray(this.availableTimeSlots) && this.availableTimeSlots.length > 0
    },
    selectedDateText() {
      if(typeof this.selectedDate !== 'undefined' && this.selectedDate !== null) {
        return new Date(this.selectedDate).toLocaleDateString()
      }
      return ''
    },
    itemIsDefined() {
      if(typeof this.item !== 'undefined' && this.item !== null) {
        return true
      }
      return false
    },
    itemTitle() {
      if(this.itemDescription) {
        const titleKey = 'item:title:' + this.item.uuid
        if(this.$te(titleKey)) {
          return this.$t(titleKey)
        }
        return this.item.title
      }
      return ''
    },
    itemDescription() {
      if(this.itemIsDefined) {
        const descriptionKey = 'item:description:' + this.item.uuid
        if(this.$te(descriptionKey)) {
          return this.$t(descriptionKey)
        }
        if(typeof this.item.description !== 'undefined' && this.item.description !== null) {
          if(typeof this.item.description.value !== 'undefined' && this.item.description.value !== null) {
            return this.item.description.value
          }
        }
      }
      return ''
    },
    itemHasVariations() {
      return this.itemIsDefined && Array.isArray(this.item.variations) && this.item.variations.length > 0
    },
    itemVariationCategories() {
      const variationCategories = []
      if(this.itemIsDefined) {
        if(Array.isArray(this.item.variations)) {
          for(let variationIndex = 0; variationIndex < this.item.variations.length; variationIndex++) {
            const variation = this.item.variations[variationIndex]
            if(typeof variation !== 'undefined' && variation !== null) {
              if(typeof variation.attribute !== 'undefined' && variation.attribute !== null) {
                if(!variationCategories.some(category => category && category.uuid === variation.attribute.uuid)) {
                  variationCategories.push(variation.attribute)
                }
              }
            }
          }
        }
      }
      return variationCategories
    },
    canAddToCart() {
      let canAddToCart = true

      // Check based on amount and variations
      if(this.itemIsDefined) {
        if(typeof this.item.price !== 'undefined' && this.item.price !== null) {
          if(typeof this.item.price.amount !== 'undefined' && this.item.price.amount <= 0) {
            if(!Array.isArray(this.selectedVariations) || this.selectedVariations.length <= 0) {
              canAddToCart = false
            }
          }
        }
      }

      // Check based on date selection
      if(this.showDateSelectionForEvents) {
        if(!this.selectedDate) {
          canAddToCart = false
        }
      }

      // Check based on time slot selection
      if(this.showTimeSelectionForEvents) {
        if(!Array.isArray(this.selectedTimeSlots) || this.selectedTimeSlots.length <= 0) {
          canAddToCart = false
        }
      }

      return canAddToCart
    }
  },
  methods: {
    localStorageIsAvailable() {
      try {
        if(window.localStorage) {
          return true
        }
      } catch (e) {
        return false
      }
    },
    updateItemQuantity(quantity) {
      this.selectedItemQuantity = quantity
    },
    setSelectedVariations(newSelectedVariations) {
      this.selectedVariations = newSelectedVariations
    },
    isDefaultVariation(variation) {
      return this.defaultVariations.some(v => v.uuid === variation.uuid);
    },
    isSelected(variation) {
      if(!this.selectedVariations) { return }
      return this.selectedVariations.some(v => v.uuid === variation.uuid && !v.removed);
    },
    validateItemSelectionBeforeAddingToCart(item) {
      return this.$store.getters.validateItemSelectionBeforeAddingToCart(item)
    },
    setVariationQuantity({ variation, quantity }) {
      const newSelectedVariations = this.selectedVariations.filter(v => v.uuid !== variation.uuid)
      for(let i = 0; i < quantity; i++) {
        newSelectedVariations.push(variation)
      }
      this.selectedVariations = newSelectedVariations
    },
    addToCart() {
      if(Array.isArray(this.selectedVariations) && this.selectedVariations.length > 0) {
        for(let variationIndex = 0; variationIndex < this.selectedVariations.length; variationIndex++) {
          const itemToAdd = JSON.parse(JSON.stringify(this.item))
          const variation = this.selectedVariations[variationIndex]
          itemToAdd.selected_variations = [variation]
          itemToAdd.timeSlots = this.selectedTimeSlots
          this.$store.commit('addItemToCart', itemToAdd)
        }
      } else if(this.selectedItemQuantity > 0) {
        for(let i = 0; i < this.selectedItemQuantity; i++) {
          const itemToAdd = JSON.parse(JSON.stringify(this.item))
          itemToAdd.timeSlots = this.selectedTimeSlots
          itemToAdd.quantity = 1
          this.$store.commit('addItemToCart', itemToAdd)
        }
      } else {
        const itemToAdd = JSON.parse(JSON.stringify(this.item))
        itemToAdd.quantity = this.selectedItemQuantity
        this.$store.commit('addItemToCart', itemToAdd)
      }
      this.selectedVariations = []
    },
    getAvailableTimeSlots() {
      this.isGettingAvailableTimeSlots = true
      this.$store.dispatch('getAvailableTimeSlots', { item: this.item, date: this.selectedDate }).then(result => {
        if(result) {
          this.availableTimeSlots = result.map(r => {
            return {...r, reserving: false}
          })
        }
      }).finally(() => {
        this.isGettingAvailableTimeSlots = false
      })
    },
    getSessionTokenFromLocalStorage() {

      let sessionToken = null;
      if(!this.inIframe() && this.localStorageIsAvailable()) {
        try {
          sessionToken = JSON.parse(window.localStorage.getItem('sessionToken'))
        }	catch (e) {
          if(window.localStorage) {
            window.localStorage.removeItem('sessionToken');
          }
        }
      }
      return sessionToken
    },
    saveSessionTokenToLocalStorage(sessionToken) {
      if(!this.inIframe() && this.localStorageIsAvailable()) {
        window.localStorage.setItem('sessionToken', JSON.stringify(sessionToken));
      }
    },
    selectDate(date) {
      this.selectedDate = date
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    selectTimeSlot(slot) {
      if(Array.isArray(this.selectedTimeSlots) && this.selectedTimeSlots.length > 0) {
        for(let timeSlotIndex = 0; timeSlotIndex < this.selectedTimeSlots.length; timeSlotIndex++) {
          const timeSlot = this.selectedTimeSlots[timeSlotIndex]
          const releasingTimeSlotIndex = this.availableTimeSlots.findIndex(s => s && s.timestampInMillis === timeSlot.timestampInMillis)
          if(releasingTimeSlotIndex >= 0) {
            this.$store.dispatch('releaseTimeSlot', { item: this.item, slot: slot, quantity: this.selectedVariations.length }).finally(() => {

            })
          }
        }
      }

      this.selectedTimeSlots = [ slot ]
      const reservingTimeSlotIndex = this.availableTimeSlots.findIndex(r => r && r.timestampInMillis === slot.timestampInMillis)

      if(reservingTimeSlotIndex >= 0) {
        this.availableTimeSlots[reservingTimeSlotIndex].reserving = true
        this.$store.dispatch('reserveTimeSlot', { item: this.item, slot: slot, quantity: this.selectedVariations.length }).finally(() => {
          this.availableTimeSlots[reservingTimeSlotIndex].reserving = false
        })
      }

      // this.selectedTimeSlots = [ slot ]
    }
  },
  mounted() {
    const sessionTokenInLocalStorage = this.getSessionTokenFromLocalStorage()
    if(!sessionTokenInLocalStorage) {
      this.$store.dispatch('fetchSessionToken').then(result => {
        if(result) {
          this.saveSessionTokenToLocalStorage(result)
        }
      })
    } else {
      this.$store.commit('updateSessionToken', sessionTokenInLocalStorage)
    }
  },
  watch: {
    selectedDate(value) {
      if(value) {
        this.selectedTimeSlots = []
        this.getAvailableTimeSlots()
      }
    },
    selectedVariations(value) {
      if(Array.isArray(value) && value.length > 0) {
        this.showAvailabilityCalendar = true
      } else {
        this.showAvailabilityCalendar = false
        this.selectedTimeSlots = []
        this.selectedDate = null
      }
    },
    selectedItemQuantity(value) {
      if(value > 0) {
        this.showAvailabilityCalendar = true
      } else {
        this.showAvailabilityCalendar = false
        this.selectedVariations = []
        this.selectedDate = null
      }
    }
}
}
</script>

<style scoped>

</style>
