<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-divider class="grey darken-3" />
    <v-row>
      <v-col
        cols="12"
        class="py-5"
      >
        <v-container fluid>
          <v-row v-if="upsellCategoryDescription">
            <v-col
              cols="12"
              class="text-center pb-1 pt-0 text-uppercase"
              style="font-size: 18px; font-weight: 800;"
            >
              <div
                :style="primaryColor ? 'color:' + primaryColor + ';' : ''"
                v-html="upsellCategoryDescription"
              />
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="1"
              class="pa-0 text-start"
            >
              <v-btn
                icon
                small
                :color="primaryColor"
                @click="previous"
              >
                <v-icon
                  size="36"
                  left
                >
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="10"
              class="flex-grow-1 pa-0"
            >
              <v-carousel
                v-model="model"
                hide-delimiters
                :show-arrows="false"
                height="auto"
              >
                <template v-for="item in upsellItems">
                  <v-carousel-item :key="item.uuid">
                    <v-container>
                      <v-row
                        align="center"
                        justify="center"
                      >
                        <UpsellItem :item="item" />
                      </v-row>
                    </v-container>
                  </v-carousel-item>
                </template>
              </v-carousel>
            </v-col>
            <v-col
              cols="1"
              class="pa-0 text-end"
            >
              <v-btn
                icon
                small
                :color="primaryColor"
                @click="next"
              >
                <v-icon
                  right
                  size="36"
                >
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider class="grey darken-3" />
  </v-container>
</template>

<script>
import UpsellItem from "@/components/UpsellItem";

export default {
	name: "Upsell",
	components: {
		UpsellItem
	},
	data() {
		return {
			model: 0
		}
	},
	computed: {
		primaryColor: function() {
			return this.$store.state.primaryColor
		},
		textColor: function() {
			return this.$store.getters.textColor(this.primaryColor)
		},
		upsellItems: function() {
			return this.$store.getters.upsellItems
		},
		upsellCategory: function() {
			return this.$store.getters.upsellCategory
		},
		upsellCategoryDescription: function() {
			// return 'Tilboð!'
			if(this.upsellCategory && this.upsellCategory.description !== null && this.upsellCategory.description !== undefined) {
				let description = this.$store.getters.translateByType('category.description', this.upsellCategory)
				if(description !== null && description !== undefined) {
					description = description.replace(/<p> <\/p>\n/gi, '')
					// eslint-disable-next-line no-irregular-whitespace
					description = description.replace(/<p>NBSP<\/p>/gi, '')
					description = description.replace(/<p>&nbsp;<\/p>/gi, '')
					description = description.replace(/<div> <\/div>\n/gi, '')
					// eslint-disable-next-line no-irregular-whitespace
					description = description.replace(/<div> <\/div>/gi, '')
					description = description.replace(/<p>\s*<\/p>/gi, '')
					description = description.replace(/<div>\s*<\/div>/gi, '')
					description = description.replace(/<div>&nbsp;<\/div>/gi, '')
					description = description.replace(/\n/gi, '')
				}
				if(description) {
					description = description.replace(/<p>/gi, '')
					description = description.replace(/<\/p>/gi, '')
				}
				return description
			}
			return null
		}
	},
	mounted() {
		if(Array.isArray(this.upsellItems) && this.upsellItems.length > 0) {
			this.model = Math.floor(Math.random() * this.upsellItems.length)
		}
	},
	methods: {
		previous() {
			this.model--
			if(this.model === -1) {
				this.model = this.upsellItems.length - 1
			}
		},
		next() {
			this.model++
		}
	}
}
</script>

<style scoped>

</style>
