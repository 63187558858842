<template>
  <div class="px-4">
    <div :class="hasCustomerCommentText ? 'py-3' : 'pb-3'">
      <span class="font-weight-bold">{{ $t('coupon.active') }}:</span> <span class="font-italic text--secondary">{{ activeCoupon.code }} - {{ activeCoupon.percentOff }}% {{ $t('coupon.discountText') }}</span>
    </div>
    <v-divider />
  </div>
</template>

<script>
export default {
	name: "CouponAsText",
	computed: {
		activeCoupon() {
			return this.$store.getters.activeCoupon
		},
		hasCustomerCommentText() {
			return !!(this.$store.state.customerComment && this.$store.state.customerComment.length)
		},
	}
}
</script>

<style scoped>

</style>
