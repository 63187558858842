import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"text-center pt-10 d-flex flex-column",attrs:{"color":"red","dark":"","tile":"","flat":"","min-height":"50vh"}},[_c(VSpacer,{staticClass:"fill-height"}),_c(VIcon,{staticStyle:{"padding-top":"20px"},attrs:{"size":"64","color":"white"}},[_vm._v(" mdi-emoticon-sad ")]),_c(VCardTitle,{staticClass:"text-center display-1 text-break"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),(_vm.subMessage)?_c(VCardText,{staticClass:"title"},[_c('div',[_vm._v(_vm._s(_vm.subMessage))])]):_vm._e(),_c(VSpacer,{staticClass:"fill-height"}),_c(VCardActions,[_c(VBtn,{attrs:{"x-large":"","depressed":"","block":""},on:{"click":_vm.goBack}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.back'))+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }