<template>
  <div>
    <v-checkbox
      v-if="variationIsBlocked"
      disabled
      hide-details
      class="mt-0"
    >
      <template v-slot:label>
        <span>
          <span>
            {{ variationLabel }}
          </span>
          <span class="caption">
            ({{ $t('soldOut') }})
          </span>
        </span>
      </template>
    </v-checkbox>
    <v-checkbox
      v-else-if="multiple"
      :input-value="variationIsSelected"
      hide-details
      :label="variationLabel"
      color="#008000"
      class="mt-0"
      :disabled="(!variationIsSelected && maxVariationsReached)"
      @change="handleVariationClick"
    />
    <v-checkbox
      v-else
      :input-value="variationIsSelected"
      hide-details
      :label="variationLabel"
      on-icon="mdi-check-circle"
      off-icon="mdi-checkbox-blank-circle-outline"
      color="#008000"
      class="mt-0"
      @change="handleVariationClick"
    />
  </div>
</template>

<script>
export default {
    name: "VariationItem",
    props: {
        variation: {
            type: Object,
            default: () => ({})
        },
        selectedVariations: {
            type: Array,
            default: () => []
        },
        multiple: {
            type: Boolean,
            default: true
        },
      maxVariationsReached: {
          type: Boolean,
        default: false
      }
    },
    computed: {
        /**
         * Checks if this variation is selected or not
         * @returns {Boolean}
         */
        variationIsSelected() {
            if(typeof this.variation !== 'undefined' && this.variation !== null) {
                return this.selectedVariations.some(v => typeof v !== 'undefined' && v !== null && !v.removed && v.uuid === this.variation.uuid)
            }
            return false
        },
        /**
         * Checks for translations for this variation label
         * @returns {String}
         */
        variationLabel() {
            const labels = []

            labels.push(this.$store.getters.translateByType('variation.label', this.variation))

            if(this.variation.price !== null && this.variation.price !== undefined && this.variation.price.amount !== null && this.variation.price.amount !== undefined && this.variation.price.amount > 0) {
              labels.push('+' + this.$store.getters.currencyAsText(this.variation.price))
            }

            return labels.join(' ')
        },
        variationLocationBlocks() {
          return this.$store.state.variationLocationBlocks
        },
        variationIsBlocked() {
          if(Array.isArray(this.variationLocationBlocks)) {
            if(typeof this.variation !== 'undefined' && this.variation !== null) {
              return this.variationLocationBlocks.some(block => block && block.variation === this.variation.uuid)
            }
          }
          return false
        }
    },
    methods: {
        /**
         * Emits variation click to parent(s)
         */
        handleVariationClick() {
            this.$emit('handleVariationClick', this.variation)
        }
    }
}
</script>

<style scoped>

</style>
