<template>
  <div class="locale-changer">
    <v-row
      v-if="false"
      dense
      style="width: 75px"
    >
      <v-col class="pa-0">
        <v-select
          v-model="currentLocale"
          solo
          :items="availableLocales"
          hide-details
        />
      </v-col>
    </v-row>
    <v-menu
      offset-x
      right
      close-on-content-click
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          id="locale-select"
          text
          class="mt-3 ml-2 grey lighten-2"
          outlined
          color="black"
          dark
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="30">
            <v-img :src="getFlagBasedOnLocale($i18n.locale)" />
          </v-avatar>
        </v-btn>
      </template>

      <v-sheet>
        <v-list
          rounded
          dense
          class="px-1"
        >
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
          >
            <v-list-item
              v-for="(item, index) in availableLocales"
              :id="`${item} - select`"
              :key="index"
              @click="selectLocale(item)"
            >
              <v-list-item-avatar size="24">
                <v-img :src="getFlagBasedOnLocale(item)" />
              </v-list-item-avatar>
              <v-list-item-title class="caption text-capitalize">
                {{ findLanguageInfo(item) }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-sheet>
    </v-menu>
  </div>
</template>

<script>
import { languages } from '../assets/languages.json'

// Language / locale selection
export default {
	name: "LanguageSelection",
  data() {
    return {
      selectedItem: 0
    }
  },
	computed: {
    languages() {
      return languages
    },
		currentLocale: {
			get() {
				return this.$i18n.locale
			},
			set(locale) {
        this.$store.dispatch('setLocale', locale)
			}
		},
		availableLocales() {
			return this.$i18n.availableLocales
		},
		language() {
			return this.$store.state.language
		}
	},
	mounted() {
		if(!this.$i18n.locale && this.language) {
			this.$i18n.locale = this.language
		}
    const index = this.availableLocales.findIndex(locale => locale && locale === this.$i18n.locale)
    if(index >= 0) {
      this.selectedItem = index
    }
	},
	methods: {
		selectLocale(locale) {
			this.$i18n.locale = locale
			this.$store.dispatch('removeOtherCategories')
		},
    findLanguageInfo(locale) {
      if(locale) {
        const foundLanguage = this.languages.find(l => l && l['iso639_1'] === locale)
        if(foundLanguage && foundLanguage.nativeName && foundLanguage.nativeName[0]) {
          return foundLanguage.nativeName[0]
        }
      }
      return null
    },
    getFlagBasedOnLocale(locale) {
      if(typeof locale === 'undefined' || locale === null) {
        return ''
      }
      return `flags/${locale}.svg`
    },
	}
}
</script>

<style scoped>

</style>
