<template>
  <v-container fluid>
    <v-row
      v-if="Array.isArray(availableTimeSlots) && availableTimeSlots.length <= 0"
      justify="center"
    >
      <v-col cols="12">
        <v-card
          flat
          color="grey lighten-2"
        >
          <v-card-text class="text-center">
            No available times
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="Array.isArray(availableTimeSlots) && availableTimeSlots.length > 0">
      <template v-for="slot in availableTimeSlots">
        <v-col
          :key="slot.label"
          cols="auto"
        >
          <v-btn
            v-if="slot.availableCount < 1 || slot.isBlocked === true"
            disabled
            depressed
          >
            {{ slot.label }} {{ $t('soldOut') }}
          </v-btn>
          <v-btn
            v-else-if="slotAtThreshold(slot)"
            :color="slotIsSelected(slot) ? 'success' : 'warning'"
            :depressed="slotIsSelected(slot)"
            :loading="reservingSlot(slot)"
            @click="selectSlot(slot)"
          >
            {{ slot.label }} - {{ slot.availableCount }} {{ $t('remaining') }}
          </v-btn>
          <v-btn
            v-else-if="slotNotAvailable(slot)"
            disabled
            depressed
          >
            {{ slot.label }} - {{ slot.availableCount }} {{ $t('remaining') }}
          </v-btn>
          <v-btn
            v-else
            :color="slotIsSelected(slot) ? 'success' : ''"
            :depressed="slotIsSelected(slot)"
            :loading="reservingSlot(slot)"
            @click="selectSlot(slot)"
          >
            {{ slot.label }}
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EventTimeSlots",
  props: {
    availableTimeSlots: {
      type: Array,
      default: null
    },
    selectedTimeSlots: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: null
    },
    selectedQuantity: {
      type: Number,
      default: 0
    }
  },
  methods: {
    selectSlot(slot) {
      this.$emit('selectTimeSlot', slot)
    },
    slotIsSelected(slot) {
      if(Array.isArray(this.selectedTimeSlots) && this.selectedTimeSlots.length > 0) {
        return this.selectedTimeSlots.some(selectedSlot => selectedSlot && selectedSlot.timestampInMillis === slot.timestampInMillis)
      }
    },
    slotAtThreshold(slot) {
      // Set default threshold at 20%
      if(typeof this.item !== 'undefined' && this.item !== null) {
        if(typeof this.item.maximumGroupedQuantity !== 'undefined' && this.item.maximumGroupedQuantity > 0) {
          return (slot.availableCount / this.item.maximumGroupedQuantity) < 0.2
        }
      }
      return false
    },
    reservingSlot(slot) {
      return typeof slot !== 'undefined' && slot !== null && typeof slot.reserving !== 'undefined' && slot.reserving !== null && slot.reserving === true
    },
    slotNotAvailable(slot) {
      if(typeof slot !== 'undefined' && slot !== null) {
        return slot.availableCount < this.selectedQuantity
      }
      return false
    }
  }
}
</script>

<style scoped>

</style>
