import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"tile":""}},[_c(VCardTitle,{staticClass:"text-break"},[_c('span',[_vm._v(_vm._s(_vm.$t('cart.remove'))+" "),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.itemTitle))]),_vm._v(" "+_vm._s(_vm.$t('cart.fromCart')))])]),_c(VCardActions,[_c(VContainer,{staticClass:"pt-0"},[_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"id":`${_vm.itemTitle}-remove-from-cart-cancel`,"x-large":"","outlined":"","block":""},on:{"click":_vm.cancel}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])])],1),_c(VCol,[_c(VBtn,{attrs:{"id":`${_vm.itemTitle}-remove-from-cart-confirm`,"x-large":"","dark":"","block":"","color":"error"},on:{"click":_vm.confirm}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.remove'))+" ")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }