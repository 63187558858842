<template>
  <v-card
    color="red"
    class="text-center pt-10 d-flex flex-column"
    dark
    tile
    flat
    min-height="50vh"
  >
    <v-spacer class="fill-height" />
    <v-icon
      style="padding-top: 20px;"
      size="64"
      color="white"
    >
      mdi-emoticon-sad
    </v-icon>
    <v-card-title class="text-center display-1 text-break">
      {{ title }}
    </v-card-title>
    <v-card-text class="title">
      {{ message }}
    </v-card-text>
    <v-card-text
      v-if="subMessage"
      class="title"
    >
      <div>{{ subMessage }}</div>
    </v-card-text>
    <v-spacer class="fill-height" />
    <v-card-actions>
      <v-btn
        x-large
        depressed
        block
        @click="goBack"
      >
        <span>
          {{ $t('buttons.back') }}
        </span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "BottomErrorMessage",
	props: {
		mainMessage: {
            type: String,
            default: ''
        },
		mainTitle: {
            type: String,
            default: ''
        },
		mainSubMessage: {
            type: String,
            default: ''
        }
	},
	data() {
		return {
			showLastErrorInFull: false
		}
	},
	computed: {
		message() {
			return this.mainMessage || this.errorMessage || ''
		},
		title() {
			return this.mainTitle || this.errorTitle || ''
		},
		subMessage() {
			return this.mainSubMessage || this.errorSubMessage || ''
		},
		errorMessage() {
			return this.$store.getters.errorMessage
		},
		errorTitle() {
			return this.$store.state.errorTitle
		},
		errorSubMessage() {
			return this.$store.state.errorSubMessage
		}
	},
	methods: {
		show() {
			this.showLastErrorInFull = !this.showLastErrorInFull
		},
		goBack() {
			this.$store.commit('updateShowBottomError', false)
			this.$store.commit('updateErrorMessage', '')
			this.$store.commit('updateErrorTitle', '')
			this.$store.commit('updateErrorSubMessage', '')
		}
	}
}
</script>

<style scoped>

</style>
