import i18n from "../i18n";
import axios from "axios";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
const defaultDelayTime = 2000

export default {
	state: {
		paymentMethodInstances: [],
		walletPaymentAvailable: false,
		applePayCapable: false,
		selectedPaymentMethod: null,
		pendingPaymentLastCheckedDate: null,
    waitUntilNextCheckInMilliSeconds: 5000
	},
	mutations: {
		setPaymentMethodInstances(state, instances) {
			state.paymentMethodInstances = instances
		},
		setWalletPaymentAvailable(state, status) {
			state.walletPaymentAvailable = status
		},
		setApplePayCapable(state, status) {
			state.applePayCapable = status
		},
		updateSelectedPaymentMethod(state, method) {
      try {
        state.selectedPaymentMethod = JSON.parse(JSON.stringify(method))
      } catch (e) {
        state.selectedPaymentMethod = method
      }
		},
		updatePendingPaymentLastCheckedDate(state, timestamp) {
			state.pendingPaymentLastCheckedDate = timestamp
		}
	},
	actions: {
		cleanPaymentInstanceValues(state) {
			if(state.paymentMethodInstances && Array.isArray(state.paymentMethodInstances)) {
				for(let i = 0; i < state.paymentMethodInstances.length; i++) {
					const paymentMethod = state.paymentMethodInstances[i]
					if(paymentMethod && paymentMethod.uiFields && Array.isArray(paymentMethod.uiFields)) {
						const { uiFields } = paymentMethod
						for(let j = 0; j < uiFields.length; j++) {
							const uiField = uiFields[j]
							if(uiField && uiField.value) {
								uiField.value = null
							}
						}
					}
				}
			}
		},
		async checkPendingPayment({ state, commit, getters, dispatch }, payload) {
			commit('updatePaymentInProgress', true)
			if(payload && (payload.retries === null || typeof payload.retries === 'undefined')) {
				payload.retries = 0
			}

			const orderStatusUrl = getters.paymentRequestUrl + '/' + getters.organizationUUID + '/' + getters.channelUUID + '/' + payload.paymentInstanceUUID + '/' + payload.orderUUID

      await delay(defaultDelayTime)

			setTimeout(function () {
				return axios.get(orderStatusUrl).then(response => {
					const statusResponse = response.data
					if (statusResponse && statusResponse.paymentTransaction && statusResponse.paymentTransaction.status === 'pending') {
						payload.retries += 1
						commit('updatePendingPaymentLastCheckedDate', Date.now())
						dispatch('checkPendingPayment', payload).then()
					} else {
						commit('setPaymentResponse', statusResponse)
						commit('updatePaymentInProgress', false)
					}
				})
			}, state.waitUntilNextCheckInMilliSeconds)
		}
	},
	getters: {
		paymentMethodInstances: (state, getters) => {
			const paymentMethodInstances = []

			if(getters.getDefaultPaymentMethodForWalletPayment) {
				paymentMethodInstances.push(getters.getDefaultPaymentMethodForWalletPayment)
			}

			for(let i = 0; i < state.paymentMethodInstances.length; i++) {
				const paymentMethodInstance = state.paymentMethodInstances[i]
				if(paymentMethodInstances.some(p => p.namespace === paymentMethodInstance.namespace)) {
					continue
				}
				paymentMethodInstances.push(paymentMethodInstance)
			}
			return paymentMethodInstances
		},
		walletPaymentAvailable: (state) => {
			return state.walletPaymentAvailable
		},
		applePayCapable: (state) => {
			return state.applePayCapable
		},
		hasPaymentMethodInstanceForWalletPayment: (state) => {
			if(state.paymentMethodInstances === null || typeof state.paymentMethodInstances === 'undefined') {
				return false
			}
			return state.paymentMethodInstances.some(instance => instance.namespace === 'borgun_rpg' || instance.namespace === 'valitor_pay')
		},
		selectedPaymentMethod: (state) => {
			return state.selectedPaymentMethod
		},
		hasApplePayPaymentMethod: (state) => {
			return state.paymentMethodInstances && state.paymentMethodInstances.find(instance => instance.namespace === 'valitor_pay' || instance.namespace === 'borgun_rpg')
		},
		getDefaultPaymentMethodForWalletPayment: (state, getters) => {
			const defaultPaymentMethod = state.paymentMethodInstances.find(instance => instance && (instance.namespace === 'valitor_pay' || instance.namespace === 'borgun_rpg'))
			if(defaultPaymentMethod) {
				return defaultPaymentMethod
			}
			return null
		},
        applePayCapablePaymentMethods: (state) => {
            if(Array.isArray(state.paymentMethodInstances)) {
                return state.paymentMethodInstances.filter(instance => instance.isApplePayCapable)
            }
            return []
        },
        hasApplePayCapablePaymentMethods: (state, getters) => {
            return getters.applePayCapablePaymentMethods.length > 0
        },
		paymentMethods: (state, getters) => {
			const paymentMethods = []

			if(getters.applePayCapable && getters.hasApplePayCapablePaymentMethods) {
				const paymentMethod = JSON.parse(JSON.stringify(getters.applePayCapablePaymentMethods[0]))
        if(paymentMethod) {
          paymentMethod.isApplePay = true
          paymentMethod.title = 'Apple Pay'
          paymentMethod.icon = 'mdi-apple'
          paymentMethods.push(paymentMethod)
        }
			}

			// if(!getters.applePayCapable && getters.walletPaymentAvailable && getters.hasPaymentMethodInstanceForWalletPayment) {
			// 	const defaultPaymentMethod = getters.getDefaultPaymentMethodForWalletPayment
      //   if(defaultPaymentMethod) {
      //     const paymentMethod = JSON.parse(JSON.stringify(defaultPaymentMethod))
      //     if(paymentMethod) {
      //       paymentMethod.isWallet = true
      //       paymentMethod.title = i18n.t('browserWallet')
      //       paymentMethod.icon = 'mdi-cellphone'
      //       paymentMethods.push(paymentMethod)
      //     }
      //   }
			// }

			for(let i = 0; i < getters.paymentMethodInstances.length; i++) {
				const paymentMethod = getters.paymentMethodInstances[i]
				if(paymentMethod.namespace === 'invoice' && (paymentMethod.icon === null || paymentMethod.icon === '')) {
					paymentMethod.icon = 'mdi-book-open-outline'
				}
				if(paymentMethod.namespace === 'valitor_greidslugatt' || paymentMethod.namespace === 'borgun_rpg' || paymentMethod.namespace === 'valitor_pay') {
					paymentMethod.icon = 'mdi-credit-card-outline'
				}
				// if(paymentMethod.namespace === 'siminn_pay' && getters.organizationUUID !== '32036a02-fd37-4044-bbb1-e55970e4531f' && getters.unitID !== '21360') {
				// 	continue
				// }
				paymentMethods.push(paymentMethod)
			}

			if(!state.selectedPaymentMethod && Array.isArray(paymentMethods) && paymentMethods.length > 0) {
        try {
          state.selectedPaymentMethod = JSON.parse(JSON.stringify(paymentMethods[0]))
        } catch (e) {
          state.selectedPaymentMethod = paymentMethods[0]
        }
			}

			return paymentMethods
		},
		paymentRequest: (state, getters) => {
			let paymentRequest = {}

			const totalAmountToPay = getters.cartTotal
			paymentRequest.amount = totalAmountToPay.amount
			paymentRequest.currency_code = totalAmountToPay.currency_code

			//billing info
			//shipping info
			//location
			//items
			//unit
			//coupon
			//comment
			//details

			return paymentRequest
		},
		pendingPaymentLastCheckedDate: (state) => {
			return state.pendingPaymentLastCheckedDate
		}
	},
}
