<template>
  <v-container fluid>
    <v-row align="center">
      <v-col cols="6">
        <span style="font-weight: 500;">
          {{ variationLabel }}
        </span>
      </v-col>
      <v-col cols="6">
        <div class="row align-center justify-end">
          <v-btn
            depressed
            style="height: 32px !important; width: 32px !important; min-width: 32px;"
            :disabled="!canDecrement"
            @click="decrement"
          >
            <v-icon :color="primaryColor">
              mdi-minus
            </v-icon>
          </v-btn>
          <div
            class="px-3"
            style="font-weight: 500; font-size: 20px;"
          >
            {{ quantity }}
          </div>
          <v-btn
            depressed
            style="height: 32px !important; width: 32px !important; min-width: 32px;"
            :disabled="!canIncrement"
            @click="increment"
          >
            <v-icon :color="primaryColor">
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EventItemVariation",
  props: {
    variation: {
      type: Object,
      default: () => ({})
    },
    selectedQuantity: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    }
  },
  computed: {
    variationIsDefined() {
      return typeof this.variation !== 'undefined' && this.variation !== null
    },
    variationLabel() {
      if(this.variationIsDefined) {
        const key = 'variation:label:' + this.variation.uuid
        if(this.$te(key)) {
          return this.$t(key)
        }
        return this.variation.label
      }
      return ''
    },
    quantity: {
      get() {
        return this.selectedQuantity
      },
      set(quantity) {
        this.$emit('setQuantity', { variation: this.variation, quantity })
      }
    },
    canDecrement() {
      if(this.min >= 0) {
        return this.quantity > this.min
      }
      return this.quantity > 0
    },
    canIncrement() {
      if(this.max >= 0 && this.max > this.min) {
        return this.quantity < this.max
      }
      return true
    },
    primaryColor() {
      return this.$store.getters.primaryColor
    }
  },
  methods: {
    increment() {
      this.quantity++
    },
    decrement() {
      this.quantity--
    }
  }
}
</script>

<style scoped>

</style>
