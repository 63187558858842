<template>
  <v-dialog
    v-if="hasCurrentItemInFull"
    :id="itemTitle"
    v-model="hasCurrentItemInFull"
    width="500"
    scrollable
  >
    <v-card>
      <v-img
        v-if="showItemImagesInItemDetails"
        :src="itemImage"
        max-height="150"
        contain
        class="mt-3 mx-3"
        style="background: transparent;"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
            />
          </v-row>
        </template>
      </v-img>
      <v-card-title
        primary-title
      >
        {{ itemTitle }}
      </v-card-title>

      <v-card-text v-html="descriptionValue" />

      <v-divider />

      <v-card-actions>
        <v-btn
          :id="`${itemTitle}-back`"
          color="error"
          text
          @click="hasCurrentItemInFull = false"
        >
          <span>
            {{ $t('buttons.close') }}
          </span>
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="hasVariations && !disableVariationSelection && !isInSpecificCategory"
          :id="`${itemTitle}-variations`"
          :color="accentColor"
          dark
          @click="changeVariations()"
        >
          <span>
            {{ $t('buttons.change') }}
          </span>
        </v-btn>
        <v-btn
          v-if="(hasDefaultVariations || hasPrice) && !itemIsBlockedByLocation"
          :id="`${itemTitle}-add`"
          :color="accentColor"
          dark
          @click="addItemToCart()"
        >
          <span>
            {{ $t('buttons.addSimple') }}
          </span>
        </v-btn>
        <v-btn
          v-if="itemIsBlockedByLocation"
          disabled
          :color="accentColor"
        >
          <span>
            {{ $t('soldOut') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	data: () => ({
	}),
	computed: {
        itemTitle() {
            return this.$store.getters.translateByType('item.title', this.currentItemInFull)
        },
		descriptionValue() {
            return this.$store.getters.translateByType('item.description.value', this.currentItemInFull)
		},
		disableVariationSelection() {
			return this.$store.state.disableVariationSelection
		},
		isInSpecificCategory() {
			const category = '24f2f6ac-e785-4c91-8ac6-b6ec07b58ac1';
			if(this.currentItemInFull.categories && this.currentItemInFull.categories.length) {
				return this.currentItemInFull.categories.some(cat => cat.uuid === category)
			}
      return false
		},
		hasPrice() {
			return !!(this.currentItemInFull.price && this.currentItemInFull.price.amount)
		},
		hasVariations() {
			return this.$store.getters.itemHasVariations(this.currentItemInFull)
		},
		hasDefaultVariations() {
			return this.$store.getters.itemHasDefaultVariations(this.currentItemInFull)
		},
		itemVariationCategories() {
			return this.$store.getters.itemVariationCategories(this.currentItemInFull)
		},
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		itemIsBlockedByLocation() {
			// return true
			return this.$store.getters.productIsBlockedByLocation(this.currentItemInFull)
		},
		hasCurrentItemInFull: {
			get: function() {

				if(this.$store.state.currentItemInFull === null) {
					return false;
				}

				return true
			},
			set: function() {
				this.$store.commit('setCurrentItemInFull', null)
			}
		},
		currentItemInFull: {
			get: function() {
				return this.$store.state.currentItemInFull
			},
			set: function() {
				this.$store.commit('setCurrentItemInFull', null)
			}
		},
    itemImage() {
      if(typeof this.currentItemInFull !== 'undefined' && this.currentItemInFull !== null && Object.keys(this.currentItemInFull).length > 0) {
        const item = this.currentItemInFull
        if(Array.isArray(item.images) && item.images.length > 0) {
          const firstImage = item.images[0]

          return this.$store.getters.itemImage(firstImage, 'medium')
        }
      }
      return ''
    },
    showItemImagesInItemDetails() {
      return this.$store.state.showItemImagesInItemDetails
    },
    variationLocationBlocks() {
      return this.$store.state.variationLocationBlocks
    }
	},
	watch: {
	},
	mounted() {
	},
	methods: {
		changeVariations() {
			this.$store.commit('updateSelectedItem', JSON.parse(JSON.stringify(this.currentItemInFull)))
			this.currentItemInFull = null;
			this.$store.commit('updateShowSelectedItemVariationSelection', true)
		},
		validateItemSelectionBeforeAddingToCart: function(item) {
			return this.$store.getters.validateItemSelectionBeforeAddingToCart(item)
		},
		addItemToCart() {
			if(this.itemVariationCategories && this.itemVariationCategories.some(category => category.required)) {
				this.changeVariations()
				return
			}
			let item = {
				...this.currentItemInFull
			}
			if(this.hasDefaultVariations) {
        item.selected_variations = []
        if(this.currentItemInFull && Array.isArray(this.currentItemInFull.default_variations)) {
          for(let index = 0; index < this.currentItemInFull.default_variations.length; index++) {
            const variation = this.currentItemInFull.default_variations[index]
            if(variation) {
              if(Array.isArray(this.variationLocationBlocks) && this.variationLocationBlocks.some(block => block && block.variation === variation.uuid)) {
                continue
              }
              item.selected_variations.push({
                ...variation,
                added: false,
                isDefault: true,
                removed: false
              })
            }
          }
        }
			}

			this.$store.commit('addItemToCart', JSON.parse(JSON.stringify(item)));
			this.$store.commit('setCurrentItemInFull', null);

			// if(this.validateItemSelectionBeforeAddingToCart(item)) {
			// 	this.$store.commit('addItemToCart', JSON.parse(JSON.stringify(item)));
			// 	this.$store.commit('setCurrentItemInFull', null);
			// }
		}
	},
};

</script>
