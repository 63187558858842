<template>
  <v-card class="pa-2">
    <iframe
      style="border: 0; width: 100%; height: 500px; padding-bottom: 0; z-index: 5;"
      :srcdoc="rawHTMLFor3DSecure"
    />
  </v-card>
</template>

<script>
/**
 * 3D Secure handling
 */
export default {
  name: "CardAuthentication",
  props: {
    authenticationContent: {
      type: String,
      default: null
    }
  },
  computed: {
    accentColor() {
      return this.$store.state.accentColor
    },
    rawHTMLFor3DSecure() {
      if(typeof this.authenticationContent !== 'undefined' && this.authenticationContent !== null) {
        return decodeURIComponent(this.authenticationContent)
      }
      return null
    },
  },
  methods: {
    colorIsLight(color) {
      return this.$store.getters.colorIsLight(color)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
