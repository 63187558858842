import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCol,{staticClass:"px-0 py-1",attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":_vm.primaryColor}},[_c(VList,{staticClass:"py-0",attrs:{"color":_vm.primaryColor,"dark":""}},[_c(VListItem,{on:{"click":_vm.addItemToCart}},[_c(VListItemAvatar,{staticStyle:{"border":"2px solid white"},attrs:{"rounded":""}},[_c(VImg,{attrs:{"src":_vm.itemImage}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.itemTitle)+" ")]),_c(VListItemSubtitle,[_c('div',[(_vm.itemHasSalePrice)?_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(_vm.itemBasePrice))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.itemPrice))])]),(!_vm.itemIsAvailableForOrdering)?_c('div',[(_vm.itemIsBlockedByLocation)?_c('span',{staticClass:"caption text--disabled"},[_vm._v(_vm._s(_vm.$t('errors.itemCurrentlyNotAvailable')))]):_c('span',{staticClass:"caption text--disabled"},[_vm._v(_vm._s(_vm.$t('soldOut')))])]):_vm._e()]),(_vm.itemsInCart)?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.itemsInCart)+" "+_vm._s(_vm.$t('cart.inCart'))+" ")]):_vm._e(),_c(VListItemSubtitle)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }