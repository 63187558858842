<template>
  <v-container v-if="organization" no-gutters fluid px-0>
    <v-row v-if="loading && !itemsList">
      <v-col>
        <v-progress-circular indeterminate color="primary" />
      </v-col>
    </v-row>

    <v-row v-if="!loading && itemsList === null">
      <v-col>
        <v-alert prominent type="error">
          {{ $t('error.plain') }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="!loading && itemsList && !itemsList.length">
      <v-col>
        <v-alert prominent type="info">
          {{ $t('items.empty') }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="!loading && itemsList && itemsList.length > 0" id="items-list" ref="items-list">
      <div id="list-top" ref="list-top" />
      <v-col cols="12" class="py-0">
        <v-alert v-if="!locationIsOpen && showLocationClosedMessage" prominent type="info" class="ma-2">
          <div>
            <div style="font-size: 20px; font-weight: 700">
              {{ $t('location.closed') }}
            </div>
            <div style="font-size: 14px">
              {{ $t('location.cannotOrder') }}
            </div>
          </div>
        </v-alert>
        <template v-else-if="!showSinglePageCategories">
          <v-select
            v-if="subCategoryDepth < 2"
            id="category-select"
            ref="category-select"
            v-model="selectIndex"
            solo
            dark
            :background-color="primaryColor"
            :items="categorySelect"
            return-object
            style="border-radius: 0"
            hide-details
          >
            <template v-slot:item="{ item }">
              <span v-html="item.text" />
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
            </template>
          </v-select>
          <landing-page v-if="showLandingPage" id="landing-page" class="pb-0" />
          <sub-category-landing-page v-if="showSubCategoryMenu" id="sub-category-page" class="pb-0" />
          <v-list v-if="!showLandingPage && !showSubCategoryMenu" three-line class="overflow-y-auto py-0" tile style="border-radius: 0">
            <BackButton v-if="landingPageFromParams" id="back-button-top" />
            <div v-if="selectedCategoryHasDescription">
              <div class="text-center pa-2" style="font-size: 16px; font-weight: 600" v-html="selectedCategoryDescription" />
              <v-divider />
            </div>
            <template v-for="(item, itemIndex) in items">
              <div :key="item.uuid">
                <EventListItem v-if="item.type === 'event'" :item="item" />
                <list-item v-else :item="item" />
                <v-divider v-if="itemIndex < items.length - 1" :key="itemIndex" :inset="!hideListPictures" />
              </div>
            </template>
            <BackButton v-if="landingPageFromParams" id="back-button-bottom" />
            <v-dialog v-if="showDialog" id="notify-on-category-select-dialog" :value="showDialog" scrollable persistent>
              <v-card>
                <v-card-title>
                  {{ $t('pleaseBeAware') }}
                </v-card-title>
                <v-card-text>
                  <div style="font-size: 16px; font-weight: 400" v-html="categoryDescription" />
                </v-card-text>
                <v-card-actions>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-btn id="notify-not-agree" large text color="red" block @click="notAgree">
                          <span>
                            {{ $t('buttons.notAgree') }}
                          </span>
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-btn id="notify-agree" block large :color="primaryColor" @click="agree">
                          <span>
                            {{ $t('buttons.agree') }}
                          </span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </template>
        <v-list v-else three-line tile class="py-0">
          <LanguageSelection style="position: absolute; right: 12px; z-index: 10" />
          <v-banner v-if="!locationIsOpen" color="grey lighten-2">
            <div>
              <div style="font-weight: 600; font-size: 20px" class="pb-1">
                {{ $t('location.closed') }}
              </div>
              <div style="font-weight: 300" class="pb-1">
                {{ $t('location.cannotOrder') }}
              </div>
              <div v-if="Array.isArray(locationOpeningHours) && locationOpeningHours.length > 0" style="font-size: 14px">
                {{ $t('openingHours') }}:
                <template v-for="(hour, index) in locationOpeningHours">
                  <span :key="hour.openHour.text">
                    <span style="font-weight: 500">
                      {{ hour.openHour.text }}
                    </span>
                    -
                    <span style="font-weight: 500">
                      {{ hour.closedHour.text + (locationOpeningHours.length > 1 && showComma(index) ? ', ' : '') }}
                    </span>
                  </span>
                  <span
                    v-if="locationOpeningHours.length > 1 && index === locationOpeningHours.length - 2"
                    :key="hour.openHour.text + '-and'"
                  >
                    {{ $t('and') }}
                  </span>
                </template>
              </div>
            </div>
          </v-banner>
          <template v-for="(category, index) in itemsByCategories">
            <v-subheader :key="index">
              {{ categoryName(category) }}
            </v-subheader>

            <template v-for="(item, itemIndex) in category.items">
              <div :key="categoryName(category) + item.uuid">
                <EventListItem v-if="item.type === 'event'" :item="item" />
                <list-item v-else :item="item" />
                <v-divider v-if="itemIndex < category.items.length - 1" :key="itemIndex + item.uuid" :inset="!hideListPictures" />
              </div>
            </template>
            <v-divider v-if="index < itemsByCategories.length - 1" :key="index + categoryName(category)" />
          </template>
        </v-list>
        <v-row id="privacy-policy" ref="privacy-policy">
          <v-col
            cols="12"
            class="text-center caption text--secondary"
            style="color: #1976d2 !important"
            @click="clickedShowPrivacyPolicyDialog"
          >
            {{ $t('privacyPolicy.read') }}
          </v-col>
        </v-row>
      </v-col>
      <div id="list-bottom" ref="list-bottom" />
    </v-row>

    <v-snackbar v-model="snack.show" :color="snack.type" :timeout="snack.timeout" style="bottom: 65px">
      <v-row justify="center">
        {{ snack.message }}
      </v-row>
    </v-snackbar>
    <privacy-policy-dialog v-if="showPrivacyPolicyDialog" v-model="showPrivacyPolicyDialog" @closed="privacyPolicyDialogClosed" />

    <v-dialog v-if="showDialog" id="notify-aware-dialog" :value="showDialog" scrollable persistent>
      <v-card>
        <v-card-title>
          {{ $t('pleaseBeAware') }}
        </v-card-title>
        <v-card-text>
          <div style="font-size: 16px; font-weight: 400" v-html="categoryDescription" />
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0">
            <v-row dense>
              <v-col cols="12">
                <v-btn id="notify-aware-dialog-not-agree" large text color="red" block @click="notAgree">
                  <span>
                    {{ $t('buttons.notAgree') }}
                  </span>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn id="notify-aware-dialog-agree" block large :color="primaryColor" dark @click="agree">
                  <span>
                    {{ $t('buttons.agree') }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import ListItem from './ListItem';
import LandingPage from '../views/LandingPage';
import SubCategoryLandingPage from '../views/SubCategoryLandingPage';
import BackButton from '@/components/BackButton';
import PrivacyPolicyDialog from '@/components/PrivacyPolicyDialog';
import LanguageSelection from '@/components/LanguageSelection';
import EventListItem from '@/components/item/EventListItem';

export default {
  name: 'Items',
  components: {
    EventListItem,
    LanguageSelection,
    PrivacyPolicyDialog,
    BackButton,
    SubCategoryLandingPage,
    LandingPage,
    ListItem,
  },
  data: () => ({
    loading: false,
    message: '',
    sessionToken: null,
    quantity: 1,
    showSnack: false,
    showDialog: false,
    addingToCart: [],
    //selectIndex: 0,
    appBarHeight: 0,
    appFooterHeight: 0,
    selectHeight: 0,
    appHeight: 0,
    showPrivacyPolicyDialog: false,
    snack: {
      show: false,
      type: null,
      message: '',
      timeout: 1000,
    },
  }),
  computed: {
    subCategoryDepth() {
      return this.$store.getters.subCategoryDepth;
    },
    showLocationClosedMessage() {
      return this.$store.state.showLocationClosedMessage;
    },
    locationIsOpen() {
      return this.$store.getters.locationIsOpen;
    },
    locationOpeningHours() {
      return this.$store.getters.locationOpeningHours;
    },
    agreedToAgeRestrictions() {
      if (this.localStorageIsAvailable()) {
        const key = `age-restriction:${this.organizationUUID}:${this.selectedCategory.uuid}`;
        const value = window.localStorage.getItem(key);
        const timeValue = window.localStorage.getItem(`${key}:time`);
        const currentTime = Date.now();

        if (!value) {
          return this.$store.state.agreedToAgeRestrictions;
        }

        if (!timeValue) {
          window.localStorage.removeItem(key);
          return this.$store.state.agreedToAgeRestrictions;
        }

        if (currentTime - timeValue >= 86400000) {
          window.localStorage.removeItem(key);
          window.localStorage.removeItem(`${key}:time`);
          return this.$store.state.agreedToAgeRestrictions;
        }

        return value;
      }
      return this.$store.state.agreedToAgeRestrictions;
    },
    categoryDescription() {
      if (this.selectedCategory !== null && this.selectedCategory !== undefined) {
        let description = this.translatedSelectedCategoryDescription;
        if (description !== null && description !== undefined) {
          description = description.replace(/<p> <\/p>\n/gi, '');
          // eslint-disable-next-line no-irregular-whitespace
          description = description.replace(/<p>NBSP<\/p>/gi, '');
          description = description.replace(/<p>&nbsp;<\/p>/gi, '');
          description = description.replace(/<div> <\/div>\n/gi, '');
          // eslint-disable-next-line no-irregular-whitespace
          description = description.replace(/<div> <\/div>/gi, '');
          description = description.replace(/<p>\s*<\/p>/gi, '');
          description = description.replace(/<div>\s*<\/div>/gi, '');
          description = description.replace(/<div>&nbsp;<\/div>/gi, '');
          description = description.replace(/\n/gi, '');
        }
        return description;
      }
      return null;
    },
    categoryHasDescription() {
      return this.categoryDescription !== null && this.categoryDescription !== undefined;
    },
    selectedPaymentMethod() {
      return this.$store.getters.selectedPaymentMethod;
    },
    paymentMethods() {
      return this.$store.getters.paymentMethods;
    },
    hideClosedCategories() {
      return this.$store.state.hideClosedCategories;
    },
    organizationName() {
      return this.$store.state.organization.label;
    },
    currentTree() {
      return this.$store.state.currentTreeLocation;
    },
    itemsByCategoryNew() {
      return this.$store.state.itemsByCategoriesNew;
    },
    buttonText() {
      if (this.currentTree && this.currentTree.length) {
        return this.$t('buttons.backToCategory', { category: this.itemsByCategoryNew[this.currentTree.slice(-1)].name });
      }
      return this.$t('buttons.backToLandingPage');
    },
    items() {
      return this.$store.getters.itemsByCategory(this.$store.state.selectedCategory);
      // if(this.itemsByCategories[this.selectIndex].hasSubCategories) {
      // 	return this.itemsBySubCategories[this.subCategoryIndex].items
      // } else {
      // 	return this.itemsByCategories[this.selectIndex].items
      // }
    },
    showSubCategoryMenu() {
      return this.$store.state.showSubCategoryMenu;
    },
    landingPageFromParams() {
      return this.$store.state.landingPageFromParams;
    },
    showLandingPage() {
      return this.$store.state.showLandingPage;
    },
    subCategoryIndex() {
      return this.$store.state.categorySubIndex;
    },
    organizationUUID() {
      return this.$store.state.organizationUUID;
    },
    selectIndex: {
      get() {
        return this.$store.state.categorySelectIndex;
      },
      set(category) {
        // Only calling this once, if show landing page is true
        if (this.showLandingPage) {
          this.$store.commit('updateShowLandingPage', false);
        }

        this.$store.commit('updateCategorySelectIndex', category);
        this.$store.commit('updateSelectedCategory', category);
        if (this.landingPageFromParams) {
          this.$store.commit('updateCurrentTreeLocation', category.route.slice());
        }

        // Update 2020-05-26: We have added an option to return to the landing page through the
        // dropdown, it is now the topmost value
        if (this.landingPageFromParams && category.value === '000') {
          this.$store.commit('updateShowLandingPage', true);
          this.$store.commit('updateSelectedCategory', null);
        }
        if (!category.isSubCategory && !category.hasSubCategories) {
          this.$store.commit('updateShowSubCategoryMenu', false);
        }
        if (
          this.landingPageFromParams &&
          category.hasSubCategories &&
          this.subCategoriesHaveItems(category) &&
          this.subCategoryFromParams
        ) {
          this.$store.commit('updateShowSubCategoryMenu', true);
        }
      },
    },
    hideListPictures() {
      return this.$store.state.hideListPictures;
    },
    subCategoryFromParams() {
      return this.$store.state.subCategoryFromParams;
    },
    categories() {
      return this.$store.state.categories;
    },
    graphUrl() {
      return this.$store.state.graphUrl;
    },
    itemsList() {
      return this.$store.state.itemsList;
    },
    organization() {
      return this.$store.state.organization;
    },
    channelUUID() {
      return this.$store.state.channelUUID;
    },
    itemsByCategories() {
      return this.$store.getters.itemsByCategories;
    },
    itemsBySubCategories() {
      return this.$store.getters.itemsBySubCategories;
    },
    itemsByCategoriesNew() {
      return this.$store.state.itemsByCategoriesNew;
    },
    categorySelect() {
      return this.$store.getters.getCategorySelectList;
    },
    itemsByCategoriesSelect() {
      return this.$store.getters.itemsByCategories
        .filter((category) => {
          if (!this.hideClosedCategories) {
            let returnCategory = true;

            if (category.opening_hours && category.opening_hours.length) {
              const oHours = this.$store.getters.openingHours(category);

              if (oHours && oHours.length) {
                oHours.forEach((openingHour) => {
                  if (openingHour && openingHour.openHour.milliseconds === openingHour.closedHour.milliseconds) {
                    returnCategory = false;
                  }
                });
              }
            }
            if (returnCategory) {
              return category;
            }
          } else {
            if (this.categoryIsOpen(category)) {
              return category;
            }
          }
        })
        .map((item) => {
          // let extra = null
          // if(item.hasSubCategories) {
          // 	extra = item.subCategories.map(s => {
          // 		return {
          // 			text: '- ' + s.name,
          // 			value: s.index
          // 		}
          // 	})
          // }
          let closed = false;
          if (!this.categoryIsOpen(item)) {
            closed = true;
          }
          return {
            text: item.name,
            value: item.uuid,
            disabled: closed,
          };
        });

      // if(this.landingPageFromParams) {
      // 	let a = [{
      // 		text: 'Forsíða',
      // 		value: 'landing',
      // 	}]
      // 	items = a.concat(items);
      // }
      //
      // return items
      //return this.$store.getters.itemsByCategories.map((item, index) => { return { text: item.name, value: index }})
    },
    showSinglePageCategories() {
      return this.$store.state.singlePageCategories;
    },
    primaryColor() {
      return this.$store.state.primaryColor;
    },
    accentColor() {
      return this.$store.state.accentColor;
    },
    primaryColorIsLight() {
      return this.$store.getters.colorIsLight(this.primaryColor);
    },
    selectedCategory() {
      return this.$store.state.selectedCategory;
    },
    translatedSelectedCategoryDescription() {
      return this.$store.getters.translateByType('category.description', this.selectedCategory);
    },
    selectedCategoryDescription() {
      let description = this.translatedSelectedCategoryDescription;
      if (description !== null && description !== undefined) {
        description = description.replace(/<p> <\/p>\n/gi, '');
        // eslint-disable-next-line no-irregular-whitespace
        description = description.replace(/<p>NBSP<\/p>/gi, '');
        description = description.replace(/<p>&nbsp;<\/p>/gi, '');
        description = description.replace(/<div> <\/div>\n/gi, '');
        // eslint-disable-next-line no-irregular-whitespace
        description = description.replace(/<div> <\/div>/gi, '');
        description = description.replace(/<p>\s*<\/p>/gi, '');
        description = description.replace(/<div>\s*<\/div>/gi, '');
        description = description.replace(/<div>&nbsp;<\/div>/gi, '');
        description = description.replace(/\n/gi, '');
      }
      return description;
    },
    selectedCategoryHasDescription() {
      return this.selectedCategoryDescription !== null && this.selectedCategoryDescription !== undefined;
    },
    organizationIsBarionOrMinigardurinn() {
      return this.$store.getters.organizationIsBarion || this.$store.getters.organizationIsMinigardurinn;
    },
  },
  watch: {
    items(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    selectedCategory(value) {
      const organizationUUIDs = [
        '61404407-7714-48f8-8194-be8cc9871737', // Duck and Rose
      ];

      const categoryUUIDs = [
        '5c0243ed-b1c0-46d4-befa-b7568127fbda', // Duck and Rose Drykkir
        'ac89aa16-58fe-49d7-9f61-3f8f039bbd86', // Duck and Rose Vín
      ];

      if (value && this.selectedCategoryHasDescription === true) {
        if (this.showLandingPage === false && this.showSubCategoryMenu === false) {
          if (this.agreedToAgeRestrictions === false) {
            if (this.organizationIsBarionOrMinigardurinn === true) {
              this.showDialog = true;
            }
          }
        }
        if (organizationUUIDs.includes(this.organizationUUID) && categoryUUIDs.includes(value.uuid)) {
          if (this.agreedToAgeRestrictions === false) {
            this.showDialog = true;
          }
        }
      }
    },
  },
  mounted() {
    this.getSessionToken();
    if (!this.landingPageFromParams && !this.singlePageCategories && this.categorySelect && this.categorySelect.length) {
      this.selectIndex = this.categorySelect[0];
    }

    if (!this.selectedPaymentMethod && this.paymentMethods.length > 0) {
      this.$store.commit('updateSelectedPaymentMethod', this.paymentMethods[0]);
    }

    // if(this.categoryHasDescription) {
    //   this.showDialog = true
    // }
  },
  methods: {
    checkIfCategoryHasProvidedAncestorCategory(category, providedCategoryUUIDs) {
      // This is not fully working yet

      // Base cases
      if (typeof category === 'undefined' || category === null) {
        return false;
      }
      if (!Array.isArray(category.parent) || category.parent.length < 1) {
        return false;
      }

      if (!Array.isArray(providedCategoryUUIDs) || providedCategoryUUIDs.length < 1) {
        return false;
      }
      if (category.parent.some((p) => p && providedCategoryUUIDs.includes(p.uuid))) {
        return true;
      }

      if (!Array.isArray(this.categorySelect) || this.categorySelect.length < 1) {
        return false;
      }

      for (let i = 0; i < category.parent.length; i++) {
        const categoryParent = category.parent[i];
        if (!categoryParent) {
          continue;
        }

        const foundCategory = this.categorySelect.find((c) => c && c.uuid === categoryParent.uuid);
        if (!foundCategory) {
          return false;
        }
        if (!foundCategory.parent) {
          return false;
        }

        if (!foundCategory.isSubCategory) {
          continue;
        }

        // Recursion
        this.checkIfCategoryHasProvidedAncestorCategory(foundCategory.parent, providedCategoryUUIDs);
      }
    },
    localStorageIsAvailable() {
      try {
        if (window.localStorage) {
          return true;
        }
      } catch (e) {
        return false;
      }
    },
    showComma(index) {
      return this.locationOpeningHours.length > 1 && index < this.locationOpeningHours.length - 2;
    },
    categoryName(category) {
      if (this.$te('category:name:' + category.uuid)) {
        return this.$t('category:name:' + category.uuid);
      }
      return this.$store.getters.translateByType('category.name', category);
    },
    agree() {
      if (this.localStorageIsAvailable()) {
        const key = `age-restriction:${this.organizationUUID}:${this.selectedCategory.uuid}`;
        window.localStorage.setItem(key, '1');
        window.localStorage.setItem(`${key}:time`, Date.now().toString());
      } else {
        this.$store.commit('setAgreedToAgeRestrictions', true);
      }
      this.showDialog = false;
    },
    notAgree() {
      if (this.$store.state.sawItems) {
        if (this.currentTree && this.currentTree.length) {
          this.$store.dispatch('goToPreviousCategory');
        } else {
          this.$store.commit('updateShowLandingPage', true);
          this.$store.commit('updateShowSubCategoryMenu', false);
          this.$store.commit('updateCategorySelectIndex', '000');
          this.$store.commit('updateSelectedCategory', null);
        }
      } else {
        const smallerTree = this.currentTree.slice(0, -1);
        if (smallerTree && smallerTree.length) {
          this.$store.dispatch('goToPreviousCategory');
        } else {
          this.$store.commit('updateShowLandingPage', true);
          this.$store.commit('updateShowSubCategoryMenu', false);
          this.$store.commit('updateCategorySelectIndex', '000');
          this.$store.commit('updateSelectedCategory', null);
        }
      }
      this.showDialog = false;
    },
    clickedShowPrivacyPolicyDialog() {
      this.showPrivacyPolicyDialog = !this.showPrivacyPolicyDialog;
    },
    privacyPolicyDialogClosed() {
      this.showPrivacyPolicyDialog = !this.showPrivacyPolicyDialog;
    },
    getItemsByCategory(category) {
      return this.$store.getters.itemsByCategory(category);
    },
    backToLandingPage() {
      if (this.currentTree && this.currentTree.length) {
        //this.$store.commit('updateShowSubCategoryMenu', true);
        this.$store.dispatch('goToPreviousCategory');
      } else {
        this.$store.commit('updateShowLandingPage', true);
        this.$store.commit('updateShowSubCategoryMenu', false);
        this.$store.commit('updateCategorySelectIndex', '000');
      }
    },
    backToCategoryLandingPage() {
      this.$store.commit('updateShowSubCategoryMenu', true);
    },
    categoryIsDisabled(category) {
      return this.$store.getters.categoryIsDisabled(category);
    },
    categorySelectItemsHeight() {
      return this.appHeight - this.appBarHeight - this.appFooterHeight - this.selectHeight - 20;
    },
    showItemInFull(item) {
      this.$store.commit('setCurrentItemInFull', item);
    },
    itemTranslatedDescriptionValue(item) {
      return this.$store.getters.translateByType('item.description.value', item);
    },
    itemDescription(item) {
      let html =
        '<span class="text--primary">' +
        this.$store.getters.currencyAsText({ amount: item.price.amount, currency_code: item.price.currency_code }) +
        '</span>';
      html += '&mdash; ';
      html += this.itemTranslatedDescriptionValue(item);

      return html;
    },
    checkImage(item) {
      if (
        !item.images ||
        !item.images.length ||
        !item.images[0] ||
        !item.images[0].file ||
        !item.images[0].file.sources ||
        !item.images[0].file.sources.thumbnail
      ) {
        return 'https://www.baconismagic.ca/wp-content/uploads/2019/01/lisbon-food-arroz-con-marisco.jpg';
      } else {
        return item.images[0].file.sources.thumbnail;
      }
    },
    addItemToCart(item) {
      this.$store.commit('addItemToCart', item);
    },
    getSessionToken() {
      let sessionToken = null;
      if (!this.inIframe() && this.localStorageIsAvailable()) {
        try {
          sessionToken = JSON.parse(window.localStorage.getItem('sessionToken'));
        } catch (e) {
          if (window.localStorage) {
            window.localStorage.removeItem('sessionToken');
          }
        }
      }
      this.sessionToken = sessionToken;
      this.$store.dispatch('updateSessionToken', sessionToken);
    },
    saveSessionToken(sessionToken) {
      if (!this.inIframe() && this.localStorageIsAvailable()) {
        window.localStorage.setItem('sessionToken', JSON.stringify(sessionToken));
      }
    },
    fetchSessionToken(resolve) {
      const graphOptions = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const url = process.env.VUE_APP_GRAPHQL_API_URL;

      const graphBody = {
        query: 'mutation { newAnonymousSession { session_token, ttl } }',
      };

      axios
        .post(url, graphBody, graphOptions)
        .then(
          function (response) {
            this.sessionToken = response.data.data.newAnonymousSession;
            this.$store.dispatch('updateSessionToken', response.data.data.newAnonymousSession);
            this.saveSessionToken(this.sessionToken);
          }.bind(this)
        )
        .catch(function () {}.bind(this))
        .finally(
          function () {
            resolve();
          }.bind(this)
        );
    },
    categoryIsOpen(category) {
      return this.$store.getters.categoryIsOpen(category);
    },
    subCategoriesHaveItems(category) {
      if (!category.hasSubCategories) {
        return false;
      }
      let hasItems = false;
      category.subCategories.forEach((cat) => {
        let c = this.$store.state.itemsByCategories[cat.uuid];
        if (c && c.items && c.items.length) {
          hasItems = true;
        }
      });
      return hasItems;
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
};
</script>

<style>
.salescloud-item-description p {
  display: inline;
}
</style>
