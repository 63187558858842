// TODO: Move product/item based store actions and getters here
export default {
	state: {
		itemLocationBlocks: [],
		hideItemsBlockedByLocation: false
	},
	mutations: {
		updateItemLocationBlocks(state, blocks) {
			state.itemLocationBlocks = blocks
		},
		setHideItemsBlockedByLocation(state, status) {
			state.hideItemsBlockedByLocation = status
		}
	},
	actions: {},
	getters: {
    itemImage: () => (itemImage, imageSource = 'original') => {
      if(typeof itemImage !== 'undefined' && itemImage !== null) {
        if(typeof itemImage.file !== 'undefined' && itemImage.file !== null) {
          if(typeof itemImage.file.sources !== 'undefined' && itemImage.file.sources !== null) {
            const { sources } = itemImage.file

            if(typeof sources[imageSource] !== 'undefined' && sources[imageSource] !== null) {
              return sources[imageSource]
            }
          }
        }
      }
      return ''
    },
		productSalePriceAmountOnly: (state, getters) => (item) => {
			if(getters.itemHasSalePrice(item)) {
				return item.sale_price.amount
			}
			return 0
		},
		productDefaultVariationsBasePrice: (state, getters) => (item) => {
			return getters.variationsBasePrice(getters.defaultVariations(item))
		},
		productDefaultVariationsWithSalePrice: (state, getters) => (item) => {
			return getters.variationsBaseIncludingSalePrice(getters.defaultVariations(item))
		},
		productBaseSalePrice: (state, getters) => (item) => {
			const productSalePrice = getters.productSalePriceAmountOnly(item)
			const variationsWithSalePrice = getters.productDefaultVariationsWithSalePrice(item)

			return productSalePrice + variationsWithSalePrice
		},
		itemLocationBlocks: (state) => {
			return state.itemLocationBlocks
		},
		hideItemsBlockedByLocation: (state) => {
			return state.hideItemsBlockedByLocation
		},
		productIsBlockedByLocation: (state, getters) => (product) => {
			if(!product) {
				return false
			}

			const location = getters.locationUUID
			if(!location) {
				return false
			}

			// const locationIsOpen = getters.locationIsOpen
      // if(!locationIsOpen) {
      //   return true
      // }

			const locationBlocks = getters.itemLocationBlocks
			if(!Array.isArray(locationBlocks)) {
				return false
			}

			const productUUID = product.uuid

			const itemBlocks = locationBlocks.filter(block => block.location === location && block.item === productUUID)
			return itemBlocks.length > 0
		}
	},
}
