<template>
  <v-container fluid>
    <v-row justify="center">
      <template v-for="category in categories">
        <v-col
          v-if="showCategoryBox(category)"
          :id="category.uuid"
          :ref="category.uuid"
          :key="category.uuid"
          :cols="colsSize(category)"
          class="grow d-flex"
        >
          <category-box
            :id="categoryName(category)"
            :category="category"
          />
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import CategoryBox from "./CategoryBox";
/** Shows a list of category boxes **/
export default {
	name: "CategoryListBox",
	components: {
       CategoryBox
    },
	props: {
		categories: {
            type: Array,
            default: () => []
        }
	},
	computed: {
		hideClosedCategories: function() {
			return this.$store.state.hideClosedCategories
		},
		itemsBySubCategories: function() {
			return this.$store.getters.itemsBySubCategories
		}
	},
	mounted() {
		// for(let i in this.$refs) {
		// 	console.log(this.$refs[i][0].clientHeight)
		// 	console.log(i)
		// }
	},
	methods: {
		categoryIsOpen: function(category) {
			return this.$store.getters.categoryIsOpen(category)
		},
		showCategoryBox: function(category) {
			if(!this.hideClosedCategories) {
				let show = true

				if(category.opening_hours && category.opening_hours.length) {
					const oHours = this.$store.getters.openingHours(category)

					if(oHours && oHours.length) {
						oHours.forEach(openingHour => {
							if(openingHour && openingHour.openHour.milliseconds === openingHour.closedHour.milliseconds) {
								show = false
							}
						})
					}
				}
				return show
			} else {
				return !!this.categoryIsOpen(category);
			}
		},
		colsSize: function(category) {
			// getWidth: function(categoryName) {
			// 	if(document.getElementById(categoryName).offsetWidth > 175 && this.$vuetify.breakpoint.name === 'xs' && this.$vuetify.breakpoint.name === 'sm') {
			// 		return '12'
			// 	} else {
			// 		return 'auto'
			// 	}
			// },
			// if(document.getElementById(category.uuid).offsetWidth > 200 && (this.$vuetify.breakpoint.name === 'xs')) {
			// 	return '12'
			// } else
			if(category.uuid === 'f07416f0-ef80-4a92-a994-7e9229cdbe0a') {
				return '12'
			} else if(this.categoryName(category).length > 12) {
				return '12'
			} else {
				return 'auto'
			}
		},
		categoryName(category) {
			return this.$store.getters.translateByType('category.name', category)
		},
	}
}
</script>

<style scoped>

</style>
