import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasError && !_vm.loading)?_c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,[_c(VAlert,{attrs:{"prominent":"","type":"error"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow",attrs:{"cols":"12","sm":"auto"}},[_c('div',[_vm._v(_vm._s(_vm.message))]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.subMessage)+" ")])]),_c(VCol,{staticClass:"shrink",attrs:{"cols":"12","sm":"auto"}},[_c(VBtn,{attrs:{"block":"","large":"","loading":_vm.loading},on:{"click":_vm.tryAgain}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('buttons.tryAgain'))+" ")])])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }