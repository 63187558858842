<template>
  <v-card
    tile
    flat
  >
    <v-card
      tile
      dark
      :color="primaryColor"
      flat
    >
      <v-card-title>
        {{ $t('coupon.activateCoupon') }}
        <v-spacer />
        <v-btn
          dark
          :color="accentColor"
          :disabled="isDisabled"
          @click="close"
        >
          <span>
            {{ $t('buttons.back') }}
          </span>
        </v-btn>
      </v-card-title>
    </v-card>
    <v-card-text>
      <div class="pb-4">
        <span v-if="!activeCoupon">{{ $t('coupon.noActive') }}</span>
        <div v-else>
          <div>{{ $t('coupon.active') }}:</div>
          <div class="font-weight-bold">
            {{ activeCoupon.code }}
          </div>
          <div class="font-italic">
            {{ activeCoupon.label }} {{ activeCoupon.description ? '- ' + activeCoupon.description : '' }} - {{ activeCoupon.percentOff }}% {{ $t('coupon.discountText') }}
          </div>
        </div>
      </div>
      <v-text-field
        v-model="couponFromInput"
        outlined
        :label="$t('coupon.title')"
        hide-details
        clearable
        @click:clear="clearCouponCode"
      />
      <div class="pt-4">
        <v-btn
          v-if="activeCoupon"
          block
          color="error"
          :loading="clearingCoupon"
          depressed
          @click="clearCouponCode"
        >
          <span>
            {{ $t('buttons.clearCouponCode') }}
          </span>
        </v-btn>
        <div
          v-if="activeCoupon && couponError"
          class="pb-1"
        />
        <v-alert
          v-if="couponError"
          type="error"
          class="mb-0"
          border="left"
        >
          {{ $t('coupon.errors.unableToApply') }}
        </v-alert>
      </div>
    </v-card-text>
    <v-btn
      tile
      block
      x-large
      dark
      height="65"
      :color="accentColor"
      :loading="processingCoupon"
      @click="checkForCoupon"
    >
      <span>
        {{ $t('buttons.confirm') }}
      </span>
    </v-btn>
  </v-card>
</template>

<script>
export default {
	name: "Coupon",
	data() {
		return {
			processingCoupon: false,
			clearingCoupon: false,
			couponError: false,
			couponCode: null,
		}
	},
	computed: {
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		isDisabled() {
			return this.processingCoupon || this.clearingCoupon
		},
		couponFromInput: {
			get() {
				return this.couponCode
			},
			set(value) {
				this.couponCode = value ? value.toUpperCase() : value
			}
		},
		activeCoupon() {
			return this.$store.getters.activeCoupon
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
		checkForCoupon() {
			this.processingCoupon = true
			this.$store.dispatch('getCoupon', this.couponCode).then(result => {
				this.couponError = !result;
				if(!this.couponError) {
					this.close()
				}
			}).finally(() => {
				this.processingCoupon = false
			})
		},
		clearCouponCode() {
			this.clearingCoupon = true
			this.couponCode = null
			this.$store.commit('setActiveCoupon', this.couponCode)
			this.couponError = false
			this.clearingCoupon = false
		}
	}
}
</script>

<style scoped>

</style>
