<template>
  <v-card
    class="text-center pt-10 pb-5 d-flex flex-column"
    tile
    flat
    height="50vh"
  >
    <v-container class="flex fill-height">
      <v-row align="center">
        <v-col>
          <div>
            <v-progress-circular
              indeterminate
              :color="primaryColor"
              size="48"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
	name: "ValidationInProgress",
	computed: {
		primaryColor() {
			return this.$store.getters.primaryColor
		}
	}
}
</script>

<style scoped>

</style>
