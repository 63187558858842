<template>
  <v-bottom-sheet
    v-if="showItemVariationSelection"
    v-model="showItemVariationSelection"
    scrollable
  >
    <v-card
      v-if="showItemVariationSelection"
      v-model="showItemVariationSelection"
      scrollable
      flat
      tile
    >
      <v-card-title>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row justify="space-between">
            <v-col
              cols="8"
              class="pb-0 text-break"
            >
              {{ itemTitle }}
            </v-col>
            <v-col
              cols="auto"
              class="pb-0 pl-0 text-end"
            >
              <v-btn
                dark
                block
                :color="accentColor"
                @click="closeDialog"
              >
                <span>
                  {{ $t('buttons.close') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="Array.isArray(selectedItemDescriptionValue) && selectedItemDescriptionValue.length > 0"
            class="text-break body-2"
          >
            <v-col cols="12">
              <div
                v-for="(text, textIndex) in selectedItemDescriptionValue"
                :key="textIndex"
              >
                {{ text }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-card-text v-if="itemHasVariations">
        <template v-for="variationCategory in itemVariationCategories">
          <div
            :key="variationCategory.uuid"
            class="py-2"
          >
            <VariationCategory
              :selected-variations="selectedVariations"
              :variation-category="variationCategory"
              @setSelectedVariations="setSelectedVariations"
              @handleVariationClick="(e) => { handleVariationClick(e, variationCategory) }"
            />
          </div>
        </template>
      </v-card-text>
      <v-card-text
        v-if="missingItemVariationSelection && showSinglePageVariations"
        class="error--text font-italic pt-0 pb-2"
      >
        {{ getErrorText() }}
      </v-card-text>
      <v-card-text
        v-if="needSelection"
        class="error--text"
      >
        {{ $t('error.needSelection') }}
      </v-card-text>
      <v-card-actions class="px-0 py-0">
        <v-btn
          v-if="!itemIsBlockedByLocation"
          color="success"
          x-large
          height="65"
          block
          tile
          :disabled="!canAddToOrder"
          :dark="canAddToOrder"
          @click="addItemToCart"
        >
          <span>{{ $t('buttons.add') }} {{ currencyAsText }}</span>
        </v-btn>
        <v-btn
          v-if="itemIsBlockedByLocation"
          color="success"
          disabled
          x-large
          height="65"
          block
          tile
          @click="addItemToCart"
        >
          <span>{{ $t('soldOut') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import VariationCategory from "@/components/item/variations/VariationCategory";

export default {
    name: "ItemVariationSelection",
    components: {
        VariationCategory
    },
    filters: {
        currency: function(value) {
            if(!value) { return value }
            if(typeof value !== 'number') {
                return value;
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
    },
    data() {
        return {
            selectedVariations: [],
            selectOneText: null,
            variationSelectIndex: 0,
            needSelection: false,
        }
    },
    computed: {
        eventsMode() {
          return this.$store.getters.eventsMode
        },
        canAddToOrder() {
          if(this.eventsMode === true) {
            if(this.itemHasVariations) {
              if(this.basePrice <= 0 && this.selectedVariations.length <= 0) {
                return false
              }
            }
          }
          return true
        },
        selectedItemDescriptionValue() {
            const description = this.$store.getters.translateByType('item.description.value', this.selectedItem)
            return this.$store.getters.pruneDescription(description)
        },
        currencyAsText() {
            let price = {
                amount: this.basePrice,
                currency_code: this.selectedItem.price.currency_code
            }

            return this.$store.getters.currencyAsText(price)
        },
        itemTitle() {
            return this.$store.getters.translateByType('item.title', this.selectedItem)
        },
        itemIsBlockedByLocation() {
            return this.$store.getters.productIsBlockedByLocation(this.selectedItem)
        },
        removeDefaultVariationsFromPrice: function() {
            return this.$store.state.removeDefaultVariationsFromPrice
        },
        isSelectDisabled: function() {
            return !!(this.itemVariationCategoriesSelect && this.itemVariationCategoriesSelect.length < 2)
        },
        missingItemVariationSelection: function() {
            return this.$store.state.missingItemVariationSelection
        },
        requiredCategoriesWithNoVariationsSelected: function() {
            return this.$store.state.itemVariationCategoriesNotSelected
        },
        categoryOnlyContainsOtherOrNone: function() {
            return this.$store.getters.categoryOnlyContainsOtherOrNone(this.itemVariationCategories)
        },
        showSinglePageVariations: function() {
            return this.$store.state.singlePageVariations
        },
        primaryColor() {
            return this.$store.state.primaryColor
        },
        accentColor() {
            return this.$store.state.accentColor
        },
        selectedItem() {
            return this.$store.state.selectedItem
        },
        itemHasVariations() {
            return this.selectedItem !== null && this.selectedItem !== undefined && Array.isArray(this.selectedItem.variations) && this.selectedItem.variations.length > 0
        },
        itemVariationCategories() {
            return this.$store.getters.itemVariationCategories(this.selectedItem)
        },
        itemVariationCategoriesSelect() {
            return this.itemVariationCategories.map((category, index) => {
                let categoryName = this.$store.getters.translateByType('category.name', category)
                return {
                    text: categoryName + (category.required ? ' *' : ''),
                    value: index
                }})
        },
        basePrice() {
            const item = this.$store.state.selectedItem
            let amount = 0, currency_code = 'ISK'

            // Base null checks
            if(typeof item === 'undefined' || item === null) {
              return {
                amount,
                currency_code
              }
            }

          // Setting the initial item amount
          if(typeof item.price !== 'undefined' && item.price !== null) {
            if(!isNaN(Number(item.price.amount))) {
              amount = Number(item.price.amount)
            }
            if(typeof item.price.currency_code !== 'undefined' && item.price.currency_code !== null) {
              currency_code = item.price.currency_code
            }
          }

          // Setting the sale price amount if applicable
          if(typeof item.sale_price !== 'undefined' && item.sale_price !== null) {
            if(!isNaN(Number(item.sale_price.amount)) && Number(item.sale_price.amount) > 0 && Number(item.sale_price.amount) < amount) {
              amount = Number(item.sale_price.amount)
            }
          }

          // Add the amount of the selected variations
          if(Array.isArray(this.selectedVariations)) {
            amount += this.selectedVariations.reduce((sum, variation) => {
              if(typeof variation !== 'undefined' && variation !== null) {
                if(typeof variation.sale_price !== 'undefined' && variation.sale_price !== null) {
                  if(!isNaN(Number(variation.sale_price.amount)) && Number(variation.sale_price.amount) > 0) {
                    return sum + Number(variation.sale_price.amount)
                  }
                }
                if(typeof variation.price !== 'undefined' && variation.price !== null) {
                  if(!isNaN(Number(variation.price.amount))) {
                    return sum + Number(variation.price.amount)
                  }
                }
              }
              return sum
            }, 0)

            const missingDefaultVariations = this.defaultVariations.filter(variation => variation && !this.selectedVariations.some(v => v && v.uuid === variation.uuid))
            if(!this.removeDefaultVariationsFromPrice && missingDefaultVariations.length > 0) {
              amount += missingDefaultVariations.reduce((sum, variation) => {
                if(typeof variation !== 'undefined' && variation !== null) {
                  if(typeof variation.sale_price !== 'undefined' && variation.sale_price !== null) {
                    if(!isNaN(Number(variation.sale_price.amount)) && Number(variation.sale_price.amount) > 0) {
                      return sum + Number(variation.sale_price.amount)
                    }
                  }
                  if(typeof variation.price !== 'undefined' && variation.price !== null) {
                    if(!isNaN(Number(variation.price.amount))) {
                      return sum + Number(variation.price.amount)
                    }
                  }
                }
                return sum
              }, 0)
            }
          }

            return amount
        },
        showItemVariationSelection: {
            get() {
                return this.$store.state.showSelectedItemVariationSelection
            },
            set(value) {
                this.$store.commit('updateShowSelectedItemVariationSelection', value)
            }
        },
        defaultVariations: function() {
            if(!this.selectedItem) { return [] }
            if(!this.selectedItem.default_variations) { return [] }
            return this.selectedItem.default_variations
        },
        getMaxHeightByScreenHeight: function() {
            if(screen.height <= 500) {
                return '25vh'
            } else if(screen.height < 700) {
                return '30vh'
            } else {
                return '45vh'
            }
        },
        variationLocationBlocks() {
          return this.$store.state.variationLocationBlocks
        }
    },
    watch: {
        showItemVariationSelection(value) {
          this.needSelection = false
          this.$store.commit('updateMissingVariationSelection', false)
          this.$store.commit('updateCategoriesNotSelected', [])
          if(value === false) {
            this.$store.commit('updateSelectedItem', null)
            this.$store.commit('updateMissingVariationSelection', false)
          }
        }
    },
    created() {
        if(Array.isArray(this.defaultVariations)) {
          for(let variationIndex = 0; variationIndex < this.defaultVariations.length; variationIndex++) {
            const variation = this.defaultVariations[variationIndex]
            const found = this.selectedItem.variations.find(vs => vs.uuid === variation.uuid)
            if(found) {
              if(Array.isArray(this.variationLocationBlocks) && this.variationLocationBlocks.some(block => block && block.variation === found.uuid)) {
                continue
              }
              this.selectedVariations.push(JSON.parse(JSON.stringify({...found, added: false, isDefault: true, removed: false})))
            }
          }
        }
    },
    mounted() {
      this.needSelection = false
      this.$store.commit('updateMissingVariationSelection', false)
      this.$store.commit('updateCategoriesNotSelected', [])
    },
    methods: {
        setSelectedVariations(newSelectedVariations) {
            this.selectedVariations = newSelectedVariations
        },
        variationLabel(variation) {
            return this.$store.getters.translateByType('variation.label', variation)
        },
        attributeAllowsMultipleQuantityOfSameVariation(attribute) {
            if(typeof attribute !== 'undefined' && attribute !== null) {
                return Boolean(attribute.multiple)
            }
            return false
        },
        currencyFormat(value) {
            return this.$store.getters.currencyFormat(value)
        },
        getErrorText() {
            let text = this.$t('error.variationSelection')
            if(Array.isArray(this.requiredCategoriesWithNoVariationsSelected) && this.requiredCategoriesWithNoVariationsSelected.length > 0) {
                text += this.requiredCategoriesWithNoVariationsSelected.map(c => c.name).join(', ')
            }
            return text
        },
        attributeMissing(attribute) {
            if(this.requiredCategoriesWithNoVariationsSelected && this.requiredCategoriesWithNoVariationsSelected.some(c => c.uuid === attribute.uuid)) {
                return 'error--text font-weight-bold'
            }
            return ''
        },
        validateItemSelectionBeforeAddingToCart: function(item) {
            return this.$store.getters.validateItemSelectionBeforeAddingToCart(item)
        },
        isDefaultVariation(variation) {
            return this.defaultVariations.some(v => v.uuid === variation.uuid);
        },
        handleVariationClick(variation, attribute) {
            if(attribute.single === true) {
                // If it is already selected, do nothing
                if(this.isSelected(variation)) {
                    return
                }

                this.variationsByAttribute(attribute).forEach(v => {
                    if(this.isSelected(v)) {
                        let i = this.selectedVariations.findIndex(vi => { return vi.uuid === v.uuid });
                        this.selectedVariations.splice(i, 1);
                    }
                });
                this.selectedVariations.push(variation);
                if(this.missingItemVariationSelection) {
                    let item = {
                        ...this.selectedItem,
                        selected_variations: this.selectedVariations
                    }
                    this.validateItemSelectionBeforeAddingToCart(item)
                }

                let requiredCats = this.itemVariationCategories.filter(cat => cat.required).filter(cat => !this.selectedVariations.some(c => c.attribute.uuid === cat.uuid))
                if(requiredCats && requiredCats.length) {
                    let firstSingle = requiredCats.find(cat => cat.single)
                    this.variationSelectIndex = this.itemVariationCategories.findIndex(cat => cat.uuid === firstSingle.uuid)
                } else {
                    this.needSelection = false
                }

            } else {
                this.needSelection = false
                if(!this.isSelected(variation)) {
                    if(!this.isDefaultVariation(variation)) {
                        this.selectedVariations.push(JSON.parse(JSON.stringify({...variation, added: true, isDefault: false, removed: false})));
                    } else {
                        this.selectedVariations[this.selectedVariations.findIndex(v => v.uuid === variation.uuid)].removed = false
                    }
                    this.selectOneText = null;
                    if(this.missingItemVariationSelection) {
                        let item = {
                            ...this.selectedItem,
                            selected_variations: this.selectedVariations
                        }
                        this.validateItemSelectionBeforeAddingToCart(item)
                    }
                } else {
                    let i = this.selectedVariations.findIndex(v => v.uuid === variation.uuid);
                    if(this.isDefaultVariation(variation)) {
                        this.selectedVariations[i].removed = true
                    } else {
                        this.selectedVariations.splice(i, 1);
                    }
                }
            }
        },
        variationsByAttribute(attribute) {
            if(this.categoryOnlyContainsOtherOrNone) {
                return this.selectedItem.variations
            }
            return this.$store.getters.variationsByAttribute(attribute).sort((f,s) => f.sorting_weight - s.sorting_weight).sort((a,b) => this.isDefaultVariation(b) - this.isDefaultVariation(a));
        },
        closeDialog() {
            this.$store.commit('updateSelectedItem', null);
            this.$store.commit('updateShowSelectedItemVariationSelection', false);
            this.$store.commit('updateMissingVariationSelection', false);
            this.$store.commit('updateCategoriesNotSelected', []);
        },
        addItemToCart() {
            // if((!this.defaultVariations || !this.defaultVariations.length) && (!this.selectedVariations || !this.selectedVariations.length)) {
            // 	this.needSelection = true
            // 	return
            // }

            let item = {
                ...this.selectedItem,
            }
            if(Array.isArray(this.selectedVariations) && this.selectedVariations.length > 0) {
                item.selected_variations = this.selectedVariations
            }

            if(this.validateItemSelectionBeforeAddingToCart(item)) {
                this.$store.commit('addItemToCart', JSON.parse(JSON.stringify(item)));
                this.$store.commit('updateShowSelectedItemVariationSelection', false);
            } else {
                this.needSelection = true
                if(Array.isArray(this.requiredCategoriesWithNoVariationsSelected) && this.requiredCategoriesWithNoVariationsSelected.length > 0) {
                    this.variationSelectIndex = this.itemVariationCategories.findIndex(category => category.uuid === this.requiredCategoriesWithNoVariationsSelected[0].uuid)
                }
            }
        },
        isSelected(variation) {
            if(!this.selectedVariations) { return }
            return this.selectedVariations.some(v => v.uuid === variation.uuid && !v.removed);
        },
    }
}
</script>

<style scoped>
</style>
