<template>
  <v-dialog
    scrollable
    :value="true"
    persistent
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-break">
        {{ $t('termsAndConditions.headerText') }}
      </v-card-title>
      <v-card-subtitle class="px-6" />
      <v-card-text>
        <div
          v-if="organization"
          id="organizationInfo"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('privacyPolicy.companyInformation') }}
          </div>
          <div>
            <span class="font-weight-bold">{{ $t('privacyPolicy.companyName') }}: </span>
            <span>{{ organization.label }}</span>
          </div>
          <div v-if="organization.ssn">
            <span class="font-weight-bold">{{ $t('privacyPolicy.companyId') }}: </span>
            <span>{{ organization.ssn }}</span>
          </div>
          <div v-if="organization.phone">
            <span class="font-weight-bold">{{ $t('privacyPolicy.companyPhone') }}: </span>
            <span>{{ organization.phone }}</span>
          </div>
          <div v-if="organization.email">
            <span class="font-weight-bold">{{ $t('privacyPolicy.companyEmail') }}: </span>
            <span>{{ organization.email.toLowerCase() }}</span>
          </div>
          <div v-if="organization.address">
            <span class="font-weight-bold">{{ $t('privacyPolicy.companyLocation') }}: </span>
            <span>{{ `${organization.address.thoroughfare}, ${organization.address.postal_code} ${organization.address.locality}` }}</span>
          </div>
        </div>
        <div
          id="general"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('termsAndConditions.general.header') }}
          </div>
          <div class="text-justify">
            {{ $t('termsAndConditions.general.text', {organizationName: organization.label}) }}
          </div>
        </div>
        <div
          id="goods"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('termsAndConditions.goodsAndServices.header') }}
          </div>
          <div class="text-justify">
            {{ $t('termsAndConditions.goodsAndServices.text') }}
          </div>
        </div>
        <div
          id="price"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('termsAndConditions.productPrice.header') }}
          </div>
          <div class="text-justify">
            {{ $t('termsAndConditions.productPrice.text') }}
          </div>
        </div>
        <div
          id="refund"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('termsAndConditions.refundPolicy.header') }}
          </div>
          <div class="text-justify">
            {{ $t('termsAndConditions.refundPolicy.text') }}
          </div>
        </div>
        <div
          id="privacy"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('privacyPolicy.header') }}
          </div>
          <div class="text-justify">
            {{ $t('privacyPolicy.text') }}
          </div>
        </div>
        <div
          id="laws"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            {{ $t('termsAndConditions.laws.header') }}
          </div>
          <div class="text-justify">
            {{ $t('termsAndConditions.laws.text') }}
          </div>
        </div>
        <div
          v-if="false"
          id="ecommerce"
          class="pb-4"
        >
          <div class="body-1 font-weight-bold pb-1">
            E-commerce
          </div>
          <div class="text-justify">
            E-commerce is provided by
            <a
              target="_blank"
              href="https://www.salescloud.is"
              rel="noopener noreferrer"
              style="text-decoration: none;"
            >
              SalesCloud
            </a>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          block
          :color="accentColor"
          dark
          @click="close"
        >
          <span>
            {{ $t('buttons.close') }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { version } from '../../package.json'

export default {
	name: "PrivacyPolicyDialog",
	computed: {
    version() {
      return version
    },
		primaryColor() {
			return this.$store.state.primaryColor
		},
		accentColor() {
			return this.$store.state.accentColor
		},
		organization() {
			return this.$store.state.organization
		}
	},
	methods: {
		close() {
			this.$emit('closed')
		}
	}
}
</script>

<style scoped>

</style>
