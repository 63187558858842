<template>
  <v-col
    v-if="field"
    :cols="field.cols || '12'"
  >
    <v-text-field
      v-if="field.type === 'number' && field.key !== 'cardSecurityCode'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      type="number"
      :name="field.key === 'cardNumber' ? 'cc-number' : ''"
      :autocomplete="field.key === 'cardNumber' ? 'cc-number' : ''"
      @input="fieldValueChange"
    />
    <v-text-field
      v-if="field.type === 'number' && field.key === 'cardSecurityCode'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      type="text"
      name="cc-csc"
      autocomplete="cc-csc"
      @input="fieldValueChange"
    />
    <v-text-field
      v-if="field.type === 'textfield'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      @input="fieldValueChange"
    />
    <v-text-field
      v-if="field.type === 'text'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      @input="fieldValueChange"
    />
    <v-text-field
      v-if="field.type === 'email'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      @input="fieldValueChange"
    />
    <v-text-field
      v-if="field.type === 'phone'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      @input="fieldValueChange"
    />
    <v-select
      v-if="field.type === 'select'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :items="field.options"
      item-value="key"
      item-text="label"
      :multiple="field.multiple"
      :disabled="paymentInProgress"
      :name="field.key === 'expiryMonth' ? 'cc-exp-month' : ( field.key === 'expiryYear' ? 'cc-exp-year' : '')"
      :autocomplete="field.key === 'expiryMonth' ? 'cc-exp-month' : ( field.key === 'expiryYear' ? 'cc-exp-year' : '')"
      @input="fieldValueChange"
    />
    <v-autocomplete
      v-if="field.type === 'autocomplete'"
      outlined
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :placeholder="field.placeholder"
      :hint="field.hint"
      :value="field.value"
      :items="field.options"
      item-value="key"
      item-text="label"
      :multiple="field.multiple"
      :disabled="paymentInProgress"
      @input="fieldValueChange"
    />
    <v-switch
      v-if="field.type === 'switch'"
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :hint="field.hint"
      :value="field.value"
      :disabled="paymentInProgress"
      @change="fieldValueChange"
    />
    <v-checkbox
      v-if="field.type === 'checkbox'"
      :label="$t(field.label)"
      :rules="field.required ? [rules.required] : []"
      hide-details="auto"
      :hint="field.hint"
      :input-value="field.value"
      :disabled="paymentInProgress"
      v-model="fieldValue"
      class="mt-0"
    />
    <div
      v-if="(field.type === 'html' || field.type === 'vhtml') && (typeof field.value !== 'undefined' && field.value !== null)"
      v-html="field.value"
    />
  </v-col>
</template>

<script>
export default {
  name: "UiField",
  props: {
    field: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showCode: false
    }
  },
  computed: {
    paymentInProgress: function () {
      return this.$store.state.paymentInProgress
    },
    rules() {
      return this.$store.getters.rules
    },
    number: {
      get() {
        if (this.field.value) {
          return this.field.value.replace(/\D/g, '')
        }
        return this.field.value
      },
      set(value) {
        this.fieldValueChange(value)
      }
    },
    fieldValue: {
      get() {
        return this.field.value
      },
      set(value) {
        if(typeof value === 'string') {
          value = this.$store.getters.pruneEmojisFromText(value)
        }
        this.fieldValueChange(value)
      }
    }
  },
  mounted() {
    if (this.field && this.field.key === 'customerUUID') {
      this.fieldValueChange('7a28a439-c3c6-419f-a5fa-b5a6e3f14517')
    }
  },
  methods: {
    fieldValueChange(value) {
      this.$emit('fieldValueChange', { value: value, key: this.field.key })
    }
  }
}
</script>

<style scoped>

</style>
