import axios from "axios";

/**
 * @property bookingsByOrder
 * @property externalDistributionUrl
 */
export default {
    state: {
        eventsMode: false,
        orderBookings: [],
        gettingOrderBookings: false,
        millisecondsUntilNextCheck: 2000,
        maxCountOfCheckTries: 30
    },
    mutations: {
        setEventsMode(state, status) {
            state.eventsMode = status
        },
        updateGettingOrderBookings(state, status) {
            state.gettingOrderBookings = status
        },
        updateOrderBookings(state, bookings) {
            state.orderBookings = bookings
        },
    },
    actions: {
        getOrderBookings: ({state, commit, getters, dispatch}, counter) => {
            const paymentResponse = getters.paymentResponse
            if(paymentResponse === null || typeof paymentResponse === 'undefined') {
                return
            }
            if(paymentResponse.paymentTransaction === null || typeof paymentResponse.paymentTransaction === 'undefined') {
                return
            }
            const entangleKey = paymentResponse.paymentTransaction.entangle_key
            let orderUUID = null
            try {
                orderUUID = entangleKey.split(':')
                orderUUID = orderUUID[0]
            } catch (e) {
                //
            }

            const body = {
                query: 'query {\n' +
                    '   bookingsByOrder(organization: "' + getters.organizationUUID + '", order: "' + orderUUID + '") {\n' +
                    '       uuid\n' +
                    '       booking_id\n' +
                    '       description\n' +
                    '       quantity\n' +
                    '       state\n' +
                    '       startsAtTime\n' +
                    '       endsAtTime\n' +
                    '       externalBookingId\n' +
                    '       externalDistributionUrl\n' +
                    '       item {\n' +
                    '           uuid\n' +
                    '           title\n' +
                    '           description {\n' +
                    '               value\n' +
                    '               summary\n' +
                    '           }\n' +
                    '           images {\n' +
                    '               file {\n' +
                    '                   sources {\n' +
                    '                       medium\n' +
                    '                   }\n' +
                    '               }\n' +
                    '           }\n' +
                    '       }\n' +
                    '       location {\n' +
                    '           uuid\n' +
                    '           label\n' +
                    '       }\n' +
                    '   }\n' +
                    '}'
            }

            return axios.post(getters.graphUrl, body).then(result => {

                // Base case for recursive result
                if (result && result.data && result.data.data) {
                    const bookingsByOrderResult = result.data.data.bookingsByOrder
                    if (Array.isArray(bookingsByOrderResult) && bookingsByOrderResult.length > 0) {
                        // Special case for BClub/B Reykjavík
                        if (getters.organizationUUID !== 'c9632662-ce96-4261-91ee-eef98f5e9223'
                            || (getters.organizationUUID === 'c9632662-ce96-4261-91ee-eef98f5e9223'
                                && bookingsByOrderResult.every(booking => booking.externalDistributionUrl !== null && typeof booking.externalDistributionUrl !== 'undefined'))) {
                            commit('updateOrderBookings', bookingsByOrderResult)
                            commit('updateGettingOrderBookings', false)
                            return bookingsByOrderResult
                        }
                    }
                }

                // Secondary base case for recursive result
                // 30 * 2000 = 60000 => 1 minute in milliseconds
                if (counter >= state.maxCountOfCheckTries) {
                    commit('updateOrderBookings', [])
                    commit('updateGettingOrderBookings', false)
                    return []
                }

                // Recursive call
                setTimeout(() => {
                    const nextCounter = counter + 1
                    return dispatch('getOrderBookings', nextCounter).then(res => res)
                }, state.millisecondsUntilNextCheck)
            })
        },
    },
    getters: {
        eventsMode: (state) => {
            return state.eventsMode
        },
        gettingOrderBookings: (state) => {
            return state.gettingOrderBookings
        },
        orderBookings: (state) => {
            return state.orderBookings
        }
    }
}
